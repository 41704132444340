import {
  getConfigurationThunk,
  getCredScansThunk,
  getNetworkThunk,
  getPreferencesThunk,
  getScannersThunk,
  getScansThunk,
  getSchedulesThunk,
  getSensorStatusThunk,
} from './Global/scannerActions';
import {
  getAssetDataThunk,
  getAssetsThunk,
  getRiskFieldSummaries,
} from './Global/assetActions';
import { getCasesThunk } from './Global/caseActions';
import { Dispatch, iState } from './configureStore';
import { getUsersThunk } from './Global/userActions';
import { setupCustomerThunk } from './Global/customerActions';
import { loadStatsThunk, StatActionType } from './Global/statActions';
import { RouteObject } from 'redux-first-router';
import { CaseStatus } from './types/cases';
import { IsAWNUser } from './utils/auth/auth';
import { RsNotificationError } from './utils';
import { RSAuthenticate } from './utils/auth/authentication';

const commonCalls = () => (dispatch: Dispatch) => {
  dispatch(loadStatsThunk(StatActionType.LOAD_WEEKLY_STATS));
};

export enum RoutesKey {
  OVERVIEW = 'OVERVIEW',
  CASE_MANAGEMENT = 'CASE_MANAGEMENT',
  SCANNER_CONFIG = 'SCANNER_CONFIG',
  RISKS = 'RISKS',
  RISK_DETAILS = 'RISK_DETAILS',
  ASSETS = 'ASSETS',
  ATTACK_SURFACE_COVERAGE = 'ATTACK_SURFACE_COVERAGE',
  ASSET_DETAILS = 'ASSET_DETAILS',
  LEGACY_RISKS = 'LEGACY_RISKS',
  LEGACY_ASSETS = 'LEGACY_ASSETS',
  LEGACY_ASSET_DETAILS = 'LEGACY_ASSET_DETAILS',
  REACH_GUIDES = 'REACH_GUIDES',
}

// an altered version of the interface `RoutesMap` provided by redux-first-router
// it is more specific to our configuration, with known keys and known value schemas
type iRoutesMap = {
  [key in RoutesKey]: RouteObject<{}, iState>;
};

export const routesMap: iRoutesMap = {
  [RoutesKey.OVERVIEW]: {
    path: '/',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
      dispatch(loadStatsThunk(StatActionType.LOAD_ALL_STATS));
      dispatch(getAssetsThunk());
      dispatch(getAssetDataThunk({ data: false }));
    },
  },
  [RoutesKey.ASSETS]: {
    path: '/assets',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
      dispatch(getAssetsThunk());
      // dispatch(getAssetActionsDataThunk({ data: false })); // Not in use for now, It will be used for commented filter-asset by categoty feature.
    },
  },
  [RoutesKey.ASSET_DETAILS]: {
    path: '/asset-details',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
      dispatch(getAssetsThunk());
    },
  },
  [RoutesKey.CASE_MANAGEMENT]: {
    path: '/case',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
      dispatch(getAssetDataThunk());
      dispatch(getUsersThunk());
      dispatch(
        getCasesThunk(
          { status: CaseStatus.Open },
          {
            source: ['sensor', 'reach', 'agent'],
          },
          true
        )
      );
    },
  },
  [RoutesKey.SCANNER_CONFIG]: {
    path: '/config',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(getNetworkThunk());
      dispatch(getScansThunk());
      dispatch(getSchedulesThunk());
      dispatch(getPreferencesThunk());
      dispatch(getCredScansThunk());
      dispatch(getSensorStatusThunk());
      dispatch(getConfigurationThunk());
      dispatch(commonCalls());
    },
  },
  [RoutesKey.RISKS]: {
    path: '/risks',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
      dispatch(getAssetDataThunk());
      dispatch(getAssetsThunk());
      dispatch(getRiskFieldSummaries({ groupBy: 'actionClass' }));
      dispatch(getCasesThunk({ status: CaseStatus.Open }));
      dispatch(getUsersThunk());
      dispatch(getScannersThunk());
    },
  },
  [RoutesKey.RISK_DETAILS]: {
    path: '/risk-details',
    thunk: async (dispatch, getState) => {
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
    },
  },
  [RoutesKey.ATTACK_SURFACE_COVERAGE]: {
    path: '/attack-surface-coverage',
    thunk: async (dispatch, getState) => {
      if (!IsAWNUser()) return dispatch({ type: 'OVERVIEW' });
      await setupCustomerThunk(dispatch, getState);
      dispatch(commonCalls());
    },
  },
  // TODO: Does this route still get used?
  [RoutesKey.REACH_GUIDES]: {
    path: '/reach-guides',
    thunk: async (dispatch) => {
      RSAuthenticate()
        .then(() => {
          const urlParams = new URLSearchParams(window.location.search);
          const id = urlParams.get('id');
          let pod = localStorage.getItem('apiPod') ?? '';
          if (pod === 'n/a') pod = 'us001-prod';
          if (id && /^(au|us|ca|eu)00\d-prod$/.test(pod)) {
            window.location.replace(
              `https://services.risk.${pod}.arcticwolf.net/rootsecure/reach/v1/guides/${id}`
            );
          } else {
            // if id is not provided, direct back to base url
            return dispatch({ type: 'OVERVIEW' });
          }
        })
        .catch((err) => {
          RsNotificationError(err);
        });
    },
  },
  [RoutesKey.LEGACY_RISKS]: {
    path: '/risk-view.html',
    thunk: (dispatch, getState) => {
      const { location } = getState();
      const query = location.query || {};
      return dispatch({ type: 'RISKS', query });
    },
  },
  [RoutesKey.LEGACY_ASSETS]: {
    path: '/asset-view.html',
    thunk: (dispatch, getState) => {
      const { location } = getState();
      const query = location.query || {};
      return dispatch({ type: 'ASSETS', query });
    },
  },
  [RoutesKey.LEGACY_ASSET_DETAILS]: {
    path: '/asset-details.html',
    thunk: (dispatch, getState) => {
      const { location } = getState();
      const query = location.query || {};
      return dispatch({ type: 'ASSET_DETAILS', query });
    },
  },
};
