import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Text,
  Dialog,
  HStack,
  Primitive,
  VStack,
  Switch,
  colors,
} from '@rtkwlf/fenrir-react';
import { faFlag } from '@rtkwlf/fontawesome-pro-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FlagItem = {
  label: string;
  key: string;
};

type FeatureFlagsViewProps = {
  flags: FlagItem[];
};

type StateType = {
  [key: string]: boolean;
};

const getFlags = (flags: FlagItem[]): StateType => {
  const temp: StateType = {};

  flags.forEach(({ key }) => {
    // temp[key] = window.localStorage.getItem(key) === 'true';
    if (window.localStorage.getItem(key) === 'true') {
      temp[key] = true;
    } else {
      temp[key] = false;
    }
  });

  return temp;
};

export const FeatureFlagsView: React.FC<FeatureFlagsViewProps> = ({
  flags,
}) => {
  const [flagStates, setFlagStates] = useState<StateType>(getFlags(flags));

  const handleCheckChange = useCallback(
    (key: string) => (isChecked?: boolean) => {
      if (isChecked) {
        const newFlagStates = { ...flagStates };
        newFlagStates[key] = true;
        setFlagStates(newFlagStates);
        window.localStorage.setItem(key, 'true');
      } else {
        const newFlagStates = { ...flagStates };
        newFlagStates[key] = false;
        setFlagStates(newFlagStates);
        window.localStorage.setItem(key, 'false');
      }
    },
    [flagStates]
  );

  return (
    <Primitive.div position='fixed' bottom='4rem' left='1rem' zIndex={2}>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Primitive.span>
            <FontAwesomeIcon
              icon={faFlag as IconProp}
              size='2x'
              color={`rgb(${colors.icon.info.default})`}
              style={{ cursor: 'pointer' }}
            />
          </Primitive.span>
        </Dialog.Trigger>
        <Dialog.Content>
          <Dialog.Header>
            <Dialog.HeaderTitle>Supported Flags</Dialog.HeaderTitle>
            <Dialog.HeaderDescription>
              <VStack paddingTop='default' paddingBottom='default'>
                {flags.map(({ key, label }) => (
                  <HStack key={key} xAlign='between' width='full'>
                    <Text>{label}</Text>
                    <Switch.Root
                      marginLeft='large'
                      aria-label={label}
                      isChecked={flagStates[key]}
                      onCheckedChange={handleCheckChange(key)}
                    >
                      <Switch.Thumb />
                    </Switch.Root>
                  </HStack>
                ))}
              </VStack>
            </Dialog.HeaderDescription>
          </Dialog.Header>
          <Dialog.Close />
        </Dialog.Content>
      </Dialog.Root>
    </Primitive.div>
  );
};
