import { AGENT_OS } from '../../../../Constants/Assets';
import { CSVDataListType } from '../../../../types/assets';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  AllParsedAudit,
  MacWirelessDataType,
  WindowsWirelessDataType,
  LinuxWirelessType,
  WindowsSoftware,
  MacSoftware,
  LinuxSoftware,
} from './parser';
import { RsCommafyInt } from '../utils';

const escapeVal = (value: string) => {
  let str = value;
  if (
    str !== null &&
    str !== undefined &&
    typeof str === 'string' &&
    str !== ''
  ) {
    str = str.replace(/"/g, '""');
    str = str.replace(/[\r\n\f\v]/g, '\n');
  }
  return '"' + str + '"';
};

const parseRawData = (objArr: string | Record<string, any>) => {
  const arr = typeof objArr != 'object' ? JSON.parse(objArr) : objArr;
  let str = '';

  for (let i = 0; i < arr.length; i++) {
    let line = '';
    for (const index in arr[i]) {
      if (line !== '') {
        line = line + ',';
      }
      line = line + arr[i][index];
    }
    str = str + line + '\r\n';
  }
  return str;
};

const getLocalTimeNoSpace = () => {
  const local = moment().local();
  return moment(local).format('YYYY-MM-DD_HH-mm-ss_[[]ZZ[]]');
};

export const initScoutAuditsCSV = (parsedAudit: AllParsedAudit) => {
  const csvDataList: CSVDataListType[] = [];

  const taskCSVData = generateDataForTaskList(parsedAudit);
  if (taskCSVData) {
    csvDataList.push(taskCSVData);
  }

  const wirelessNetwokCSVData = generateDataForWirelessNetwork(parsedAudit);
  if (wirelessNetwokCSVData) {
    csvDataList.push(wirelessNetwokCSVData);
  }

  const usbDevicesCSVData = generateDataForUsbDevices(parsedAudit);
  if (usbDevicesCSVData) {
    csvDataList.push(usbDevicesCSVData);
  }

  const softwarePackageCSVData = generateDataForSoftwarePackage(parsedAudit);
  if (softwarePackageCSVData) {
    csvDataList.push(softwarePackageCSVData);
  }

  if (csvDataList.length > 0) {
    addToZip(csvDataList);
  }
};

const generateDataForTaskList = (parsedAudit: AllParsedAudit) => {
  const localTime = getLocalTimeNoSpace();
  const fileName = `task-list_${localTime}.csv`;
  const taskListDataArr = [];

  if (parsedAudit?.os === AGENT_OS.WINDOWS) {
    const taskListData = parsedAudit?.taskList.data;

    const headers = {
      name: 'Name',
      processId: 'Process ID',
      handleCount: 'Handle Count',
      workingSetSize: 'Working Set Size',
      sessionId: 'Session ID',
      threadCount: 'Thread Count',
      priority: 'Priority',
    };

    taskListDataArr.push(headers);

    taskListData.forEach((task) => {
      taskListDataArr.push({
        name: escapeVal(task.processName),
        processId: escapeVal(task.pid),
        handleCount: escapeVal(task.handleCount),
        workingSetSize: escapeVal(RsCommafyInt(task.workingSetSize)),
        sessionId: escapeVal(task.sessionId),
        threadCount: escapeVal(task.threadCount),
        priority: escapeVal(task.priority),
      });
    });

    const jsonObj = JSON.stringify(taskListDataArr);
    const taskCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return taskCSVData;
  } else if (parsedAudit?.os === AGENT_OS.MACOS) {
    const taskListData = parsedAudit?.taskList.data;
    const headers = {
      tt: 'TT',
      pid: 'PID',
      rss: 'RSS',
      vsz: 'VSZ',
      pcpu: 'PCPU',
      pmem: 'PMEM',
      ppid: 'PPID',
      stat: 'STAT',
      time: 'Time',
      command: 'Command',
    };

    taskListDataArr.push(headers);

    taskListData.forEach((task) => {
      taskListDataArr.push({
        tt: escapeVal(task.tt),
        pid: escapeVal(task.pid),
        rss: escapeVal(task.rss),
        vsz: escapeVal(task.vsz),
        pcpu: escapeVal(task.pcpu),
        pmem: escapeVal(task.pmem),
        ppid: escapeVal(task.ppid),
        stat: escapeVal(task.stat),
        time: escapeVal(task.time),
        command: escapeVal(task.command),
      });
    });

    const jsonObj = JSON.stringify(taskListDataArr);
    const taskCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return taskCSVData;
  } else if (parsedAudit?.os === AGENT_OS.LINUX) {
    const taskListData = parsedAudit?.taskList.data;
    const headers = {
      tt: 'TT',
      pid: 'PID',
      rss: 'RSS',
      vsz: 'VSZ',
      pcpu: 'PCPU',
      pmem: 'PMEM',
      ppid: 'PPID',
      stat: 'STAT',
      time: 'Time',
      command: 'Command',
      started: 'Started',
    };

    taskListDataArr.push(headers);

    taskListData.forEach((task) => {
      taskListDataArr.push({
        tt: escapeVal(task.tt),
        pid: escapeVal(task.pid),
        rss: escapeVal(task.rss),
        vsz: escapeVal(task.vsz),
        pcpu: escapeVal(task.pcpu),
        pmem: escapeVal(task.pmem),
        ppid: escapeVal(task.ppid),
        stat: escapeVal(task.stat),
        time: escapeVal(task.time),
        command: escapeVal(task.command),
        started: escapeVal(task.started ?? ''),
      });
    });

    const jsonObj = JSON.stringify(taskListDataArr);
    const taskCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return taskCSVData;
  }
};

const generateDataForWirelessNetwork = (parsedAudit: AllParsedAudit) => {
  const localTime = getLocalTimeNoSpace();
  const fileName = `wireless-networks_${localTime}.csv`;
  const wirelessNetworkDataArr = [];

  if (parsedAudit?.os === AGENT_OS.WINDOWS) {
    const wirelessNetworkData = parsedAudit?.wirelessNetwork.data;
    const headers = {
      name: 'Name',
      networkType: 'Network Type',
      authentication: 'Authentication',
      encryption: 'Encryption',
    };
    wirelessNetworkDataArr.push(headers);

    if (wirelessNetworkData) {
      wirelessNetworkData.forEach((wirelessNetworkObj) => {
        const wirelessArr = wirelessNetworkObj as WindowsWirelessDataType;

        wirelessNetworkDataArr.push({
          name: escapeVal(wirelessArr.name),
          networkType: escapeVal(wirelessArr.networkType),
          authentication: escapeVal(wirelessArr.authentication),
          encryption: escapeVal(wirelessArr.encryption),
        });
      });
    }

    const jsonObj = JSON.stringify(wirelessNetworkDataArr);
    const wirelessNetworkCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return wirelessNetworkCSVData;
  } else if (parsedAudit?.os === AGENT_OS.MACOS) {
    const wirelessNetworkData = parsedAudit?.wirelessNetwork.data;
    const headers = {
      ssidname: 'SSID Name',
      security: 'Security',
      iscurrent: 'Is Current',
      mode: 'Mode',
      bssid: 'BSSID',
      noise: 'Noise',
      signal: 'Signal',
      channel: 'Channel',
      country: 'Country',
      networktype: 'Network Type',
      transmitrate: 'Transmit Rate',
      mcsindex: 'MCS Index',
    };

    wirelessNetworkDataArr.push(headers);

    if (wirelessNetworkData) {
      wirelessNetworkData.forEach((wirelessNetworkObj) => {
        const wirelessArr = wirelessNetworkObj as MacWirelessDataType;

        wirelessNetworkDataArr.push({
          ssidname: escapeVal(wirelessArr.ssidname),
          security: escapeVal(wirelessArr.security),
          iscurrent: escapeVal(wirelessArr.iscurrent),
          mode: escapeVal(wirelessArr.mode),
          bssid: escapeVal(wirelessArr.bssid),
          noise: escapeVal(wirelessArr.noise),
          signal: escapeVal(wirelessArr.signal),
          channel: escapeVal(wirelessArr.channel),
          country: escapeVal(wirelessArr.country),
          networktype: escapeVal(wirelessArr.networktype),
          transmitrate: escapeVal(wirelessArr.transmitrate),
          mcsindex: escapeVal(wirelessArr.mcsindex),
        });
      });
    }

    const jsonObj = JSON.stringify(wirelessNetworkDataArr);
    const wirelessNetworkCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return wirelessNetworkCSVData;
  } else if (parsedAudit?.os === AGENT_OS.LINUX) {
    const wirelessNetworkData = parsedAudit?.wirelessNetwork.data;
    const headers = {
      message: 'Message',
      networks: 'Networks',
    };
    wirelessNetworkDataArr.push(headers);

    if (wirelessNetworkData) {
      wirelessNetworkData.forEach((wirelessNetworkObj) => {
        const wirelessArr = wirelessNetworkObj as LinuxWirelessType;

        wirelessNetworkDataArr.push({
          message: escapeVal(wirelessArr.message),
          networks: escapeVal(wirelessArr.networks),
        });
      });
    }

    const jsonObj = JSON.stringify(wirelessNetworkDataArr);
    const wirelessNetworkCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return wirelessNetworkCSVData;
  }
};

const generateDataForUsbDevices = (parsedAudit: AllParsedAudit) => {
  const localTime = getLocalTimeNoSpace();
  const fileName = `usb-devices_${localTime}.csv`;
  const usbDevicesDataArr = [];

  if (parsedAudit?.os === AGENT_OS.WINDOWS) {
    const usbDevicesData = parsedAudit?.usbDevices.data;
    const headers = {
      name: 'Name',
      deviceId: 'Device ID',
      status: 'Status',
    };
    usbDevicesDataArr.push(headers);

    usbDevicesData.forEach((usbDevicesObj) => {
      usbDevicesDataArr.push({
        name: escapeVal(usbDevicesObj.name),
        deviceId: escapeVal(usbDevicesObj.deviceID),
        status: escapeVal(usbDevicesObj.status),
      });
    });

    const jsonObj = JSON.stringify(usbDevicesDataArr);
    const usbDevicesCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return usbDevicesCSVData;
  } else if (parsedAudit?.os === AGENT_OS.MACOS) {
    const usbDevicesData = parsedAudit?.usbDevices.data;
    const headers = {
      name: 'Name',
      manufacturer: 'Manufacturer',
      speed: 'Speed',
      version: 'Verison',
      vendorid: 'Vendor ID',
      productid: 'Product ID',
      serialnumber: 'Serial Number',
    };

    usbDevicesDataArr.push(headers);

    usbDevicesData.forEach((usbDevicesObj) => {
      usbDevicesDataArr.push({
        name: escapeVal(usbDevicesObj.name ?? ''),
        manufacturer: escapeVal(usbDevicesObj.manufacturer ?? ''),
        speed: escapeVal(usbDevicesObj.speed ?? ''),
        version: escapeVal(usbDevicesObj.version ?? ''),
        vendorid: escapeVal(usbDevicesObj.vendorId ?? ''),
        productid: escapeVal(usbDevicesObj.productId ?? ''),
        serialnumber: escapeVal(usbDevicesObj.serialNumber ?? ''),
      });
    });

    const jsonObj = JSON.stringify(usbDevicesDataArr);
    const usbDevicesCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return usbDevicesCSVData;
  } else if (parsedAudit?.os === AGENT_OS.LINUX) {
    const usbDevicesData = parsedAudit?.usbDevices.data;

    const headers = {
      name: 'Name',
      device: 'Device',
      deviceId: 'Device ID',
      bus: 'Bus',
    };
    usbDevicesDataArr.push(headers);

    usbDevicesData.forEach((usbDevicesObj) => {
      usbDevicesDataArr.push({
        name: escapeVal(usbDevicesObj.name),
        device: escapeVal(usbDevicesObj.device),
        deviceId: escapeVal(usbDevicesObj.id),
        bus: escapeVal(usbDevicesObj.bus),
      });
    });

    const jsonObj = JSON.stringify(usbDevicesDataArr);
    const usbDevicesCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return usbDevicesCSVData;
  }
};

const generateDataForSoftwarePackage = (parsedAudit: AllParsedAudit) => {
  const localTime = getLocalTimeNoSpace();
  const fileName = `software-packages_${localTime}.csv`;
  const softwarePackageDataArr = [];

  if (parsedAudit?.os === AGENT_OS.WINDOWS) {
    const softwarePackageData = parsedAudit?.software.data;

    const headers = {
      name: 'Name',
      version: 'Version',
      vendor: 'Vendor',
      installed: 'Installed',
      installLoc: 'Install Location',
      installSrc: 'Install Source',
    };

    softwarePackageDataArr.push(headers);

    if (softwarePackageData) {
      (softwarePackageData as WindowsSoftware[]).forEach((softwareObj) => {
        softwarePackageDataArr.push({
          name: escapeVal(softwareObj.name),
          version: escapeVal(softwareObj.version),
          vendor: escapeVal(softwareObj.vendor),
          installed: escapeVal(softwareObj.installDate),
          installLoc: escapeVal(softwareObj.installLocation),
          installSrc: escapeVal(softwareObj.installSource),
        });
      });
    }

    const jsonObj = JSON.stringify(softwarePackageDataArr);
    const softwarePackageCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return softwarePackageCSVData;
  } else if (parsedAudit?.os === AGENT_OS.MACOS) {
    const softwarePackageData = parsedAudit?.software.data;
    const headers = {
      kind: 'Kind',
      name: 'Name',
      version: 'Version',
      location: 'Location',
      signedby: 'Signed By',
      intel64bit: 'Intel 64bit',
      lastmodified: 'Last Modified',
      obtainedfrom: 'Obtained From',
    };

    softwarePackageDataArr.push(headers);

    if (softwarePackageData) {
      (softwarePackageData as MacSoftware[]).forEach((softwareObj) => {
        softwarePackageDataArr.push({
          kind: escapeVal(softwareObj.kind),
          name: escapeVal(softwareObj.name),
          version: escapeVal(softwareObj.version),
          location: escapeVal(softwareObj.location),
          signedby: escapeVal(softwareObj.signedBy),
          intel64bit: escapeVal(softwareObj.intel64Bit),
          lastmodified: escapeVal(softwareObj.lastModified),
          obtainedfrom: escapeVal(softwareObj.obtainedFrom),
        });
      });
    }

    const jsonObj = JSON.stringify(softwarePackageDataArr);
    const softwarePackageCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return softwarePackageCSVData;
  } else if (parsedAudit?.os === AGENT_OS.LINUX) {
    const softwarePackageData = parsedAudit?.software.data;
    const headers = {
      arch: 'Arch',
      name: 'Name',
      summary: 'Summary',
      version: 'Version',
    };

    softwarePackageDataArr.push(headers);

    if (softwarePackageData) {
      (softwarePackageData as LinuxSoftware[]).forEach((softwareObj) => {
        softwarePackageDataArr.push({
          arch: escapeVal(softwareObj.arch),
          name: escapeVal(softwareObj.name),
          summary: escapeVal(softwareObj.summary),
          version: escapeVal(softwareObj.version),
        });
      });
    }

    const jsonObj = JSON.stringify(softwarePackageDataArr);
    const softwarePackageCSVData = {
      file: fileName,
      data: parseRawData(jsonObj),
    };

    return softwarePackageCSVData;
  }
};

const addToZip = (CSVDataArr: CSVDataListType[]) => {
  const zip = new JSZip();
  const localTime = getLocalTimeNoSpace();
  const fileName = 'scout-audits_' + localTime + '.zip';

  CSVDataArr.forEach((csvData) => {
    if (csvData.data !== '') {
      zip.file(csvData.file, csvData.data);
    }
  });

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, fileName);
  });
};
