import { LoadStatsAction, StatActionType } from './statActions';
import { iIndustryData, iRiskSummary } from '../types/stat';

export interface iStatReducer {
  weeklySummaries: Array<iRiskSummary>;
  weeklyIndustryData: Array<iIndustryData>;

  allSummaries: Array<iRiskSummary>;
  allIndustryData: Array<iIndustryData>;
}

const initialState: iStatReducer = {
  weeklySummaries: [],
  weeklyIndustryData: [],

  allSummaries: [],
  allIndustryData: [],
};

export const statReducer = (state = initialState, action: LoadStatsAction) => {
  const { payload, type } = action;
  const newState = { ...state };
  switch (type) {
    case StatActionType.LOAD_WEEKLY_STATS: {
      newState.weeklySummaries = payload.summaries;
      newState.weeklyIndustryData = payload.industryData;
      return newState;
    }
    case StatActionType.LOAD_ALL_STATS: {
      newState.allSummaries = payload.summaries;
      newState.allIndustryData = payload.industryData;
      return newState;
    }
    default: {
      return state;
    }
  }
};
