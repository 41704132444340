import { AGENT_OS } from '../../../Constants/Assets';
import {
  LinuxHardware,
  MacHardware,
  WindowsHardware,
} from './AgentAudits/parser';

export type rowType = {
  key: string;
  label: string;
  dataKey: string;
};

export type AssetDetailsType = { [id: string]: rowType[] };

export type DetailsData = {
  source: string;
  category: string;
  deviceName: string;
  os: string;
  foundBy: string;
  manufacturer: string;
  // criticality?: number;
  tagIDs?: number[];
};

export const nonAgentDetailsFields: AssetDetailsType = {
  details: [
    { key: 'source', label: 'Source', dataKey: 'source' },
    { key: 'category', label: 'Category', dataKey: 'category' },
    { key: 'deviceName', label: 'Device Name', dataKey: 'deviceName' },
    { key: 'os', label: 'OS', dataKey: 'osInfo.name' },
    { key: 'foundBy', label: 'Found By', dataKey: 'osInfo.reason' },
    { key: 'manufacturer', label: 'Manufacturer', dataKey: 'manufacturer' },
    { key: 'criticality', label: 'Criticality', dataKey: 'criticality' },
    { key: 'tagIDs', label: 'Tags', dataKey: 'tags' },
  ],
  hostIdentification: [
    {
      key: 'dnsHostname',
      label: 'DNS Hostname',
      dataKey: 'deviceName:DNS',
    },
    { key: 'netBIOSName', label: 'NetBIOS Name', dataKey: 'deviceName:NBName' },
    { key: 'iPv4Address', label: 'IPv4 Address', dataKey: 'ip' },
    { key: 'assetId', label: 'Asset ID', dataKey: 'id' },
  ],
  profileActivity: [
    {
      key: 'assetCreatedElem',
      label: 'Profile Created',
      dataKey: 'profileHistoryDates:assetCreatedElem',
    },
    {
      key: 'assetLastUpdatedElem',
      label: 'Profile Last Updated',
      dataKey: 'profileHistoryDates:assetLastUpdatedElem',
    },
    { key: 'country', label: 'Country', dataKey: 'country' },
    { key: 'city', label: 'City', dataKey: 'city' },
  ],
};

export const getAgentDetailsField = (): AssetDetailsType => {
  return {
    details: [
      { key: 'source', label: 'Source', dataKey: 'source' },
      { key: 'category', label: 'Category', dataKey: 'category' },
      { key: 'deviceName', label: 'Device Name', dataKey: 'deviceName' },
      { key: 'os', label: 'OS', dataKey: 'osInfo.product' },
      { key: 'foundBy', label: 'Found By', dataKey: 'osInfo.reason' },
      { key: 'manufacturer', label: 'Manufacturer', dataKey: 'manufacturer' },
      { key: 'criticality', label: 'Criticality', dataKey: 'criticality' },
      { key: 'tagIDs', label: 'Tags', dataKey: 'tags' },
    ],
    location: [
      { key: 'longitude', label: 'Longitude', dataKey: 'longitude' },
      { key: 'latitude', label: 'Latitude', dataKey: 'latitude' },
      { key: 'city', label: 'City', dataKey: 'city' },
      { key: 'country', label: 'Country', dataKey: 'country' },
    ],
    hostIdentification: [
      {
        key: 'dnsHostname',
        label: 'DNS Hostname',
        dataKey: 'deviceName:DNS',
      },
      {
        key: 'netBIOSName',
        label: 'NetBIOS Name',
        dataKey: 'deviceName:NBName',
      },
      { key: 'iPv4Address', label: 'IPv4 Address', dataKey: 'ip' },
      { key: 'assetId', label: 'Asset ID', dataKey: 'id' },
    ],
    advancedIdentification: [
      { key: 'userName', label: 'Latest Username', dataKey: 'userName' },
      { key: 'systemModel', label: 'System Model', dataKey: 'systemModel' },
      {
        key: 'systemBootTime',
        label: 'System Boot Time',
        dataKey: 'systemBootTime',
      },
      {
        key: 'externalIP',
        label: 'External IP Address',
        dataKey: 'externalIP',
      },
      {
        key: 'lastSeen',
        label: 'Last Observed Time',
        dataKey: 'lastSeen',
      },
      { key: 'clientUUID', label: 'Client ID', dataKey: 'clientUUID' },
    ],
    profileActivity: [
      {
        key: 'assetCreatedElem',
        label: 'Profile Created',
        dataKey: 'profileHistoryDates:assetCreatedElem',
      },
      {
        key: 'assetLastUpdatedElem',
        label: 'Profile Last Updated',
        dataKey: 'profileHistoryDates:assetLastUpdatedElem',
      },
      { key: 'country', label: 'Country', dataKey: 'country' },
      { key: 'city', label: 'City', dataKey: 'city' },
    ],
  };
};

export type EnrichmentCard = {
  title: string;
  key: string;
  isExpanded: boolean;
};

export type EnrichmentCardType = {
  hostIdentification: EnrichmentCard;
  advancedIdentification: EnrichmentCard;
  profileActivity: EnrichmentCard;
};

export const defaultEnrichmentCards = {
  hostIdentification: {
    title: 'Host Identification',
    key: 'hostIdentification',
    isExpanded: false,
  },
  advancedIdentification: {
    title: 'Advanced Identification',
    key: 'advancedIdentification',
    isExpanded: false,
  },
  profileActivity: {
    title: 'Profile Activity',
    key: 'profileActivity',
    isExpanded: false,
  },
};

export type HardwareDetailsType = {
  os: AGENT_OS | undefined;
  hardwareData: WindowsHardware | LinuxHardware | MacHardware | undefined;
};

export const getEnrichmentData = (hardWareDetails: HardwareDetailsType) => {
  switch (hardWareDetails.os) {
    case AGENT_OS.WINDOWS:
      return getEnrichFieldsForWindows(hardWareDetails);
    case AGENT_OS.MACOS:
      return getEnrichFieldsForMac(hardWareDetails);
    case AGENT_OS.LINUX:
      return getEnrichFieldsForLinux(hardWareDetails);
    default:
      return undefined;
  }
};

export const escapeSpecialChar = (entry: any) => {
  if (typeof entry != 'undefined' && entry !== '') {
    let str = JSON.stringify(entry);
    str = str.replace(/\r?\n|\r/g, '');
    str = str.replace(/"/g, ''); //XXX not sure this is doing what we think it should be doing...
    str = str.replace(/,/g, '');
    return str;
  } else {
    return '--';
  }
};

const getEnrichFieldsForWindows = (
  hardWareDetails: HardwareDetailsType
): EnrichmentFieldsType => {
  const hardwareData = hardWareDetails.hardwareData as WindowsHardware;

  const fieldsToReturn = {
    hostIdentification: [
      {
        label: 'Host Name',
        value: escapeSpecialChar(hardwareData.hostName),
      },
      { label: 'OS Name', value: escapeSpecialChar(hardwareData.osName) },
      { label: 'OS Version', value: escapeSpecialChar(hardwareData.osVersion) },
      {
        label: 'OS Manufacturer',
        value: escapeSpecialChar(hardwareData.osManufacturer),
      },
      {
        label: 'OS Configuration',
        value: escapeSpecialChar(hardwareData.osConfiguration),
      },
      {
        label: 'OS Build Type',
        value: escapeSpecialChar(hardwareData.osBuildType),
      },
      {
        label: 'Registered Owner',
        value: escapeSpecialChar(hardwareData.registeredOwner),
      },
      {
        label: 'Registered Organization',
        value: escapeSpecialChar(hardwareData.registeredOrganization),
      },
      { label: 'Product ID', value: escapeSpecialChar(hardwareData.productId) },
      {
        label: 'Original Install Date',
        value: escapeSpecialChar(hardwareData.originalInstallDate),
      },
      {
        label: 'System Boot Time',
        value: escapeSpecialChar(hardwareData.systemBootTime),
      },
      {
        label: 'System Manufacturer',
        value: escapeSpecialChar(hardwareData.systemManufacturer),
      },
      {
        label: 'Page File Location',
        value: escapeSpecialChar(hardwareData.pageFileLocation),
      },
      { label: 'Domain', value: escapeSpecialChar(hardwareData.domain) },
      {
        label: 'Login Server',
        value: escapeSpecialChar(hardwareData.loginServer),
      },
    ],
    advanceIdentification: [
      {
        label: 'Hotfixes',
        value: escapeSpecialChar(hardwareData.hotfixes),
      },
      {
        label: 'Number of Hotfixes',
        value: escapeSpecialChar(hardwareData.numberHotFixes),
      },
      {
        label: 'Network Cards',
        value: escapeSpecialChar(hardwareData.networkCards),
      },
      {
        label: 'Number of network cards',
        value: escapeSpecialChar(hardwareData.numberNetworkCards),
      },
      {
        label: 'HyperV Requirements',
        value: escapeSpecialChar(hardwareData.hyperVRequirements),
      },
    ],
    profileActivity: [
      {
        label: 'System Model',
        value: escapeSpecialChar(hardwareData.systemModel),
      },
      {
        label: 'System Type',
        value: escapeSpecialChar(hardwareData.systemType),
      },
      {
        label: 'Processor',
        value: escapeSpecialChar(hardwareData.processor),
      },
      {
        label: 'Number Processor',
        value: escapeSpecialChar(hardwareData.numberProcessor),
      },
      {
        label: 'BIOS Version',
        value: escapeSpecialChar(hardwareData.biosVersion),
      },
      {
        label: 'Windows Directory',
        value: escapeSpecialChar(hardwareData.windowsDirectory),
      },
      {
        label: 'System Directory',
        value: escapeSpecialChar(hardwareData.systemDirectory),
      },
      {
        label: 'Boot Device',
        value: escapeSpecialChar(hardwareData.bootDevice),
      },
      {
        label: 'System Locale',
        value: escapeSpecialChar(hardwareData.systemLocale),
      },
      {
        label: 'Input Locale',
        value: escapeSpecialChar(hardwareData.inputLocale),
      },
      {
        label: 'Timezone',
        value: escapeSpecialChar(hardwareData.timeZone),
      },
      {
        label: 'Total Physical Memory',
        value: escapeSpecialChar(hardwareData.totalPhysicalMemory),
      },
      {
        label: 'Available Physical Memory',
        value: escapeSpecialChar(hardwareData.availablePhysicalMemory),
      },
      {
        label: 'Virtual Max Memory',
        value: escapeSpecialChar(hardwareData.virtualMaxMemory),
      },
      {
        label: 'Virtual Available Memory',
        value: escapeSpecialChar(hardwareData.virtualAvailMemory),
      },
      {
        label: 'Virtual Inuse Memory',
        value: escapeSpecialChar(hardwareData.virtualInuseMemory),
      },
    ],
  };

  return fieldsToReturn;
};
const getEnrichFieldsForLinux = (
  hardWareDetails: HardwareDetailsType
): EnrichmentFieldsType => {
  const hardwareData = hardWareDetails.hardwareData as LinuxHardware;

  const fieldsToReturn = {
    hostIdentification: [
      {
        label: 'OS Name',
        value: escapeSpecialChar(hardwareData.osName),
      },
      {
        label: 'OS Version',
        value: escapeSpecialChar(hardwareData.osVersion),
      },
      {
        label: 'System Manufacturer',
        value: escapeSpecialChar(hardwareData.systemManufacturer),
      },
      {
        label: 'Time Since Boot',
        value: escapeSpecialChar(hardwareData.timeSinceBoot),
      },
      {
        label: 'Serial Number',
        value: escapeSpecialChar(hardwareData.serialNumber),
      },
    ],
    advanceIdentification: [
      {
        label: 'Cache',
        value: escapeSpecialChar(hardwareData.cacheSize),
      },
      {
        label: 'Hardware UUID',
        value: escapeSpecialChar(hardwareData.hardwareUuid),
      },
      {
        label: 'Motherboard',
        value: escapeSpecialChar(hardwareData.mobo),
      },
      {
        label: 'Board Manufacturer',
        value: escapeSpecialChar(hardwareData.boardManufacturer),
      },
    ],
    profileActivity: [
      {
        label: 'Processor',
        value: escapeSpecialChar(hardwareData.processorName),
      },
      {
        label: 'Processor Speed',
        value: escapeSpecialChar(hardwareData.processorSpeed),
      },
      {
        label: 'Processor Cores',
        value: escapeSpecialChar(hardwareData.processorCores),
      },
      {
        label: 'Processor Gen',
        value: escapeSpecialChar(hardwareData.processorGeneration),
      },
      {
        label: 'Processor Manufacturer',
        value: escapeSpecialChar(hardwareData.processorManufacturer),
      },
      {
        label: 'Processor Model',
        value: escapeSpecialChar(hardwareData.processorModel),
      },
      {
        label: 'BIOS Vendor',
        value: escapeSpecialChar(hardwareData.biosVendor),
      },
      {
        label: 'BIOS Version',
        value: escapeSpecialChar(hardwareData.biosVersion),
      },
      {
        label: 'BIOS Release',
        value: escapeSpecialChar(hardwareData.biosReleaseDate),
      },
    ],
  };

  return fieldsToReturn;
};

const getEnrichFieldsForMac = (
  hardWareDetails: HardwareDetailsType
): EnrichmentFieldsType => {
  const hardwareData = hardWareDetails.hardwareData as MacHardware;

  const fieldsToReturn = {
    hostIdentification: [
      {
        label: 'Computer Name',
        value: escapeSpecialChar(hardwareData.computerName),
      },
      {
        label: 'Username',
        value: escapeSpecialChar(hardwareData.userName),
      },
      {
        label: 'Time Since Boot',
        value: escapeSpecialChar(hardwareData.timeSinceBoot),
      },
      {
        label: 'System Version',
        value: escapeSpecialChar(hardwareData.systemVersion),
      },
      {
        label: 'System Integrity Protection',
        value: escapeSpecialChar(hardwareData.systemIntegrityProtection),
      },
      {
        label: 'SMC Version',
        value: escapeSpecialChar(hardwareData.smcVersion),
      },
      {
        label: 'Serial Number',
        value: escapeSpecialChar(hardwareData.serialNumber),
      },
      {
        label: 'Secure Virtual Memory',
        value: escapeSpecialChar(hardwareData.secureVirtualMemory),
      },
    ],
    advanceIdentification: [
      {
        label: 'L3 Cache',
        value: escapeSpecialChar(hardwareData.l3Cache),
      },
      {
        label: 'L2 Cache',
        value: escapeSpecialChar(hardwareData.l2Cache),
      },
      {
        label: 'Kernel Version',
        value: escapeSpecialChar(hardwareData.kernelVersion),
      },
      {
        label: 'Hardware UUID',
        value: escapeSpecialChar(hardwareData.hardwareUuid),
      },
      {
        label: 'Boot Volume',
        value: escapeSpecialChar(hardwareData.bootVolume),
      },
      {
        label: 'Boot ROM Version',
        value: escapeSpecialChar(hardwareData.bootRomVersion),
      },
      {
        label: 'Boot Mode',
        value: escapeSpecialChar(hardwareData.bootMode),
      },
    ],
    profileActivity: [
      {
        label: 'Processor Speed',
        value: escapeSpecialChar(hardwareData.processorSpeed),
      },
      {
        label: 'Processor Name',
        value: escapeSpecialChar(hardwareData.processorName),
      },
      {
        label: 'Number of Processors',
        value: escapeSpecialChar(hardwareData.hostnumberOfProcessorsName),
      },
      {
        label: 'Number of Cores',
        value: escapeSpecialChar(hardwareData.numberOfCores),
      },
      {
        label: 'Model Name',
        value: escapeSpecialChar(hardwareData.modelName),
      },
      {
        label: 'Model Identifier',
        value: escapeSpecialChar(hardwareData.modelIdentifier),
      },
      {
        label: 'Memory',
        value: escapeSpecialChar(hardwareData.memory),
      },
    ],
  };

  return fieldsToReturn;
};

export type NonAgentDetailsType = {
  details: {
    [id: string]: string;
  };
  hostIdentification: {
    [id: string]: string;
  };
  profileActivity: {
    [id: string]: string;
  };
};

export type AgentDetailsType = {
  details: {
    [id: string]: string;
  };
  hostIdentification: {
    [id: string]: string;
  };
  profileActivity: {
    [id: string]: string;
  };
  location: {
    [id: string]: string | number;
  };
  advancedIdentification: {
    [id: string]: string | number;
  };
};

export type HardwareFieldsType = {
  label: string;
  value: string;
};

export type EnrichmentFieldsType = {
  hostIdentification: HardwareFieldsType[];
  advanceIdentification: HardwareFieldsType[];
  profileActivity: HardwareFieldsType[];
};
