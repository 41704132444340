// *** Regex as per IP validations ***

// Sub components of regular expressions. Note that these should NEVER cause
// component matches (all groupings should be non-capturing groups using (?:))
// as those are left to the consumer of these sub elements.

const OCTAL_VALUE = `(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:[01][0-9]{2})|(?:[0-9]{1,2}))`,
  // Any value from 0 - 255, including leading 0 (000, 00, 012, 19, 255, ...), disallows > 255.

  OCTET =
    `(?:(?:` + OCTAL_VALUE + `?-` + OCTAL_VALUE + `?)|` + OCTAL_VALUE + `)`,
  // Any value that is a range of octal values: 5, 6-9, 100-200, 19-5, ...
  // Missing values on either side of a - are assumed to be min / max.
  // Note that ordering is not constrained, so 255-0 is valid.

  CIDR_VALUE = `(?:(?:3[0-2])|(?:[0-2]?[0-9]))`;
// Integers from 0 to 32, permitting leading 0

// Values in the form A-B.C-D.E-F.G-H, where any octet range is defined as above.
export const IPSET_RANGE = new RegExp(
    `^s*(?!-)(` + OCTET + `).(` + OCTET + `).(` + OCTET + `).(` + OCTET + `)s*$`
  ),
  // IPSET values with an additional CIDR component attached supported
  IPSET_RANGE_CIDR = new RegExp(
    `^s*(?!-)(` +
      OCTET +
      `).(` +
      OCTET +
      `).(` +
      OCTET +
      `).(` +
      OCTET +
      `)/(` +
      CIDR_VALUE +
      `)s*$`
  ),
  // Similar to IPSET, but restricted such that only the last octet can be
  // defined as a range, ensuring there are no discontinuities in the range.
  IP_CONTIGUOUS_RANGE_SHORT = new RegExp(
    `^s*(?!-)((?:` + OCTAL_VALUE + `.){3})(` + OCTET + `)s*$`
  ),
  // Values representing a start and end IP address (10.0.0.0-10.30.20.0)
  IP_CONTIGUOUS_RANGE_LONG = new RegExp(
    `^s*(?!-)((?:` +
      OCTAL_VALUE +
      `.){3}` +
      OCTAL_VALUE +
      `)-((?:` +
      OCTAL_VALUE +
      `.){3}` +
      OCTAL_VALUE +
      `)s*$`
  );
