export const GREY = {
  primary: '#333333',
  secondary: '#ededed',
  tertiary: '#777777',
  lightTertiary: `#cccccc`,
  bright: '#58595B',
};

export const BLUE = {
  primary: '#337ab7',
  secondary: '#17aedf',
  tertiary: '#23618e',
};

export const WHITE = {
  primary: '#ffffff',
  secondary: '#f7f7f7',
  tertiary: '#e6e9ed',
};

export const BLACK = {
  primary: '#002244',
};
export const GREEN = {
  primary: '#5cb85c',
};

export const RED = {
  primary: '#d9534f',
  error: '#a53f31',
  secondary: '#F18C7E',
};

export const SEVERITY_COLOR = {
  critical: '#F18C7E',
  high: '#F9B479',
  medium: '#F2D600',
  low: '#9ED7FF',
  none: '#81CA72',
  unassigned: '#F1F1F1',
};

export const YELLOW = {
  primary: '#f0ad4e',
};

export const ORANGE = {
  primary: 'orange',
};

export const STATUS_COLOR = {
  red: '#F18C7E',
  green: '#81CA72',
  blue: '#9ED7FF',
  yellow: '#F7E666',
  grey: '#EBEBEB',
};
