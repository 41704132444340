import React from 'react';
import { useState } from 'react';
import { Button, Grid, Primitive, VStack } from '@rtkwlf/fenrir-react';
import { collapsedStringMax } from './AgentTreeView';
import ExpandableString from './ExpandableString';
import { indexCharCount } from './treeviewFunctions';

interface ExpandedNestedStringProps {
  strArray: string[];
  index: number;
}

const ExpandedNestedString: React.FC<ExpandedNestedStringProps> = ({
  strArray,
  index,
}) => {
  const [allStrExpanded, setAllStrExpanded] = useState<boolean>(false);
  const expandable =
    strArray.length > 1 && strArray.some((i) => i.length > collapsedStringMax);

  return (
    <VStack gap={'zero'}>
      {expandable && (
        <Primitive.div paddingLeft='xxlarge'>
          <Button
            variant='secondary'
            onClick={() => setAllStrExpanded(!allStrExpanded)}
            margin={'zero'}
            data-testid='node-child-metadata-string-expand'
          >
            {allStrExpanded ? 'Collapse' : 'Expand'}
          </Button>
        </Primitive.div>
      )}
      <Grid.Root
        key={`expandedNestedStringGrid${index}`}
        columns={`${indexCharCount(strArray) + 1}ch 1fr`}
        paddingBottom='xsmall'
        paddingTop='xsmall'
        paddingLeft='xxlarge'
        data-testid='node-child-metadata-string'
        overflow='auto'
        style={{ width: '85vw' }}
      >
        {strArray.map((str: string, strIndex: number) => {
          return (
            <React.Fragment key={`expandedNestedString${strIndex}`}>
              <Grid.Item
                fontWeight='bold'
                textTransform='capitalize'
              >{`${strIndex + 1}:`}</Grid.Item>

              <Grid.Item fontWeight='bold' textColor='warningDefault'>
                <ExpandableString
                  value={str}
                  expandedByParent={allStrExpanded}
                  canExpandSelf={false}
                />
              </Grid.Item>
            </React.Fragment>
          );
        })}
      </Grid.Root>
    </VStack>
  );
};

export default ExpandedNestedString;
