import { ColumnProps, Table } from '../../../Reusables/table/Table';
import React from 'react';
import { RowTileV2 } from '../../../Reusables/RowTileV2';
import { Accordion } from '../Accordion';
import AscPanelSubtitle from '../AscPanelSubtitle';
import ReachTable from '../ReachTable';
import {
  ContainerDiv,
  ReachScheduleTableColumn,
  ReachTableColumn,
  StyledContainer,
  getPercentageString,
  getReachHostsLastScanFailed,
  getReachHostsNoScanScheduled,
  getReachTargetGroups,
  getCspmTargetGroups,
  getCspmAccountsLastScanFailed,
  getCspmAccountsNoScanScheduled,
} from '../utils';
import { ChildNode } from '../Accordion';
import { BLUE } from '../../../Constants/Styles';
import { useAttackSurfaceContext } from '../Context/AscContext';
import { Host, ReachGroup } from '../../../types/attackSurface';
import { Primitive, Tooltip } from '@rtkwlf/fenrir-react';

type ScheduleProps = {
  header: string;
  notScannedTitle: string;
  scheduleTitle: string;
  notScannedItems: ChildNode[];
  scheduleItems: ChildNode[];
};

const EvaSection: React.FC<ScheduleProps> = ({
  header,
  notScannedTitle,
  scheduleTitle,
  notScannedItems,
  scheduleItems,
}) => {
  return (
    <>
      <AscPanelSubtitle titleLevel={4} title={header} />
      <AscPanelSubtitle title={notScannedTitle} />
      <Accordion childrenNodes={notScannedItems} />
      <AscPanelSubtitle title={scheduleTitle} />
      <Accordion childrenNodes={scheduleItems} />
    </>
  );
};

const columns: ColumnProps<ReachTableColumn>[] = [
  {
    key: 'hosts',
    dataIndex: 'hosts',
    title: 'Targets Not Being Scanned',
    justify: 'flex-start',
    render: ({ record: { hosts } }) => (
      <>
        {hosts.map((host) => (
          <div>{host}</div>
        ))}
      </>
    ),
  },
  {
    key: 'count',
    dataIndex: 'count',
    title: 'Count',
    justify: 'flex-start',
  },
  {
    key: 'percent',
    dataIndex: 'percent',
    title: 'Percentage',
    justify: 'flex-start',
    render: ({ record: { percent } }) => <>{percent}</>,
  },
];

const scheduleColumns: ColumnProps<ReachScheduleTableColumn>[] = [
  {
    key: 'total',
    dataIndex: 'total',
    title: 'Total',
    justify: 'flex-start',
  },
  {
    key: 'missedTargets',
    dataIndex: 'missedTargets',
    title: (
      <>
        Missed Targets &nbsp;
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <i className='fa fa-info-circle' style={{ color: BLUE.primary }} />
          </Tooltip.Trigger>
          <Tooltip.Content maxWidth='16rem'>
            <Primitive.p textColor='accentGreySecondary'>
              The most recent scan of that host has a status of “failed”
            </Primitive.p>
            <Primitive.p textColor='accentGreySecondary'>
              AND/OR There is no recent scan at all
            </Primitive.p>
            <Primitive.p textColor='accentGreySecondary'>
              AND/OR The most recent scan does not have a startTime (i.e.
              startTime === ‘’)
            </Primitive.p>
          </Tooltip.Content>
        </Tooltip.Root>
      </>
    ),
    justify: 'flex-start',
    render: ({ record: { missedTargets } }) => (
      <>
        {missedTargets.map((target) => (
          <div>{target}</div>
        ))}
        {missedTargets.length === 0 && 'None'}
      </>
    ),
  },
  {
    key: 'scheduledTargets',
    dataIndex: 'scheduledTargets',
    title: 'Scheduled Targets',
    justify: 'flex-start',
    render: ({ record: { scheduledTargets } }) => (
      <>
        {scheduledTargets.map((target) => (
          <div>{target}</div>
        ))}
        {scheduledTargets.length === 0 && 'None'}
      </>
    ),
  },
];

export const AscEvaContainer = () => {
  const { reach } = useAttackSurfaceContext();
  const reachHostsTotal = Object.keys(reach.hosts).length;
  const reachHostsLastScanFailed: Host[] = getReachHostsLastScanFailed(reach);
  const reachHostsNoScanScheduled: Host[] = getReachHostsNoScanScheduled(reach);

  const ReachItems = [
    {
      title: `Last Scan Failed (${reachHostsLastScanFailed.length})`,
      node: (
        <ReachTable
          columns={columns}
          data={[
            {
              hosts: reachHostsLastScanFailed.map((host) => host.name),
              count: reachHostsLastScanFailed.length,
              percent: getPercentageString(
                reachHostsLastScanFailed.length,
                reachHostsTotal
              ),
            },
          ]}
        />
      ),
    },
    {
      title: `No Scan Scheduled (${reachHostsNoScanScheduled.length})`,
      node: (
        <ReachTable
          columns={columns}
          data={[
            {
              hosts: reachHostsNoScanScheduled.map((host) => host.name),
              count: reachHostsNoScanScheduled.length,
              percent: getPercentageString(
                reachHostsNoScanScheduled.length,
                reachHostsTotal
              ),
            },
          ]}
        />
      ),
    },
  ];

  const cspmHostsTotal = Object.keys(reach.cspm_accounts).length;
  const cspmHostsLastScanFailed: Host[] = getCspmAccountsLastScanFailed(reach);
  const cspmHostsNoScanScheduled: Host[] =
    getCspmAccountsNoScanScheduled(reach);

  const CSPMItems = [
    {
      title: `Last Scan Failed (${cspmHostsLastScanFailed.length})`,
      node: (
        <ReachTable
          columns={columns}
          data={[
            {
              hosts: cspmHostsLastScanFailed.map((host) => host.name),
              count: cspmHostsLastScanFailed.length,
              percent: getPercentageString(
                cspmHostsLastScanFailed.length,
                cspmHostsTotal
              ),
            },
          ]}
        />
      ),
    },
    {
      title: `No Scan Scheduled (${cspmHostsNoScanScheduled.length})`,
      node: (
        <ReachTable
          columns={columns}
          data={[
            {
              hosts: cspmHostsNoScanScheduled.map((host) => host.name),
              count: cspmHostsNoScanScheduled.length,
              percent: getPercentageString(
                cspmHostsNoScanScheduled.length,
                cspmHostsTotal
              ),
            },
          ]}
        />
      ),
    },
  ];

  const cspmScheduleItems = getScheduleItems(getCspmTargetGroups(reach));
  const ScheduleItems = getScheduleItems(getReachTargetGroups(reach));

  return (
    <ContainerDiv>
      <RowTileV2
        data-testid='table-row-header'
        id='AscAgentCoverage'
        title={'EVA Reach Coverage'}
        description={''}
        buttons={<div></div>}
      >
        <EvaSection
          header={'EVA'}
          notScannedTitle={'Targets Not Being Scanned'}
          scheduleTitle={'EVA Reach Schedules'}
          notScannedItems={ReachItems}
          scheduleItems={ScheduleItems}
        />

        <EvaSection
          header={'CSPM'}
          notScannedTitle={'CSPM Accounts Not Being Scanned'}
          scheduleTitle={'CSPM Schedules'}
          notScannedItems={CSPMItems}
          scheduleItems={cspmScheduleItems}
        />
      </RowTileV2>
    </ContainerDiv>
  );
};

const getScheduleItems = (schedules: ReachGroup[]) => {
  return schedules
    .sort((a: ReachGroup, b: ReachGroup) =>
      a.hostCount < b.hostCount ? 1 : -1
    )
    .map((schedule) => {
      const tagList = [];
      if (schedule.tasks.vulnerability) tagList.push('Vulnerability');
      if (schedule.tasks.webCrawler) tagList.push('Web Crawler');
      if (schedule.tasks.darkWeb) tagList.push('Dark Web');
      if (schedule.tasks.webserver) tagList.push('Web Server');
      return {
        title: `${schedule.name} - ${tagList.join(', ')} (${
          schedule.hostCount
        } hosts; ${schedule.missedHostCount} missed) - ${
          schedule.scheduleDisabled ? ' inactive' : schedule.status
        }`,
        node: (
          <StyledContainer data-testid='Asc-table-22'>
            <Table
              data={[
                {
                  total: schedule.hostCount,
                  missedTargets: schedule.hosts
                    .filter((host: Host) => host.missed)
                    .map((host: Host) => host.name),
                  scheduledTargets: schedule.hosts.map(
                    (host: Host) => host.name
                  ),
                },
              ]}
              columns={scheduleColumns}
              dataUniqueKey='id'
              emptyComponent={<div>No Data Available</div>}
            />
          </StyledContainer>
        ),
      };
    });
};

export default AscEvaContainer;
