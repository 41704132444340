import { Severity } from '../types/risks';

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Returns the RGB HEX colour code appropriate based on the supplied CVSS score
 * @param cvss - numeric CVSS 0.0 - 10.0
 */
export const getColorForCVSS = (cvss: number): string => {
  if (cvss < 0.1) {
    return '#518042';
  } else if (cvss < 4) {
    return '#8FD8FF';
  } else if (cvss < 7) {
    return '#F5E150';
  } else if (cvss < 9) {
    return '#FFC483';
  } else {
    return '#F79086';
  }
};

/**
 * Returns the RGX HEX colour code appropriate based on the supplied severity
 * @param {Severity} severity - text token representing severity of CVSS
 */
export const getColorForSeverity = (severity: Severity): string => {
  switch (severity) {
    case 'critical':
      return getColorForCVSS(10);
    case 'high':
      return getColorForCVSS(8);
    case 'medium':
      return getColorForCVSS(5);
    case 'low':
      return getColorForCVSS(2);
    case 'none':
      return getColorForCVSS(0);
  }
};

/**
 * Returns the textual severity rating based on CVSS score
 * See: https://www.first.org/cvss/specification-document#Qualitative-Severity-Rating-Scale
 * @param {number} cvss - the CVSS score
 * @return {Severity} text token of the severity based on CVSS score
 */
export const getSeverityForCVSS = (cvss: number): Severity => {
  if (cvss < 0.1) {
    return 'none';
  } else if (cvss < 4) {
    return 'low';
  } else if (cvss < 7) {
    return 'medium';
  } else if (cvss < 9) {
    return 'high';
  } else {
    return 'critical';
  }
};
