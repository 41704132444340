import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { iState } from './configureStore';
import { store } from './store';
import ReduxToastr from 'react-redux-toastr';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ThemeProvider } from '@rtkwlf/fenrir-react';

// css
import '../public/vendor/bootstrap/dist/css/bootstrap.min.css';
import '../public/vendor/font-awesome/css/font-awesome.min.css';
import '../public/build/css/custom.css';
import '../public/build/css/pdf-sections.css';
import '../public/build/css/rootsecure-base.css';
import '@rtkwlf/fenrir-react/style.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './fenrir-mrd-tweaks.css';

import App from './App';
import 'map.prototype.tojson';
import { initFeatureFlagState } from '../public/js/features';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 5, // 5 Minutes
      refetchOnWindowFocus: false,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// Changes for React 18
const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer as HTMLElement);

initFeatureFlagState();

root.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <ThemeProvider>
      <Provider store={store}>
        <App />
        <ReduxToastr
          timeOut={10000}
          newestOnTop={false}
          preventDuplicates
          position='top-right'
          // @ts-ignore
          getState={(state: iState) => state.toasts}
          transitionIn='fadeIn'
          transitionOut='fadeOut'
          progressBar
          closeOnToastrClick
        />
      </Provider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
  </PersistQueryClientProvider>
);
