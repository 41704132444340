import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { NodeId } from 'react-accessible-treeview/dist/TreeView/types';
import moment from 'moment-timezone';
import { inflate } from 'pako';

import { iRisk } from '../../../Global/riskReducer';

export interface ITreeNode<M extends IFlatMetadata> {
  id?: NodeId;
  name: string;
  isBranch?: boolean;
  children?: ITreeNode<M>[];
  metadata?: M;
}

export type AnnotationArtifacts = Record<string, any>;

export type Annotations = {
  title?: string;
  finding?: string;
  operation?: string;
  related_artifacts?: AnnotationArtifacts[];
  children?: Annotations[];
  result?: string;
};

export type ArtifactValue =
  | string
  | number
  | boolean
  | string[]
  | ArtifactObject
  | ArtifactObject[];

export interface ArtifactObject {
  [key: string]: ArtifactValue;
}

interface ArtifactArray extends Array<ArtifactValue> {}

const getArtifactObjectValue = <T, K extends keyof T>(data: T, key: K) => {
  return data[key];
};

export const longestKey = (
  metadata: ArtifactObject,
  exclusions: string[]
): number => {
  let longestKey = 0;

  for (const property in metadata) {
    const propVal = getArtifactObjectValue(metadata, property);
    if (
      ('type' !== property && exclusions.includes(property)) ||
      Array.isArray(propVal) ||
      isJson(getArtifactObjectValue(metadata, property).toString())
    ) {
      continue;
    }

    const len = property.length;
    if (len > longestKey) {
      longestKey = len;
    }
  }

  return longestKey;
};

export const indexCharCount = (array: ArtifactArray): number => {
  if (array.length < 10) {
    return 1;
  }
  if (array.length < 100) {
    return 2;
  }
  if (array.length < 1000) {
    return 3;
  }
  return 4;
};

export const getArtifactKey = (key: string): string => {
  return key.replace(/_/g, ' ');
};

export const isJson = (str: string) => {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object') {
      return !isStrArray(obj); // technically JSON because is an array, but we handle string arrays differently
    }
  } catch (e) {} // don't care, its not an object

  return false;
};

export const isStrArray = (value: ArtifactObject | ArtifactArray): boolean => {
  if (Array.isArray(value)) {
    if (value.length < 1) {
      return false;
    }
    return value.every((i) => typeof i === 'string');
  }

  return false;
};

export const getArtifactValue = (
  key: string,
  value: ArtifactObject | ArtifactArray | ArtifactValue,
  artifactsType: string | undefined
): string => {
  if (Array.isArray(value)) {
    if (typeof value[0] === 'object' || typeof value[0] === 'string') {
      return JSON.stringify(value);
    }
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  if (
    'file' === artifactsType &&
    'last_modified' === key &&
    typeof value === 'number'
  ) {
    const parsedDate = moment(value).utc().format();
    return parsedDate === 'Invalid date' ? value.toString() : parsedDate;
  } else if (
    'password' === artifactsType &&
    'last_login' === key &&
    typeof value === 'number'
  ) {
    const parsedDate = moment.unix(value).utc().format();
    return parsedDate === 'Invalid date' ? value.toString() : parsedDate;
  }

  return value.toString();
};

const getChildren = (
  annotations: Annotations[]
): ITreeNode<AnnotationArtifacts>[] => {
  const childrenData: ITreeNode<AnnotationArtifacts>[] = [];

  annotations.forEach((element) => {
    let children: ITreeNode<IFlatMetadata>[] = [];
    let metadata: AnnotationArtifacts[] = [];

    if (element.children) {
      children = getChildren(element.children);
    }

    if (element.related_artifacts) {
      metadata = element.related_artifacts;
    }

    let nodeName = element.operation || '';
    if (!element.children) {
      nodeName = element.title || '';
    }

    childrenData.push({
      name: nodeName,
      metadata,
      children,
    });
  });

  return childrenData;
};

export const getTreeData = (annotations: Annotations) => {
  let children: ITreeNode<IFlatMetadata>[] = [];

  if (annotations.children) {
    children = getChildren(annotations.children);
  }

  let nodeName = annotations.operation || '';
  if (!annotations.children) {
    nodeName = annotations.title || '';
  }

  const folder: ITreeNode<AnnotationArtifacts> = {
    name: nodeName,
    children,
  };

  return folder;
};

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const decompressJovalAnnotations = (
  risk: iRisk | undefined
): Annotations => {
  if (!risk?.observations?.[0]?.jovalAnnotations) {
    return {};
  }

  const uint8Array = base64ToArrayBuffer(risk.observations[0].jovalAnnotations);
  const inflated = inflate(uint8Array);
  const inflatedStr = new TextDecoder().decode(inflated);
  const retVal = JSON.parse(inflatedStr);
  return retVal;
};
