import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Field,
  HStack,
  MultiSelect,
  MultiSelectOption,
  Select,
  Text,
  TextField,
  TextareaField,
  VStack,
} from '@rtkwlf/fenrir-react';
import { toastr } from 'react-redux-toastr';
import { isValidTarget } from '../../utils/ipUtils';
import { addCredsThunk, updateCredsThunk } from '../../Global/scannerActions';
import {
  SensorCredential,
  SensorCredentialType,
  SensorCredentialUpdate,
} from '../../types/scanner';
import { useAppDispatch } from '../../hooks';
import { ModalBox } from '../../Reusables/ModalBox';

// A replacement for UI kit input to support custom props like `autocomplete`
const StyledInput = styled.input`
  height: 32px;
  font-size: 14px;
  width: 100%;
  color: #000000;
  padding: 0 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;

  &:focus {
    border-color: #23618e;
    outline: none;
  }
`;

// TODO: upgrade UI Kit to get native support of required input styling
const RequiredLabel = styled.label`
  ::before {
    content: '* ';
    color: #c43836;
  }
`;

const USERNAME_REGEX = /^[a-zA-Z0-9_\\\-.@]+$/;

const TYPE_OPTIONS: { text: string; value: SensorCredentialType }[] = [
  { text: 'Username / Password', value: 'up' },
  { text: 'Username / SSH Key', value: 'usk' },
];

type Props = {
  visible: boolean;
  onClose(): void;
  credToEdit: SensorCredential | null;
};

export const CredScanModal = ({ visible, onClose, credToEdit }: Props) => {
  const dispatch = useAppDispatch();

  const isEditing = !!credToEdit;

  const [typeOption, setTypeOption] = useState(TYPE_OPTIONS[0].value);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sshKey, setSshkey] = useState('');
  const [hosts, setHosts] = useState<MultiSelectOption[]>([]);
  const [selectedHosts, setSelectedHosts] = useState<MultiSelectOption[]>([]);

  let formValid = !!(name && hosts.length > 0);
  if (formValid) {
    formValid = !!username;
  }

  const hasInvalid = selectedHosts.some((h) => !isValidTarget(h.value));
  const msg = hasInvalid ? 'Invalid IP address' : '';

  // reset form inputs
  React.useEffect(() => {
    setTypeOption(
      credToEdit?.type === 'usk' ? TYPE_OPTIONS[1].value : TYPE_OPTIONS[0].value
    );
    setName(credToEdit?.name ?? '');
    setComment(credToEdit?.comment ?? '');
    setHosts(credToEdit?.hosts?.map((h) => ({ label: h, value: h })) ?? []);
    setSelectedHosts(
      credToEdit?.hosts?.map((h) => ({ label: h, value: h })) ?? []
    );
    setUsername('');
    setPassword('');
    setSshkey('');
  }, [credToEdit, visible]);

  const handleSubmit = async () => {
    if (!USERNAME_REGEX.test(username)) {
      toastr.error(
        'Username invalid',
        'Username may only contain alphanumeric characters or the following: - _ \\ . @'
      );
      return;
    }

    const data: SensorCredentialUpdate = {
      name,
      comment,
      hosts: hosts.map((h) => h.value),
    };

    data.credential = {
      type: typeOption,
      login: username,
      password: password,
      privkey: sshKey,
      community: '',
      authalgorithm: '',
      privalgorithm: '',
      certificate: '',
    };

    if (!credToEdit) {
      await dispatch(addCredsThunk(data));
    } else {
      await dispatch(updateCredsThunk(credToEdit.id, data));
    }

    onClose();
  };

  return (
    <ModalBox
      isOpen={visible}
      onCloseModal={onClose}
      title='Configure Credentials for Target Hosts'
      width='520px'
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button onClick={handleSubmit} isDisabled={!formValid || !!msg}>
            Okay
          </Button>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      }
    >
      <VStack paddingX='large' paddingBottom='large' gap='xsmall' width='full'>
        <Text>
          A single set of credentials may be configured for multiple hosts.
        </Text>
        <HStack width='full'>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>
              <RequiredLabel htmlFor='CredScanName'>Name</RequiredLabel>
            </Field.Label>
            <Field.Content>
              <TextField
                id='CredScanName'
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
              />
            </Field.Content>
          </Field.Root>
        </HStack>
        <HStack width='full'>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>
              <label htmlFor='CredScanComment'>Description</label>
            </Field.Label>
            <Field.Content>
              <TextareaField
                id='CredScanComment'
                value={comment}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setComment(event.target.value)
                }
              />
            </Field.Content>
          </Field.Root>
        </HStack>

        <HStack width='full'>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>
              <RequiredLabel>Hosts</RequiredLabel>
            </Field.Label>
            <Field.Info>
              Please specify the hosts by IP address that should use the
              credentials above for scanning.
            </Field.Info>
            <Field.Content>
              <MultiSelect
                isCreatable
                placeholder={'e.g. 1.2.3.4, 1.2.3.4/24'}
                appearance={msg ? 'danger' : 'neutral'}
                validationMessage={msg}
                options={hosts}
                selectedOptions={selectedHosts}
                onSelectedOptionsChange={setSelectedHosts}
                handleCreate={(
                  newItem: string,
                  updateFilterString: (filter: string) => void
                ) => {
                  if (newItem.trim()) {
                    const newOption = {
                      label: newItem.trim(),
                      value: newItem.trim(),
                    };
                    setHosts((prev) => [...prev, newOption]);
                    setSelectedHosts((prev) => [...prev, newOption]);
                    updateFilterString('');
                  }
                }}
              />
            </Field.Content>
          </Field.Root>
        </HStack>

        <HStack width='full'>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>Type</Field.Label>
            <Field.Content>
              <Select
                options={TYPE_OPTIONS}
                onValueChange={(option) => {
                  if (option) {
                    setTypeOption(option as SensorCredentialType);
                  }
                }}
                value={typeOption}
              />
            </Field.Content>
          </Field.Root>
        </HStack>

        <HStack width='full'>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>
              <RequiredLabel htmlFor='CredScanUsername'>Username</RequiredLabel>
            </Field.Label>
            <StyledInput
              data-lpignore='true'
              autoComplete='off'
              id='CredScanUsername'
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Field.Root>
          <Field.Root appearance='neutral'>
            <Field.Label marginTop='medium'>
              <label htmlFor='CredScanPassword'>
                {typeOption !== 'usk' ? 'Password' : 'Passphase (Optional)'}
              </label>
            </Field.Label>
            <StyledInput
              data-lpignore='true'
              autoComplete='off'
              id='CredScanPassword'
              type='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Field.Root>
        </HStack>

        {typeOption === 'usk' && (
          <HStack width='full'>
            <Field.Root appearance='neutral'>
              <Field.Label marginTop='medium'>
                <label htmlFor='CredScanSshKey'>SSH Key</label>
              </Field.Label>
              <Field.Content>
                <TextareaField
                  id='CredScanSshKey'
                  value={sshKey}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setSshkey(event.target.value)
                  }
                />
              </Field.Content>
            </Field.Root>
          </HStack>
        )}
      </VStack>
    </ModalBox>
  );
};
