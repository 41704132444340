import React from 'react';
import styled from 'styled-components';
import { Button } from '@rtkwlf/fenrir-react';
import moment from 'moment';
import { getComingSoon, setFeatureFlag } from '../../public/js/features';
import { escape } from 'lodash';

type Props = {
  featureFlagName: string;
};

const DISTANT_PAST = moment().startOf('year').year(2001).toISOString();

const Bar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0px -10px;
  padding: 5px 5px 0 10px;
  border: 1px solid #2b90d2;
  border-radius: 3px;
  background: #e1f4ff;
  flex-wrap: wrap;
`;

export const ComingSoonBanner = ({ featureFlagName }: Props) => {
  const flag = getComingSoon(featureFlagName) || {
    date: DISTANT_PAST,
    hideToday: '',
    comingSoonDisplay: false,
    nowAvailableDisplay: false,
    comingSoonText: '',
    nowAvailableText: '',
  };
  const isComingSoon = flag.date >= moment().toISOString();
  // if before release date and comingSoonDisplay
  // OR if after release date and nowAvailableDisplay
  // AND user has not hit remind me tomorrow (based on hideToday date in FF)
  const [visible, setVisible] = React.useState<boolean>(
    ((flag.comingSoonDisplay === true && isComingSoon) ||
      (flag.nowAvailableDisplay === true &&
        flag.date < moment().toISOString())) &&
      (!flag.hideToday ||
        moment(flag.hideToday).format('YYYY-MM-DD') !==
          moment().utc().format('YYYY-MM-DD'))
  );

  const remindLater = () => {
    setFeatureFlag(featureFlagName, {
      ...flag,
      hideToday: moment().utc().toISOString(),
    });
    setVisible(false);
  };

  const dismissForever = () => {
    if (isComingSoon) {
      setFeatureFlag(featureFlagName, {
        ...flag,
        comingSoonDisplay: false,
      });
    } else {
      setFeatureFlag(featureFlagName, {
        ...flag,
        nowAvailableDisplay: false,
      });
    }
    setVisible(false);
  };

  const date = moment(flag.date);

  React.useEffect(() => {
    const expiryDate = moment(flag.date).add(14, 'days');
    if (moment() > expiryDate) {
      setFeatureFlag(featureFlagName, {
        ...flag,
        nowAvailableDisplay: false,
      });
      setVisible(false);
    }
  }, [featureFlagName, flag, flag.date]);

  if (visible) {
    return (
      <Bar data-testid='coming-soon-banner'>
        <div>
          <a
            href={
              isComingSoon
                ? '/coming-soon.html'
                : `/now-available-${date.utc().format('YYYY-MM-DD')}.html`
            }
            target='_blank'
            rel='noreferrer'
          >
            <Button variant='link' iconLeft={<i className='fa fa-star' />}>
              {isComingSoon ? flag.comingSoonText : flag.nowAvailableText}
            </Button>
          </a>
        </div>
        <div
          style={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
        >
          <Button variant='secondary' onClick={remindLater}>
            Remind Me Tomorrow
          </Button>
          <Button variant='secondary' onClick={dismissForever}>
            Don't Show Again
          </Button>
        </div>
      </Bar>
    );
  }
  return null;
};
