import React from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';
import { useAssetCatalog } from './AssetTable/hooks';
import { configSelectors } from '../../Global/configReducer';
import { customerSelectors } from '../../Global/customerReducer';
import {
  BulkAssetDeleteRequest,
  BulkAssetDeleteResponse,
} from '../../apiClient/dashboard';
import riskAPI from '../../utils/riskAPI';

export const useDeleteAssets = (assetIDs: string[], onSuccess?: () => void) => {
  const { onAssetsDeleteSuccess } = useAssetCatalog();

  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);

  const deleteFn = React.useCallback(async () => {
    const payload: BulkAssetDeleteRequest = {
      notes: '',
      requests: assetIDs.map((id) => ({
        device: id,
      })),
    };

    return riskAPI.post(
      `${servicesHost}/rootsecure/v1/customers/${customerId}/bulkAssetDelete`,
      payload
    );
  }, [assetIDs, customerId, servicesHost]);

  const { mutate } = useMutation({
    mutationFn: deleteFn,
    onError: () => {
      toastr.error('Failed to delete the asset(s)', '');
    },
    onSuccess: (data) => {
      if (data.status === 207) {
        const res = data.data as BulkAssetDeleteResponse;
        if (res?.meta?.length === assetIDs.length) {
          // everything failed, can be seen as an error
          toastr.error('Failed to delete the asset(s)', '');
        } else {
          toastr.warning('Some assets deleted successfully', '');
          onAssetsDeleteSuccess();
          onSuccess?.();
        }
      } else {
        toastr.success('Asset(s) deleted successfully', '');
        onAssetsDeleteSuccess();
        onSuccess?.();
      }
    },
  });

  return {
    onDelete: mutate,
  };
};
