import ipRangeCheck from 'ip-range-check';
// @ts-ignore
import ipCheckRange from 'ip-check-range';
import * as ipaddr from 'ipaddr.js';
import {
  IPSET_RANGE,
  IPSET_RANGE_CIDR,
  IP_CONTIGUOUS_RANGE_LONG,
  IP_CONTIGUOUS_RANGE_SHORT,
} from './ipRegex';

export const ipIsInRange = (ipAddress: string, range: Array<string>) =>
  ipCheckRange(ipAddress, range) || ipRangeCheck(ipAddress, range);

/**
 * Check if a given IP address or CIDR range is not public (private, reserved,
 * multicast, loopback, etc... per RFC1918)
 * @param {string} ipAddress - IPv4 address (optionally with /xx CIDR notation)
 */
export const isPrivate = (ipAddress: string): boolean => {
  let ip;
  try {
    ip = ipaddr.parse(ipAddress);
  } catch (e) {}
  try {
    ip = ipaddr.parseCIDR(ipAddress)[0];
  } catch (e) {}
  if (!ip) {
    return false;
  }
  const range = ip.range();
  return range !== 'unicast';
};

// NOTE:- 0.0.0.0/0 is valid for denylists but not applicable for schedules
export const INVALID_SCHEDULE_TARGET = '0.0.0.0/0';
export const isValidTarget = (target: string) => {
  return (
    IPSET_RANGE_CIDR.test(target) ||
    IP_CONTIGUOUS_RANGE_LONG.test(target) ||
    IP_CONTIGUOUS_RANGE_SHORT.test(target) ||
    IPSET_RANGE.test(target)
  );
};

export const removeLeadingZerosFromIp = (ipAddress: string) => {
  // Split the IP address by dots
  const parts = ipAddress.split('.');

  // Check for CIDR notation
  const hasCIDR = parts[3].includes('/');

  let cidr = '';
  if (hasCIDR) {
    cidr = '/' + parts[3].split('/')[1];
    parts[3] = parts[3].split('/')[0];
  }

  // Loop through each part
  for (let i = 0; i < parts.length; i++) {
    // Check if it is a range
    if (parts[i].includes('-')) {
      const rangeParts = parts[i].split('-');
      rangeParts[0] = parseInt(rangeParts[0], 10).toString();
      rangeParts[1] = parseInt(rangeParts[1], 10).toString();
      parts[i] = rangeParts.join('-');
    } else {
      parts[i] = parseInt(parts[i], 10).toString();
    }
  }

  // Join the parts back together with '.'
  let result = parts.join('.');

  // Append CIDR notation if it existed
  if (hasCIDR) {
    result += cidr;
  }

  return result;
};
