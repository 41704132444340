import riskAPI from '../utils/riskAPI';
import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import { iGetState } from '../configureStore';
import {
  iIndustryData,
  iRiskSummary,
  v1RiskSummariesResponse,
} from '../types/stat';
import { EPOCH_WEEK, EPOCH_YEAR } from '../Constants/Time';
import _ from 'lodash';

// ----- Action Constants -----

export enum StatActionType {
  LOAD_WEEKLY_STATS = 'LOAD_WEEKLY_STATS',
  LOAD_ALL_STATS = 'LOAD_ALL_STATS',
}

export type LoadStatsAction = {
  type: StatActionType;
  payload: {
    summaries: Array<iRiskSummary>;
    industryData: Array<iIndustryData>;
  };
};

// ----- Action Creators -----

const LOAD_STATS_ACTION = (
  type: StatActionType,
  summaries: iRiskSummary[],
  industryData: iIndustryData[]
): LoadStatsAction => ({
  type,
  payload: {
    summaries,
    industryData,
  },
});

// ----- Thunk Action Creators -----

export const loadStatsThunk =
  (actionType: StatActionType) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const createdAfter = Math.floor(
      (Date.now() -
        (actionType === StatActionType.LOAD_WEEKLY_STATS
          ? EPOCH_WEEK
          : EPOCH_YEAR)) /
        // removing milliseconds to create a unix epoch
        1000
    );

    const { customer } = getState();

    try {
      const response = await riskAPI.get<v1RiskSummariesResponse>(
        `/rootsecure/rida/v1/customers/${customer.currentCustomer.id}/riskSummaries`,
        { params: { createdAfter } }
      );

      const { data: summaries } = response.data;
      let { industryRiskScores } = response.data.meta;

      // if a company was registered within last year, they will have fewer summaries than industry scores
      // remove the additional industry scores so that the two arrays have equal lengths
      if (actionType === StatActionType.LOAD_ALL_STATS) {
        if (industryRiskScores.length > summaries.length) {
          industryRiskScores = industryRiskScores.slice(-summaries.length);
        }
      }

      dispatch(LOAD_STATS_ACTION(actionType, summaries, industryRiskScores));
    } catch (error) {
      toastr.error(_.get(error, 'code', ''), 'Failed to load risk summaries');
    }
  };
