import React from 'react';
import styled from 'styled-components';

import { WHITE, GREY } from '../Constants/Styles';
import PanelTitle from './PanelTitle';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const StyledContainer = styled.div<{
  $newStyle?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 10px;
  display: inline-block;
  background: #fff;
  padding: ${(props) => (props.$newStyle ? '20px' : '10px 17px')};
  border: 1px solid ${(props) => (props.$newStyle ? '#cccccc' : WHITE.tertiary)};
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
`;

const StyledRow = styled.div<{
  $newStyle?: boolean;
}>`
  width: 100%;
  margin-bottom: ${(props) => (props.$newStyle ? '10px' : '')};
  display: inline-block;
  background: ${WHITE.primary};
  box-shadow: ${GREY.primary};
`;

const StyledTitleContainer = styled.div<{
  $newStyle?: boolean;
}>`
  display: flex;
  ${(props) =>
    props?.$newStyle
      ? ``
      : `border-bottom: 2px solid #e6e9ed;
      padding: 1px 5px 6px;
      margin-bottom: 10px;
      white-space: nowrap;
      align-items: center`}
`;

const StyledTitle = styled.h2`
  margin: 5px;
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 17px;
`;

const StyledButtonBar = styled.div`
  margin-left: 0.5em;
`;

interface Props {
  id?: string;
  title: string;
  description?: string;
  buttons?: React.ReactNode;
  buttonsContainerStyle?: React.CSSProperties;
  children: any;
  newStyle?: boolean;
}

export const RowTile = ({
  id,
  title,
  buttons,
  description,
  children,
  buttonsContainerStyle,
  newStyle,
}: Props) => (
  <StyledContainer data-testid={`${id}-wrapper`} $newStyle={newStyle}>
    <StyledRow $newStyle={newStyle}>
      <StyledTitleContainer $newStyle={newStyle}>
        {newStyle ? (
          <PanelTitle
            data-testid={id}
            title={title}
            tooltipText={description}
          />
        ) : (
          <>
            <StyledTitle data-testid={id}>{title}</StyledTitle>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    width: '20px',
                    fontSize: '14px',
                    display: 'block',
                    margin: 'auto 0',
                  }}
                  cursor='pointer'
                />
              </Tooltip.Trigger>
              <Tooltip.Content side='top' maxWidth='20rem'>
                <Tooltip.Text>{description}</Tooltip.Text>
              </Tooltip.Content>
            </Tooltip.Root>
          </>
        )}
        <StyledButtonBar style={buttonsContainerStyle}>
          {buttons}
        </StyledButtonBar>
      </StyledTitleContainer>
      {children}
    </StyledRow>
    <div className='clearfix' />
  </StyledContainer>
);
