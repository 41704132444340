import React, { FunctionComponent } from 'react';
// @ts-ignore
import SlidingPane from 'react-sliding-pane';
import './sliding_pane.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { connect } from 'react-redux';
import { iState } from '../configureStore';
import { CLOSE_INFO_PANE } from './infoPaneActions';

// Import Info Panes

import RiskPane from './Panes/RiskPane';

// Add Modals to Object

const PANES: any = {
  RiskPane,
};

const InfoPaneController = ({
  closePane,
  isOpen,
  openFrom,
  pane,
  payload,
  state,
  width,
}: any) => {
  const Contents: FunctionComponent<any> = PANES[pane];
  return (
    <SlidingPane
      closeIcon={<div>X</div>}
      from={openFrom || 'right'}
      isOpen={isOpen}
      onRequestClose={closePane}
      width={width || '200px'}
      hideHeader
    >
      <Contents payload={payload} state={state} />
    </SlidingPane>
  );
};

const mapStateToProps = ({ infoPane }: iState) => ({
  isOpen: infoPane.isOpen,
  openFrom: infoPane.openFrom,
  pane: infoPane.pane,
  payload: infoPane.payload,
  state: infoPane.state,
  width: infoPane.width,
});

const mapDispatchToProps = (dispatch: any) => ({
  closePane: () => dispatch({ type: CLOSE_INFO_PANE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPaneController);
