import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { BLUE } from '../../Constants/Styles';
import { bulkUpdateRisksThunk } from '../../Global/riskActions';
import { Button } from '@rtkwlf/fenrir-react';
import { useAppDispatch } from '../../hooks';
import { iRisk } from '../../Global/riskReducer';

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButtonContainer = styled.div`
  margin: 40px 14px 14px 14px;
  display: flex;
  flex-direction: row-reverse;
`;

const StyledTextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 30px;
  width: 470px;
  max-width: 800px;
  height: 80px;
  max-height: 120px;

  &:focus {
    outline: 0;
  }
`;

const StyledRow = styled.div`
  margin: 40px 14px 14px 14px;
  display: flex;
  justify-content: space-between;
`;

const StyledContainer = styled.div`
  width: 500px;
  margin: 40px 40px 20px 40px;
  max-height: 100vh;
`;

const StyledTitle = styled.h3`
  margin-left: 14px;
  font-family: Lato;
  font-style: normal;
  color: ${BLUE.primary};
`;

const StyledText = styled.p`
  margin-left: 15px;
  max-width: 1000px;
  font-family: Lato;
  font-style: normal;
`;

type AcceptRiskProps = {
  selectedRisks: Array<iRisk>;
  closeModal: () => void;
  updatePage?: () => void;
};

export const AcceptRisk: React.FC<AcceptRiskProps> = ({
  selectedRisks,
  closeModal,
  updatePage,
}) => {
  const [reason, setReason] = useState<string>('');
  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(() => {
    dispatch(
      bulkUpdateRisksThunk(
        { state: 'Accepted', notes: reason },
        {},
        {},
        selectedRisks,
        updatePage
      )
    );
    closeModal();
  }, [closeModal, dispatch, reason, selectedRisks, updatePage]);

  return (
    <StyledContainer>
      <StyledTitle>Accept Risk</StyledTitle>
      <StyledText>Please provide a reason to accept</StyledText>
      <StyledText>{`${selectedRisks.length} ${
        selectedRisks.length > 1 ? 'risks' : 'risk'
      } selected`}</StyledText>
      <StyledRow>
        <StyledInnerContainer>
          <StyledTextArea
            value={reason}
            onChange={(e: any) => setReason(e.target.value)}
          />
        </StyledInnerContainer>
      </StyledRow>
      <StyledButtonContainer>
        <Button
          isDisabled={!reason}
          onClick={() => {
            if (reason.length > 0) handleUpdate();
          }}
        >
          Accept
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
