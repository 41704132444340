// There is a known issue with Jest + TypeScript that causes enums to become undefined
// https://github.com/kulshekhar/ts-jest/issues/281
// example:
// ● Test suite failed to run
//     TypeError: Cannot read property 'PROCESSING_CASES' of undefined
// One way to resolve this is to isolate the exports of enums to a single module

export enum CaseActionTypes {
  PROCESSING_CASES = 'PROCESSING_CASES',
  SET_CASES = 'SET_CASES',
  ADD_CASE = 'ADD_CASE',
  REMOVE_ASSIGNMENT = 'REMOVE_ASSIGNMENT',
  UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT',
  REMOVE_CASE = 'REMOVE_CASE',
  REMOVE_RISK_FROM_CASE = 'REMOVE_RISK_FROM_CASE',
}

export enum ScannerActionTypes {
  LOAD_SCANNERS = 'LOAD_SCANNERS',
  LOAD_SCANS = 'LOAD_SCANS',
  LOAD_NETWORK = 'LOAD_NETWORK',
  LOAD_SCHEDULES = 'LOAD_SCHEDULES',
  LOAD_PREFERENCES = 'LOAD_PREFERENCES',
  LOAD_CONFIG = 'LOAD_CONFIG',
  REMOVE_SCHEDULE = 'REMOVE_SCHEDULE',
  TOGGLE_SCAN = 'TOGGLE_SCAN',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  LOAD_CRED_SCANS = 'LOAD_CRED_SCANS',
  REMOVE_CREDS = 'REMOVE_CREDS',
  ADD_SCHEDULE = 'ADD_SCHEDULE',
  SET_CREDENTIAL = 'SET_CREDENTIAL',
  UPDATE_STATUS = 'UPDATE_STATUS',
  SET_CURRENT_SCANNER = 'SET_CURRENT_SCANNER',
}
