import { useMemo } from 'react';
import { ColumnProps } from '../../Reusables/table/Table';

import { assetsUpdatedFieldsType } from './utils';

export function useModalAssetTableColumns() {
  const columns: ColumnProps<assetsUpdatedFieldsType>[] = [
    { key: 'id', dataIndex: 'id', title: 'ID', width: 25, sortable: true },
    {
      key: 'oldCategory',
      dataIndex: 'oldCategory',
      title: 'Old Category',
      width: 7,
      sortable: true,
    },
    {
      key: 'newCategory',
      dataIndex: 'newCategory',
      title: 'Updated Category',
      width: 8,
      sortable: true,
    },
    {
      key: 'oldDeviceName',
      dataIndex: 'oldDeviceName',
      title: 'Old Device Name',
      width: 15,
      sortable: true,
    },
    {
      key: 'newDeviceName',
      dataIndex: 'newDeviceName',
      title: 'Updated Device Name',
      width: 15,
      sortable: true,
    },
    {
      key: 'oldTags',
      dataIndex: 'oldTags',
      title: 'Old Tags',
      width: 10,
      sortable: false,
    },
    {
      key: 'newTags',
      dataIndex: 'newTags',
      title: 'Updated Tags',
      width: 20,
      sortable: false,
    },
    {
      key: 'oldCriticalityString',
      dataIndex: 'oldCriticalityString',
      title: 'Old Criticality',
      width: 15,
      sortable: true,
    },
    {
      key: 'newCriticalityString',
      dataIndex: 'newCriticalityString',
      title: 'Updated Criticality',
      width: 15,
      sortable: true,
    },
  ];

  return columns;
}
