import { useState } from 'react';
import { Button, Primitive, VStack } from '@rtkwlf/fenrir-react';
import { collapsedStringMax } from './AgentTreeView';

interface ExpandableStringProps {
  value: string;
  expandedByParent: boolean;
  canExpandSelf: boolean;
}

export const ExpandableString: React.FC<ExpandableStringProps> = ({
  value,
  expandedByParent,
  canExpandSelf,
}) => {
  const [selfExpanded, setExpanded] = useState<boolean>(false);

  return value.length > collapsedStringMax ? (
    <VStack gap='xsmall' fontWeight='bold' textColor='warningDefault'>
      {selfExpanded || expandedByParent
        ? value
        : `${value.substring(0, collapsedStringMax)}...`}
      {canExpandSelf && (
        <Button
          variant='link'
          onClick={() => setExpanded(!selfExpanded)}
          style={{ justifyContent: 'unset' }}
          data-testid='expandable-string-toggle'
        >
          {selfExpanded ? 'Collapse' : 'Expand'}
        </Button>
      )}
    </VStack>
  ) : (
    <Primitive.div fontWeight='bold' textColor='warningDefault'>
      {value}
    </Primitive.div>
  );
};

export default ExpandableString;
