import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useRendallApi } from '../useRendallApi';
import { customerSelectors } from '../../../Global/customerReducer';

export const useQueryAssetCapabilities = () => {
  const api = useRendallApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  return useQuery({
    queryKey: [organizationId, 'asset-capability-query'],
    queryFn: async () => api.getCapabilities({ organizationId }),
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
};
