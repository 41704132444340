import riskAPI from '../utils/riskAPI';
import { useQuery } from '@tanstack/react-query';

export type TagType = {
  tagID: number;
  name: string;
  description: string;
  reserved: boolean;
};

export type AssetUpdateTagDescriptionType = {
  customerId: string;
  tagID: number;
  body: {
    description: string;
  };
};

type TagsResponse = {
  tags: TagType[];
};

export const getAssetTags = async (customerId: string): Promise<TagType[]> => {
  const apiURL = `/rootsecure/v1/customers/${customerId}/tags`;
  const response = await riskAPI.get<TagsResponse>(apiURL);
  return response.data.tags;
};

export const useQueryAllTags = (customerId: string) => {
  const queryTags = useQuery({
    queryKey: [`getAssetTags-${customerId}`],
    queryFn: () => getAssetTags(customerId),
    retry: false,
  });

  // On initial load all reserved tags will be shown
  return {
    ...queryTags,
    data: queryTags.data?.sort((x, y) => {
      if (x.reserved === y.reserved) return 0;
      if (x.reserved) return -1;
      return 1;
    }),
  };
};

export const updateAssetTagDescription = async (
  params: AssetUpdateTagDescriptionType
) => {
  const apiURL = `/rootsecure/v1/customers/${params.customerId}/tags/${params.tagID}`;
  const response = await riskAPI.patch(apiURL, params.body);

  return response;
};
