import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { riskSelectors, RiskWithAssetInfo } from '../../Global/riskReducer';
import { assetSelectors } from '../../Global/assetReducer';
import { useQueryAllTags } from '../../Global/assetTags';
import { customerSelectors } from '../../Global/customerReducer';

/**
 * Enrich risks with asset related data (e.g. criticality, tags, OS)
 */
export const useEnrichRisks = (): RiskWithAssetInfo[] => {
  const risksMap = useSelector(riskSelectors.getRisksMap);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const allAssetsById = useSelector(assetSelectors.getAllAssetsById);
  const { data: allTags } = useQueryAllTags(customerId);

  return useMemo(() => {
    return Array.from(risksMap.values(), (data) => {
      const risk: RiskWithAssetInfo = { ...data };

      if (data.assetID && allAssetsById[data.assetID]) {
        const asset = allAssetsById[data.assetID];

        // append asset criticality
        risk.assetCriticality = asset.criticality ?? 0;

        // append asset tags
        const tagIDs = asset.tags ?? [];
        risk.assetTags = allTags?.filter((t) => tagIDs.includes(t.tagID)) ?? [];

        // append os info
        risk.assetOS = asset?.osInfo?.product ?? 'unknown';

        risk.assetCategory = asset?.category ?? 'unassigned';
      }
      return risk;
    });
  }, [allAssetsById, allTags, risksMap]);
};
