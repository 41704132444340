import React from 'react';
import { Heading, Tooltip, VStack } from '@rtkwlf/fenrir-react';
import { BLUE } from '../Constants/Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface iPanelTitle {
  title: string;
  tooltipText?: React.ReactNode;
}

const PanelTitle = ({ title, tooltipText }: iPanelTitle) => (
  <VStack marginBottom='large'>
    <Heading level='4'>
      {title}
      {tooltipText ? (
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <FontAwesomeIcon
              data-testid='info-icon'
              icon={faInfoCircle}
              color={BLUE.primary}
              style={{ marginLeft: '0.5em' }}
              cursor='pointer'
            />
          </Tooltip.Trigger>
          <Tooltip.Content maxWidth='16rem'>
            <Tooltip.Text>{tooltipText as string}</Tooltip.Text>
          </Tooltip.Content>
        </Tooltip.Root>
      ) : null}
    </Heading>
  </VStack>
);
export default PanelTitle;
