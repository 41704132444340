import { Dispatch, iGetState } from '../configureStore';
import { AxiosError, AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { RoutesKey, routesMap } from '../routesMap';
import {
  CLEAR_ASSIGNED_RISKS,
  getRisksThunk_V2,
  SET_ASSIGNED_RISKS,
} from './riskActions';
import { iCase, iRiskAssignment } from './caseReducer';
import { RiskType } from './riskReducer';
import { CaseParams, CasesMeta, GetCasesResponse } from '../types/cases';
import { CaseActionTypes } from './actionEnums';
import riskAPI from '../utils/riskAPI';

type iProcessingAction = {
  type: CaseActionTypes.PROCESSING_CASES;
  isProcessing: boolean;
};

type iSetCasesAction = {
  type: CaseActionTypes.SET_CASES;
  payload: {
    cases: Array<iCase>;
    pageState: {
      meta: CasesMeta;
      filters: CaseParams;
    };
  };
};

type iAddCaseAction = {
  type: CaseActionTypes.ADD_CASE;
  payload: {
    newCase: iCase;
  };
};

type iRemoveAssignmentAction = {
  type: CaseActionTypes.REMOVE_ASSIGNMENT;
  payload: {
    riskId: string;
    riskType: RiskType;
  };
};

export type iUpdateAssignmentAction = {
  type: CaseActionTypes.UPDATE_ASSIGNMENT;
  payload: {
    caseId: string;
    assignment: iRiskAssignment;
    riskId: string;
    riskType: RiskType;
  };
};

type iRemoveCaseAction = {
  type: CaseActionTypes.REMOVE_CASE;
  payload: {
    caseId: string;
  };
};

type iRemoveRiskFromCaseAction = {
  type: 'REMOVE_RISK_FROM_CASE';
  payload: {
    caseId: string;
    riskId: string;
    riskType: RiskType;
  };
};

/**
 * Collection of all case actions with their payload schema
 */
export type iCaseAction =
  | iProcessingAction
  | iSetCasesAction
  | iAddCaseAction
  | iRemoveAssignmentAction
  | iUpdateAssignmentAction
  | iRemoveCaseAction
  | iRemoveRiskFromCaseAction;

// ----- Action Creators -----

/**
 * A type checking wrapper to create (plain object) case actions
 */
const CASE_ACTIONS = {
  SET_CASES: (
    cases: Array<iCase>,
    meta: any,
    filters: any
  ): iSetCasesAction => ({
    type: CaseActionTypes.SET_CASES,
    payload: {
      cases,
      pageState: {
        meta,
        filters,
      },
    },
  }),
  ADD_CASE: (newCase: iCase): iAddCaseAction => ({
    type: CaseActionTypes.ADD_CASE,
    payload: { newCase },
  }),
  REMOVE_ASSIGNMENT: (
    riskId: string,
    riskType: RiskType
  ): iRemoveAssignmentAction => ({
    type: CaseActionTypes.REMOVE_ASSIGNMENT,
    payload: {
      riskId,
      riskType,
    },
  }),
  UPDATE_ASSIGNMENT: (
    caseId: string,
    riskId: string,
    assignment: iRiskAssignment,
    riskType: RiskType
  ): iUpdateAssignmentAction => ({
    type: CaseActionTypes.UPDATE_ASSIGNMENT,
    payload: {
      caseId,
      riskId,
      assignment,
      riskType,
    },
  }),
  REMOVE_CASE: (caseId: string): iRemoveCaseAction => ({
    type: CaseActionTypes.REMOVE_CASE,
    payload: {
      caseId,
    },
  }),
  REMOVE_RISK_FROM_CASE: (
    caseId: string,
    riskId: string,
    riskType: RiskType
  ): iRemoveRiskFromCaseAction => ({
    type: CaseActionTypes.REMOVE_RISK_FROM_CASE,
    payload: {
      caseId,
      riskId,
      riskType,
    },
  }),
};

// ----- Thunk Action Creators -----

export const getCasesThunk =
  (
    caseParams: CaseParams = {},
    riskParams?: { [key: string]: string | number | boolean | Array<string> },
    getRisks?: boolean
  ) =>
  (dispatch: any, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .get<GetCasesResponse>(`/rootsecure/casemgmt/v1/customers/${id}/cases`, {
        params: caseParams,
      })
      .then((response) => {
        const cases = response.data.data;
        if (getRisks) {
          dispatch({ type: CLEAR_ASSIGNED_RISKS });
          cases.map((Case: iCase) =>
            dispatch(
              getRisksThunk_V2(
                {
                  caseId: Case.id,
                  limit: 10000,
                  ...riskParams,
                },
                SET_ASSIGNED_RISKS
              )
            )
          );
        }
        dispatch(
          CASE_ACTIONS.SET_CASES(
            cases,
            response.data.meta,
            response.config.params
          )
        );
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to retrieve cases', error.code as string);
      })
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const updateAssignmentThunk =
  (riskId: string, update: Partial<iRiskAssignment>, riskType: RiskType) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;
    return riskAPI
      .patch(
        `/rootsecure/casemgmt/v1/customers/${id}/assignedrisks/${riskId}`,
        {
          assignment: update,
        }
      )
      .then((response: AxiosResponse) => {
        const { caseId, assignment } = response.data.data;
        dispatch(
          CASE_ACTIONS.UPDATE_ASSIGNMENT(caseId, riskId, assignment, riskType)
        );
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to update case', error.code as string);
      });
  };

export const updateRiskCaseThunk =
  (riskId: string, caseId: any, riskType: RiskType) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer, location } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .post(
        `/rootsecure/casemgmt/v1/customers/${id}/cases/${caseId}/assignedrisks`,
        { riskId }
      )
      .then((response: AxiosResponse) => {
        const { caseId, assignment } = response.data.data;
        dispatch(
          CASE_ACTIONS.UPDATE_ASSIGNMENT(caseId, riskId, assignment, riskType)
        );
        if (location.type === RoutesKey.CASE_MANAGEMENT) {
          // Refresh Data
          const updateThunk: any = routesMap[location.type].thunk;
          if (updateThunk) {
            dispatch(updateThunk);
          }
        }
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to update case', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const deleteRiskCaseThunk =
  (riskId: string, caseId: string, riskType: RiskType) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer, location } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .delete(
        `/rootsecure/casemgmt/v1/customers/${id}/cases/${caseId}/assignedrisks/${riskId}`
      )
      .then(() => {
        dispatch(CASE_ACTIONS.REMOVE_RISK_FROM_CASE(caseId, riskId, riskType));
        if (location.type === RoutesKey.CASE_MANAGEMENT) {
          // Refresh Data
          // TODO: this refresh may be brutal, see if we can minimize re-fetches
          const updateThunk = routesMap[location.type].thunk;
          if (updateThunk) {
            dispatch(updateThunk);
          }
        }
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to update case', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const createCaseThunk =
  (caseData: { [key: string]: string }) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .post(`/rootsecure/casemgmt/v1/customers/${id}/cases`, { ...caseData })
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.ADD_CASE(response.data.data));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to create case', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const closeCaseThunk =
  (caseId: any, params: { [key: string]: string }) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;

    dispatch({ type: CaseActionTypes.PROCESSING_CASES, isProcessing: true });

    return riskAPI
      .patch(`/rootsecure/casemgmt/v1/customers/${id}/cases/${caseId}`, params)
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.REMOVE_CASE(caseId));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to update case', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: CaseActionTypes.PROCESSING_CASES,
          isProcessing: false,
        })
      );
  };

export const deleteAssignmentThunk =
  (riskId: string, riskType: RiskType) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    const { id } = customer.currentCustomer;
    return riskAPI
      .delete(`/rootsecure/casemgmt/v1/customers/${id}/assignedrisks/${riskId}`)
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.REMOVE_ASSIGNMENT(riskId, riskType));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to remove assignment', error.code as string)
      );
  };
