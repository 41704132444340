import React from 'react';

import InfoRows, { SourceRenderer } from './InfoRows';
import AssetEditDetails from './AssetEditDetails';
import { nonAgentDetailsFields } from './AssetDetails';

import styled from 'styled-components';
import { GREY } from '../../../Constants/Styles';
import { TagsRenderer } from './InfoRowRenderers/TagsRenderer';
import { CriticalityPill } from './InfoRowRenderers/CriticalityRenderer';

const WrapStyle = styled.div`
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid ${GREY.lightTertiary};
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 260px;
`;

type nonAgentDataType = {
  details: { [id: string]: string };
  hostIdentification: { [id: string]: string };
  profileActivity: { [id: string]: string };
};

type StateProps = {
  data: nonAgentDataType;
  profileImage: React.ReactNode;
};

export type Props = StateProps;

export const NonAgentDetails = ({ data, profileImage }: Props) => {
  return (
    <>
      <div className='row'>
        <div className='col-md-4 col-xs-12'>
          <WrapStyle data-testid='asset-nonagent-details'>
            <InfoRows
              key='details'
              title='Details'
              columns={nonAgentDetailsFields.details}
              data={data.details}
              infoHeader={
                <AssetEditDetails
                  data={data.details}
                  assetId={data.hostIdentification.assetId}
                />
              }
              profileImage={profileImage}
              renderers={{
                source: SourceRenderer,
                criticality: CriticalityPill,
                tagIDs: TagsRenderer,
              }}
            />
          </WrapStyle>
        </div>
        <div className='col-md-4 col-xs-12'>
          <WrapStyle data-testid='asset-nonagent-host'>
            <InfoRows
              key='hostIdentification'
              title='Host Identification'
              columns={nonAgentDetailsFields.hostIdentification}
              data={data.hostIdentification}
            />
          </WrapStyle>
        </div>
        <div className='col-md-4 col-xs-12'>
          <WrapStyle data-testid='asset-nonagent-activity'>
            <InfoRows
              key='ProfileActivity'
              title='Profile Activity'
              columns={nonAgentDetailsFields.profileActivity}
              data={data.profileActivity}
            />
          </WrapStyle>
        </div>
      </div>
    </>
  );
};

export default NonAgentDetails;
