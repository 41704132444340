import { BLUE } from './../Constants/Styles';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { atoms } from '@rtkwlf/fenrir-react';
import { Tooltip } from '@rtkwlf/fenrir-react';

const WrapIconStyle = styled.div`
  padding: 5px;
  border: 1px solid ${BLUE.primary};
  height: 32px;
  width: 32px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

interface iDownloadCSVIcon {
  tooltipText?: string;
  handleExport?: () => void;
  disabled?: boolean;
}

export const DownloadCSVIcon = ({
  tooltipText,
  handleExport,
  disabled,
}: iDownloadCSVIcon) => {
  return (
    <>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <WrapIconStyle
            style={disabled ? { border: '1px solid #99b4c9' } : undefined}
            onClick={handleExport}
          >
            <FontAwesomeIcon
              icon={faTable}
              className={atoms({
                textColor: disabled ? 'accentBlueSecondary' : 'brandDefault',
              })}
              cursor='pointer'
            />
          </WrapIconStyle>
        </Tooltip.Trigger>
        <Tooltip.Content side='top'>
          <Tooltip.Text>{tooltipText}</Tooltip.Text>
        </Tooltip.Content>
      </Tooltip.Root>
    </>
  );
};

export default DownloadCSVIcon;
