import React from 'react';
import { DataWithDuplicateAssets } from '../../../Global/assetReducer';
import { ColumnProps, Table } from '../../../Reusables/table/Table';
import { useAssetCatalog, useTableSort } from './hooks';
import { Button, HStack, Primitive, atoms, Text } from '@rtkwlf/fenrir-react';
import { DeleteDuplicateModal } from './DeleteDuplicateModal';
import { AssetSourceRenderer } from './AssetSourceRenderer';
import DeleteDuplicateButton from '../../../Reusables/DeleteDuplicateButton';
import { CriticalityPill } from '../AssetDetails/InfoRowRenderers/CriticalityRenderer';
import TagPills from '../../../Reusables/TagPills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@rtkwlf/fenrir-react';

const DuplicateTable: React.FC<{ data: DataWithDuplicateAssets[] }> = ({
  data,
}) => {
  const { selectedAssetIDs, toggleAsset } = useAssetCatalog();

  const duplicateAssetsColumns: ColumnProps<DataWithDuplicateAssets>[] = [
    {
      key: 'all-assets',
      title: null,
      width: 1,
      justify: 'center',
      render: ({ record: { id } }) => (
        <input
          type='checkbox'
          key={id}
          name={id}
          checked={selectedAssetIDs.includes(id)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            toggleAsset(e.target.checked, id);
          }}
          style={{ marginRight: '3px' }}
        />
      ),
    },
    {
      key: 'source',
      title: 'Source',
      render: AssetSourceRenderer,
      sortable: true,
    },
    { key: 'ip', dataIndex: 'ip', title: 'IP', width: 7, sortable: true },
    {
      key: 'deviceName',
      dataIndex: 'deviceName',
      title: 'Device Name',
      sortable: true,
    },
    { key: 'mac', dataIndex: 'mac', title: 'MAC', width: 10, sortable: true },
    { key: 'os', dataIndex: 'os', title: 'OS', width: 10, sortable: true },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'UUID',
      sortable: true,
    },
    {
      key: 'lastSeen',
      dataIndex: 'lastSeen',
      title: 'Last Seen',
      sortable: true,
    },
    {
      key: 'lastScannedTime',
      dataIndex: 'lastScannedTime',
      title: 'Last Successful Scan',
      sortable: true,
    },
    {
      key: 'criticality',
      dataIndex: 'criticality',
      title: 'Asset Criticality',
      sortable: true,
      render: ({ record: { criticality } }) => (
        <CriticalityPill criticality={criticality} />
      ),
    },
    {
      key: 'tag',
      dataIndex: 'tag',
      title: 'Tags',
      width: 10,
      render: ({ record: { assetTags } }) => (
        <TagPills tags={assetTags} tooltipPosition='left' />
      ),
    },
    {
      key: 'delete',
      title: '',
      width: 3,
      justify: 'center',
      render: ({ record }) => <DeleteDuplicateButton asset={record} />,
    },
  ];
  const columns = duplicateAssetsColumns.filter((col) => col.visible ?? true);

  const { sortedData, onSort } = useTableSort(data);

  return (
    <Table<DataWithDuplicateAssets>
      className={atoms({
        paddingTop: 'zero',
        paddingLeft: 'xsmall',
      })}
      data={sortedData}
      onSort={onSort}
      columns={columns}
      dataUniqueKey='id'
    />
  );
};

const DuplicateAssetNestedRenderer: React.FC<{
  record: DataWithDuplicateAssets;
}> = ({ record }) => {
  const { id, duplicateData } = record;

  const [expanded, setExpanded] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  if (!duplicateData || duplicateData.length === 0) {
    return null;
  }

  const isEVA = record.source === 'reach';

  const deleteAllButton = (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Primitive.div cursor='pointer'>
          <Button
            variant='tertiary'
            marginTop='small'
            onClick={() => setDeleteModalOpen(true)}
            iconLeft={
              <FontAwesomeIcon icon={faTrash} className='fa fa-trash' />
            }
            isDisabled={isEVA}
          >
            Delete All Potential Duplicate Assets
          </Button>
        </Primitive.div>
      </Tooltip.Trigger>
      {isEVA && (
        <Tooltip.Content side='left' maxWidth='25rem'>
          <Tooltip.Text>
            To delete External Vulnerability Assessment (EVA) assets, remove
            them from the relevant scan configuration.
          </Tooltip.Text>
        </Tooltip.Content>
      )}
    </Tooltip.Root>
  );

  return (
    <>
      <HStack justifyContent='space-between'>
        <HStack paddingLeft='smedium' padding='smedium' gap='xsmall'>
          <HStack cursor='pointer' onClick={() => setExpanded((prev) => !prev)}>
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              className={atoms({
                marginRight: 'medium',
                marginLeft: 'xsmall',
                textColor: 'brandDefault',
              })}
            />
            <Text styledAs='body4' marginRight='small' textColor='brandDefault'>
              {`(${duplicateData?.length}) Potential Duplicate Assets Found`}
            </Text>
          </HStack>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                color='#23618E'
                fontSize='14px'
                cursor='pointer'
              />
            </Tooltip.Trigger>
            <Tooltip.Content side='top'>
              <Tooltip.Text
                style={{
                  display: 'block',
                  marginBottom: '7px',
                  textAlign: 'left',
                }}
              >
                These are potential duplicate assets.
              </Tooltip.Text>
              <Tooltip.Text>
                These need to be reviewed before making a decision to delete.
              </Tooltip.Text>
            </Tooltip.Content>
          </Tooltip.Root>
        </HStack>
        {expanded && (
          <>
            {deleteAllButton}
            {!isEVA && (
              <DeleteDuplicateModal
                assets={record.duplicateData || []}
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
              />
            )}
          </>
        )}
      </HStack>
      {expanded && (
        <div data-testid={`asset-duplicate-container-${id}`}>
          <DuplicateTable data={duplicateData} />
        </div>
      )}
    </>
  );
};

export { DuplicateAssetNestedRenderer };
