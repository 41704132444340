import React from 'react';
import { useSelector } from 'react-redux';
import { DisplayTable } from '../../Reusables/DisplayTable';
import { RowTile } from '../../Reusables/RowTile';
import { CredScanModal } from '../../Modals/Modals/CredScan';
import { scannerSelectors } from '../../Global/scannerReducer';
import { EditButton } from '../../Reusables/EditButton';
import DeleteButton from '../../Reusables/DeleteButton';
import { deleteCredsThunk } from '../../Global/scannerActions';
import { SensorCredential } from '../../types/scanner';
import { Button, HStack } from '@rtkwlf/fenrir-react';
import { useAppDispatch } from '../../hooks';
import styled from 'styled-components';

const headers = ['Name', 'Type', 'Hosts', 'Description', 'Modify'];

const StyledDiv = styled.div`
  margin: 0px -20px 0px 0px;
`;

export const CredScanTile = () => {
  const dispatch = useAppDispatch();
  const creds = useSelector(scannerSelectors.getCurrentScannerCredScans);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [credToEdit, setCredToEdit] = React.useState<SensorCredential | null>(
    null
  );

  const closeModal = () => {
    setModalOpen(false);
    setCredToEdit(null);
  };

  const rows = React.useMemo(() => {
    if (!creds) {
      return [];
    }

    return Array.from(creds.values()).map((cred) => [
      cred.name,
      cred.type === 'up' ? 'username/password' : 'username/ssh',
      cred.hosts,
      cred.comment,
      <HStack gap='zero'>
        <EditButton
          onClick={() => {
            setCredToEdit(cred);
            setModalOpen(true);
          }}
        />
        <DeleteButton
          title='Delete Credentialed Scan'
          message={
            <div>
              Please Confirm.
              <br />
              <br />
              Are you sure that you want to delete this scan?
            </div>
          }
          yesClick={() => dispatch(deleteCredsThunk(cred.id))}
          yesButtonText='Delete Scan'
          noButtonText='Cancel'
        />
      </HStack>,
    ]);
  }, [creds, dispatch]);

  return (
    <StyledDiv>
      <RowTile
        title='Credentialed Scanning'
        buttons={
          <Button
            onClick={() => {
              setModalOpen(true);
              setCredToEdit(null);
            }}
            variant='secondary'
          >
            Add new scan credentials
          </Button>
        }
        buttonsContainerStyle={{
          position: 'absolute',
          right: 0,
          marginRight: '10px',
        }}
        description='The list of hosts enabled for credentialed scanning.'
        newStyle
      >
        <DisplayTable headers={headers} rows={rows} />
      </RowTile>

      <CredScanModal
        visible={modalOpen}
        onClose={closeModal}
        credToEdit={credToEdit}
      />
    </StyledDiv>
  );
};
