import $ from 'jquery';
import {
  IsAWNUser,
  corpStyles,
  RsIsSecurityAnalyst,
  logout,
} from '../../utils/auth/auth';
import { getAnalyticsHost } from '../../Global/configReducer';

const setContentHeight = function () {
  const $BODY = $('body');
  const $SIDEBAR_FOOTER = $('.sidebar-footer');
  const $LEFT_COL = $('.left_col');
  const $RIGHT_COL = $('.right_col');
  const $NAV_MENU = $('.nav_menu');
  const $FOOTER = $('footer');
  // reset height
  $RIGHT_COL.css('min-height', $(window).height());

  const bodyHeight = $BODY.outerHeight();
  const footerHeight = $BODY.hasClass('footer_fixed') ? -10 : $FOOTER.height();
  const leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height();
  let contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;

  // normalize content
  contentHeight -= $NAV_MENU.height() + footerHeight;

  $RIGHT_COL.css('min-height', contentHeight);
};

export function legacyUpdateMenuLinks() {
  const analytics = document.getElementById('rs-analytics-link');
  if (analytics) {
    analytics.href = getAnalyticsHost();
  }

  if (RsIsSecurityAnalyst()) {
    const html = $('#rs-console-link').html().replace('Scanner', 'Analyst');

    $('#rs-console-link').html(html);
  }

  const usermgmtmenu = document.getElementById('rs-user-mgmt-menu-link');
  if (
    usermgmtmenu &&
    (localStorage.getItem('user_role') === 'Company Administrator' ||
      localStorage.getItem('user_role') === 'Security Analyst')
  ) {
    usermgmtmenu.style.display = 'block';
  }

  if (IsAWNUser()) {
    const ascView = document.getElementById('rs-asc-link');
    if (ascView !== null) ascView.style.display = 'block';
  }

  const logoutBtn = $('#rs-logout-link');
  if (logoutBtn) {
    logoutBtn.click(function (e) {
      e.preventDefault();
      logout();
    });
  }

  const copyright = document.getElementById('rs-copyright-div');
  if (copyright) {
    copyright.innerHTML = corpStyles.copyright;
  }
}

export const clickHandlers = () => {
  const $SIDEBAR_MENU = $('#sidebar-menu');
  $SIDEBAR_MENU.find('a').on('click', function (ev) {
    var $li = $(this).parent();

    if ($li.is('.active')) {
      $li.removeClass('active active-sm');
      $('ul:first', $li).slideUp(function () {
        setContentHeight();
      });
    } else {
      // prevent closing menu if we are on child menu
      if (!$li.parent().is('.child_menu')) {
        $SIDEBAR_MENU.find('li').removeClass('active active-sm');
        $SIDEBAR_MENU.find('li ul').slideUp();
      } else {
        if ($('body').is('.nav-sm')) {
          $SIDEBAR_MENU.find('li').removeClass('active active-sm');
          $SIDEBAR_MENU.find('li ul').slideUp();
        }
      }
      $li.addClass('active');

      $('ul:first', $li).slideDown(function () {
        setContentHeight();
      });
    }
  });
};

// moved here from /public/js/sensor_groups/util/object_extensions.js
Object.isEmpty = function (obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

Object.size = function (obj) {
  var size = 0;

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }

  return size;
};

Date.prototype.format = function (mask, utc) {
  return dateFormat(this, mask, utc);
};

//pulled out of  /public/js/fullstory.js
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = 'o-1JY8D8-na1';
window['_fs_namespace'] = 'FS';
(function (m, n, e, t, l, o, g, y) {
  // only run in prod, not local, test or beta
  if (
    window.location.href.toLowerCase().indexOf('risk.arcticwolf.com') > -1 &&
    window.location.href.toLowerCase().indexOf('beta-risk.arcticwolf.com') ===
      -1
  ) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log(
          'FullStory namespace conflict. Please set window["_fs_namespace"].'
        );
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = 'anonymous';
    o.src = 'https://' + _fs_script;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function (i, v, s) {
      g(l, { uid: i }, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g('event', { n: i, p: v }, s);
    };
    g.anonymize = function () {
      g.identify(!!0);
    };
    g.shutdown = function () {
      g('rec', !1);
    };
    g.restart = function () {
      g('rec', !0);
    };
    g.log = function (a, b) {
      g('log', [a, b]);
    };
    g.consent = function (a) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
    g.setVars = function (n, p) {
      g('setVars', [n, p]);
    };
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y])
      m[y] = function () {
        return g._w[y].apply(this, arguments);
      };
    g._v = '1.3.0';
  }
})(window, document, window['_fs_namespace'], 'script', 'user');
