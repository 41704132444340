import React, { useState, useMemo } from 'react';
import AssetTable from '../../AssetTable';
import {
  WindowsUSBDeviceType,
  LinuxUSBDeviceType,
  MacUSBDeviceType,
  USBDevicesByOS,
} from './parser';

import { ColumnProps, SortState } from '../../../../Reusables/table/Table';
import { sortData } from '../../utils';
import { AGENT_OS } from '../../../../Constants/Assets';

const WINDOWS_USB_DEVICES_COLUMNS: ColumnProps<WindowsUSBDeviceType>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'deviceID',
    dataIndex: 'deviceID',
    title: 'Device ID',
    sortable: true,
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
    sortable: true,
  },
];

const LINUX_USB_DEVICES_COLUMNS: ColumnProps<LinuxUSBDeviceType>[] = [
  {
    key: 'deviceId',
    dataIndex: 'id',
    title: 'Device ID',
    sortable: true,
  },
  {
    key: 'bus',
    dataIndex: 'bus',
    title: 'Bus',
    sortable: true,
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'device',
    dataIndex: 'device',
    title: 'Device',
    sortable: true,
  },
];

const MAC_USB_DEVICES_COLUMNS: ColumnProps<MacUSBDeviceType>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'manufacturer',
    dataIndex: 'manufacturer',
    title: 'Manufacturer',
    sortable: true,
  },
  {
    key: 'speed',
    dataIndex: 'speed',
    title: 'Speed',
    sortable: true,
  },
  {
    key: 'version',
    dataIndex: 'version',
    title: 'Version',
    sortable: true,
  },
  {
    key: 'vendorId',
    dataIndex: 'vendorID',
    title: 'Vendor ID',
    sortable: true,
  },
  {
    key: 'productId',
    dataIndex: 'productID',
    title: 'Product ID',
    sortable: true,
  },
  {
    key: 'serialNumber',
    dataIndex: 'serialNumber',
    title: 'Serial Number',
    sortable: true,
  },
];

const COLUMNS_BY_OS = {
  [AGENT_OS.WINDOWS]: WINDOWS_USB_DEVICES_COLUMNS,
  [AGENT_OS.MACOS]: MAC_USB_DEVICES_COLUMNS,
  [AGENT_OS.LINUX]: LINUX_USB_DEVICES_COLUMNS,
};

export type Props<OS extends AGENT_OS> = {
  os: OS;
  usbDevicesData: USBDevicesByOS[OS][];
  isLoading: boolean;
};

const USBDevicesTable = <OS extends AGENT_OS>({
  isLoading,
  os,
  usbDevicesData,
}: Props<OS>) => {
  type DataType = USBDevicesByOS[OS];
  type USBDevicesField = keyof DataType;

  const [sortCol, setSortCol] = useState<USBDevicesField>();
  const [sortState, setSortState] = useState<SortState>('none');

  const handleSort = (col: string, state: SortState) => {
    setSortCol(col as USBDevicesField);
    setSortState(state);
  };

  const data = useMemo(() => {
    if (!sortCol || sortState === 'none') {
      return usbDevicesData;
    }

    return sortData<USBDevicesByOS[OS]>(
      sortState === 'dsc',
      sortCol as USBDevicesField,
      [...usbDevicesData]
    );
  }, [usbDevicesData, sortCol, sortState]);

  return (
    <AssetTable
      data={data}
      columns={COLUMNS_BY_OS[os] as unknown as ColumnProps<DataType>[]}
      isLoading={isLoading}
      handleSort={handleSort}
      isSearch
    />
  );
};

export default USBDevicesTable;
