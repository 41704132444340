import { ColumnProps, Table } from '../../Reusables/table/Table';
import { ReachTableColumn, StyledContainer } from './utils';

export interface Props {
  data: ReachTableColumn[];
  columns: ColumnProps<ReachTableColumn>[];
}

const ReachTable = ({ data, columns }: Props) => {
  return (
    <StyledContainer data-testid='Asc-reach-table'>
      <Table
        data={data}
        columns={columns}
        dataUniqueKey='id'
        emptyComponent={<div>No Data Available</div>}
      />
    </StyledContainer>
  );
};

export default ReachTable;
