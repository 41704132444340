import React, { useCallback, useMemo } from 'react';
import { closeCaseThunk } from '../../Global/caseActions';
import { closeModal } from '../modalActions';
import {
  Banner,
  Button,
  HStack,
  Primitive,
  Select,
  VStack,
} from '@rtkwlf/fenrir-react';
import { iState } from '../../configureStore';

import { useSelector } from 'react-redux';
import { riskSelectors } from '../../Global/riskReducer';
import { RISK_SELECTABLE_STATES } from '../../Constants/Risks';
import { iCase } from '../../Global/caseReducer';
import { ModalBox } from '../../Reusables/ModalBox';
import { useAppDispatch } from '../../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CloseCase: React.FC<Props> = ({ onClose, isOpen }) => {
  const dispatch = useAppDispatch();
  const casesMap = useSelector((state: iState) => state.cases.cases);
  const assignedRiskMap = useSelector(riskSelectors.getAssignedRiskMap);
  const caseOptions = React.useMemo(
    () =>
      Array.from(casesMap.values()).map((Case: iCase) => ({
        text: Case.title,
        value: Case.id,
      })),
    [casesMap]
  );
  const [selectedCase, setSelectedCase] = React.useState<string | undefined>();

  const isHavingOpenRisk = useMemo(() => {
    const assignedRisks = Array.from(assignedRiskMap.values());

    return assignedRisks.some(
      (assignedRisk) =>
        assignedRisk.attributes.caseId === selectedCase &&
        assignedRisk.state === RISK_SELECTABLE_STATES.OPEN
    );
  }, [selectedCase, assignedRiskMap]);

  React.useEffect(() => {
    // reset selection when modal is re-opened
    setSelectedCase(undefined);
  }, [isOpen]);

  const closeCase = useCallback(
    (caseId: string) => {
      dispatch(
        closeCaseThunk(caseId, {
          status: 'Resolved',
          resolutionStatus: 'TruePositive',
          summary: 'Fixed all vulnerabilities',
        })
      );
      closeModal();
    },
    [dispatch]
  );

  const onOk = React.useCallback(() => {
    if (selectedCase) {
      closeCase(selectedCase);
      onClose();
    }
  }, [closeCase, onClose, selectedCase]);

  return (
    <ModalBox
      title='Close Plan'
      onCloseModal={onClose}
      isOpen={isOpen}
      width='520px'
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button onClick={onOk} isDisabled={!selectedCase}>
            Close Plan
          </Button>
        </HStack>
      }
    >
      <VStack paddingX='large' paddingBottom='large' gap='xsmall'>
        <Primitive.div width='full'>
          <Select
            placeholder='Select a plan...'
            options={caseOptions}
            value={selectedCase}
            onValueChange={(value) => setSelectedCase(value)}
          />
        </Primitive.div>
        {isHavingOpenRisk && (
          <Banner
            marginTop='smedium'
            appearance='danger'
            description='Plan still has open risks'
          />
        )}
      </VStack>
    </ModalBox>
  );
};
