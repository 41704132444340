import { iState } from '../configureStore';
import { LOAD_CUSTOMERS, SET_CUSTOMER } from './customerActions';

export interface iCustomer {
  deploymentID: string;
  id: string;
  label: string;
  name: string;
}

export interface iAwnCustomer {
  name: string;
  id: string;
  riskID: string;
}

export interface iCustomerState {
  currentCustomer: iCustomer;
  customers: Map<string, iAwnCustomer>;
}

const initialState: iCustomerState = {
  currentCustomer: JSON.parse(
    localStorage.getItem('currentCustomer') as string
  ),
  customers: new Map(),
};

export const customerReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  const newState = {
    ...state,
  };
  if (!payload) return state;
  switch (type) {
    case LOAD_CUSTOMERS:
      payload.forEach((customer: iAwnCustomer) => {
        newState.customers.set(customer.riskID, customer);
      });
      return newState;
    case SET_CUSTOMER:
      newState.currentCustomer = payload.customer;
      return newState;
    default:
      return state;
  }
};

export const customerSelectors = {
  getCustomerId: (store: iState) => store.customer.currentCustomer?.id,
  getCurrentCustomerDetails: (store: iState) => store.customer.currentCustomer,
};
