import React, { useEffect } from 'react';
import {
  toggleScanThunk,
  updatePreferencesThunk,
} from '../../Global/scannerActions';
import {
  Button,
  Switch,
  Text,
  HStack,
  VStack,
  Checkbox,
  Primitive,
  BannerRoot,
  BannerContent,
  BannerIcon,
  BannerTextContent,
  BannerDescription,
  useToastContext,
} from '@rtkwlf/fenrir-react';
import {
  TROUBLESHOOTING_WARNING_ONE,
  TROUBLESHOOTING_WARNING_TWO,
  TROUBLESHOOTING_WARNING_THREE,
} from '../../Constants/Common';
import { useAppDispatch } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { ModalBox } from '../../Reusables/ModalBox';
import {
  useScannerConfig,
  useScannerPrefs,
} from '../../Pages/ScannerConfig/ScannerQueries';
import { AxiosResponse } from 'axios';

type ScannerPref = {
  id: number;
  enabled: boolean;
};

type Props = {
  id: string;
  visible: boolean;
  onClose: () => void;
};

export const TroubleshootingSettings = ({ id, onClose, visible }: Props) => {
  const dispatch = useAppDispatch();
  const { addToast } = useToastContext();

  const [warningRead, setWarningRead] = React.useState(false);
  const { data: scannerConfig } = useScannerConfig(id);
  const { data: scannerPrefs } = useScannerPrefs(id);

  const [bruteforce, setbruteforce] = React.useState(
    !scannerPrefs?.data?.find((pref: ScannerPref) => pref.id === 1)?.enabled
  );
  const [cgi, setCgi] = React.useState(
    !scannerPrefs?.data?.find((pref: ScannerPref) => pref.id === 2)?.enabled
  );
  useEffect(() => {
    setbruteforce(
      !scannerPrefs?.data?.find((pref: ScannerPref) => pref.id === 1)?.enabled
    );
    setCgi(
      !scannerPrefs?.data?.find((pref: ScannerPref) => pref.id === 2)?.enabled
    );
  }, [scannerPrefs?.data]);

  useEffect(() => {
    setPing(scannerConfig?.data?.pingOnly);
  }, [scannerConfig?.data]);

  const toggleBruteforce = () => {
    const index = scannerPrefs?.data
      .map((pref: ScannerPref) => pref.id)
      .indexOf(1);
    if (index > -1 && scannerPrefs) {
      scannerPrefs.data[index].enabled = bruteforce;
    }
    dispatch(
      updatePreferencesThunk([
        {
          id: 1,
          enabled: bruteforce,
        },
      ])
    );
    setbruteforce(!bruteforce);
  };

  const toggleCGI = () => {
    const index = scannerPrefs?.data
      .map((pref: ScannerPref) => pref.id)
      .indexOf(2);
    if (index > -1 && scannerPrefs) {
      scannerPrefs.data[index].enabled = cgi;
    }
    dispatch(
      updatePreferencesThunk([
        {
          id: 2,
          enabled: cgi,
        },
      ])
    );
    setCgi(!cgi);
  };

  const [ping, setPing] = React.useState(scannerConfig?.data?.pingOnly);

  const togglePing = () => {
    if (scannerConfig?.data) {
      scannerConfig.data.pingOnly = !ping;
    }
    dispatch(
      toggleScanThunk(id, {
        pingOnly: !ping,
      })
    );
    setPing(!ping);
  };

  const stopScan = () => {
    dispatch(
      toggleScanThunk(id, {
        scans: false,
        nmap: false,
        hardstop: true,
      })
    );
    setWarningRead(false);
    onClose();
    addToast({
      title: 'Success',
      message:
        'Request to stop scanning has been sent. You can review the Scanning Queue for progress.',
      duration: 20,
      appearance: 'success',
    });
  };

  return (
    <ModalBox
      onCloseModal={onClose}
      isOpen={visible}
      title='Troubleshooting Settings'
      width='700px'
      footer={
        <HStack xAlign='between' width='full'>
          <Button
            appearance='danger'
            onClick={stopScan}
            isDisabled={!warningRead}
          >
            Stop All Scanning Now
          </Button>
          <Button variant='secondary' onClick={onClose}>
            Close
          </Button>
        </HStack>
      }
    >
      <VStack paddingX='large' paddingBottom='large' gap='xsmall'>
        <Text>
          Only adjust these settings to troubleshoot or during an emergency.
        </Text>
        <VStack marginTop='medium' gap='xsmall'>
          <HStack yAlign='center' marginY='small' gap='small'>
            <Switch.Root
              margin='zero'
              aria-label='Brute force checks (normally turned on)'
              onCheckedChange={toggleBruteforce}
              defaultIsChecked={
                !scannerPrefs?.data?.find((pref: any) => pref.id === 1)?.enabled
              }
            >
              <Switch.Thumb />
            </Switch.Root>
            <Text>Brute force checks (normally turned on)</Text>
          </HStack>
          <HStack yAlign='center' marginY='small' gap='small'>
            <Switch.Root
              margin='zero'
              aria-label='CGI scanning (normally turned on)'
              onCheckedChange={toggleCGI}
              defaultIsChecked={
                !scannerPrefs?.data?.find((pref: any) => pref.id === 2)?.enabled
              }
            >
              <Switch.Thumb />
            </Switch.Root>
            <Text>CGI scanning (normally turned on)</Text>
          </HStack>
          <HStack yAlign='center' marginY='small' gap='small'>
            <Switch.Root
              margin='zero'
              aria-label='Only ping the target (normally turned off)'
              onCheckedChange={togglePing}
              defaultIsChecked={scannerConfig?.data?.pingOnly}
            >
              <Switch.Thumb />
            </Switch.Root>
            <Text>Only ping the target (normally turned off)</Text>
          </HStack>
        </VStack>

        <VStack marginTop='medium'>
          <Text styledAs='body2'>Stop All Scanning Now</Text>
          <Text>
            Stopping all scanning has many repercussions. Before you can stop
            all scanning, you must acknowledge the following:
          </Text>
          <BannerRoot appearance='warning'>
            <BannerContent>
              <BannerIcon>
                <FontAwesomeIcon icon={faCircleExclamation} />
              </BannerIcon>
              <BannerTextContent>
                <BannerDescription>
                  <Primitive.ul margin='zero'>
                    <Primitive.li>{TROUBLESHOOTING_WARNING_ONE}</Primitive.li>
                    <Primitive.li>{TROUBLESHOOTING_WARNING_TWO}</Primitive.li>
                    <Primitive.li>{TROUBLESHOOTING_WARNING_THREE}</Primitive.li>
                  </Primitive.ul>
                </BannerDescription>
              </BannerTextContent>
            </BannerContent>
          </BannerRoot>
          <Checkbox.Root
            id='warning-read'
            isChecked={warningRead}
            onCheckedChange={() => setWarningRead(!warningRead)}
          >
            <Checkbox.Text>
              I have read and understand the implications of Stop All Scanning
              Now.
            </Checkbox.Text>
          </Checkbox.Root>
        </VStack>
      </VStack>
    </ModalBox>
  );
};
