import { AssetCSVHeader } from './AssetCatalog';

export function useExportAssetsHeaders() {
  const exportAssetsHeaders: AssetCSVHeader[] = [
    { label: 'Device ID', key: 'id' },
    { label: 'Asset IP', key: 'ip' },
    { label: 'Device Name', key: 'deviceName' },
    { label: 'Category', key: 'category' },
    { label: 'Tags', key: 'tagCSV' },
    {
      label: 'Asset Criticality',
      key: 'critCSV',
    },
  ];

  return exportAssetsHeaders;
}
