import React from 'react';

const Footer = () => (
  <footer className='cerberus-col-margin-left'>
    <div className='pull-right'>
      <div
        id='rs-copyright-div'
        style={{
          display: 'inline-block',
          paddingRight: '50px',
          color: '#23618E',
        }}
      ></div>
      <a
        id='rs-terms'
        style={{ display: 'inline-block', color: '#23618E' }}
        href='https://arcticwolf.com/privacy-policy-for-customer-portal-users/'
      >
        Privacy & Terms
      </a>
    </div>
    <div className='clearfix'></div>
  </footer>
);

export default Footer;
