import React from 'react';
import { RowTileV2 } from '../../../Reusables/RowTileV2';
import { ScheduleNestedRenderer } from '../ScheduleNestedRenderer';
import AscPanelSubtitle from '../AscPanelSubtitle';
import { AgentNestedRenderer } from './AgentNestedRenderer';
import { ContainerDiv } from '../utils';

export type Props = {
  description: string;
  title?: string;
};

export const AscAgentContainer = ({ description, title }: Props) => {
  return (
    <ContainerDiv>
      <RowTileV2
        data-testid='table-row-header'
        id='AscAgentCoverage'
        title={title || 'Agent Coverage'}
        description={description}
        buttons={<div></div>}
      >
        <AscPanelSubtitle title={'Agents Not Being Scanned'} />
        <AgentNestedRenderer />
        <AscPanelSubtitle title={'Agent Schedules'} />
        <ScheduleNestedRenderer />
      </RowTileV2>
    </ContainerDiv>
  );
};
