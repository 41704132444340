// corporateid is an alias for partner id which was something that User Management used to support.  The following is the mapping:
// 0 is RootCellar
// 32 is ArcticWolf
// 75 is Arctic Wolf Trial (eVA)
// 0 and 32 are roughly equivalent now

import { RSLogout } from './authentication';

const ROOT_CELLER = '0';
const ARCTIC_WOLF = '32';
const ARCTIC_WOLF_PRESALES = '75';

export const IsAWNUser = () => {
  const partnerID = localStorage.getItem('partner_id');
  return (
    partnerID === ROOT_CELLER ||
    partnerID === ARCTIC_WOLF ||
    partnerID === ARCTIC_WOLF_PRESALES
  );
};

export const IsAwnTrial = () => {
  var partnerID = localStorage.getItem('partner_id');
  return RsIsSecurityAnalyst() && partnerID === ARCTIC_WOLF_PRESALES;
};

export const IsRsUser = () => {
  return RsIsSecurityAnalyst() && IsAWNUser();
};

export const RsIsSecurityAnalyst = () => {
  return localStorage.getItem('user_role') === 'Security Analyst';
};

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('auth_attempted');
  localStorage.removeItem('auth_authenticated');
  localStorage.removeItem('user_role');
  localStorage.removeItem('partner_id');
  localStorage.removeItem('login_id');
  localStorage.removeItem('sensor');
  localStorage.removeItem('sensor_group');
  localStorage.removeItem('sensor_view_mode');

  try {
    RSLogout();
  } catch (err) {
    console.error('Failed to logout');
  }
};

// maintaining the corpStyles object to keep historical context of where this came from
export const corpStyles = {
  copyright: 'Copyright &copy;Arctic Wolf. All rights reserved.',
};
