/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

// Components

import { CustomerSelect } from './CustomerSelect';
import { CSV } from './CSV';
import TopTiles from './TopTiles';
import { WHITE } from '../../Constants/Styles';

// Legacy Utility Functions

import { toggleMenu } from './utils';
import { iState } from '../../configureStore';
interface Props extends PropsFromRedux {
  isSmallIcon: boolean;
  setIsSmallIcon: any;
}

const StyledContainer = styled.div`
  padding: 1px;
  background: ${WHITE.secondary};
`;

const StyledBar = styled.div`
  display: flex;
  margin-left: '28px';
  justify-content: space-between;
`;

const StyledButtonBar = styled.div`
  display: flex;
  list-style: none;
`;

const StyledIcon = styled.i`
  color: #002244;
`;

const TopNav = ({ isAuthorized, isSmallIcon, setIsSmallIcon }: Props) => {
  return (
    <StyledContainer
      className={'top_nav cerberus-col-margin-left'}
      style={{ marginRight: '-10px' }}
    >
      <div className='nav_menu'>
        {isAuthorized && (
          <Fragment>
            <div
              className='nav toggle'
              onClick={() => {
                setIsSmallIcon(!isSmallIcon);
                toggleMenu();
              }}
            >
              <a id='menu_toggle'>
                <StyledIcon className='fa fa-bars' />
              </a>
            </div>
            <StyledBar>
              <CustomerSelect />
              <StyledButtonBar>
                <li>
                  <CSV />
                </li>
              </StyledButtonBar>
            </StyledBar>
          </Fragment>
        )}
      </div>
      {isAuthorized && <TopTiles />}
    </StyledContainer>
  );
};

const mapStateToProps = ({ location }: iState) => ({
  isAuthorized:
    // @ts-ignore
    location.type !== '@@redux-first-router/NOT_FOUND' &&
    !!sessionStorage.getItem('auth_authenticated'),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TopNav);
