import React from 'react';
import { iState } from '../../configureStore';
import styled from 'styled-components';

import { DisplayTable } from '../../Reusables/DisplayTable';
import { BLUE } from '../../Constants/Styles';
import { setScannerThunk } from '../../Global/scannerActions';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';

const StyledContainer = styled.div`
  width: 1080px;
  margin: 40px;
  max-height: 100vh;
`;

const StyledTitle = styled.h3`
  color: ${BLUE.primary};
`;

const headers = ['Name', 'Deployment ID', 'ID'];

type Props = {
  closeModal: () => void;
};

export const ScannerSelect: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useAppDispatch();

  const scanners = useSelector((state: iState) =>
    Array.from(state.scanner.scanners.values())
  );

  const filters: Array<any> = [
    {
      text: '3',
      value: '3',
    },
    {
      text: '10',
      value: '10',
    },
    {
      text: '20',
      value: '20',
    },
  ];

  const selectScanner = (row: Array<string>) => {
    dispatch(
      setScannerThunk(
        {
          label: row[0],
          deploymentID: row[1],
          id: row[2],
        },
        true
      )
    );
  };

  return (
    <StyledContainer>
      <StyledTitle>Scanner Select</StyledTitle>
      <DisplayTable
        filterOptions={filters}
        rowClick={(row: Array<string>) => {
          selectScanner(row);
          closeModal();
        }}
        headers={headers}
        rows={scanners.map((customer) => [
          customer.label,
          customer.deploymentID,
          customer.id,
        ])}
      />
    </StyledContainer>
  );
};
