export type StatusType =
  | 'Connected'
  | 'Degraded'
  | 'Disconnected'
  | 'Idle'
  | 'Loading'
  | 'No Brute Force Checks'
  | 'No CGI Scanning'
  | 'Not Configured'
  | 'Not Scanning'
  | 'Ping Only'
  | 'Running'
  | 'Scheduled'
  | 'Scanning'
  | 'Waiting'
  | 'Misconfigured'
  | 'Disabled';

export const STATUS: Record<string, StatusType> = {
  CONNECTED: 'Connected',
  DEGRADED: 'Degraded',
  DISCONNECTED: 'Disconnected',
  IDLE: 'Idle',
  LOADING: 'Loading',
  NO_BRUTE_FORCE: 'No Brute Force Checks',
  NO_CGI: 'No CGI Scanning',
  NOT_CONFIGURED: 'Not Configured',
  NOT_SCANNING: 'Not Scanning',
  PING_ONLY: 'Ping Only',
  RUNNING: 'Running',
  SCHEDULED: 'Scheduled',
  SCANNING: 'Scanning',
  WAITING: 'Waiting',
  MISCONFIGURED: 'Misconfigured',
  DISABLED: 'Disabled',
};
