import React, { useState } from 'react';

import InfoRows, { SourceRenderer } from './InfoRows';
import HardwareInfoRow from './HardwareInfoRow';
import Chart from 'react-google-charts';
import {
  defaultEnrichmentCards,
  EnrichmentCardType,
  getAgentDetailsField,
  getEnrichmentData,
  HardwareDetailsType,
} from './AssetDetails';
import AssetEditDetails from './AssetEditDetails';

import styled from 'styled-components';
import { GREY } from '../../../Constants/Styles';
import CardToggleButton from './CardToggleButton';
import { TagsRenderer } from './InfoRowRenderers/TagsRenderer';
import { CriticalityPill } from './InfoRowRenderers/CriticalityRenderer';

const cardHeight = '260px';

const WrapStyle = styled.div`
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid ${GREY.lightTertiary};
  box-sizing: border-box;
  border-radius: 4px;
  min-height: ${cardHeight};
`;

const CardContainer = styled.div`
  border: 1px solid ${GREY.lightTertiary};
  margin-bottom: 15px;
`;

const StyledWrapInfo = styled.div<{ $isExpanded: boolean }>`
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  height: ${(props) => (props.$isExpanded ? 'auto' : cardHeight)};
`;

type AgentDataType = {
  details: { [id: string]: string };
  location: { [id: string]: string | number };
  hostIdentification: { [id: string]: string };
  advancedIdentification: { [id: string]: string | number };
  profileActivity: { [id: string]: string };
};

type StateProps = {
  data: AgentDataType;
  hardWareDetails: HardwareDetailsType;
  profileImage: React.ReactNode;
};

export type Props = StateProps;

export const AgentDetails = ({
  data,
  hardWareDetails,
  profileImage,
}: Props) => {
  const [enrichmentCards, setEnrichmentCards] = useState<EnrichmentCardType>(
    defaultEnrichmentCards
  );
  const moreFieldsByOS = getEnrichmentData(hardWareDetails);
  const agentDetailsFields = getAgentDetailsField();

  // set the data for world map marker
  const mapLocationData = [
    ['Latitude', 'Longitude', { type: 'string', role: 'tooltip' }],
    [
      data?.location?.latitude,
      data?.location?.longitude,
      `${data?.location?.city} - ${data?.location?.country}`,
    ],
  ];

  const toggleCard = (enrichmentCardKey: string) => {
    const cardKey = enrichmentCardKey as keyof EnrichmentCardType;
    setEnrichmentCards((prevState) => ({
      ...prevState,
      [cardKey]: {
        ...prevState[cardKey],
        isExpanded: !prevState[cardKey].isExpanded,
      },
    }));
  };

  const hostIdentification = enrichmentCards.hostIdentification;
  const advanceIdentification = enrichmentCards.advancedIdentification;
  const profileActivity = enrichmentCards.profileActivity;

  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-xs-12'>
          <WrapStyle>
            <InfoRows
              key='details'
              title='Details'
              columns={agentDetailsFields.details}
              data={data.details}
              infoHeader={
                <AssetEditDetails
                  data={data.details}
                  assetId={data.hostIdentification.assetId}
                />
              }
              profileImage={profileImage}
              renderers={{
                source: SourceRenderer,
                criticality: CriticalityPill,
                tagIDs: TagsRenderer,
              }}
            />
          </WrapStyle>
        </div>
        <div className='col-md-6 col-xs-12'>
          <WrapStyle>
            <div className='row'>
              <div className='col-md-6 col-xs-12'>
                <InfoRows
                  key='location'
                  title='Location'
                  columns={agentDetailsFields.location}
                  data={data.location}
                />
              </div>
              <div className='col-md-6 col-xs-12'>
                {data?.location?.latitude !== 0 &&
                data?.location?.longitude !== 0 ? (
                  <Chart
                    width={'auto'}
                    height={'180px'}
                    chartType='GeoChart'
                    rootProps={{ 'data-testid': '1' }}
                    data={mapLocationData}
                    options={{
                      colorAxis: {
                        colors: ['#BDEFE5', '#23618E'],
                      },
                      datalessRegionColor: '#395067',
                      defaultColor: '#F58220',
                    }}
                  />
                ) : null}
              </div>
            </div>
          </WrapStyle>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4 col-xs-12'>
          <CardContainer>
            <StyledWrapInfo $isExpanded={hostIdentification.isExpanded}>
              <InfoRows
                key={hostIdentification.key}
                title={hostIdentification.title}
                columns={agentDetailsFields.hostIdentification}
                data={data.hostIdentification}
              />
              <HardwareInfoRow
                dataRows={moreFieldsByOS?.hostIdentification}
                isExpanded={hostIdentification.isExpanded}
              />
            </StyledWrapInfo>
            <CardToggleButton
              cardObj={hostIdentification}
              fieldsByOS={moreFieldsByOS}
              toggleCard={toggleCard}
            />
          </CardContainer>
        </div>

        <div className='col-md-4 col-xs-12'>
          <CardContainer>
            <StyledWrapInfo
              $isExpanded={advanceIdentification.isExpanded}
              data-testid='advancedIdentification'
            >
              <InfoRows
                key={advanceIdentification.key}
                title={advanceIdentification.title}
                columns={agentDetailsFields.advancedIdentification}
                data={data.advancedIdentification}
              />
              <HardwareInfoRow
                dataRows={moreFieldsByOS?.advanceIdentification}
                isExpanded={advanceIdentification.isExpanded}
              />
            </StyledWrapInfo>
            <CardToggleButton
              cardObj={advanceIdentification}
              fieldsByOS={moreFieldsByOS}
              toggleCard={toggleCard}
            />
          </CardContainer>
        </div>
        <div className='col-md-4 col-xs-12'>
          <CardContainer>
            <StyledWrapInfo $isExpanded={profileActivity.isExpanded}>
              <InfoRows
                key={profileActivity.key}
                title={profileActivity.title}
                columns={agentDetailsFields.profileActivity}
                data={data.profileActivity}
              />
              <HardwareInfoRow
                dataRows={moreFieldsByOS?.profileActivity}
                isExpanded={profileActivity.isExpanded}
              />
            </StyledWrapInfo>
            <CardToggleButton
              cardObj={profileActivity}
              fieldsByOS={moreFieldsByOS}
              toggleCard={toggleCard}
            />
          </CardContainer>
        </div>
      </div>
    </>
  );
};

export default AgentDetails;
