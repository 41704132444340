import * as React from 'react';

import styled, { css } from 'styled-components';

import { atoms, Primitive, Text } from '@rtkwlf/fenrir-react';
import { GlobalTheme, useTheme } from '../../types/legacyTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export interface TableLoadingProps {
  loadingComponent?: React.ReactNode;
}

const LoadingContainer = styled(Primitive.div)`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingMask = styled.div<{ theme: GlobalTheme }>`
  ${({ theme }: { theme: GlobalTheme }) => css`
    height: 100%;
    width: 100%;

    opacity: 0.8;
    background: ${theme.colors.primaryBackground};
  `}
`;

const LoadingText = styled(Text).attrs({ styledAs: 'body1' })`
  position: absolute;
`;

const iconClass = atoms({
  textColor: 'brandDefault',
  marginRight: 'small',
});

export const TableLoading: React.FunctionComponent<TableLoadingProps> = ({
  loadingComponent,
}) => {
  const theme = useTheme();

  return (
    <LoadingContainer>
      <LoadingMask theme={theme} />
      <LoadingText theme={theme}>
        <FontAwesomeIcon icon={faCircleNotch} className={iconClass} spin />
        {loadingComponent == null ? 'Loading Data...' : loadingComponent}
      </LoadingText>
    </LoadingContainer>
  );
};
