import { SET_PAGE_STATE, SET_SERVICE_HOST } from './configActions';
import { SET_ASSIGNED_RISKS } from './riskActions';
import { CaseParams, CasesMeta } from '../types/cases';
import { CaseActionTypes } from './actionEnums';
import { iState } from '../configureStore';
import { SET_AUTH0_LOADING_COMPLETE } from '../utils/auth/authentication';

export interface iStyles {
  color: string;
  image: string;
  imageStyle: string;
  imagePrefix: string;
  domain: string;
  riskCritical: string;
  riskHigh: string;
  riskMedium: string;
  riskLow: string;
  emailSupport: string;
  productName: string;
  logAnalysisEnabled: number;
  partnerId: number;
  css: string;
  terms: string;
  copyright: string;
  favicon: string;
}

export interface iEnvironment {
  dev: boolean;
  servicesHost: string;
}

export interface iConfigState {
  styles: iStyles;
  environment: iEnvironment;
  pageState: {
    [key: string]: any;
    cases?: {
      meta?: CasesMeta;
      filters?: CaseParams;
    };
  };
  isAuth0Loading: boolean;
}

export interface iConfigAction {
  type: string;
  payload: any;
}

export const getAnalyticsHost = () => {
  const pod = localStorage.getItem('apiPod');
  const host = window.location.hostname;

  if (!pod) {
    console.error('panic: no pod found in localStorage');
  }
  if (host === 'localhost' || host === 'risk.global-test.arcticwolf.net') {
    return 'https://analytics.rootsoc.com';
  }

  return `https://analytics.${pod}.arcticwolf.net`;
};

export const getServicesHost = () => {
  const isE2eTesting = window.localStorage.getItem('e2e_testing');
  const enableProd = window.localStorage.getItem('enable_prod');

  if (isE2eTesting === 'true') return 'http://localhost:7001';

  const isUsingRootsecure = localStorage.getItem('ff_local_rootsecure');
  if (isUsingRootsecure === 'true') return 'http://localhost:8080';

  const host = window.location.hostname;
  if (
    host === 'risk.global-test.arcticwolf.net' ||
    (host === 'localhost' && enableProd !== 'true')
  )
    return 'https://services.risk.us001-test.arcticwolf.net';

  const apiPod = localStorage.getItem('apiPod');
  const pod = apiPod === 'n/a' && host === 'localhost' ? 'us001-prod' : apiPod;

  if (!pod) {
    console.error('panic: no pod found in localStorage');
  }
  return `https://services.risk.${pod}.arcticwolf.net`;
};

const initialState: iConfigState = {
  styles: {
    color: '',
    image: '',
    imageStyle: '',
    imagePrefix: '',
    domain: '',
    riskCritical: '',
    riskHigh: '',
    riskMedium: '',
    riskLow: '',
    emailSupport: '',
    productName: '',
    logAnalysisEnabled: -1,
    partnerId: -1,
    css: '',
    terms: '',
    copyright: '',
    favicon: '',
  },
  environment: {
    dev: window.location.hostname !== 'dashboard.rootsoc.com',
    servicesHost: getServicesHost(),
  },
  pageState: {},

  // currently, the app experiences a flash of empty/broken dashboard while Auth0 is loading (fetching tokens, checking sessions, etc.)
  // if we ever want to display a proper loading screen, subscribe to this flag
  isAuth0Loading: false,
};

export const configReducer = (state = initialState, action: iConfigAction) => {
  const { payload, type } = action;
  const newState = { ...state };
  switch (type) {
    case SET_PAGE_STATE:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          ...payload,
        },
      };
    case CaseActionTypes.SET_CASES:
      newState.pageState = {
        ...state.pageState,
        cases: payload.pageState,
      };
      return newState;
    case SET_ASSIGNED_RISKS:
      const assignedRisks = { ...payload.pageState };
      if (payload.pageState?.filters?.caseId)
        delete payload.pageState?.filters?.caseId;
      newState.pageState = {
        ...state.pageState,
        assignedRisks,
      };
      return newState;
    case SET_SERVICE_HOST:
      const { host } = payload;
      newState.environment.servicesHost = host;
      return newState;
    case SET_AUTH0_LOADING_COMPLETE:
      newState.isAuth0Loading = false;
      return newState;
    default:
      return state;
  }
};

export const configSelectors = {
  getServiceHost: (store: iState) => store.config.environment.servicesHost,
  getAuth0: (store: iState) => store.config.isAuth0Loading,
};
