import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  HStack,
  Primitive,
  Select,
  SelectOption,
  Text,
  TextField,
  atoms,
} from '@rtkwlf/fenrir-react';
import { GREY } from '../Constants/Styles';
import { sortBy } from 'lodash';
import useDebounce from '../utils/useDebounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faSortAsc,
  faSortDesc,
} from '@fortawesome/free-solid-svg-icons';

const StyledContainer = styled.div`
  display: block;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: start;
  align-items: center;
  border-bottom: 2px solid #e6e9ed;

  &:hover {
    cursor: pointer;
  }
`;

const BaseStyling = styled.div`
  font-family: Lato;
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
`;

const StyledHeaderItem = styled(BaseStyling)<{ $count: number }>`
  color: ${GREY.bright};
  font-size: 16px;
  padding: 8px 16px;
  font-weight: bold;
  width: ${(props) => 100 / props.$count}%;
  display: flex;
  align-items: center;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: start;
  border-bottom: 1px solid #e6e9ed;
`;

const StyledRowItem = styled(BaseStyling)<{
  $count: number;
  $hasRowClickHandler: boolean;
}>`
  padding: 8px 16px;

  font-weight: normal;
  text-overflow: ellipsis;
  width: ${(props) => 100 / props.$count}%;
  ${(props) =>
    props.$hasRowClickHandler
      ? `
    &:hover {
      cursor: pointer;
    } 
    `
      : ''}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin: 2px;
  }
`;

const StyledText = styled.p`
  margin: auto 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const filters: SelectOption[] = [
  {
    text: 'All',
    value: '0',
  },
  {
    text: '3',
    value: '3',
  },
  {
    text: '10',
    value: '10',
  },
  {
    text: '20',
    value: '20',
  },
];

const noOp = () => {};

export const DisplayTable = ({
  defaultSortColumn = 0,
  filterOptions = filters,
  focus,
  headers = [],
  reverseSort,
  rowClick = noOp,
  rows = [],
}: any) => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [filter, setFilter] = useState(10);
  const [sortIndex, setSortIndex] = useState(defaultSortColumn);
  const [reverse, setReverse] = useState(reverseSort);
  const [selectedPagePerValue, setPagePerCount] = useState<string>(
    filterOptions[2].value
  );

  const searchInput = useRef<any>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchFilter(debouncedSearchTerm);
    } else {
      setSearchFilter('');
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (focus) {
      searchInput.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sortedRows = rows.filter((row: Array<Array<any>>) => {
    let isMatch = false;
    row.some((entry: any) => {
      if (typeof entry === 'string' || typeof entry === 'number') {
        isMatch = entry
          .toString()
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      }
      if (Array.isArray(entry)) {
        entry.some((value: any) => {
          if (typeof value === 'string' || typeof value === 'number') {
            isMatch = value
              .toString()
              .toLowerCase()
              .includes(searchFilter.toLowerCase());
          }
          return isMatch;
        });
      }
      return isMatch;
    });
    return isMatch;
  });

  // @ts-ignore
  const start: number = parseInt(page - 1) * (filter ? (filter as any) : 1);
  const end: number = parseInt(
    // @ts-ignore
    parseInt(start) + (filter ? parseInt(filter) : parseInt(sortedRows.length))
  );

  sortedRows = sortBy(sortedRows, (array: Array<Array<any>>) => {
    const column = array[sortIndex];
    if (Array.isArray(column)) {
      return column[0];
    }
    return column;
  });

  if (reverse) {
    sortedRows.reverse();
  }

  const displayedRows = sortedRows.slice(start, end);

  const getLastPage = () => {
    if (filterOptions) {
      return Math.ceil(sortedRows.length / (filter as any));
    }
    return 1;
  };

  const handlePageChange = (option: string) => {
    setPage(1);

    setPagePerCount(option || filterOptions[0].value);
    setFilter(Number(option ?? filterOptions[0].value));
  };

  return (
    <StyledContainer>
      <HStack xAlign='between' marginBottom='xsmall'>
        <HStack gap='small'>
          <Text styledAs='body3'>Show</Text>
          <Primitive.div width='5rem'>
            <Select
              isClearable={false}
              value={selectedPagePerValue}
              options={filterOptions}
              onValueChange={(value) => handlePageChange(value)}
            />
          </Primitive.div>
          <Text styledAs='body3'>Entries</Text>
        </HStack>
        <Primitive.div width='12rem'>
          <TextField
            ref={searchInput}
            value={searchTerm}
            placeholder='Search'
            onChange={(e: SyntheticEvent) =>
              setSearchTerm((e.target as HTMLInputElement).value)
            }
            iconRight={<FontAwesomeIcon icon={faSearch} />}
          />
        </Primitive.div>
      </HStack>
      <StyledHeader>
        {headers.map((element: any, index: number) => (
          <StyledHeaderItem
            $count={headers.length}
            key={element}
            onClick={() => {
              if (sortIndex !== index) {
                setSortIndex(index);
                setReverse(false);
              } else if (sortIndex === index && reverse) {
                setReverse(false);
              } else {
                setReverse(true);
              }
            }}
          >
            {element}
            <Primitive.div paddingTop='zero' paddingLeft='small'>
              <FontAwesomeIcon
                icon={faSortAsc}
                className={atoms({
                  textColor:
                    sortIndex === index && !reverse
                      ? 'brandDefault'
                      : 'accentGreyDefault',
                  position: 'absolute',
                })}
              />
              <FontAwesomeIcon
                icon={faSortDesc}
                className={atoms({
                  textColor:
                    sortIndex === index && reverse
                      ? 'brandDefault'
                      : 'accentGreyDefault',
                  display: 'block',
                })}
              />
            </Primitive.div>
          </StyledHeaderItem>
        ))}
      </StyledHeader>
      <StyledRowContainer>
        {displayedRows.map((row: any, i: number) => (
          <StyledRow onClick={() => rowClick(row)} key={i} role='row'>
            {row.map((element: any, i: number) => (
              <StyledRowItem
                $count={headers.length}
                $hasRowClickHandler={rowClick !== noOp}
                key={i}
              >
                {Array.isArray(element)
                  ? element.map((e: any, i: number) => <div key={i}>{e}</div>)
                  : element}
              </StyledRowItem>
            ))}
          </StyledRow>
        ))}
      </StyledRowContainer>
      <ButtonContainer>
        {filter && (
          <Fragment>
            <Button
              isDisabled={page === 1}
              onClick={() => setPage(1)}
              iconLeft={<i className='fa fa-angle-double-left' />}
            />
            <Button
              isDisabled={page === 1}
              onClick={() => setPage(page - 1)}
              iconLeft={<i className='fa fa-angle-left' />}
            />
          </Fragment>
        )}
        <StyledText>{`Showing ${sortedRows.length ? start + 1 : 0} - ${
          end > sortedRows.length ? sortedRows.length : end
        } of ${sortedRows.length} matches`}</StyledText>
        {filter && (
          <Fragment>
            <Button
              isDisabled={page >= getLastPage()}
              onClick={() => setPage(page + 1)}
              iconLeft={<i className='fa fa-angle-right' />}
            />
            <Button
              isDisabled={page >= getLastPage()}
              onClick={() => setPage(getLastPage())}
              iconLeft={<i className='fa fa-angle-double-right' />}
            />
          </Fragment>
        )}
      </ButtonContainer>
    </StyledContainer>
  );
};
