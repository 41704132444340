import React, { useState, useCallback, useMemo } from 'react';

import styled from 'styled-components';
import { RED } from '../Constants/Styles';
import ConfirmationBox from './ConfirmationBox';

const StyledDelete = styled.div`
  border: solid ${RED.primary} 1px;
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
  color: ${RED.primary};

  &:hover {
    cursor: pointer;
  }
`;

type DeleteButtonPropsType = {
  title: string;
  message: React.ReactElement;
  yesClick: () => void;
  yesButtonText: string;
  noButtonText: string;
};

const DeleteButton = (props: DeleteButtonPropsType) => {
  const { title, message, yesButtonText, noButtonText, yesClick } = props;

  const [showConfirmationBox, setShowConfirmationBox] = useState(false);

  const onClick = useCallback(() => {
    setShowConfirmationBox(
      (prevShowConfirmationBox) => !prevShowConfirmationBox
    );
  }, [setShowConfirmationBox]);

  const yesClickHandler = useCallback(() => {
    yesClick();
    setShowConfirmationBox(false);
  }, [setShowConfirmationBox, yesClick]);

  const noClick = useCallback(() => {
    setShowConfirmationBox(false);
  }, [setShowConfirmationBox]);

  const confirmProps = useMemo(() => {
    return {
      title,
      message,
      yesClick: yesClickHandler,
      noClick,
      yesButtonText,
      noButtonText,
    };
  }, [message, noButtonText, noClick, title, yesButtonText, yesClickHandler]);

  return (
    <>
      <StyledDelete onClick={onClick} data-testid='delete-asset-old'>
        <i className='fa fa-trash' />
      </StyledDelete>

      {showConfirmationBox ? <ConfirmationBox {...confirmProps} /> : null}
    </>
  );
};

export default DeleteButton;
