import React from 'react';
import styled from 'styled-components';
import { Heading, Primitive } from '@rtkwlf/fenrir-react';

interface iPanelTitle {
  title: string;
  tooltipText?: React.ReactNode;
  titleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

const TitleStyle = styled(Heading)`
  user-select: all;
`;

const AscPanelSubtitle = ({ title, titleLevel = 5 }: iPanelTitle) => (
  <Primitive.div marginTop='large' marginBottom='medium' marginLeft='zero'>
    <TitleStyle level={`${titleLevel}`}>{title}</TitleStyle>
  </Primitive.div>
);

export default AscPanelSubtitle;
