import React, { useCallback, useMemo } from 'react';
import { SortState } from '../../../Constants/Common';
import { sortData } from '../utils';

export const useTableSort = <T>(data: T[]) => {
  const [sortState, setSortState] = React.useState<SortState>('none');
  const [sortCol, setSortCol] = React.useState('');

  const sortedData = useMemo(() => {
    if (sortState === 'none') {
      return data;
    }

    return sortData(sortState === 'dsc', sortCol as keyof T, [...data]);
  }, [data, sortCol, sortState]);

  const onSort = useCallback((column: string, state: SortState) => {
    setSortCol(column);
    setSortState(state);
  }, []);

  return {
    sortedData,
    onSort,
  };
};

export type AssetCatalogContextType = {
  selectedAssetIDs: string[];
  toggleAsset: (checked: boolean, assetId: string) => void;
  selectedCriteriaKeys: string[];
  onAssetsDeleteSuccess: () => void;
};

export const AssetCatalogContext = React.createContext<AssetCatalogContextType>(
  undefined as any
);

export const useAssetCatalog = () => {
  const ctx = React.useContext(AssetCatalogContext);

  if (!ctx) {
    console.error(
      'AssetSelectionContext is undefined, please verify you are calling useAssetSelection() as child of AssetCatalogContext.Provider'
    );
  }

  return ctx;
};
