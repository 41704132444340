import React from 'react';
import {
  Button,
  HStack,
  Text,
  Switch,
  useToastContext,
} from '@rtkwlf/fenrir-react';
import { ThreeDots } from 'react-loader-spinner';
import { BLUE } from '../Constants/Styles';
import { TOASTS } from './IvaScanControlMessages';
import { ModalBox } from './ModalBox';

export type Props = {
  modalTitle: string;
  modalSentence: string;
  warning: string;
  buttonText: string;
  checked?: boolean;
  isLoading: boolean;
  modalConfirm: () => Promise<boolean[]>;
};

const ScanningDisableToggle: React.FC<Props> = ({
  modalTitle,
  modalSentence,
  warning,
  buttonText,
  checked,
  modalConfirm,
  isLoading,
}) => {
  // show the modal
  const [showDisableScanModal, setShowDisableScanModal] = React.useState(false);

  // toasts control
  const { addToast } = useToastContext();

  // hide the modal on cancel
  const hideModal = () => {
    setShowDisableScanModal(false);
  };

  // toggle is switched off
  const toggleOff = () => {
    setShowDisableScanModal(true);
  };

  // toggle is switched on
  const toggleOn = async () => {
    // get promises
    const [scheduleEnabled] = await modalConfirm();

    if (scheduleEnabled) {
      addToast(TOASTS.ENABLE_SUCCESS);
    } else {
      addToast(TOASTS.ENABLE_FAIL);
    }
  };

  // make call
  const disableScanSchedule = async () => {
    // get request statuses
    const [scheduleStopped, scansStopped] = await modalConfirm();

    // close modal
    setShowDisableScanModal(false);

    // if successful, schedule is disabled
    // set notifications accordingly
    if (scheduleStopped && scansStopped) {
      addToast(TOASTS.ALL_SUCCESS);
    } else if (scheduleStopped) {
      addToast(TOASTS.SCHEDULE_SUCCESS);
      addToast(TOASTS.SCAN_FAIL);
    } else if (scansStopped) {
      addToast(TOASTS.SCAN_SUCCESS);
      addToast(TOASTS.SCHEDULE_FAIL);
    } else {
      addToast(TOASTS.ALL_FAIL);
    }
  };

  return (
    <>
      <ModalBox
        title={modalTitle}
        onCloseModal={hideModal}
        isOpen={showDisableScanModal}
        width='520px'
        footer={
          <HStack xAlign='right' gap='xsmall' height='2.5rem'>
            {!isLoading && (
              <>
                <Button
                  data-testid='stop-modal-confirm'
                  appearance='danger'
                  iconLeft={<i className='fa fa-stop-circle' />}
                  onClick={disableScanSchedule}
                >
                  {buttonText}
                </Button>
                <Button
                  data-testid='stop-modal-cancel'
                  onClick={hideModal}
                  variant='secondary'
                >
                  Cancel
                </Button>
              </>
            )}
          </HStack>
        }
      >
        <HStack paddingX='large' paddingBottom='large'>
          {!isLoading ? (
            <Text styledAs='body1'>
              <div>{modalSentence}</div>
              <br />
              <div>{warning}</div>
              <br />
              <div>Are you sure you want to continue?</div>
            </Text>
          ) : (
            <HStack xAlign='center' yAlign='center'>
              <ThreeDots color={BLUE.tertiary} height={50} width={50} />
            </HStack>
          )}
        </HStack>
      </ModalBox>
      <HStack data-testid='scan-schedule-toggle'>
        <Switch.Root
          margin='zero'
          aria-label='disable scan schedule'
          onCheckedChange={() => {
            if (checked) {
              toggleOff();
            } else {
              toggleOn();
            }
          }}
          isDisabled={isLoading}
          isChecked={checked || false}
        >
          <Switch.Thumb />
        </Switch.Root>
      </HStack>
    </>
  );
};

export default ScanningDisableToggle;
