import styled from 'styled-components';
import { BLUE, GREY } from '../../../Constants/Styles';
import { Tooltip } from '@rtkwlf/fenrir-react';

const ContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: ${GREY.bright};
`;

const TileDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`;

const Icon = styled.i`
  color: ${BLUE.primary};
  margin-left: 5px;
`;

export type Tile = {
  title: string;
  display: string;
  showTooltip?: boolean;
  description?: string;
};

type CountProps = {
  tiles: Tile[];
};

export const CountTile: React.FC<CountProps> = ({ tiles }) => {
  return (
    <ContainerDiv id='tiles'>
      {tiles.map((tile, index) => (
        <TileDiv
          key={`${index}-tile-div`}
          style={{
            borderRight:
              index !== tiles.length - 1
                ? `1px solid ${GREY.lightTertiary}`
                : '0px',
          }}
        >
          <span data-testid='tile-title'>{tile.title}</span>
          <span data-testid='tile-desc'>
            {tile.display}{' '}
            {tile.showTooltip ? (
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Icon className='fa fa-info-circle' />
                </Tooltip.Trigger>
                <Tooltip.Content side='top' maxWidth='16rem'>
                  <Tooltip.Text>{tile.description}</Tooltip.Text>
                </Tooltip.Content>
              </Tooltip.Root>
            ) : null}
          </span>
        </TileDiv>
      ))}
    </ContainerDiv>
  );
};
