import { AxiosError } from 'axios';
import { toastr } from 'react-redux-toastr';

import {
  nonAgentDetailsFields,
  rowType,
  AssetDetailsType,
  AgentDetailsType,
  NonAgentDetailsType,
  getAgentDetailsField,
} from './AssetDetails';

import {
  iAsset,
  iAssetProfileOsInfo,
  iOsInfo,
  iProfileHistory,
} from '../../../Global/assetReducer';
import {
  AssetDetailsCSVDataType,
  AssetResetSensorType,
  ScoutAuditType,
} from '../../../types/assets';
import riskAPI from '../../../utils/riskAPI';
import { findLastSeen, parseDeviceName } from '../utils';

import moment from 'moment';

import { ASSET_ICONS } from '../../../Constants/Icons';

type historyDataType = {
  date: number;
  value: string;
};

// input: "DNS:<name>|NBName:<name>"
const parseDeviceNameToObj = (nameStr: string, name: string) => {
  var deviceNameArr = nameStr.split('|');
  for (let b = 0; b < deviceNameArr.length; ++b) {
    var current = deviceNameArr[b];

    if (current.indexOf(name) === 0) {
      return current.substr(name.length).trim();
    }
  }

  return '';
};

type ProfileDatesType = {
  assetCreatedElem: string;
  assetLastUpdatedElem: string;
};

// ----- API Calls -----

export const getAssetDetails = async (
  customerId: string,
  assetId: string,
  servicesHost: string,
  clientId: string
): Promise<iAsset> => {
  let data = {} as iAsset;

  const apiURL = clientId
    ? `${servicesHost}/rootsecure/v1/customers/${customerId}/assets/scout/${clientId}`
    : `${servicesHost}/rootsecure/v1/customers/${customerId}/assets/${assetId}`;

  const response = await riskAPI.get(apiURL);
  data = response.data.data;
  return data;
};

export const getAssetProfileData = async (apiURL: string) => {
  let data: iProfileHistory[] = [];
  try {
    const response = await riskAPI.get<iProfileHistory[]>(apiURL);
    data = response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    toastr.error(
      axiosError.code || '',
      'Failed to load asset profile history data'
    );
  }
  return data;
};

export const processAssetDetailsInfo = (
  data: iAsset,
  assetProfileData: assetProfileHistoryType[],
  source: string
) => {
  let profileDates = {} as ProfileDatesType;

  const sectionSchemas =
    source === 'agent'
      ? Object.keys(getAgentDetailsField())
      : Object.keys(nonAgentDetailsFields);

  const processedData: {
    [key: string]: {
      [id: string]: string;
    };
  } = {};

  const assetDetailsFields: AssetDetailsType =
    source === 'agent' ? getAgentDetailsField() : nonAgentDetailsFields;

  const formattedLastSeen = moment
    .utc(
      findLastSeen(
        data.lastVerifiedDeviceName,
        data.lastVerifiedMac,
        data.lastVerifiedOs,
        data.lastScannedTime
      )
    )
    .format('ddd, MMM D, YYYY, h:mm:ss a [GMT]Z');

  sectionSchemas.forEach((sectionSchema: string) => {
    const sectionData: Record<string, string> = {};

    assetDetailsFields[`${sectionSchema}`].forEach((section: rowType) => {
      if (sectionSchema === 'advancedIdentification') {
        sectionData['lastSeen'] = formattedLastSeen;
      }
      const dataKey: string = section.dataKey;
      const sectionKey: string = section.key;
      if (dataKey.includes('osInfo.')) {
        const arrStr = dataKey.split('.');
        sectionData[sectionKey] =
          data?.osInfo[arrStr[1] as keyof iOsInfo] ?? '';
      } else if (dataKey.includes('source')) {
        sectionData[sectionKey] = data.source!;
      } else if (dataKey.includes('deviceName')) {
        if (dataKey.includes(':')) {
          const devNameArr = dataKey.split(':');
          sectionData[sectionKey] = parseDeviceNameToObj(
            data.deviceName,
            `${devNameArr[1]}:`
          );
        } else {
          sectionData[sectionKey] = parseDeviceName(
            data.userDeviceName || data.deviceName
          );
        }
      } else if (dataKey.includes('profileHistoryDates:')) {
        const profileArr = dataKey.split(':');
        const ProfileDateKey = profileArr[1];

        if (
          !profileDates.assetCreatedElem ||
          !profileDates.assetLastUpdatedElem
        ) {
          profileDates = assetProfileHistoryCallback(assetProfileData);
        }

        sectionData[sectionKey] =
          profileDates[ProfileDateKey as keyof ProfileDatesType];
      } else if (dataKey === 'lastVerifiedExternalIP') {
        sectionData[sectionKey] = RsLocalDate(data.lastVerifiedExternalIP);
      } else if (dataKey === 'tags') {
        // @ts-ignore: details are currently typed to have string value everywhere, this is hard to overwrite
        // for now, the consumer is responsible of parsing it to the actual type (array of numbers)
        sectionData[sectionKey] = data.tags ?? [];
      } else {
        sectionData[sectionKey] = data[dataKey as keyof iAsset] as string;
      }

      if (sectionData[sectionKey] === '') {
        sectionData[sectionKey] = '--';
      }
    });

    processedData[sectionSchema] = sectionData;
  });

  return processedData;
};

const assetProfileHistoryCallback = (data: assetProfileHistoryType[]) => {
  const profileDates = {} as ProfileDatesType;

  let assetCreated: number = 0;
  let assetLastUpdated: number = 0;

  if (!data || data.length === 0) {
    profileDates.assetCreatedElem = 'No Profile History';
    profileDates.assetLastUpdatedElem = '--';
    return profileDates;
  }

  for (let i = 0; i < data.length; ++i) {
    var current = data[i];

    if (assetCreated === 0) {
      assetCreated = current.tsseconds;
    } else {
      if (assetCreated > current.tsseconds) {
        assetCreated = current.tsseconds;
      }
    }

    if (assetLastUpdated === 0) {
      assetLastUpdated = current.tsseconds;
    } else {
      if (assetLastUpdated < current.tsseconds) {
        assetLastUpdated = current.tsseconds;
      }
    }
  }

  profileDates.assetCreatedElem = RsLocalDate(assetCreated);
  profileDates.assetLastUpdatedElem = RsLocalDate(assetLastUpdated);

  return profileDates;
};

const toDateTimeZone = (date: Date) => {
  const format = 'ddd, MMM D, YYYY, h:mm:ss a [GMT]Z';

  return moment(date, format).utcOffset('en-CA').format(format);
};

const RsLocalDate = (src: number) => {
  const date = new Date(src * 1000);

  return toDateTimeZone(date);
};

export const RsLocalDateFromStr = (src: string) => {
  if (src === '') {
    return src;
  }
  return toDateTimeZone(new Date(src));
};

//e.g. "8fa120df-338e-4f47-a85d-f41db4644f34;Default;192.168.0.64"
const parseHistoryIp = (ipStr: string) => {
  const arr = ipStr.match(/Default;(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/);

  if (arr && arr?.length > 0) {
    return arr[1];
  }

  return '';
};

const getDeviceNameByTime = (
  ts: number,
  deviceNameHistoryArr: historyDataType[]
) => {
  let name = '';
  for (let x = 0; x < deviceNameHistoryArr.length; ++x) {
    var cur = deviceNameHistoryArr[x];

    if (cur.date <= ts) {
      name = cur.value;
    }
  }

  return name;
};

const getOSByTime = (ts: number, osHistoryArr: historyDataType[]) => {
  let name = '';
  for (let x = 0; x < osHistoryArr.length; ++x) {
    const cur = osHistoryArr[x];

    if (cur.date <= ts) {
      name = cur.value;
    }
  }

  return name;
};

const getMacByTime = (ts: number, macHistoryArr: historyDataType[]) => {
  var name = '';
  for (let x = 0; x < macHistoryArr.length; ++x) {
    var cur = macHistoryArr[x];

    if (cur.date <= ts) {
      name = cur.value;
    }
  }
  return name;
};

export type assetProfileHistoryType = {
  id?: string;
  ip: string;
  devicename: string;
  raw: string;
  type: string;
  mac: string;
  mappingeffects: string;
  os: string;
  when: string;
  tsseconds: number;
};

export const getAssetHistoryProfileData = (jsonObjData: iProfileHistory[]) => {
  const tableArr: Array<assetProfileHistoryType> = [];

  const deviceNameHistoryArr: Array<historyDataType> = [];
  const osHistoryArr: Array<historyDataType> = [];
  const macHistoryArr: Array<historyDataType> = [];

  jsonObjData.forEach((history: iProfileHistory) => {
    if (history.type === 'DeviceName') {
      const historyObj = {
        date: history.tsseconds,
        value: parseDeviceName(history.value as string),
      };

      deviceNameHistoryArr.push(historyObj);
    } else if (history.type === 'OS') {
      const osObj = history.value as iAssetProfileOsInfo;

      const historyObj = {
        date: history.tsseconds,
        value: osObj?.Name,
      };

      osHistoryArr.push(historyObj);
    } else if (history.type === 'MAC') {
      const historyObj = {
        date: history.tsseconds,
        value: history.value,
      };

      macHistoryArr.push(historyObj as historyDataType);
    }
  });

  jsonObjData.forEach((history: iProfileHistory) => {
    if (history.type === 'IPLookup') {
      const entry: assetProfileHistoryType = {
        id: history.deviceid,
        ip: parseHistoryIp(history.ip),
        devicename: getDeviceNameByTime(
          history.tsseconds,
          deviceNameHistoryArr
        ),
        os: getOSByTime(history.tsseconds, osHistoryArr),
        mac: getMacByTime(history.tsseconds, macHistoryArr),
        type: history.type,
        raw: '',
        mappingeffects: history.mappingeffects,
        when: RsLocalDate(history.tsseconds),
        tsseconds: history.tsseconds,
      };

      tableArr.push(entry);
    } else if (history.type === 'DeviceName') {
      const historyValue = history.value as string;

      const entry: assetProfileHistoryType = {
        id: history.deviceid,
        ip: parseHistoryIp(history.ip),
        devicename: parseDeviceName(historyValue),
        os: getOSByTime(history.tsseconds, osHistoryArr),
        mac: getMacByTime(history.tsseconds, macHistoryArr),
        type: history.type,
        raw: history.raw,
        mappingeffects: history.mappingeffects,
        when: RsLocalDate(history.tsseconds),
        tsseconds: history.tsseconds,
      };

      tableArr.push(entry);
    } else if (history.type === 'OS') {
      const osObj = history.value as iAssetProfileOsInfo;
      const osName: string = osObj.Name;

      const entry: assetProfileHistoryType = {
        id: history.deviceid,
        ip: parseHistoryIp(history.ip),
        devicename: getDeviceNameByTime(
          history.tsseconds,
          deviceNameHistoryArr
        ),
        os: osName,
        mac: getMacByTime(history.tsseconds, macHistoryArr),
        type: history.type,
        raw: history.raw,
        mappingeffects: history.mappingeffects,
        when: RsLocalDate(history.tsseconds),
        tsseconds: history.tsseconds,
      };

      tableArr.push(entry);
    } else if (history.type === 'MAC') {
      const entry: assetProfileHistoryType = {
        id: history.deviceid,
        ip: parseHistoryIp(history.ip),
        devicename: getDeviceNameByTime(
          history.tsseconds,
          deviceNameHistoryArr
        ),
        os: getOSByTime(history.tsseconds, osHistoryArr),
        mac: history.value as string,
        type: history.type,
        raw: history.raw,
        mappingeffects: history.mappingeffects,
        when: RsLocalDate(history.tsseconds),
        tsseconds: history.tsseconds,
      };

      tableArr.push(entry);
    } else {
      toastr.error('', 'Unhandled History Type');
    }
  });

  return tableArr;
};

export const getAssetDetailsCSVData = (
  assetData: AgentDetailsType | NonAgentDetailsType
) => {
  const data: Array<AssetDetailsCSVDataType> = [
    {
      deviceName: assetData.details.deviceName,
      dnsHostname: assetData.hostIdentification.dnsHostname,
      netBIOSName: assetData.hostIdentification.netBIOSName,
      iPv4Address: assetData.hostIdentification.iPv4Address,
      assetId: assetData.hostIdentification.assetId,
      assetCreatedElem: assetData.profileActivity.assetCreatedElem,
      assetLastUpdatedElem: assetData.profileActivity.assetLastUpdatedElem,
      os: assetData.details.os,
      foundBy: assetData.details.foundBy,
      manufacturer: assetData.details.manufacturer,
    },
  ];

  return data;
};

export const resetSensorDefaults = async (params: AssetResetSensorType) => {
  const apiURL = `${params.servicesHost}/rootsecure/v1/dashboard/customers/${params.customerId}/assets/${params.assetId}`;
  const requestObj = JSON.stringify({ deviceName: '', category: '' });

  const response = await riskAPI.patch(apiURL, requestObj);
  return response;
};

export const RsCommafyInt = (num: number | string) => {
  if (!num) num = 0;
  if (typeof num === 'string') {
    num = parseInt(num);
    if (isNaN(num)) {
      return '0';
    }
  }
  const result = Number(num.toFixed(0)).toLocaleString().split(/\s/).join(',');
  return result;
};

export const getAgentAudit = async (
  uuid: string,
  servicesHost: string,
  clientUUID: string
): Promise<Array<ScoutAuditType>> => {
  let data: Array<ScoutAuditType> = [];
  const apiURL: string = `${servicesHost}/rootsecure/v1/dashboard/scout/audit/${uuid}/${clientUUID}`;
  const response = await riskAPI.get(apiURL);
  data = response.data;

  return data;
};

export const getAssetImage = (productStr: string) => {
  const prodName = productStr;
  let image = '';

  if (prodName.indexOf('linux') > -1 || prodName.indexOf('kernel') > -1) {
    image = 'images/linux.png';
  } else if (prodName.indexOf('esxi') > -1) {
    image = 'images/esxi.jpg';
  } else if (prodName.indexOf('windows') > -1) {
    image = 'images/win-logo.png';
  } else if (prodName.indexOf('openbsd') > -1) {
    image = 'images/openbsd.png';
  } else if (prodName.indexOf('freebsd') > -1) {
    image = 'images/freebsd.svg';
  } else if (prodName.indexOf('netbsd') > -1) {
    image = 'images/netbsd-logo.svg';
  } else if (prodName.indexOf('centos') > -1) {
    image = 'images/centos-logo.png';
  } else if (prodName.indexOf('tv') > -1) {
    image = 'images/apple-tv-logo.png';
  } else if (
    prodName.indexOf('blackberry_os') > -1 ||
    prodName.indexOf('blackberry_playbook_os') > -1
  ) {
    image = 'images/blackberry-os.png';
  } else if (prodName.indexOf('suse_linux') > -1) {
    image = 'images/suse-logo.png';
  } else if (prodName.indexOf('ubuntu_linux') > -1) {
    image = 'images/ubuntu-logo.png';
  } else if (prodName.indexOf('macOS') > -1) {
    image = 'images/macOS.png';
  } else {
    image = 'images/unknown.jpg';
  }

  return image;
};

export const getAssetTypeImage = (source: string | undefined) => {
  return source ? ASSET_ICONS[source] : '';
};
