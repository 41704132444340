import React, { Fragment } from 'react';
import { RISK_STATES, STATUS } from '../../Constants/Risks';

import ActionList from './ActionList';

const RISK_INITIAL_FILTERS = {
  order: 'issueLevel',
  direction: 'desc',
  page: 0,
  limit: 10,
  fromLevel: 4,
  toLevelInclusive: 10,
  source: ['sensor', 'reach', 'agent'],
  state: [
    RISK_STATES.OPEN,
    RISK_STATES.IN_PROGRESS,
    RISK_STATES.ACKNOWLEDGED,
    RISK_STATES.FAILED_VALIDATION,
    RISK_STATES.FIXED,
  ],
  issueAction: '',
  status: [STATUS.ACTIVE, STATUS.INACTIVE],
  criticality: [],
  tagID: [],
};

// risks page
const Risks = () => (
  <Fragment>
    <ActionList
      description={
        'These are the current risks in your network, including those which are Accepted and False Positive.'
      }
      initialFilters={RISK_INITIAL_FILTERS}
      filterDescription={
        <>
          You can use filters to adjust the items in the table. For example,
          select or deselect a Source to show or hide table entries for that
          scan type.
        </>
      }
    />
  </Fragment>
);

export default Risks;
