import React, { useState } from 'react';
import { AssetDeleteModal } from './AssetBulkDeleteModal';
import { useDeleteAssets } from '../useDeleteAssets';
import { Button, Primitive } from '@rtkwlf/fenrir-react';
import { isFreshAgent } from './utils';
import { iAssetCatalogDataType } from '../../../Global/assetReducer';
import { Tooltip } from '@rtkwlf/fenrir-react';
type DeleteDuplicateButtonProps = {
  asset: iAssetCatalogDataType;
};

const AssetDeleteButton = ({ asset }: DeleteDuplicateButtonProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onClose = () => setDeleteModalOpen(false);

  const { onDelete } = useDeleteAssets([asset.id], onClose);

  // if set, the button should be disabled with a tooltip explanation
  let tooltip: string | undefined = undefined;

  if (isFreshAgent(asset)) {
    tooltip =
      'Agent assets that were active in the last 24 hours cannot be deleted.';
  } else if (asset.source === 'reach') {
    tooltip =
      'To delete External Vulnerability Assessment (EVA) assets, remove them from the relevant scan configuration.';
  }
  return (
    <>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Primitive.div cursor='pointer'>
            <Button
              data-testid='single-delete'
              appearance='danger'
              variant='secondary'
              isDisabled={!!tooltip}
              onClick={() => setDeleteModalOpen(true)}
              iconLeft={<i className='fa fa-trash' />}
            />
          </Primitive.div>
        </Tooltip.Trigger>
        {!!tooltip && (
          <Tooltip.Content side='left' maxWidth='25rem'>
            <Tooltip.Text>{tooltip}</Tooltip.Text>
          </Tooltip.Content>
        )}
      </Tooltip.Root>
      <AssetDeleteModal
        onClose={onClose}
        isOpen={deleteModalOpen}
        onDelete={() => onDelete()}
        plural={false}
      />
    </>
  );
};

export default AssetDeleteButton;
