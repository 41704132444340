import React from 'react';
import { useAssetCatalog } from './hooks';
import { useDeleteAssets } from '../useDeleteAssets';
import { Button, HStack, Text, VStack } from '@rtkwlf/fenrir-react';
import { ModalBox } from '../../../Reusables/ModalBox';

type ModalProps = {
  onDelete(): void;
  onClose(): void;
  isOpen: boolean;
  plural: boolean;
};

const AssetDeleteModal: React.FC<ModalProps> = ({
  onDelete,
  isOpen,
  onClose,
  plural,
}) => {
  return (
    <ModalBox
      title={'Confirm Deletion'}
      onCloseModal={onClose}
      isOpen={isOpen}
      width='640px'
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button
            appearance='danger'
            onClick={() => onDelete()}
            iconLeft={<i className='fa fa-trash' />}
          >
            {`Delete${plural ? ' Selected' : ''}`}
          </Button>
          <Button onClick={onClose} variant='secondary'>
            Close
          </Button>
        </HStack>
      }
    >
      <VStack paddingLeft='large' marginBottom='medium' gap='smedium'>
        <Text styledAs='body4'>
          There may be associated risks with
          {plural ? ' the selected assets' : ' the asset'}.
        </Text>
        <Text styledAs='body4'>
          Any associated risks will be deleted along with the asset
          {plural ? 's' : ''}.
        </Text>
        <Text styledAs='body4'>
          Are you sure you want to delete
          {plural ? ' these assets' : ' this asset'}?
        </Text>
      </VStack>
    </ModalBox>
  );
};

type Props = {
  isOpen: boolean;
  onClose(): void;
};

const AssetBulkDeleteModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const { selectedAssetIDs } = useAssetCatalog();

  const { onDelete } = useDeleteAssets(selectedAssetIDs);

  return (
    <AssetDeleteModal
      onClose={onClose}
      isOpen={isOpen}
      onDelete={() => onDelete()}
      plural
    />
  );
};

export { AssetDeleteModal, AssetBulkDeleteModal };
