export enum CaseSeverity {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum CaseStatus {
  Open = 'Open',
  Resolved = 'Resolved',
  Deleted = 'Deleted',
}

export interface Case {
  id: string;
  customerUuid: string;
  deploymentId: string;
  title: string;
  description: string;
  severity: CaseSeverity;
  owner: string;
  status: CaseStatus;
  summary: string;
  tags: Array<string>;

  createdTime: string;
  createdBy: string;
  lastModifiedTime: string;
  updatedBy: string;
  startDate: string;
}

export interface CaseParams {
  limit?: number;
  severity?: CaseSeverity;
  createdAfter?: string;
  status?: CaseStatus;
  tag?: string;
  owner?: string;
}

export interface CasesMeta {
  count?: number;
  next?: string;
  previous?: string;
  params?: CaseParams;
}

export interface GetCasesResponse {
  meta: CasesMeta;
  data: Array<Case>;
}
