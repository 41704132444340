import React, { Fragment, useState } from 'react';
import { RowTile } from '../../Reusables/RowTile';
import StatusIcon, { StatusIconProps } from '../../Reusables/StatusIcon';
import { connect, ConnectedProps } from 'react-redux';
import {
  toggleScanThunk,
  updateScannerConfigurationThunk,
} from '../../Global/scannerActions';
import { STATUS } from '../../Constants/Labels';
import { Dispatch } from '../../configureStore';
import { TroubleshootingSettings } from '../../Modals/Modals/TroubleshootingSettings';
import {
  Button,
  Heading,
  HStack,
  Primitive,
  Switch,
  Text,
  VStack,
} from '@rtkwlf/fenrir-react';
import { useAppDispatch } from '../../hooks';
import styled from 'styled-components';
import { BLUE } from '../../Constants/Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalBox } from '../../Reusables/ModalBox';
import { ScannerSelect } from '../../Modals/Modals/ScannerSelect';
import { ScannerConfigMultiSelect } from '../../Reusables/ScannerConfigMultiSelect';
import { Tooltip } from '@rtkwlf/fenrir-react';

const StyledDiv = styled.div`
  margin: 0px -20px 0px 0px;
`;

const StyledItem = styled.div`
  flex: 1;
`;

const StyledIcon = styled.i`
  margin-left: 10px;
  color: ${BLUE.primary};
`;

const StyledTitle = styled(Heading).attrs({ level: '5' })`
  padding-bottom: 10px;
  display: flex;
`;

interface Props extends PropsFromRedux {
  id: string;
}

const ScannerConfigurationRow = ({
  id,
  scanner,
  updateScannerConfiguration,
  updateDNS,
}: Props) => {
  const [troubleshootingModalOpen, toggleTroubleshootingModal] =
    React.useState(false);
  const [showScannerSelect, setShowScannerSelect] = useState(false);
  const dispatch = useAppDispatch();

  if (!scanner?.config) {
    return null;
  }

  const { status, network, config } = scanner;

  const denyList =
    config.blacklist?.map((i: string) => ({
      label: i,
      value: i,
    })) || [];

  const dnsList =
    config.dns_servers?.map((i: string) => ({
      label: i,
      value: i,
    })) || [];

  const togglenmap = () => {
    dispatch(
      toggleScanThunk(id, {
        nmap: !config.nmap,
      })
    );
  };

  const togglescans = () => {
    dispatch(
      toggleScanThunk(id, {
        scans: !config.scans,
      })
    );
  };

  function hoursFormat(hour: string, suffix: string = ' ago'): string {
    // 5 days
    const hourn: number = parseFloat(hour);
    if (Number.isNaN(hourn)) {
      return 'never';
    } else if (hourn > 8760) {
      return 'more than a year' + suffix;
    } else if (hourn > 120.0) {
      return `${Math.round(hourn / 24)} days` + suffix;
    }
    return `${hourn} hours` + suffix;
  }

  let connectionStatusDetail: string = 'Loading.';
  let connectionStatusProps: StatusIconProps = {
    icon: 'fa-times',
    text: 'Disconnected',
  };

  if (!status) {
    connectionStatusProps = {
      icon: 'fa-spin fa-circle-o-notch',
      text: 'Loading',
    };
  } else if (status.connstatus.toLowerCase() === 'green') {
    connectionStatusProps = {
      icon: 'fa-check',
      text: 'Connected',
    };
    connectionStatusDetail =
      'The scanner is connected to the Managed Risk service.';
  } else if (status.connstatus.toLowerCase() === 'red') {
    connectionStatusDetail = `The scanner has not been connected to the Managed Risk service for ${hoursFormat(
      status.agedirect,
      ''
    )}.`;
  }

  const setScannerStatusPillWithFF = (status: any) => {
    switch (status?.reportstatusstring) {
      case 'scanning':
        return {
          icon: 'fa-check',
          text: STATUS.SCANNING,
          details: 'Scanner operating normally.',
        };
      case 'not scanning':
        return {
          icon: 'fa-times',
          text: STATUS.NOT_SCANNING,
          details: `Last completed host scan was ${hoursFormat(
            status?.agenmap
          )}.`,
        };
      case 'misconfigured':
        return {
          icon: 'fa-exclamation-triangle',
          text: STATUS.MISCONFIGURED,
          details: `Last completed vulnerability scan was ${hoursFormat(
            status?.agescan
          )}.`,
        };
      case 'disabled':
        return {
          icon: 'fa-times',
          text: STATUS.DISABLED,
          details: `Last completed vulnerability scan was ${hoursFormat(
            status?.agescan
          )}.`,
        };
      case 'degraded':
        return {
          icon: 'fa-exclamation-triangle',
          text: STATUS.DEGRADED,
          details: `Last completed vulnerability scan was ${hoursFormat(
            status?.agescan
          )}.`,
        };
      case 'na':
      default:
        return {
          icon: 'fa-exclamation-triangle',
          text: STATUS.NOT_CONFIGURED,
          details: 'Unconfigured scanner.',
        };
    }
  };

  const { icon, text, details } = setScannerStatusPillWithFF(status);

  return (
    <Fragment>
      <StyledDiv>
        <RowTile
          id='scanner-configuration'
          title='Scanner Configuration'
          description='Operational status of the sensors, network, and subnetworks that you want to scan.'
          buttons={
            <Button
              margin='zero'
              variant='secondary'
              onClick={() => toggleTroubleshootingModal(true)}
            >
              Troubleshooting Settings
            </Button>
          }
          buttonsContainerStyle={{ marginLeft: 'auto', marginRight: 0 }}
          newStyle
        >
          <VStack>
            <HStack yAlign='top' xAlign='between' width='full'>
              <StyledItem>
                <StyledTitle>{'Scanner ID'}</StyledTitle>
                <Text styledAs='body3'>
                  {id}
                  <StyledIcon
                    data-testid='scannerSelect'
                    onClick={() => setShowScannerSelect(true)}
                    className='fa fa-search'
                  />
                </Text>
              </StyledItem>
              <StyledItem>
                <StyledTitle>{'Connection Status'}</StyledTitle>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <Primitive.span>
                      <StatusIcon {...connectionStatusProps} />
                    </Primitive.span>
                  </Tooltip.Trigger>
                  <Tooltip.Content side='top' maxWidth='20rem'>
                    <Tooltip.Text>{connectionStatusDetail}</Tooltip.Text>
                  </Tooltip.Content>
                </Tooltip.Root>
              </StyledItem>
              <StyledItem>
                <StyledTitle>
                  {'Host Identification Scans'}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color={BLUE.primary}
                        style={{ marginLeft: '0.5em', alignSelf: 'center' }}
                        cursor='pointer'
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content side='top' maxWidth='20rem'>
                      <Tooltip.Text>
                        This scan identifies hosts in your network. Disabling
                        this scan also disables Vulnerability Scanning.
                      </Tooltip.Text>
                    </Tooltip.Content>
                  </Tooltip.Root>
                </StyledTitle>
                <Switch.Root
                  margin='zero'
                  aria-label='toggle host identification scans'
                  onCheckedChange={togglenmap}
                  isChecked={config.nmap || false}
                >
                  <Switch.Thumb />
                </Switch.Root>
              </StyledItem>

              <StyledItem>
                <StyledTitle>{'DenyList IP/Networks'}</StyledTitle>
                <Primitive.div id='denyList' className='hide-tags'>
                  <ScannerConfigMultiSelect
                    items={denyList}
                    update={updateScannerConfiguration}
                  />
                </Primitive.div>
              </StyledItem>
            </HStack>
            <HStack yAlign='top' xAlign='between' width='full'>
              <StyledItem>
                <StyledTitle>{'Scanner IP Address'}</StyledTitle>
                <Text styledAs='body3'>{network?.ipaddr}</Text>
              </StyledItem>
              <StyledItem>
                <StyledTitle>{'Scanning Status'}</StyledTitle>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <Primitive.span>
                      <StatusIcon
                        data-testid='scanStatus'
                        icon={icon}
                        text={text}
                      />
                    </Primitive.span>
                  </Tooltip.Trigger>
                  <Tooltip.Content
                    side='top'
                    maxWidth='20rem'
                    data-testid='scanStatusTooltipContent'
                  >
                    <Tooltip.Text>{details}</Tooltip.Text>
                  </Tooltip.Content>
                </Tooltip.Root>
              </StyledItem>
              <StyledItem>
                <StyledTitle>
                  {'Vulnerability Scanning'}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color={BLUE.primary}
                        style={{ marginLeft: '0.5em', alignSelf: 'center' }}
                        cursor='pointer'
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content side='top' maxWidth='20rem'>
                      <Tooltip.Text>
                        This scan finds vulnerabilities on the hosts in your
                        network.
                      </Tooltip.Text>
                    </Tooltip.Content>
                  </Tooltip.Root>
                </StyledTitle>
                <Switch.Root
                  margin='zero'
                  aria-label='toggle vulnerability scanning'
                  onCheckedChange={togglescans}
                  isChecked={config.scans || false}
                >
                  <Switch.Thumb />
                </Switch.Root>
              </StyledItem>
              <StyledItem>
                <StyledTitle>{'Host Collection DNS Servers'}</StyledTitle>
                <Primitive.div id='dnsList' className='hide-tags'>
                  <ScannerConfigMultiSelect
                    items={dnsList}
                    update={updateDNS}
                  />
                </Primitive.div>
              </StyledItem>
            </HStack>
            <HStack yAlign='top' xAlign='between' width='full'>
              <StyledItem>
                <StyledTitle>{'Netmask'}</StyledTitle>
                <Text styledAs='body3'>{network?.ipnet}</Text>
              </StyledItem>
            </HStack>
          </VStack>
        </RowTile>
        <TroubleshootingSettings
          id={id}
          visible={troubleshootingModalOpen}
          onClose={() => toggleTroubleshootingModal(false)}
        />
      </StyledDiv>

      {showScannerSelect && (
        <ModalBox onCloseModal={() => setShowScannerSelect(false)}>
          <ScannerSelect closeModal={() => setShowScannerSelect(false)} />
        </ModalBox>
      )}
    </Fragment>
  );
};

const connector = connect(
  ({ scanner: { scanners } }, { id }) => ({
    scanner: scanners.get(id),
  }),
  (dispatch: Dispatch, ownProps: { id: string }) => {
    const { id } = ownProps;
    return {
      updateScannerConfiguration: (input: Array<string>) =>
        dispatch(
          updateScannerConfigurationThunk(
            id,
            input.map((value: string) => value.trim()),
            'blacklist',
            'IP Deny List updated successfully'
          )
        ),
      updateDNS: (input: Array<string>) => {
        dispatch(
          updateScannerConfigurationThunk(
            id,
            input.map((value: string) => value.trim()),
            'dns_servers',
            'DNS List updated successfully'
          )
        );
      },
    };
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScannerConfigurationRow);
