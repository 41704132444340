import React from 'react';
import styled from 'styled-components';
import { GREY } from '../../Constants/Styles';
import { Primitive, atoms } from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export type ChildNode = {
  node: React.ReactNode;
  title: string;
};

type BoxBorderType = {
  $childIndex: number;
  $expanded: number[];
  $childrenNodes: ChildNode[];
};

const BoxBorder = styled(Primitive.div)<BoxBorderType>`
  border: 1px solid ${GREY.lightTertiary};
  margin-top: -1px;

  background-color: ${(props) =>
    props.$expanded.includes(props.$childIndex) ? '#ecf7ff' : ''};

  border-radius: ${(props) =>
    props.$childIndex === 0
      ? '4px 4px 0px 0px'
      : props.$childIndex === props.$childrenNodes.length - 1
        ? '0px 0px 4px 4px'
        : '0px'};
`;

const ChildNodeContainer = styled(Primitive.div)`
  border-left: 1px solid ${GREY.lightTertiary};
  border-right: 1px solid ${GREY.lightTertiary};
`;

export interface Props {
  childrenNodes: ChildNode[];
}

const Accordion: React.FC<Props> = ({ childrenNodes }) => {
  const [expanded, setExpanded] = React.useState<number[]>([]);

  const Accordion = childrenNodes.map((child, index) => (
    <div data-testid='accordion' key={index}>
      <BoxBorder
        paddingLeft='smedium'
        width='full'
        display='flex'
        justifyContent='space-between'
        $childIndex={index}
        $expanded={expanded}
        $childrenNodes={childrenNodes}
      >
        <Primitive.div
          display='flex'
          justifyContent='space-between'
          width='full'
        >
          <Primitive.div
            display='flex'
            padding='small'
            paddingLeft='medium'
            textColor='brandDefault'
            width='full'
          >
            <Primitive.div
              data-testid='accordian-click'
              cursor='pointer'
              width='full'
              justifyContent='space-between'
              display='flex'
              onClick={() =>
                setExpanded(
                  expanded.includes(index)
                    ? expanded.filter((current) => current !== index)
                    : [...expanded, index]
                )
              }
            >
              <Primitive.span
                fontWeight='bold'
                fontSize='small'
                display='flex'
                justifyContent='space-between'
              >
                {child.title}
              </Primitive.span>
              <FontAwesomeIcon
                className={atoms({
                  paddingTop: 'xsmall',
                  marginRight: 'small',
                })}
                icon={expanded.includes(index) ? faChevronUp : faChevronDown}
              />
            </Primitive.div>
          </Primitive.div>
        </Primitive.div>
      </BoxBorder>
      <ChildNodeContainer>
        {expanded.includes(index) && child.node}
      </ChildNodeContainer>
    </div>
  ));

  return <>{Accordion}</>;
};

export { Accordion };
