import React from 'react';

const Unlicensed = () => {
  return (
    <div className='right_col' role='main' style={{ minHeight: '2840.99px' }}>
      <div className='row'>
        <div className='col-md-12 col-sm-12 col-xs-12 rs-no-horizontal-pad'>
          <h2 style={{ alignItems: 'center' }}>
            You do not have access to the Managed Risk dashboard. Please contact
            Security Services.
          </h2>
        </div>
      </div>
      <div className='clearfix'></div>
    </div>
  );
};

export default Unlicensed;
