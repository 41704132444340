import { iCaseAction } from './caseActions';
import { CaseActionTypes } from './actionEnums';
import { iState } from '../configureStore';

export interface iRiskAssignment {
  owner: string;
  startDate: string;
  endDate: string;
  dueDate: string;
}

export interface iAssignedRisk {
  risk: iRiskURI;
  assignment: iRiskAssignment;
}

export interface iRiskURI {
  id: string;
  href: string;
}

export interface iCase {
  assignedRisks?: Array<iAssignedRisk>;
  createdBy: string;
  createdTime: string;
  customerUuid: string;
  deploymentId: string;
  description: string;
  endTime?: string;
  id: string;
  owner: string;
  severity: string;
  startDate: string;
  status: string;
  tags?: Array<string>;
  title: string;
  updatedAt?: string;
  updatedBy?: string;
}

const initialState = {
  cases: new Map(),
};

export interface iCases {
  isProcessing?: boolean;
  cases: Map<string, iCase>;
}

export const caseReducer = (
  state: iCases = initialState,
  action: iCaseAction
) => {
  const newState: iCases = {
    ...state,
    // must manually clone this to get a new Map instance
    // otherwise we may accidentally mutate the previous state
    cases: new Map(state.cases),
  };

  switch (action.type) {
    case CaseActionTypes.PROCESSING_CASES: {
      newState.isProcessing = action.isProcessing ?? false;
      return newState;
    }

    case CaseActionTypes.SET_CASES: {
      const { cases } = action.payload;
      newState.cases = new Map(cases.map((item) => [item.id, item]));
      return newState;
    }

    case CaseActionTypes.REMOVE_CASE: {
      const { caseId } = action.payload;
      newState.cases.delete(caseId);
      return newState;
    }

    case CaseActionTypes.ADD_CASE: {
      const { newCase } = action.payload;
      newState.cases.set(newCase.id, newCase);
      return newState;
    }

    case CaseActionTypes.UPDATE_ASSIGNMENT: {
      const { caseId, riskId, assignment } = action.payload;
      const currentCase = newState.cases.get(caseId);
      if (!currentCase?.assignedRisks) {
        return state;
      }
      currentCase.assignedRisks = currentCase.assignedRisks.map(
        (assignedRisk: iAssignedRisk) => {
          if (assignedRisk.risk.id === riskId) {
            return {
              ...assignedRisk,
              assignment: {
                ...assignedRisk.assignment,
                ...assignment,
              },
            };
          } else {
            return assignedRisk;
          }
        }
      );
      newState.cases.set(caseId, currentCase);
      return newState;
    }

    case CaseActionTypes.REMOVE_RISK_FROM_CASE: {
      const { caseId, riskId } = action.payload;
      const currentCase = newState.cases.get(caseId);
      if (!currentCase?.assignedRisks) {
        return state;
      }
      const riskIndexToRemove = currentCase.assignedRisks.findIndex(
        (assignedRisk) => assignedRisk.risk.id === riskId
      );
      if (riskIndexToRemove === -1) {
        return state;
      }
      // must clone currentCase.assignedRisks here
      // to avoid mutating the previous state during splice
      currentCase.assignedRisks = [...currentCase.assignedRisks].splice(
        riskIndexToRemove,
        1
      );
      newState.cases.set(caseId, currentCase);
      return newState;
    }

    default:
      return state;
  }
};

export const caseSelectors = {
  getCaseMap: (store: iState): Map<string, iCase> => store.cases.cases,
  getCaseIsProcessing: (store: iState): boolean =>
    store.cases.isProcessing ?? false,
};
