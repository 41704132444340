// ----- Action Constants -----

import { AxiosError, AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import { iGetState } from '../configureStore';
import riskAPI from '../utils/riskAPI';

export const SET_USERS = 'SET_USERS';

// ----- Action Creators -----

// ----- Thunk Action Creators -----

export const getUsersThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    return riskAPI
      .get(
        `/rootsecure/v1/dashboard/usermgmt/scanners/${customer.currentCustomer.id}/users`
      )
      .then((response: AxiosResponse) => {
        return dispatch({
          type: SET_USERS,
          payload: response.data,
        });
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to retrieve users', error.code as string)
      );
  };
