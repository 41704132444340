import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import {
  Field,
  Button,
  HStack,
  Select,
  TextField,
  VStack,
  Grid,
} from '@rtkwlf/fenrir-react';

import { bulkUpdateAssets } from '../../../Global/assetActions';

import { AssetUpdateFieldsType } from '../utils';
import { configSelectors } from '../../../Global/configReducer';
import { customerSelectors } from '../../../Global/customerReducer';
import { useAppDispatch } from '../../../hooks';
import { ModalBox } from '../../../Reusables/ModalBox';
import { ASSET_CATEGORIES } from '../../../Constants/Assets';

export type Props = {
  data: Record<string, string>;
  assetId: string;
};

const AssetEditDetails = ({ data, assetId }: Props) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [deviceName, setDeviceName] = useState<string>('');
  const [isUpdateClick, setIsUpdateClick] = useState<boolean>(false);

  const assetCatgories = ASSET_CATEGORIES.map((category: string) => ({
    value: category,
    text: category,
  }));

  const setDefaultValues = useCallback(() => {
    setSelectedCategory(data.category);
    setDeviceName(data.deviceName);
  }, [data]);

  useEffect(() => {
    setDefaultValues();
  }, [setDefaultValues]);

  const clearFields = useCallback(() => {
    setDefaultValues();
    setVisibleEditModal(false);
    setIsUpdateClick(false);
    queryClient.invalidateQueries({ queryKey: [`getAssetDetails-${assetId}`] });
  }, [assetId, queryClient, setDefaultValues]);

  const handleUpdate = useCallback(() => {
    setIsUpdateClick(true);
    const selectedAssetsObj: Array<AssetUpdateFieldsType> = [
      {
        device_id: assetId,
        category: selectedCategory || '',
        deviceName: deviceName,
      },
    ];

    dispatch(
      bulkUpdateAssets(selectedAssetsObj, servicesHost, customerId, clearFields)
    );
  }, [
    assetId,
    clearFields,
    customerId,
    deviceName,
    dispatch,
    selectedCategory,
    servicesHost,
  ]);

  const isChanged = !!(selectedCategory || deviceName);

  return (
    <>
      <Button
        margin='zero'
        onClick={() => setVisibleEditModal(true)}
        variant='secondary'
        iconLeft={<i className='fa fa-pencil' />}
      >
        Edit Details
      </Button>
      <ModalBox
        title={'Edit Asset Details'}
        onCloseModal={clearFields}
        isOpen={visibleEditModal}
        width='699px'
        footer={
          <HStack yAlign='center' xAlign='right' gap='xsmall'>
            <Button onClick={clearFields} variant='secondary'>
              Cancel
            </Button>
            <Button
              data-testid='assetDetailUpdateDTI'
              onClick={handleUpdate}
              isDisabled={!isChanged}
              isLoading={isUpdateClick}
            >
              Update
            </Button>
          </HStack>
        }
      >
        <VStack width='full' paddingX='large' paddingBottom='large'>
          <Grid.Root
            columns={{
              monitor: '1fr 1fr',
              tablet: '1fr 1fr',
            }}
            gap={'default'}
            width={'full'}
          >
            <Grid.Item>
              <Field.Root>
                <Field.Label>Category</Field.Label>
                <Field.Content>
                  <Select
                    isClearable={false}
                    value={selectedCategory}
                    options={assetCatgories}
                    onChange={(selection) => setSelectedCategory(selection!)}
                  />
                </Field.Content>
              </Field.Root>
            </Grid.Item>
            <Grid.Item>
              <Field.Root>
                <Field.Label>Device Name</Field.Label>
                <Field.Content>
                  <TextField
                    value={deviceName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDeviceName(e.target.value)
                    }
                    placeholder='Enter Device Name'
                  />
                </Field.Content>
              </Field.Root>
            </Grid.Item>
          </Grid.Root>
        </VStack>
      </ModalBox>
    </>
  );
};

export default AssetEditDetails;
