import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';

import PanelTitle from '../../../Reusables/PanelTitle';
import TaskListTable from './AgentAudits/TaskListTable';
import WirelessNetworksTable from './AgentAudits/WirelessNetworksTable';
import USBDevicesTable from './AgentAudits/USBDevicesTable';
import { initScoutAuditsCSV } from './AgentAudits/AgentAuditCSVExport';

import { GREY } from '../../../Constants/Styles';
import styled from 'styled-components';
import { AllParsedAudit } from './AgentAudits/parser';
import SoftwareTable from './AgentAudits/SoftwareTable';
import { EXPORT_CSV_TOOLTIP } from '../../../Constants/Common';
import { DownloadCSVIcon } from '../../../Reusables/DownloadCSVIcon';
import { atoms, Primitive } from '@rtkwlf/fenrir-react';

const WrapStyle = styled.div`
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid ${GREY.lightTertiary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const WrapButtonStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NoDataStyle = styled.div`
  display: flex;
  justify-content: center;
`;

const AgentAuditContainer = styled(Primitive.div)`
  margin: 0px -20px 0px -20px;
`;

export type Props = {
  isError: boolean;
  isLoading: boolean;
  parsedAudit: AllParsedAudit;
};

const panel = atoms({
  margin: 'default',
  padding: 'medium',
  backgroundColor: 'monoWhite',
  borderRadius: 'default',
});

const AgentAudits = ({ parsedAudit, isError, isLoading }: Props) => {
  const handleExport = () => {
    if (parsedAudit) {
      initScoutAuditsCSV(parsedAudit);
    }
  };

  useEffect(() => {
    if (isError) {
      toastr.error('Failed to fetch Agent audit data', '');
    }
  }, [isError]);

  return (
    <AgentAuditContainer>
      <Primitive.div className={panel}>
        <div className='row' data-testid='agent-audit-header'>
          <div className='col-md-6'>
            <PanelTitle title={'Agent Audits'} />
          </div>
          <div className='col-md-6'>
            <WrapButtonStyle>
              <DownloadCSVIcon
                handleExport={handleExport}
                tooltipText={EXPORT_CSV_TOOLTIP}
                disabled={!parsedAudit}
              />
            </WrapButtonStyle>
          </div>
        </div>

        <WrapStyle data-testid='agent-audit-task-list'>
          <div className='row'>
            <div className='col-md-6'>
              <PanelTitle title={'Task List'} />
            </div>
            <Primitive.div textAlign='right' className='col-md-6'>
              {parsedAudit?.taskList.auditTime ?? ''}
            </Primitive.div>
          </div>
          {parsedAudit ? (
            <div className='row'>
              <div className='col-xs-12'>
                <TaskListTable
                  os={parsedAudit.os}
                  taskListData={parsedAudit.taskList.data}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataStyle>No Data Available</NoDataStyle>
          )}
        </WrapStyle>

        <WrapStyle data-testid='agent-audit-wireless-network'>
          <div className='row'>
            <div className='col-md-6'>
              <PanelTitle title={'Wireless Networks'} />
            </div>
            <Primitive.div textAlign='right' className='col-md-6'>
              {parsedAudit?.wirelessNetwork.auditTime ?? ''}
            </Primitive.div>
          </div>
          {parsedAudit ? (
            <div className='row'>
              <div className='col-xs-12'>
                <WirelessNetworksTable
                  os={parsedAudit.os}
                  wirelessData={parsedAudit.wirelessNetwork.data}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataStyle>No Data Available</NoDataStyle>
          )}
        </WrapStyle>

        <WrapStyle data-testid='agent-audit-usb-devices'>
          <div className='row'>
            <div className='col-md-6'>
              <PanelTitle title={'USB Devices'} />
            </div>
            <Primitive.div textAlign='right' className='col-md-6'>
              {parsedAudit?.usbDevices.auditTime ?? ''}
            </Primitive.div>
          </div>
          {parsedAudit ? (
            <div className='row'>
              <div className='col-xs-12'>
                <USBDevicesTable
                  os={parsedAudit.os}
                  usbDevicesData={parsedAudit.usbDevices.data}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataStyle>No Data Available</NoDataStyle>
          )}
        </WrapStyle>

        <WrapStyle data-testid='agent-audit-software-package'>
          <div className='row'>
            <div className='col-md-6'>
              <PanelTitle title={'Software Packages'} />
            </div>
            <Primitive.div textAlign='right' className='col-md-6'>
              {parsedAudit?.software.auditTime ?? ''}
            </Primitive.div>
          </div>
          {parsedAudit ? (
            <div className='row'>
              <div className='col-xs-12'>
                <SoftwareTable
                  os={parsedAudit.os}
                  softwareData={parsedAudit.software.data}
                  isLoading={isLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataStyle>No Data Available</NoDataStyle>
          )}
        </WrapStyle>
      </Primitive.div>
    </AgentAuditContainer>
  );
};

export default AgentAudits;
