import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import PanelTitle from '../PanelTitle';
import {
  Button,
  Checkbox,
  Field,
  HStack,
  MultiSelect,
  MultiSelectOption,
  Primitive,
  Select,
  Text,
  TextField,
} from '@rtkwlf/fenrir-react';
import { DatePicker } from '../DatePicker';
import { iAssetCategories } from '../../Global/assetReducer';

import {
  ASSET_FILTERS_LABEL,
  ASSET_FILTERS_TOOLTIP,
  CRITICALITY_MAP,
} from '../../Constants/Common';
import { ASSET_ICONS } from '../../Constants/Icons';
import {
  SOURCE,
  RISK_STATES,
  STATUS,
  DEFAULT_FILTER_STATE,
  ACTIONS,
} from '../../Constants/Risks';
import { RiskScoreFilter } from './RiskScoreFilter';
import { RisksParams } from '../../types/risks';
import moment from 'moment';
import { getSelectedValues } from '../../Pages/Assets/utils';
import { useQueryAllTags } from '../../Global/assetTags';
import { useSelector } from 'react-redux';
import { customerSelectors } from '../../Global/customerReducer';
import { BLUE } from '../../Constants/Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@rtkwlf/fenrir-react';
import {
  ffLittleWinsMR20240821,
  isFeatureEnabled,
} from '../../../public/js/features';
import { ASSET_CATEGORIES } from '../../Constants/Assets';

export type SelectType = {
  value: string;
  label: string;
};

export type SelectNumberType = {
  value: number;
  label: string;
};

const FilterWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  padding: 20px;
  border: 1px solid;
  border-color: #cccccc;
  border-radius: 4px;
  min-width: 600px;
`;

const StyledIcon = styled.i`
  margin: 0 5px 0 0;
`;

const DatePickerStyle = styled(DatePicker)`
  width: 110px;
  height: 32px !important;
  font-size: 14px;
  padding: 0 4px;
  color: hsl(0, 0%, 50%);
  border-color: #b3b3b3 !important;
  border-radius: 4px !important;
`;

const StyledClearFilter = styled(Primitive.div)`
  padding-top: 18px;
`;

type Props = {
  assets?: Array<iAssetCategories>;
  users?: Array<MultiSelectOption>;
  status?: Array<string> | string;
  state?: Array<string>;
  showActionFilter?: boolean;
  issueAction?: string;
  filters: RisksParams;
  defaultRiskScores: Array<number>;
  handleFilters: (filter: RisksParams) => void;
  multiSearch?: boolean;
  showResolvedDate?: boolean;
  initialFilters?: RisksParams;
  showLastSuccessfulDate?: boolean;
};

const stateOptions = Object.values(RISK_STATES).map((state: string) => ({
  label: state,
  value: state,
}));

const statusOptions = Object.values(STATUS).map((state: string) => ({
  label: state,
  value: state,
}));

const actionOptions = Object.values(ACTIONS).map((state: string) => ({
  text: state,
  value: state,
}));

const searchInfo =
  'Enter a search term, or a comma-separated list of terms, to automatically create a filter for each term.';

const MultiSelectWrapper = ({ children, ...props }: PropsWithChildren) => {
  return (
    <Primitive.div
      className='truncate-tags'
      flex={1}
      minWidth='max-content'
      maxWidth='25rem'
      {...props}
    >
      {children}
    </Primitive.div>
  );
};

type MultiSelectWrapperWithTooltipProps = {
  label: string;
  placeholder: string;
  options: MultiSelectOption[];
  selectedOptions: MultiSelectOption[];
  tooltipText: string;
  onSelectedOptionsChange: (optionsSelected: MultiSelectOption[]) => void;
  numberOfTags?: number;
  props?: any;
};

const MultiSelectWrapperWithTooltip: React.FC<
  MultiSelectWrapperWithTooltipProps
> = ({
  label,
  placeholder,
  options,
  selectedOptions,
  tooltipText,
  onSelectedOptionsChange,
  numberOfTags = 2,
  ...props
}) => {
  return (
    <Primitive.div
      className='truncate-tags multiselect-with-tooltip'
      flex={1}
      minWidth='max-content'
      maxWidth='25rem'
      {...props}
    >
      <Field.Root>
        <Field.Label>{label}</Field.Label>
        <Field.Content>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <Primitive.div>
                <MultiSelect
                  numberOfTags={numberOfTags}
                  placeholder={
                    placeholder === 'All' && selectedOptions.length
                      ? ''
                      : placeholder
                  }
                  options={options}
                  selectedOptions={selectedOptions}
                  onSelectedOptionsChange={onSelectedOptionsChange}
                />
              </Primitive.div>
            </Tooltip.Trigger>
            <Tooltip.Content side='top' maxWidth='20rem'>
              <Tooltip.Text>{tooltipText}</Tooltip.Text>
            </Tooltip.Content>
          </Tooltip.Root>
        </Field.Content>
      </Field.Root>
    </Primitive.div>
  );
};

// consolidate categories that are showing on existing DM assets
// with known categories that can come from ADS
export const getUniqueCategories = (
  categories: iAssetCategories[],
  adsCategories: string[]
) => {
  return [
    ...new Set([
      ...categories.map((category: iAssetCategories) =>
        category.name.toLowerCase()
      ),
      ...adsCategories,
    ]),
  ];
};

export const Filters = ({
  assets,
  users,
  status,
  showActionFilter = false,
  issueAction,
  state,
  filters,
  handleFilters,
  showResolvedDate = false,
  multiSearch,
  initialFilters,
  showLastSuccessfulDate,
}: Props) => {
  const [selectedAction, setSelectedAction] = useState<string>(
    issueAction || 'All'
  );
  const { data: tags } = useQueryAllTags(
    useSelector(customerSelectors.getCustomerId)
  );

  const uniqueCategories = getUniqueCategories(assets || [], ASSET_CATEGORIES);

  const assetCatgories = uniqueCategories?.map((category: string) => ({
    value: category,
    label: category,
  }));
  const criticalityOptions = Object.values(CRITICALITY_MAP).map(
    (value, key) => ({
      value: `${key}`,
      label: value.value,
    })
  );
  const onSourceChange = (checked: boolean, value: string) => {
    const sourcesData = filters.source || [];

    checked
      ? handleFilters({ ...filters, source: [...sourcesData, value] })
      : handleFilters({
          ...filters,
          source: [...sourcesData].filter((source: string) => source !== value),
        });
  };

  const clearAllFilter = (e: any) => {
    e.target.blur();
    handleFilters({
      ...{ limit: filters.limit, order: filters.order },
      ...DEFAULT_FILTER_STATE,
      ...(initialFilters || {}),
    });
    setSelectedAction('All');
  };

  // init dropdown values
  const userDropdown = initDropdown(filters.owner);
  const stateDropdown = initDropdown(filters.state);
  const statusDropdown = initDropdown(filters.status);
  const categoryDropdown = initDropdown(filters.category);
  const searchDropdown = initDropdown(filters.search, 'None');
  const tagsOptions =
    typeof tags !== 'undefined'
      ? tags.map((tag) => {
          return { label: tag.name, value: `${tag.tagID}` };
        })
      : [];
  const selectedTags = getSelectedValues(filters.tagID || [], tagsOptions);
  const selectedCrit = getSelectedValues(
    filters.criticality ?? [],
    criticalityOptions
  );

  return (
    <FilterWrap>
      <PanelTitle
        title={ASSET_FILTERS_LABEL}
        tooltipText={ASSET_FILTERS_TOOLTIP}
      />

      <HStack
        xAlign='left'
        yAlign='top'
        wrap='wrap'
        data-testid='filters-top-row'
      >
        <RiskScoreFilter
          defaultRange={[
            initialFilters?.fromLevel || initialFilters?.fromLevel === 0
              ? initialFilters?.fromLevel
              : 4,
            initialFilters?.toLevelInclusive || 10,
          ]}
          selectedScores={[
            filters.fromLevel || filters.fromLevel === 0
              ? filters.fromLevel
              : 4,
            filters.toLevelInclusive || filters.toLevelInclusive === 0
              ? filters.toLevelInclusive
              : 10,
          ]}
          onScoreChange={(riskScore) => {
            handleFilters({
              ...filters,
              fromLevel: riskScore[0],
              toLevelInclusive: riskScore[1],
            });
          }}
        />

        {showLastSuccessfulDate && (
          <Primitive.div>
            <Field.Root>
              <Field.Label>Last Successful Scan Date Range</Field.Label>
              <Field.Content>
                <HStack gap='small'>
                  <DatePickerStyle
                    className='select-fromScannedAfter'
                    selected={
                      filters.firstScannedAfter
                        ? moment.unix(filters.firstScannedAfter).toDate()
                        : filters.firstScannedAfter
                    }
                    placeholderText={'MM/DD/YYYY'}
                    onChange={(event: any) => {
                      if (event !== null) {
                        handleFilters({
                          ...filters,
                          firstScannedAfter: getDateEpoch(moment(event)),
                        });
                      } else {
                        handleFilters({
                          ...filters,
                          firstScannedAfter: undefined,
                        });
                      }
                    }}
                  />
                  <Text>to</Text>
                  <DatePickerStyle
                    className='select-toScannedBefore'
                    selected={
                      filters.firstScannedBefore
                        ? moment.unix(filters.firstScannedBefore).toDate()
                        : filters.firstScannedBefore
                    }
                    placeholderText={'MM/DD/YYYY'}
                    onChange={(event: any) => {
                      if (event !== null) {
                        handleFilters({
                          ...filters,
                          firstScannedBefore: getDateEpoch(moment(event)),
                        });
                      } else {
                        handleFilters({
                          ...filters,
                          firstScannedBefore: undefined,
                        });
                      }
                    }}
                  />
                </HStack>
              </Field.Content>
            </Field.Root>
          </Primitive.div>
        )}

        <Primitive.div>
          <Field.Root>
            <Field.Label>Discovery Date Range</Field.Label>
            <Field.Content>
              <HStack gap='small'>
                <DatePickerStyle
                  className='select-fromDiscoveryDate'
                  selected={
                    filters.firstIdentifiedAfter
                      ? moment.unix(filters.firstIdentifiedAfter).toDate()
                      : filters.firstIdentifiedAfter
                  }
                  placeholderText={'MM/DD/YYYY'}
                  onChange={(event: any) => {
                    if (event !== null) {
                      handleFilters({
                        ...filters,
                        firstIdentifiedAfter: getDateEpoch(moment(event)),
                      });
                    } else {
                      handleFilters({
                        ...filters,
                        firstIdentifiedAfter: undefined,
                      });
                    }
                  }}
                />
                <Text>to</Text>
                <DatePickerStyle
                  className='select-toDiscoveryDate'
                  selected={
                    filters.firstIdentifiedBefore
                      ? moment.unix(filters.firstIdentifiedBefore).toDate()
                      : filters.firstIdentifiedBefore
                  }
                  placeholderText={'MM/DD/YYYY'}
                  onChange={(event: any) => {
                    if (event !== null) {
                      handleFilters({
                        ...filters,
                        firstIdentifiedBefore: getDateEpoch(moment(event)),
                      });
                    } else {
                      handleFilters({
                        ...filters,
                        firstIdentifiedBefore: undefined,
                      });
                    }
                  }}
                />
              </HStack>
            </Field.Content>
          </Field.Root>
        </Primitive.div>

        {typeof users !== 'undefined' && (
          <MultiSelectWrapperWithTooltip
            label='Users'
            placeholder='All'
            options={users}
            selectedOptions={userDropdown.value}
            tooltipText={userDropdown.tooltip}
            onSelectedOptionsChange={(optionsSelected) => {
              handleFilters({
                ...filters,
                owner: optionsSelected.map((x) => x.value),
              });
            }}
          />
        )}

        {!!showResolvedDate && (
          <Primitive.div>
            <Field.Root>
              <Field.Label>Resolved Date Range</Field.Label>
              <Field.Content>
                <HStack gap='small'>
                  <DatePickerStyle
                    className='select-resolvedAfter'
                    selected={
                      filters.resolvedAfter
                        ? moment.unix(filters.resolvedAfter).toDate()
                        : filters.resolvedAfter
                    }
                    placeholderText={'MM/DD/YYYY'}
                    onChange={(event: any) => {
                      if (event !== null) {
                        handleFilters({
                          ...filters,
                          resolvedAfter: getDateEpoch(moment(event)),
                        });
                      } else {
                        handleFilters({
                          ...filters,
                          resolvedAfter: undefined,
                        });
                      }
                    }}
                  />
                  <Text>to</Text>
                  <DatePickerStyle
                    className='select-resolvedBefore'
                    selected={
                      filters.resolvedBefore
                        ? moment.unix(filters.resolvedBefore).toDate()
                        : filters.resolvedBefore
                    }
                    placeholderText={'MM/DD/YYYY'}
                    onChange={(event: any) => {
                      if (event !== null) {
                        handleFilters({
                          ...filters,
                          resolvedBefore: getDateEpoch(moment(event)),
                        });
                      } else {
                        handleFilters({
                          ...filters,
                          resolvedBefore: undefined,
                        });
                      }
                    }}
                  />
                </HStack>
              </Field.Content>
            </Field.Root>
          </Primitive.div>
        )}

        {typeof state !== 'undefined' && (
          <MultiSelectWrapperWithTooltip
            label='State'
            placeholder='All'
            options={stateOptions}
            selectedOptions={stateDropdown.value}
            tooltipText={stateDropdown.tooltip}
            onSelectedOptionsChange={(optionsSelected) => {
              handleFilters({
                ...filters,
                state: optionsSelected.map((value) => value.value) ?? [],
              });
            }}
          />
        )}
        {typeof status !== 'undefined' && (
          <MultiSelectWrapperWithTooltip
            label='Status'
            placeholder='All'
            options={statusOptions}
            selectedOptions={statusDropdown.value}
            tooltipText={statusDropdown.tooltip}
            onSelectedOptionsChange={(optionsSelected) => {
              handleFilters({
                ...filters,
                status: optionsSelected.map((value) => value.value) ?? [],
              });
            }}
          />
        )}
        {showActionFilter && isFeatureEnabled(ffLittleWinsMR20240821) && (
          <Primitive.div width='14rem' data-testid='action-filter'>
            <Field.Root>
              <Field.Label>Action</Field.Label>
              <Field.Content>
                <Primitive.div>
                  <Select
                    isClearable={false}
                    value={selectedAction}
                    options={actionOptions}
                    onSelection={(optionsSelected) => {
                      handleFilters({
                        ...filters,
                        issueAction:
                          optionsSelected?.value === 'All'
                            ? ''
                            : optionsSelected?.value,
                      });
                      setSelectedAction(optionsSelected?.value ?? '');
                    }}
                  />
                </Primitive.div>
              </Field.Content>
            </Field.Root>
          </Primitive.div>
        )}
      </HStack>
      <hr style={{ borderTop: '2px solid #cfcfcf' }} />
      <HStack
        wrap='wrap'
        xAlign='left'
        yAlign='top'
        data-testid='filters-bottom-row'
      >
        {multiSearch ? (
          <Primitive.div>
            <HStack gap='small'>
              <Text styledAs='body4' marginBottom='small'>
                Search
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={BLUE.primary}
                      style={{ marginLeft: '4px' }}
                      cursor='pointer'
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Content side='top' maxWidth='20rem'>
                    <Tooltip.Text>{searchInfo}</Tooltip.Text>
                  </Tooltip.Content>
                </Tooltip.Root>
              </Text>
            </HStack>
            <MultiSelectWrapper data-testid='select-search-selector'>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Primitive.div>
                    <MultiSelect
                      isCreatable
                      numberOfTags={1}
                      placeholder='Type and press Enter'
                      options={searchDropdown.value}
                      selectedOptions={searchDropdown.value}
                      onSelectedOptionsChange={(
                        selectedOptions: MultiSelectOption[]
                      ) => {
                        handleFilters({
                          ...filters,
                          search: selectedOptions.map((i) => i.value),
                        });
                      }}
                      handleCreate={(
                        newOption: string,
                        updateFilterInput: (filter: string) => void
                      ) => {
                        if (newOption.length) {
                          handleFilters({
                            ...filters,
                            search: [
                              ...searchDropdown.value.map((i) => i.value),
                              ...newOption.split(','),
                            ],
                          });
                          updateFilterInput('');
                        }
                      }}
                    />
                  </Primitive.div>
                </Tooltip.Trigger>
                <Tooltip.Content side='top' maxWidth='20rem'>
                  <Tooltip.Text>{searchDropdown.tooltip}</Tooltip.Text>
                </Tooltip.Content>
              </Tooltip.Root>
            </MultiSelectWrapper>
          </Primitive.div>
        ) : (
          <Primitive.div width='16%'>
            <Field.Root>
              <Field.Label>Search</Field.Label>
              <Field.Content>
                <TextField
                  data-testid='search'
                  value={filters.search}
                  placeholder='Search'
                  iconRight={<FontAwesomeIcon icon={faSearch} />}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFilters({
                      ...filters,
                      search: e.target.value,
                    })
                  }
                />
              </Field.Content>
            </Field.Root>
          </Primitive.div>
        )}
        <Primitive.div maxWidth='max-content'>
          <Field.Root>
            <Field.Label>Source</Field.Label>
            <Field.Content>
              <HStack gap='small' height='2rem'>
                <Checkbox.Root
                  data-testid={`source-${SOURCE.SENSOR}`}
                  isChecked={filters.source?.includes(SOURCE.SENSOR) || false}
                  onCheckedChange={(checked: boolean) =>
                    onSourceChange(checked, SOURCE.SENSOR)
                  }
                >
                  <Checkbox.Text>
                    <StyledIcon className={`fa ${ASSET_ICONS.sensor}`} />
                    IVA
                  </Checkbox.Text>
                </Checkbox.Root>
                <Checkbox.Root
                  data-testid={`source-${SOURCE.REACH}`}
                  isChecked={filters.source?.includes(SOURCE.REACH) || false}
                  onCheckedChange={(checked: boolean) =>
                    onSourceChange(checked, SOURCE.REACH)
                  }
                >
                  <Checkbox.Text>
                    <StyledIcon className={`fa ${ASSET_ICONS.reach}`} />
                    EVA
                  </Checkbox.Text>
                </Checkbox.Root>

                <Checkbox.Root
                  data-testid={`source-${SOURCE.AGENT}`}
                  isChecked={filters.source?.includes(SOURCE.AGENT) || false}
                  onCheckedChange={(checked: boolean) =>
                    onSourceChange(checked, SOURCE.AGENT)
                  }
                >
                  <Checkbox.Text>
                    <StyledIcon className={`fa ${ASSET_ICONS.agent}`} />
                    Agent
                  </Checkbox.Text>
                </Checkbox.Root>
              </HStack>
            </Field.Content>
          </Field.Root>
        </Primitive.div>
        {typeof tags !== 'undefined' && (
          <MultiSelectWrapperWithTooltip
            label='Asset Tags'
            placeholder='Select...'
            options={tagsOptions}
            numberOfTags={1}
            selectedOptions={selectedTags}
            tooltipText={
              selectedTags.length === 0
                ? 'All'
                : selectedTags.map((tag) => tag.label).join(', ')
            }
            onSelectedOptionsChange={(optionsSelected) => {
              handleFilters({
                ...filters,
                tagID: optionsSelected.map((x) => Number(x.value)),
              });
            }}
            data-testid='filter-tags'
          />
        )}
        <MultiSelectWrapperWithTooltip
          label='Asset Criticality'
          placeholder='Select...'
          numberOfTags={1}
          options={criticalityOptions}
          selectedOptions={selectedCrit}
          data-testid='filter-criticality'
          tooltipText={
            selectedCrit.length === 0
              ? 'All'
              : selectedCrit.map((crit) => crit.label).join(', ')
          }
          onSelectedOptionsChange={(optionsSelected) => {
            handleFilters({
              ...filters,
              criticality: optionsSelected?.map((x) => Number(x.value)),
            });
          }}
        />
        {typeof assets !== 'undefined' && (
          <MultiSelectWrapperWithTooltip
            label='Asset Category'
            placeholder='Select...'
            numberOfTags={1}
            options={assetCatgories as MultiSelectOption[]}
            selectedOptions={categoryDropdown.value}
            data-testid='filter-asset-category'
            tooltipText={categoryDropdown.tooltip}
            onSelectedOptionsChange={(optionsSelected) => {
              handleFilters({
                ...filters,
                category: optionsSelected.map((x) => x.value),
              });
            }}
          />
        )}

        <StyledClearFilter marginLeft='auto' height='4rem'>
          <Button
            data-testid='clear-filters'
            variant='secondary'
            onClick={clearAllFilter}
            iconLeft={<i className='fa fa-lg fa-times' />}
          >
            Clear Filters
          </Button>
        </StyledClearFilter>
      </HStack>
    </FilterWrap>
  );
};
export default Filters;

const getDateEpoch = (date: moment.Moment) => {
  const epochDate = date.valueOf().toString();
  return parseInt(epochDate.substring(0, epochDate.length - 3));
};

const initDropdown = (
  incomingValue: string | Array<string> | undefined,
  defaultTooltip: string = 'All'
) => {
  let tooltip = defaultTooltip ? defaultTooltip : 'All';
  let value: SelectType[] = [];
  if (incomingValue && incomingValue.toString().length > 0) {
    if (Array.isArray(incomingValue)) {
      tooltip = incomingValue?.map((x) => x).join(', ');
      value = incomingValue?.map((x) => ({
        value: x,
        label: x,
      }));
    } else {
      tooltip = incomingValue;
      value = [{ value: incomingValue, label: incomingValue }];
    }
  }
  return { tooltip, value };
};
