import $ from 'jquery';
import { getAuthToken } from './authentication';
import { getServicesHost } from '../../Global/configReducer';
import riskAPI from '../../utils/riskAPI';

// WARNING this file is duplicated for jquery pages /public/js/authorization.js

function getEnv() {
  const PROD_DOMAINS = [
    'dashboard.rootsoc.com',
    'beta-risk.arcticwolf.com',
    'risk.arcticwolf.com',
    'localhost',
  ];
  const isE2eTesting = window.localStorage.getItem('e2e_testing');
  const enableProd = window.localStorage.getItem('enable_prod');
  const host = window.location.hostname;

  if (
    isE2eTesting === 'true' ||
    (host === 'localhost' && enableProd !== 'true')
  )
    return 'test';

  return PROD_DOMAINS.includes(window.location.hostname) ? 'prod' : 'test';
}

const isGlobalTest = () => {
  const enableProd = window.localStorage.getItem('enable_prod');
  const host = window.location.hostname;

  return (
    window.location.hostname === 'risk.global-test.arcticwolf.net' ||
    (host === 'localhost' && enableProd !== 'true')
  );
};

export const getCustomerPod = (customerId) => {
  if (isGlobalTest()) {
    return 'n/a';
  }
  const env = getEnv();
  const podData = $.ajax({
    url: `https://prp.prp.${env}.global-${env}.arcticwolf.net/api/v2/organizations/${customerId}/`,
    headers: { Authorization: 'Bearer ' + getAuthToken() },
    dataType: 'json',
    async: false,
  }).responseJSON;

  return `${podData.pod}-${getEnv()}`;
};

function updateSensor(sensor) {
  localStorage.setItem(
    'sensor',
    JSON.stringify({
      uuid: sensor.uuid,
      label: sensor.label,
      deploymentID: sensor.deploymentID,
      id: sensor.uuid,
    })
  );
  localStorage.setItem(
    'scanner',
    JSON.stringify({
      uuid: sensor.uuid,
      label: sensor.label,
      deploymentID: sensor.deploymentID,
      id: sensor.uuid,
    })
  );
}

function authorizeUser(user) {
  // TODO: should this be identified at the first /customers API call?
  if (user.status === 401) {
    window.location = 'unlicensed.html';
  }

  if (!Object.isEmpty(user)) {
    localStorage.setItem('user_role', user.role);
    localStorage.setItem('partner_id', user.corporateid);
    localStorage.setItem('login_id', user.loginid);
    localStorage.setItem('feature_inclusions', user.featurevisibility);
    localStorage.setItem('zero_deployment_sensor', user.zerosensor);

    if (user.defaultsensor === '') {
      window.location = 'landing.html';
    }
  }
}

const getCustomerAwnIDFromDeploymentID = (deploymentID) => {
  return deploymentID.replace(/\d+/g, '');
};

/**
 * Fetch the list of customers (with corresponding region & pod)
 *  that the current user has access to
 */
const fetchCustomers = () => {
  let customers = [];

  if (isGlobalTest()) {
    try {
      const riskCustomers = $.ajax({
        url: `${getServicesHost()}/rootsecure/v1/customers`,
        headers: { Authorization: 'Bearer ' + getAuthToken() },
        dataType: 'json',
        async: false,
      }).responseJSON.data;

      customers = riskCustomers.map((riskCustomer) => ({
        pod: 'n/a',
        riskID: riskCustomer.id,
        id: getCustomerAwnIDFromDeploymentID(riskCustomer.deploymentID),
        name: riskCustomer.name,
      }));
    } catch (e) {
      console.error(e);
    }
  } else {
    const env = getEnv();

    try {
      const awnCustomers = $.ajax({
        url: `https://prp.prp.${env}.global-${env}.arcticwolf.net/api/v1/customer-switch`,
        headers: { Authorization: 'Bearer ' + getAuthToken() },
        dataType: 'json',
        async: false,
      }).responseJSON;

      customers = awnCustomers.map((awnCustomer) => ({
        riskID: awnCustomer.riskID,
        id: awnCustomer.id,
        name: awnCustomer.name,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  if (customers.length === 0) {
    console.error('No customers found or error fetching customers.');
    window.location = 'unlicensed.html';
  }

  localStorage.setItem('customers', JSON.stringify(customers));

  return customers;
};

const setAPIPod = (pod) => {
  localStorage.setItem('apiPod', pod);
};

const checkStoredCustomerIsValid = () => {
  let storedCustomer;
  try {
    storedCustomer = JSON.parse(
      localStorage.getItem('currentCustomer') || 'null'
    );
  } catch (e) {
    // if we can't even parse the currentCustomer, it is definitely invalid
    // treat it as such. otherwise, users will get stuck at the BSOD
    return false;
  }

  const storedPod = localStorage.getItem('apiPod');
  const allCustomers = JSON.parse(localStorage.getItem('customers') || 'null');

  if (!storedCustomer || !storedPod || !allCustomers) {
    return false;
  }

  const foundCustomer = allCustomers.find(
    (customer) => customer.riskID === storedCustomer.id
  );

  if (!foundCustomer) {
    return false;
  }

  const pod = getCustomerPod(foundCustomer.id);

  if (pod !== storedPod) {
    console.error(
      `mismatch between stored customer ${pod} and pod ${storedPod}`
    );
    return false;
  }

  return true;
};

// check if UUIDs are valid
const validUUID = (id) => {
  // not sure if the uppercase is necessary
  const regex = new RegExp(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  return regex.test(id);
};

// removes the specified parameter from the url string
function removeURLParameter(param) {
  const params = new URLSearchParams(window.location.search);
  if (params.get(param)) params.delete(param);
  return window.location.href.split('?')[0] + '?' + params.toString();
}

function getUrlVars() {
  const params = window.location.href.slice(
    window.location.href.indexOf('?') + 1
  );
  var vars = new URLSearchParams(params);
  for (const [key, value] of vars) {
    var val = value.replace(/#.*/g, '');
    vars.set(key, val);
  }
  return vars;
}

// get the param from urlVars, return undefined for legacy behavior
const retrieveUrlVars = (key) => {
  var val = getUrlVars().get(key);
  if (!val) {
    return undefined;
  } else {
    return val;
  }
};

const fetchUserData = async (url, timeout) => {
  if (window.Cypress) {
    return $.ajax({
      url: getServicesHost() + '/rootsecure/v1/lookup',
      headers: { Authorization: 'Bearer ' + getAuthToken() },
      dataType: 'json',
      async: false,
    }).responseJSON;
  }
  try {
    const response = await riskAPI.get(url, { timeout: timeout });
    return response.data;
  } catch (error) {
    const response = await riskAPI.get(url, { timeout: timeout * 4 });
    return response.data;
  }
};

const lookupUser = () => {
  const TIMEOUT = 15000;
  const url = getServicesHost() + '/rootsecure/v1/lookup';

  return fetchUserData(url, TIMEOUT);
};

/**
 * Determine the current customer & pod to use, and sync it to localStorage.
 * Possible scenarios:
 * 1) an id (could be either a scanner ID or a customer ID) is present in URL query params:
 *    a) if another param cid is provided, use cid to look up and update pod
 *    b) if no cid is provided, try to treat id as a customer id and lookup pod
 * 2) the current localStorage is "valid", see `checkStoredCustomerIsValid`:
 *    do nothing;
 * 3) the current localStorage is "invalid":
 *    initialize everything based on the first customer of the current user
 */
const updateCurrentCustomer = () => {
  try {
    const allCustomers = JSON.parse(localStorage.getItem('customers'));

    const urlID = retrieveUrlVars('id');
    const urlCID = retrieveUrlVars('cid');

    if (validUUID(urlID)) {
      let cid = '';
      if (validUUID(urlCID)) {
        // if cid is provided, use it to lookup and update pod
        cid = urlCID;
        const customerId = allCustomers.find(
          (customer) => customer.riskID === urlCID
        ).id;

        // fetch pod for specific cid.
        const pod = getCustomerPod(customerId);
        setAPIPod(pod);
      } else {
        const customer = allCustomers.find(
          (customer) => customer.riskID === urlID
        );
        if (customer) {
          // if id is provided and is a customer ID (i.e. found in allCustomers)
          // use it to lookup and update pod
          cid = urlID;
          const customerId = customer.id;

          const pod = getCustomerPod(customerId);
          setAPIPod(pod);
        } else {
          // id is a scanner ID, call sensordata API to find out customer ID
          const scannerData = $.ajax({
            url: `${getServicesHost()}/rootsecure/v1/sensordata/${urlID}`,
            headers: { Authorization: 'Bearer ' + getAuthToken() },
            dataType: 'json',
            async: false,
          }).responseJSON;

          cid = scannerData.customerID;
        }
      }

      updateSensor({ uuid: urlID, label: '' });

      const storedCustomer = JSON.parse(
        localStorage.getItem('currentCustomer') || 'null'
      );

      lookupUser().then((user) => {
        authorizeUser(user);
        const customerDetails = user.customers.find(
          (customer) => customer.id === cid
        );
        if (!storedCustomer || cid !== storedCustomer.id) {
          localStorage.setItem(
            'currentCustomer',
            JSON.stringify({
              name: customerDetails.name,
              deploymentID: customerDetails.deploymentID,
              id: customerDetails.id,
            })
          );

          if (window.location.pathname.indexOf('.html') < 0) {
            // this is a React page, to avoid race condition, force a reload
            // this makes sure Redux init *after* this function sets the newer customer in localstorage
            // reload the page with complete url'
            var newUrl = removeURLParameter('id');
            window.history.replaceState({}, document.title, newUrl);
            window.location.reload();
          }
        }
        // remove id parameter from url after customer switch
        var newUrl = removeURLParameter('id');
        window.history.replaceState({}, document.title, newUrl);
      });
    } else if (!checkStoredCustomerIsValid()) {
      const awnCustomer = allCustomers[0];
      const pod = getCustomerPod(awnCustomer.id);
      setAPIPod(pod);

      // use lookup to retrieve more info e.g. UUIDs for this customer
      lookupUser().then((user) => {
        authorizeUser(user);

        const customerDetails = user.customers.find(
          (customer) => customer.id === awnCustomer.riskID
        );

        localStorage.setItem(
          'currentCustomer',
          JSON.stringify({
            name: customerDetails.name,
            label: customerDetails.label,
            deploymentID: customerDetails.deploymentID,
            id: customerDetails.id,
          })
        );

        if (window.location.pathname.indexOf('.html') < 0) {
          // this is a React page, to avoid race condition, force a reload
          // this makes sure Redux init *after* this function sets the newer customer in localstorage
          window.location.reload();
        }
      });
    } else {
      lookupUser().then((user) => {
        authorizeUser(user);
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const RSAuthorize = () => {
  fetchCustomers();
  updateCurrentCustomer();
};
