import React from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import riskAPI from '../../utils/riskAPI';
import { customerSelectors } from '../../Global/customerReducer';
import { toastr } from 'react-redux-toastr';

import {
  Field,
  Button,
  HStack,
  Primitive,
  Tooltip,
  VStack,
} from '@rtkwlf/fenrir-react';
import styled from 'styled-components';
import { BLUE, GREY } from '../../Constants/Styles';

import AssetTagTable, { useFilterTags } from './AssetTagTable';
import { useQueryAllTags } from '../../Global/assetTags';

import { Formik, useField } from 'formik';
import * as Yup from 'yup';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const NEW_TAG_TOOLTIP_TEXT =
  'Tags are used to help organize Assets into different groups.';
const NEW_TAG_TOOLTIP_SUBTEXT = [
  'Tag values cannot exceed 50 characters.',
  'Tag values can include lowercase letters, numbers, and special characters in (-_).',
  'Uppercase letters are translated to lowercase when typed/pasted.',
];

const DESCRIPTION_TOOLTIP = 'A brief summary / representation of the tag';
const MAX_TAG_LENGTH = 50;
const MAX_DESC_LENGTH = 200;

// A-Z is updated to lowercase
const VALID_TAG_FORMAT = /^[a-zA-Z0-9_-]*$/;

const StyledTitle = styled.p`
  font-weight: 1000;
  font-size: 18px;
`;

const StyledInput = styled.input`
  height: 32px;
  width: 100%;
  border: 1px solid;
  border-color: ${GREY.lightTertiary};
  border-radius: 4px;
  padding: 0 10px;
`;

const StyledSearchInput = styled(StyledInput)`
  width: 200px;
  padding-left: 30px;
`;

const StyledIcon = styled.i`
  color: ${BLUE.primary};
`;

type Props = {
  visible: boolean;
  setShowCreateTagModal: (status: boolean) => void;
};

type AssetCreateTagType = {
  name: string;
  description?: string;
};

const tagSchema: Yup.ObjectSchema<AssetCreateTagType> = Yup.object({
  name: Yup.string()
    .max(MAX_TAG_LENGTH, 'Tag values cannot exceed 50 characters.')
    .matches(
      VALID_TAG_FORMAT,
      'Tag values can include lowercase letters, numbers and special characters in ( - _ ).'
    )
    .required('Tag name is required'),
  description: Yup.string().max(
    MAX_DESC_LENGTH,
    'Tag description cannot exceed 200 characters.'
  ),
});

export const mapYupToFormikErrors = ({ inner }: Yup.ValidationError) =>
  inner.reduce(
    (memo, { path, message }) => ({
      ...memo,
      // @ts-ignore
      [path]: (memo[path] || []).concat(message),
    }),
    {}
  );

const createAssetTag = async (
  params: AssetCreateTagType & { customerId: string }
) => {
  const apiURL = `/rootsecure/v1/customers/${params.customerId}/tags`;
  const requestObj = {
    name: params.name,
    description: params.description,
  };
  const response = await riskAPI.post(apiURL, requestObj);
  return response;
};

const getErrorStatus = (error: string | undefined, touched: boolean) => {
  return touched ? (error?.length ? 'danger' : 'neutral') : undefined;
};

const getErrorMessage = (error: string | undefined) => {
  return error?.length
    ? (error as unknown as string[]).map((e) => (
        <div style={{ fontSize: 10 }} key={e}>
          {e}
          <br />
        </div>
      ))
    : null;
};

const TagNameInput = () => {
  const [{ onBlur, value }, { error, touched }, { setValue }] =
    useField('name');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.toLowerCase());
  };

  return (
    <Field.Root appearance={getErrorStatus(error, touched)}>
      <Field.Label>
        <HStack gap='small'>
          New Tag
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <FontAwesomeIcon color={BLUE.primary} icon={faQuestionCircle} />
            </Tooltip.Trigger>
            <Tooltip.Content side='top'>
              <VStack>
                <Tooltip.Text>{NEW_TAG_TOOLTIP_TEXT}</Tooltip.Text>
                <VStack gap='xsmall'>
                  {NEW_TAG_TOOLTIP_SUBTEXT.map((s) => (
                    <Tooltip.Text key={s}>{s}</Tooltip.Text>
                  ))}
                </VStack>
              </VStack>
            </Tooltip.Content>
          </Tooltip.Root>
        </HStack>
      </Field.Label>
      <Field.Content>
        <StyledInput
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          name='name'
          placeholder='Enter Tag Name'
        />
      </Field.Content>
      <Field.Message>{getErrorMessage(error)}</Field.Message>
    </Field.Root>
  );
};

const TagDescriptionInput = () => {
  const [{ onBlur, onChange, value }, { error, touched }] =
    useField('description');

  return (
    <Field.Root appearance={getErrorStatus(error, touched)}>
      <Field.Label>
        <HStack gap='small'>
          Description
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <FontAwesomeIcon color={BLUE.primary} icon={faQuestionCircle} />
            </Tooltip.Trigger>
            <Tooltip.Content side='top'>
              <Tooltip.Text>{DESCRIPTION_TOOLTIP}</Tooltip.Text>
            </Tooltip.Content>
          </Tooltip.Root>
        </HStack>
      </Field.Label>
      <Field.Content>
        <StyledInput
          style={{ width: '500px' }}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name='description'
          placeholder='Enter a Description'
        />
      </Field.Content>
      <Field.Message>{getErrorMessage(error)}</Field.Message>
    </Field.Root>
  );
};

const AssetCreateTag = ({ visible, setShowCreateTagModal }: Props) => {
  const queryClient = useQueryClient();
  const customerId = useSelector(customerSelectors.getCustomerId);
  const { data: allTagsData } = useQueryAllTags(customerId);
  const { sortedData, filterText, handleSort, handleFilter } = useFilterTags(
    allTagsData || []
  );

  const { mutate: createAssetTagMutate } = useMutation({
    mutationFn: createAssetTag,
    onError: () => {
      toastr.error('Failed to create tag', '');
    },
    onSuccess: () => {
      toastr.success('Tag created successfully', '');
      queryClient.invalidateQueries({
        queryKey: [`getAssetTags-${customerId}`],
      });
    },
  });

  const validateTags = (values: AssetCreateTagType) => {
    let matchingTagError = false;
    if (allTagsData) {
      for (let i = 0; i < allTagsData.length; ++i) {
        if (values.name === allTagsData[i].name) {
          matchingTagError = true;
          break;
        }
      }
    }
    const errors = tagSchema
      .validate(values, { abortEarly: false })
      .then(() => {})
      .catch(mapYupToFormikErrors)
      .then((result) => {
        return matchingTagError
          ? { ...result, name: ['Tag already exists'] }
          : result;
      });
    return errors;
  };

  return (
    <ModalBox
      title='Asset Tag Management'
      onCloseModal={() => setShowCreateTagModal(false)}
      isOpen={visible}
      width='1100px'
      position={ModalPositionType.top}
    >
      <Primitive.div paddingX='large' paddingBottom='large'>
        <StyledTitle>Create Tag</StyledTitle>
        <HStack yAlign='bottom' xAlign='left' marginBottom='small'>
          <Formik
            initialValues={{ name: '', description: '' }}
            onSubmit={(values) =>
              createAssetTagMutate({ ...values, customerId })
            }
            validate={validateTags}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
                <HStack width='full'>
                  <TagNameInput />
                  <TagDescriptionInput />
                  <Button
                    margin='zero'
                    type='submit'
                    variant='secondary'
                    isDisabled={
                      !props.dirty ||
                      !!props.errors.name ||
                      !!props.errors.description
                    }
                    iconLeft={<StyledIcon className='fa fa-pencil-square-o' />}
                  >
                    Create Tag
                  </Button>
                </HStack>
              </form>
            )}
          </Formik>
        </HStack>
        <StyledTitle>Tags</StyledTitle>
        <HStack
          yAlign='center'
          xAlign='left'
          position='relative'
          marginBottom='medium'
        >
          <StyledSearchInput
            value={filterText}
            onChange={handleFilter}
            placeholder='Search'
          />
          <i
            className='fa fa-search'
            style={{ position: 'absolute', left: '10px' }}
          />
        </HStack>
        <AssetTagTable data={sortedData} handleSort={handleSort} />
      </Primitive.div>
    </ModalBox>
  );
};

export default AssetCreateTag;
