import React, { useCallback, useState } from 'react';

import {
  Button,
  Checkbox,
  Heading,
  HStack,
  Primitive,
  Switch,
  Text,
  VStack,
} from '@rtkwlf/fenrir-react';
import { isEqual } from 'lodash';
import { DuplicateCriteria } from './AssetCatalog';
import { IsAWNUser } from '../../utils/auth/auth';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';

type AssetsInput = {
  criteria: Array<DuplicateCriteria>;
  onChangeCriteria: (value: string) => void;
  selectedDuplicateAssets: string[];
};

const DuplicateAssetsInput = ({
  criteria,
  onChangeCriteria,
  selectedDuplicateAssets,
}: AssetsInput) => (
  <VStack marginTop='small' gap='xsmall'>
    {criteria.map((option, index) => {
      const isRequired = !!option.isRequired;

      return (
        <Checkbox.Root
          key={`${option.name}-${index}`}
          onCheckedChange={() => onChangeCriteria(option.field)}
          isChecked={
            isRequired || selectedDuplicateAssets.includes(option.field)
          }
          isDisabled={isRequired}
        >
          <Checkbox.Text>
            {option.name}
            {isRequired ? ' (Required)' : ''}
          </Checkbox.Text>
        </Checkbox.Root>
      );
    })}
  </VStack>
);

type Props = {
  criteria: Array<DuplicateCriteria>;
  appliedCriteria?: Array<string>;
  setDuplicateCriteria: (criteria: Array<string>) => void;
  defaultOnlyShowDuplicate: boolean;
  toggleOnlyShowDuplicate: (showDuplicate: boolean) => void;
  defaultIsExcludeUnknown: boolean;
  toggleExcludeUnknown: (showUnknown: boolean) => void;
};

const DuplicateAssets = ({
  criteria,
  appliedCriteria,
  setDuplicateCriteria,
  defaultOnlyShowDuplicate,
  defaultIsExcludeUnknown,
  toggleOnlyShowDuplicate,
  toggleExcludeUnknown,
}: Props) => {
  const defaultCriteria = appliedCriteria ?? [];
  const [visible, setVisibility] = useState(false);
  const [selectedDuplicateAssets, setSelectedDuplicateAssets] =
    useState(defaultCriteria);
  const [showOnlyDuplicate, setshowOnlyDuplicate] = React.useState(
    defaultOnlyShowDuplicate
  );
  const [isExcludeUnknown, setExcludeUnknown] = React.useState(
    defaultIsExcludeUnknown
  );

  const onChangeCriteria = (value: string) => {
    const newCriteria = [...selectedDuplicateAssets];
    if (newCriteria.length && newCriteria.includes(value)) {
      newCriteria.splice(selectedDuplicateAssets.indexOf(value), 1);
    } else {
      newCriteria.push(value);
    }

    setSelectedDuplicateAssets(newCriteria);
  };

  const handleSaveCriteria = () => {
    setDuplicateCriteria(selectedDuplicateAssets);
    setVisibility(!visible);
    toggleOnlyShowDuplicate(showOnlyDuplicate);
    toggleExcludeUnknown(isExcludeUnknown);
  };

  const handleToggleDuplicate = React.useCallback(() => {
    setshowOnlyDuplicate(!showOnlyDuplicate);
  }, [setshowOnlyDuplicate, showOnlyDuplicate]);

  const isDisableSaveButton =
    isEqual(selectedDuplicateAssets.sort(), defaultCriteria.sort()) &&
    defaultOnlyShowDuplicate === showOnlyDuplicate &&
    defaultIsExcludeUnknown === isExcludeUnknown;

  const handleModalCancel = useCallback(
    () => setVisibility(!visible),
    [visible]
  );

  return (
    <>
      {IsAWNUser() && (
        <Button
          margin='zero'
          data-testid='duplicate-button'
          variant='tertiary'
          iconLeft={<i className={`fa fa-copy`} />}
          onClick={() => setVisibility(true)}
        >
          Duplicate Asset Criteria
        </Button>
      )}

      <ModalBox
        title={'Duplicate Asset Criteria'}
        onCloseModal={handleModalCancel}
        isOpen={visible}
        width='699px'
        position={ModalPositionType.top}
        footer={
          <HStack yAlign='center' xAlign='right' gap='xsmall'>
            <Button
              variant='primary'
              onClick={handleSaveCriteria}
              isDisabled={isDisableSaveButton}
            >
              Save Criteria
            </Button>
            <Button onClick={handleModalCancel} variant='secondary'>
              Cancel
            </Button>
          </HStack>
        }
      >
        <Primitive.div
          paddingX='large'
          paddingBottom='large'
          data-testid='duplicate-asset-container'
        >
          <Heading marginBottom='small' level='4'>
            Only Show Duplicate Assets
          </Heading>
          <HStack gap='small' yAlign='center'>
            <Switch.Root
              margin='zero'
              aria-label='Show only duplicate assets'
              onCheckedChange={handleToggleDuplicate}
              isChecked={showOnlyDuplicate}
            >
              <Switch.Thumb />
            </Switch.Root>
            <Text styledAs='body4'>
              {showOnlyDuplicate ? 'Enabled' : 'Disabled'}
            </Text>
          </HStack>

          <Heading marginTop='medium' marginBottom='small' level='4'>
            Criteria
          </Heading>
          <Text styledAs='body1' display='block' maxWidth='40rem'>
            Select the fields that the system will use to detect a potential
            duplicate asset.
            <br />
            Devices with identical values for the selected fields are flagged as
            potential duplicate assets.
          </Text>

          <DuplicateAssetsInput
            criteria={criteria}
            onChangeCriteria={onChangeCriteria}
            selectedDuplicateAssets={selectedDuplicateAssets}
          />
          <Heading marginTop='medium' marginBottom='small' level='4'>
            Additional criteria
          </Heading>

          <Checkbox.Root
            key='excludeUnknown'
            isChecked={isExcludeUnknown}
            onCheckedChange={() => setExcludeUnknown(!isExcludeUnknown)}
          >
            <Checkbox.Text>Exclude Unknown Values</Checkbox.Text>
          </Checkbox.Root>
        </Primitive.div>
      </ModalBox>
    </>
  );
};

export default DuplicateAssets;
