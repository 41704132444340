import React from 'react';
import { Group } from '../../types/attackSurface';
import { Accordion } from './Accordion';
import AscScheduleAgentTable from './AscAgent/ScheduleAgentTable';
import { useAttackSurfaceContext } from './Context/AscContext';
import { getScoutTargetGroups } from './utils';

const ScheduleNestedRenderer: React.FC = () => {
  const { scouts } = useAttackSurfaceContext();
  const scheduleArray: Group[] = getScoutTargetGroups(scouts).sort(
    (a: Group, b: Group) => (a.clientCount < b.clientCount ? 1 : -1)
  );
  const ScheduleItems = scheduleArray.map((schedule) => {
    return {
      title: `${schedule.name}
      ${
        schedule.vulnerabilityScan
          ? schedule.vulnerabilityScan && schedule.benchmarkScan
            ? ' - Vulnerability & Benchmark'
            : ' - Vulnerability'
          : ' - Benchmark'
      }
      (${schedule.clientCount})`,
      node: (
        <div data-testid={`agent-schedule-container-${schedule.name}`}>
          <AscScheduleAgentTable id={schedule.id} />
        </div>
      ),
    };
  });
  return <Accordion childrenNodes={ScheduleItems} />;
};

export { ScheduleNestedRenderer };
