import React from 'react';
import { HardwareFieldsType } from './AssetDetails';
import { HStack, Primitive, Text, Tooltip, VStack } from '@rtkwlf/fenrir-react';
import InfoRowWithCopy from './InfoRowWithCopy';

type StateProps = {
  dataRows: HardwareFieldsType[] | undefined;
  isExpanded: boolean;
};

export type Props = StateProps;

const HardwareInfoRow = ({ dataRows, isExpanded }: Props) => {
  if (!dataRows || !isExpanded) {
    return null;
  }

  return (
    <Primitive.div marginTop='medium' data-testid='hardware-card-info'>
      <VStack gap='smedium'>
        {dataRows.map((field, index) => {
          if (field.label === 'Hotfixes') {
            return (
              <InfoRowWithCopy field={field} key={`${field.label}-${index}`} />
            );
          }
          return (
            <HStack
              xAlign='between'
              width='full'
              key={`${field.label}-${index}`}
            >
              <Primitive.div maxWidth='50%'>
                <Text userSelect='all' styledAs='body2'>
                  {field.label}
                </Text>
              </Primitive.div>
              <Primitive.div maxWidth='50%'>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <Text textColor='infoDefault' truncate>
                      {field.value}
                    </Text>
                  </Tooltip.Trigger>
                  <Tooltip.Content side='top'>
                    <Tooltip.Text>{field.value}</Tooltip.Text>
                  </Tooltip.Content>
                </Tooltip.Root>
              </Primitive.div>
            </HStack>
          );
        })}
      </VStack>
    </Primitive.div>
  );
};

export default HardwareInfoRow;
