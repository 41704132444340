// ----- Action Constants -----

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

// ----- Action Creators -----

export const closeModal = () => ({ type: CLOSE_MODAL });

export const openModal = (modal: string, payload?: any) => ({
  type: OPEN_MODAL,
  payload: {
    modal,
    payload,
  },
});

// ----- Thunk Action Creators -----
