import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ColumnProps, SortState } from '../../Reusables/table/Table';
import AssetTable from './AssetTable';

import { assetsUpdatedFieldsType, sortData } from './utils';
import { setRefreshAssetsThunk } from '../../Global/assetActions';
import { useAppDispatch } from '../../hooks';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { Button, HStack, Primitive } from '@rtkwlf/fenrir-react';
import { useModalAssetTableColumns } from './use-modal-asset-table-columns';

type Props = {
  data: assetsUpdatedFieldsType[];
  skippedData: assetsUpdatedFieldsType[];
  setUpdatedData: (data: assetsUpdatedFieldsType[]) => void;
  setSkippedData: (data: assetsUpdatedFieldsType[]) => void;
};

const AssetUpdatedDataModal = ({
  data,
  skippedData,
  setUpdatedData,
  setSkippedData,
}: Props) => {
  const [tableData, setTableData] = useState<assetsUpdatedFieldsType[]>([]);
  const [visible, setVisibility] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updatedData = [...data];

    if (updatedData.length) {
      dispatch(setRefreshAssetsThunk());
      setTableData(updatedData);
    } else {
      setTableData(skippedData);
    }

    if (updatedData.length > 0 || skippedData.length > 0) {
      setVisibility(true);
    }
  }, [dispatch, skippedData, data]);

  const closeModal = () => {
    setVisibility(false);
    setUpdatedData([]);
    setSkippedData([]);
  };

  const handleSort = useCallback(
    (column: string, state: SortState) => {
      const newData = sortData(
        state === 'dsc',
        column as keyof assetsUpdatedFieldsType,
        [...tableData]
      );

      setTableData([...newData]);
    },
    [tableData]
  );

  return (
    <ModalBox
      title={'Asset User Configuration Update Successful'}
      onCloseModal={closeModal}
      isOpen={visible}
      position={ModalPositionType.top}
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button onClick={closeModal} variant='primary'>
            Okay
          </Button>
          <Button onClick={closeModal} variant='secondary'>
            Close
          </Button>
        </HStack>
      }
    >
      <Primitive.div
        paddingX='large'
        paddingBottom='small'
        data-testid='update-status-table'
      >
        <AssetTable
          data={tableData}
          columns={useModalAssetTableColumns()}
          handleSort={handleSort}
        />
      </Primitive.div>
    </ModalBox>
  );
};

export default AssetUpdatedDataModal;
