import * as React from 'react';

import styled, { css } from 'styled-components';

import { ColumnProps, SortState } from './Table';

import { Cell } from './Cell';
import { Text } from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortAsc,
  faSortDesc,
} from '@fortawesome/free-solid-svg-icons';
import { GlobalTheme } from '../../types/legacyTheme';

interface HeaderCellProps<T> {
  column: ColumnProps<T>;
  header?: boolean;
  onClick: (key: string | number, state: SortState) => void;
  sortedColumn?: string | number;
  theme: GlobalTheme;
}

interface StyledCellProps {
  $theme: GlobalTheme;
  $sortable?: boolean;
}

interface StyledSubtitleContentProps {
  $sortable?: boolean;
}

const getSortState = (currentState: string): SortState => {
  switch (currentState) {
    case 'none':
      return 'asc';
    case 'asc':
      return 'dsc';
    default:
      return 'none';
  }
};

const StyledCell = styled(Cell)<StyledCellProps>`
  ${({ $theme, $sortable }) => css`
    transition: background ${$theme.animationTimeFast}s ease-in-out;

    ${$sortable &&
    css`
      &:hover {
        cursor: pointer;
        background: ${$theme.colors.hoverBackground};
      }
    `}
  `}
`;

const StyledSubtitle = styled(Text).attrs({ styledAs: 'body2' })<{
  $theme: GlobalTheme;
}>`
  display: flex;
  align-items: center;
  color: ${({ $theme }) => $theme.tableHeadFontColor};
  font-size: ${({ $theme }) => $theme.tableHeadFontSize};
`;

const SubtitleContent = styled.span<StyledSubtitleContentProps>`
  ${({ $sortable }) => css`
    margin-right: ${$sortable ? '8px' : 0};
  `}
`;

const SortIcon = (
  <Text textColor='placeholder'>
    <FontAwesomeIcon icon={faSort} />
  </Text>
);
const SortAscIcon = (
  <Text textColor='brandDefault'>
    <FontAwesomeIcon icon={faSortAsc} />
  </Text>
);
const SortDescIcon = (
  <Text textColor='brandDefault'>
    <FontAwesomeIcon icon={faSortDesc} />
  </Text>
);

export const HeaderCell = <T extends any = any>(props: HeaderCellProps<T>) => {
  const { column, header, onClick, sortedColumn, theme } = props;

  const [sortState, setSortState] = React.useState<SortState>('none');

  const handleClick = React.useCallback(() => {
    if (column.sortable) {
      const newSortState = getSortState(sortState);
      onClick(column.key, newSortState);
      setSortState(newSortState);
    }
  }, [onClick, column, sortState]);

  // reset the sort state to remove the styles when the sorted column changes
  React.useEffect(() => {
    if (sortedColumn !== column.key) {
      setSortState('none');
    }
  }, [column.key, sortedColumn]);

  return (
    <StyledCell
      $sortable={column.sortable}
      onClick={handleClick}
      justify={column.justify}
      header={header}
      $theme={theme}
    >
      <StyledSubtitle $theme={theme}>
        <SubtitleContent $sortable={column.sortable}>
          {column.title}
        </SubtitleContent>
        {column.sortable && sortState === 'none' && SortIcon}
        {column.sortable && sortState === 'asc' && SortAscIcon}
        {column.sortable && sortState === 'dsc' && SortDescIcon}
      </StyledSubtitle>
    </StyledCell>
  );
};
