import { AssetActionTypes } from './assetReducer';

const initialState: any = {
  heatmap: [],
};

export const visualizationReducer = (state = initialState, action: any) => {
  const { payload, type } = action;
  const newState = { ...state };
  switch (type) {
    case AssetActionTypes.LOAD_ASSET_DATA:
      newState.heatmap = payload.meta?.severities?.map(
        (category: { [key: string]: string | number }) => ({
          name: category.severity,
          count: category.count,
          maxScore: category.maxScore,
        })
      );
      return newState;
    default:
      return state;
  }
};
