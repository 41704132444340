import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useAdsApi } from '../useAdsApi';
import { customerSelectors } from '../../../Global/customerReducer';
import { GetDeviceRequest, QueryRequest } from '@rtkwlf/asset-data-service';
import {
  ffAssetUiBeta,
  isFeatureEnabled,
} from '../../../../public/js/features';

const retry = false;

export const useAdsDevices = () => {
  const api = useAdsApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  const queryRequest: QueryRequest = { offset: 0, limit: 10 };

  return useQuery({
    queryKey: [organizationId, 'query-devices-ads'],
    queryFn: async () =>
      api.queryDevices({ organizationId, queryRequest: queryRequest }),
    retry,
    enabled: isFeatureEnabled(ffAssetUiBeta),
  });
};

export const useAdsDevice = (deviceId: string, enabled: boolean) => {
  const api = useAdsApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  const deviceRequest: GetDeviceRequest = { organizationId, deviceId };

  return useQuery({
    queryKey: [organizationId, 'query-device-ads', deviceId],
    queryFn: async () => api.getDevice(deviceRequest),
    retry,
    enabled,
  });
};
