import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { HStack, IconButton, Primitive, Text } from '@rtkwlf/fenrir-react';
import { ArtifactObject, isStrArray } from './treeviewFunctions';
import ExpandedNestedObject from './ExpandedNestedObject';
import ExpandedNestedString from './ExpandedNestedString';

interface ExpandableObjectProps {
  property: string;
  artifact: string[] | ArtifactObject | ArtifactObject[];
  artifactIndex: number;
}

const ExpandableObject: React.FC<ExpandableObjectProps> = ({
  property,
  artifact,
  artifactIndex,
}) => {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Primitive.div key={`${artifactIndex}artifactsIndex`}>
      <HStack>
        <IconButton
          onClick={() => setOpen(!open)}
          paddingTop='xsmall'
          aria-label='settings'
          data-testid='expandable-property-toggle'
        >
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </IconButton>
        <Text
          textColor='brandTertiary'
          textTransform='capitalize'
          styledAs='body4'
        >
          {`${property}:`}
        </Text>
      </HStack>
      <React.Fragment key={`expandedParent${artifactIndex}`}>
        {open &&
          (isStrArray(artifact) ? (
            <ExpandedNestedString
              strArray={artifact as string[]} // better than using 'any', we are sure this is a string array but TS does not believe us
              index={artifactIndex}
            />
          ) : (
            (Array.isArray(artifact) ? artifact : [artifact]).map(
              (obj, dynamicChildIndex) => {
                return (
                  <React.Fragment
                    key={`expandedPropNestedJsonIndex${dynamicChildIndex}`}
                  >
                    <ExpandedNestedObject
                      object={obj as ArtifactObject}
                      index={dynamicChildIndex}
                    />
                  </React.Fragment>
                );
              }
            )
          ))}
      </React.Fragment>
    </Primitive.div>
  );
};

export default ExpandableObject;
