import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import { RowTileV2 } from '../../Reusables/RowTileV2';
import { AscAgentContainer } from './AscAgent/AgentTableContainer';
import { AscEvaContainer } from './AscEva/EvaTableContainer';
import { AscIvaContainer } from './AscIva/IvaTableContainer';
import { AssetCountContainer } from './AssetCount/AssetCountContainer';
import { Primitive } from '@rtkwlf/fenrir-react';

const ContainerDiv = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  margin-top: 20px;
`;

const FragmentContainer = styled(Primitive.div)`
  margin-right: -20px;
`;

export type Props = {
  description: string;
  title?: string;
  children?: ReactNode | undefined;
};

export const AscContainer = ({ description, title }: Props) => {
  return (
    <ContainerDiv>
      <div>
        <FragmentContainer>
          <Fragment>
            <RowTileV2
              data-testid='table-row-header'
              id='Asc'
              title={title || 'Attack Surface Coverage'}
              description={description}
              buttons={<div></div>}
            >
              <AssetCountContainer
                description={
                  'The sum of assets by source, including potential duplicates.'
                }
              />
              <AscIvaContainer description={'IVA Assets.'} />
              <AscAgentContainer
                description={
                  'Information regarding the coverage of the customers agent scans.'
                }
              />
              <AscEvaContainer />
            </RowTileV2>
          </Fragment>
        </FragmentContainer>
      </div>
    </ContainerDiv>
  );
};
