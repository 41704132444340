import * as React from 'react';

import styled, { css } from 'styled-components';

import { Primitive } from '@rtkwlf/fenrir-react';
import { Cell } from './Cell';

import { ColumnProps, NestedRow, OnRowProps } from './Table';
import { GlobalTheme, useTheme } from '../../types/legacyTheme';

interface BodyProps<T> {
  columns: ColumnProps<T>[];
  nestedRow?: NestedRow<T>;
  data: T[];
  dataUniqueKey: string | number;
  emptyComponent?: React.ReactNode;
  onRow?: OnRowProps<T>;
  selectedRowKey?: string | number;
}

const TD = styled.td<{
  theme: GlobalTheme;
}>`
  ${({ theme }) => css`
    border-bottom: ${theme.tableBodyRowBorder};
    border-color: ${theme.tableBodyRowBorderColor};
  `}
`;

const TR = styled.tr<{
  $theme: GlobalTheme;
  $selected: boolean;
  $nestedVisible: boolean;
}>`
  ${({ $theme, $selected, $nestedVisible }) => css`
    cursor: default;

    &:hover {
      ${!$selected &&
      !$nestedVisible &&
      css`
        background: ${$theme.colors.hoverBackground};
      `}

      // Fenrir-TODO: colors.accent.grey.secondary
      ${$nestedVisible && `background: #EBEBEB;`}
    }

    // Fenrir-TODO: colors.background.widget.highlight
    ${$nestedVisible && `background: #F5F5F5;`}

    ${$selected &&
    !$nestedVisible &&
    css`
      background: ${$theme.colors.quaternaryBackground};
    `};

    transition: background ${$theme.animationTimeVeryFast}s ease-in-out;
  `}
`;

const BodyText = styled(Primitive.div).attrs({ fontSize: 'default' })<{
  theme: GlobalTheme;
  selected?: boolean;
}>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.tableBodyFontColor};
  font-size: ${({ theme }) => theme.tableBodyFontSize};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
`;

const EmptyContentContainer = styled.div`
  ${({ theme }) => css`
    height: ${theme.tableEmptyContainerHeight};

    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Body = <T extends {}>(props: BodyProps<T>) => {
  const {
    columns,
    nestedRow,
    data,
    dataUniqueKey,
    emptyComponent,
    onRow,
    selectedRowKey,
  } = props;

  const theme = useTheme();

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, d: T) => {
      if (onRow && onRow.onClick) {
        onRow.onClick(e, d);
      }
    },
    [onRow]
  );

  const renderDataIndex = React.useCallback(
    (column: ColumnProps<T>, data: any) => {
      if (column.dataIndex == null) {
        // eslint-disable-next-line no-console
        console.warn(
          `You must supply a dataIndex or render function for column: ${column.key}`
        );
        return null;
      } else {
        return data[column.dataIndex];
      }
    },
    []
  );

  const renderBodyCell = React.useCallback(() => {
    if (data.length === 0) {
      return (
        <tr>
          <TD colSpan={columns.length}>
            <EmptyContentContainer theme={theme}>
              <BodyText theme={theme}>
                {emptyComponent == null ? 'No Data' : emptyComponent}
              </BodyText>
            </EmptyContentContainer>
          </TD>
        </tr>
      );
    }

    return data.map((d, index) => {
      const NestedRowRenderer = nestedRow?.render;
      const nestedVisible = !!nestedRow?.visible(d);

      return (
        <React.Fragment key={index}>
          <TR
            $nestedVisible={nestedVisible}
            $theme={theme}
            $selected={
              // @ts-ignore
              selectedRowKey ? selectedRowKey === d[dataUniqueKey] : false
            }
            onClick={(e) => handleClick(e, d)}
          >
            {columns.map((c) => {
              const Renderer = c.render;
              return (
                <TD key={c.key} theme={theme}>
                  <Cell justify={c.justify}>
                    <BodyText
                      selected={
                        selectedRowKey
                          ? // @ts-ignore
                            selectedRowKey === d[dataUniqueKey]
                          : false
                      }
                      theme={theme}
                    >
                      {Renderer == null ? (
                        renderDataIndex(c, d)
                      ) : (
                        <Renderer record={d} />
                      )}
                    </BodyText>
                  </Cell>
                </TD>
              );
            })}
          </TR>

          {!!NestedRowRenderer && nestedVisible && (
            <TR
              $nestedVisible
              $theme={theme}
              $selected={
                // @ts-ignore
                selectedRowKey ? selectedRowKey === d[dataUniqueKey] : false
              }
            >
              <TD theme={theme} colSpan={columns.length}>
                <NestedRowRenderer record={d} />
              </TD>
            </TR>
          )}
        </React.Fragment>
      );
    });
  }, [
    data,
    dataUniqueKey,
    columns,
    nestedRow,
    emptyComponent,
    renderDataIndex,
    theme,
    selectedRowKey,
    handleClick,
  ]);

  return <tbody>{renderBodyCell()}</tbody>;
};

Body.displayName = 'TableBody';
