import React from 'react';
import {
  CRITICALITY_MAP,
  FENRIR_COLOR_MAP,
} from '../../../../Constants/Common';
import { Badge } from '@rtkwlf/fenrir-react';

export type CriticalityProps = {
  criticality?: number;
};

/**
 * Renders a pill. Can be used by Asset & Risk tables.
 */
const CriticalityPill: React.FC<CriticalityProps> = (props) => {
  const criticality =
    typeof props.criticality != 'number' ? 0 : props.criticality;

  const bgColorCode = CRITICALITY_MAP[criticality].color;
  const namedBgColor = FENRIR_COLOR_MAP[bgColorCode].namedColor;
  const iconClass = CRITICALITY_MAP[criticality].icon;
  const pillText = CRITICALITY_MAP[criticality].value.toUpperCase();

  return (
    <Badge.Root backgroundColor={namedBgColor}>
      <Badge.Icon>
        <i className={`fa ${iconClass}`} />
      </Badge.Icon>
      <Badge.Text>{pillText}</Badge.Text>
    </Badge.Root>
  );
};

export { CriticalityPill };
