/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, Text, VStack } from '@rtkwlf/fenrir-react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const MissingData: React.FC = () => {
  return (
    <VStack xAlign='center' padding='small'>
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={`rgb(${colors.accent.grey.default})`}
        style={{
          height: '50px',
          width: '55px',
        }}
      />
      <Text textAlign='center' style={{ whiteSpace: 'pre-line' }}>
        There is no data to display.
      </Text>
    </VStack>
  );
};
