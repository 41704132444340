import styled from 'styled-components';
import { ColumnProps, Table } from '../../../Reusables/table/Table';
import { AscSchedule, getScoutTargetGroupClients } from '../utils';
import { Agent } from './AgentNestedRenderer';
import { useAttackSurfaceContext } from '../Context/AscContext';
import { ScoutData } from '../../../types/attackSurface';

const StyledContainer = styled.div`
  display: block;
  overflow-x: auto;
  padding-bottom: 30px;
  margin-bottom: -30px;
  margin-left: 32px;

  table thead th {
    white-space: nowrap;
  }

  td {
    vertical-align: top;
  }
`;

export interface Props {
  id: string;
}

export const getAscAgentSchedules = (
  scheduleId: string,
  scouts: ScoutData
): AscSchedule[] => {
  const scheduleContent: Agent[] | {}[] = getScoutTargetGroupClients(
    scouts,
    scheduleId
  );

  return [
    {
      scheduleContent: scheduleContent,
      scheduleCount: scheduleContent.length,
    },
  ];
};

const AscScheduleAgentTable = ({ id }: Props) => {
  const { scouts } = useAttackSurfaceContext();
  const columns: ColumnProps<AscSchedule>[] = [
    {
      key: 'scheduleCount',
      dataIndex: 'scheduleCount',
      title: 'Schedule Agent Count',
      justify: 'flex-start',
      width: 27,
    },
    {
      key: 'scheduleContent',
      dataIndex: 'scheduleContent',
      title: 'Schedule Agents',
      justify: 'flex-start',
      render: ({ record: { scheduleContent } }) => (
        <>
          {scheduleContent.map((schedule, i) => (
            <div key={i}>
              {Object.keys(schedule).length > 0 && 'deviceName' in schedule
                ? schedule?.deviceName
                : ''}
            </div>
          ))}
        </>
      ),
    },
  ];

  return (
    <StyledContainer data-testid='Asc-scheduled-agent-table'>
      <Table
        data={getAscAgentSchedules(id, scouts)}
        columns={columns}
        dataUniqueKey='id'
        emptyComponent={<div>No Data Available</div>}
      />
    </StyledContainer>
  );
};

export default AscScheduleAgentTable;
