import React from 'react';
import { TagType } from '../Global/assetTags';
import { sortByReservedTags } from '../Pages/Assets/utils';
import { Tag, Tooltip, TagProps, HStack, VStack } from '@rtkwlf/fenrir-react';

type TagState = 'reserved' | 'custom';

type HandleTagOverflowProps = (
  tags: TagType[],
  tooltipPosition: 'top' | 'left'
) => React.ReactNode[];

type TagsProps = {
  tags?: Array<TagType>;
  tooltipPosition?: 'top' | 'left';
  data?: { tagIDs: number[] };
  justify?: 'left' | 'right';
};

interface TagMapItem {
  appearance: TagProps['appearance'];
  icon: React.ReactNode;
}

const tagMap: { [key in TagState]: TagMapItem } = {
  reserved: {
    appearance: 'neutral',
    icon: <i className='fa fa-lock'></i>,
  },
  custom: {
    appearance: 'default',
    icon: null,
  },
};

/**
 * The maximum total characters of tag names. Anything beyond the limit will be
 *  collapsed into a text of "X more..." with an on-hover tooltip containing *everything*
 */
const MAX_CHAR = 30;

export const AssetTagPill: React.FunctionComponent<{
  record: TagType;
  tooltipPosition?: 'top' | 'left';
}> = ({
  record: { name, description, reserved },
  tooltipPosition = 'left',
}) => {
  const state = reserved ? 'reserved' : 'custom';
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Tag
          appearance={tagMap[state].appearance}
          iconLeft={tagMap[state].icon}
        >
          {name}
        </Tag>
      </Tooltip.Trigger>
      <Tooltip.Content maxWidth='20rem' side={tooltipPosition}>
        <Tooltip.Text>{description}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

export const handleTagOverflow: HandleTagOverflowProps = (
  tags,
  tooltipPosition
) => {
  const visibleTags: React.ReactNode[] = [];
  let charTotal = 0;

  for (const tag of tags) {
    const tagIsTooBig = tag.name.length > MAX_CHAR;
    const tagWillNotFit = charTotal + tag.name.length > MAX_CHAR;

    if (tagIsTooBig || tagWillNotFit) {
      continue;
    }

    charTotal += tag.name.length;

    visibleTags.push(
      <AssetTagPill
        record={tag}
        key={tag.tagID}
        tooltipPosition={tooltipPosition}
      />
    );
  }

  return visibleTags;
};

const TagPills = ({
  tags,
  tooltipPosition = 'left',
  justify = 'left',
}: TagsProps) => {
  if (!tags || (tags && tags.length === 0)) {
    return null;
  }

  // tags are sorted on reserved key
  const sortedTags = sortByReservedTags(tags);
  const visibleTags = handleTagOverflow(sortedTags, tooltipPosition);
  const remainingSize = tags.length - visibleTags.length;

  return (
    <HStack
      wrap='wrap'
      gap='xsmall'
      data-testid='tags-renderer'
      xAlign={justify}
    >
      {visibleTags}
      {remainingSize > 0 && (
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <Tag appearance='neutral'>{`${remainingSize} more...`}</Tag>
          </Tooltip.Trigger>
          <Tooltip.Content
            side={tooltipPosition}
            data-testid='tags-overflow-tooltip'
          >
            <VStack>
              {tags.map(({ name }) => (
                <Tooltip.Text key={name}>{`• ${name}`}</Tooltip.Text>
              ))}
            </VStack>
          </Tooltip.Content>
        </Tooltip.Root>
      )}
    </HStack>
  );
};

export default TagPills;
