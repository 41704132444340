import React from 'react';

import { Button, HStack, Primitive } from '@rtkwlf/fenrir-react';
import { ModalBox } from './ModalBox';

type ConfirmationBoxPropsType = {
  title: string;
  message: React.ReactElement;
  yesClick: () => void;
  noClick: () => void;
  yesButtonText: string;
  noButtonText: string;
};

const ConfirmationBox = (props: ConfirmationBoxPropsType) => {
  const { title, message, yesButtonText, noButtonText, yesClick, noClick } =
    props;

  return (
    <ModalBox
      isOpen
      onCloseModal={noClick}
      title={title}
      width={'520px'}
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button
            variant='secondary'
            data-testid='confirmation-box-no-button'
            onClick={noClick}
          >
            {noButtonText}
          </Button>
          <Button data-testid='confirmation-box-yes-button' onClick={yesClick}>
            {yesButtonText}
          </Button>
        </HStack>
      }
    >
      <Primitive.div paddingX='large' paddingBottom='small'>
        {message}
      </Primitive.div>
    </ModalBox>
  );
};

export default ConfirmationBox;
