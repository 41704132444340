import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  TagType,
  updateAssetTagDescription,
  AssetUpdateTagDescriptionType,
} from '../../Global/assetTags';
import { sortData } from './utils';
import { customerSelectors } from '../../Global/customerReducer';

import { ColumnProps, Table, SortState } from '../../Reusables/table/Table';

import { Button, Checkbox, HStack, Text } from '@rtkwlf/fenrir-react';

import { BLUE, RED, GREY } from '../../Constants/Styles';

import './AssetTagTable.css';
import { AssetTagPill } from '../../Reusables/TagPills';

type AssetTagTableProps = {
  data: TagType[] | undefined;
  columns?: ColumnProps<TagType>[];
  handleSort?: (key: string, state: SortState) => void;
};

const StyledInput = styled.input`
  height: 32px;
  width: 400px;
  border: 1px solid;
  border-color: ${GREY.lightTertiary};
  border-radius: 4px;
  padding: 6px 0px 5px 8px;
`;

const StyledIcon = styled.i<{ isDelete?: boolean }>`
  width: 32px;
  height: 32px;
  border: 1px solid ${(props) => (props?.isDelete ? RED.primary : BLUE.primary)};
  color: ${(props) => (props?.isDelete ? RED.primary : BLUE.primary)};
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

type AssetTagDescriptionRowProps = {
  tagID: number;
  description: string;
  isEditing?: boolean;
};

export type TagCheckedState = {
  checked: number[];
  indeterminates: number[];
};

const DEFAULT_COLUMNS: ColumnProps<TagType>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Tag',
    width: 4,
    sortable: true,
    render: ({ record }) => (
      <HStack>
        <AssetTagPill record={record} />
      </HStack>
    ),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: 'Description',
    width: 7,
    render: ({ record: { description, reserved, tagID } }) => (
      <div style={{ width: '620px' }} key={`asset-tag-${tagID}`}>
        {reserved ? (
          <HStack>
            <Text>{description}</Text>
          </HStack>
        ) : (
          <AssetTagDescriptionRow description={description} tagID={tagID} />
        )}
      </div>
    ),
    sortable: true,
  },
];

const AssetTagDescriptionRow = ({
  description,
  tagID,
}: AssetTagDescriptionRowProps) => {
  const queryClient = useQueryClient();
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [isEditing, setEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(description);

  const { mutate: updateAssetTagDescriptionMutate, isPending } = useMutation({
    mutationFn: updateAssetTagDescription,
    onError: async () => {
      toastr.error('Failed to update tag description', '');
    },
    onSuccess: async () => {
      toastr.success('Successfully updated tag description', '');
      await queryClient.invalidateQueries({
        queryKey: [`getAssetTags-${customerId}`],
      });
      setEditing(false);
    },
  });

  const handleSave = () => {
    const params: AssetUpdateTagDescriptionType = {
      customerId,
      tagID,
      body: {
        description: newDescription,
      },
    };
    updateAssetTagDescriptionMutate(params);
  };

  const handleCancel = () => {
    setNewDescription(description);
    setEditing(false);
  };

  return !isEditing ? (
    <HStack xAlign='between'>
      <Text>{description}</Text>
      <StyledIcon onClick={() => setEditing(true)} className='fa fa-pencil' />
    </HStack>
  ) : (
    <HStack xAlign='between'>
      <StyledInput
        value={newDescription}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewDescription(e.target.value)
        }
      />
      <Button
        margin='zero'
        variant='primary'
        onClick={handleSave}
        isDisabled={newDescription === description}
        isLoading={isPending}
      >
        Save Tag
      </Button>
      <Button margin='zero' variant='secondary' onClick={handleCancel}>
        Cancel
      </Button>
    </HStack>
  );
};

export const useTagSelection = (
  allTagsData: TagType[] | undefined,
  initialCheckedState: TagCheckedState
) => {
  const [checkedTags, setCheckedTags] = useState<number[]>([]);
  const [allTagsChecked, setAllTagsChecked] = useState(false);
  const [indeterminates, setIndeterminates] = useState<number[]>([]);

  useEffect(() => {
    setIndeterminates(initialCheckedState.indeterminates);
    setCheckedTags(initialCheckedState.checked);
  }, [initialCheckedState]);

  const checkTags = (checked: boolean, tagID: number) => {
    let updatedCheckedTags = [...checkedTags];
    if (checked) {
      updatedCheckedTags.push(tagID);
    } else {
      setAllTagsChecked(false);
      updatedCheckedTags = updatedCheckedTags.filter((id) => id !== tagID);
    }
    setCheckedTags(updatedCheckedTags);
    if (allTagsData && updatedCheckedTags.length === allTagsData.length) {
      setAllTagsChecked(true);
    }
  };

  const selectAllTags = () => {
    const checked = !allTagsChecked;
    if (checked) {
      const ids = allTagsData?.map((tag) => tag.tagID);
      if (ids) {
        setCheckedTags(ids);
      }
      setIndeterminates([]);
    } else {
      setCheckedTags([]);
    }

    setAllTagsChecked(checked);
  };

  const columns: ColumnProps<TagType>[] = [
    {
      key: 'all-tags',
      title: (
        <Checkbox.Root
          name={'check-all-tags'}
          isChecked={allTagsChecked ?? false}
          onCheckedChange={selectAllTags}
        />
      ),
      width: 1,
      justify: 'center',
      render: ({ record: { tagID } }: { record: TagType }) => (
        <Checkbox.Root
          key={'tag' + tagID}
          name={'tag' + tagID}
          isChecked={checkedTags.includes(tagID)}
          isIndeterminate={indeterminates.includes(tagID)}
          onCheckedChange={(e) => {
            checkTags(e, tagID);
            setIndeterminates(indeterminates.filter((id) => tagID !== id));
          }}
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Tag',
      width: 6,
      sortable: true,
      render: ({ record }) => (
        <HStack>
          <AssetTagPill record={record} />
        </HStack>
      ),
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      width: 10,
      render: ({ record: { description } }) => <div>{description}</div>,
      sortable: true,
    },
  ];
  return { columns, checkedTags, indeterminates };
};

export const useFilterTags = (data: TagType[]) => {
  const [sortCol, setSortCol] = useState('');
  const [sortState, setSortState] = useState<SortState>('none');
  const [filterText, setFilterText] = useState('');
  const handleSort = (column: string, state: SortState) => {
    setSortCol(column);
    setSortState(state);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const sortedData = useMemo(() => {
    let output = data || [];

    if (filterText) {
      output = output.filter(
        (tag) =>
          tag.description.includes(filterText) || tag.name.includes(filterText)
      );
    }

    if (sortState !== 'none') {
      output = sortData(sortState === 'dsc', sortCol as keyof TagType, [
        ...output,
      ]);
    }

    return output;
  }, [sortCol, sortState, data, filterText]);

  return { sortedData, filterText, handleSort, handleFilter };
};

const AssetTagTable = ({
  data: allTagsData,
  columns,
  handleSort,
}: AssetTagTableProps) => {
  return (
    <Table
      className='asset-tag-table'
      data={allTagsData || []}
      columns={columns ?? DEFAULT_COLUMNS}
      dataUniqueKey={'tagID'} // How to get individual customerId here
      emptyComponent={<div>No Data Available</div>}
      onSort={handleSort}
    />
  );
};

export default AssetTagTable;
