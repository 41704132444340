import styled from 'styled-components';
import { GREEN } from '../Constants/Styles';

const StyledEdit = styled.div`
  border: solid ${GREEN.primary} 1px;
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
  color: ${GREEN.primary};

  &:hover {
    cursor: pointer;
  }
`;

export const EditButton = ({ onClick }: any) => {
  return (
    <StyledEdit onClick={onClick}>
      <i className='fa fa-pencil' />
    </StyledEdit>
  );
};
