import Axios, { InternalAxiosRequestConfig } from 'axios';
import { store } from '../store';
import Qs from 'qs';
import { getAuthToken } from './auth/authentication';

/**
 * Apply the Authorization token and API base URL before sending the request
 */
export const reqInterceptor = async (config: InternalAxiosRequestConfig) => {
  const state = store.getState();
  const access_token = await getAuthToken();
  config.headers.Authorization = `Bearer ${access_token}`;
  config.baseURL = state.config.environment.servicesHost;

  return config;
};

const riskAPI = Axios.create({
  paramsSerializer: {
    serialize: (params) => {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    },
  },
});
// Add a request interceptor
riskAPI.interceptors.request.use(reqInterceptor);

export default riskAPI;
