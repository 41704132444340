import _ from 'lodash';
import React, { FC, PropsWithChildren } from 'react';
import { IPCheck } from '../../../Reusables/IPChecker';
import styled from 'styled-components';
import {
  AscAsset,
  Client,
  GroupParent,
  Host,
  IvaData,
  ReachData,
  ReachGroup,
  Scanner,
  ScannerSchedules,
  ScannerTarget,
  ScoutData,
} from '../../../types/attackSurface';
import {
  useQueriesScannerSchedules,
  useQueryReachGroups,
  useQueryScannerAssets,
  useQueryScanners,
  useQueryScoutClients,
  useQueryScoutGroups,
  isIpInRange,
} from '../utils';
import { ThreeDots } from 'react-loader-spinner';
import { BLUE } from '../../../Constants/Styles';

type AscContextType = {
  scouts: ScoutData;
  reach: ReachData;
  iva: IvaData;
};

const WrapLoaderStyle = styled.div`
  margin: 75px 0px;
  display: flex;
  justify-content: center;
`;

export const AttackSurfaceContext = React.createContext<AscContextType | null>(
  null
);
AttackSurfaceContext.displayName = 'AttackSurfaceContext';

const ERROR_MESSAGE =
  'AttackSurfaceContext hook must be used within an AttackSurfaceContext.Provider; ensure your node is wrapped in one.';

export const useAttackSurfaceContext = (): AscContextType => {
  const ctx = React.useContext(AttackSurfaceContext);
  if (ctx === null) {
    throw new Error(ERROR_MESSAGE);
  } else {
    return ctx;
  }
};

const getBlankHost = (overrides: Partial<Host>) => {
  return {
    ...{
      accountID: '',
      autoDetectChildren: false,
      autoScanChildren: false,
      cidrBlock: '',
      createTime: '',
      created_time: '',
      description: '',
      domainName: '',
      href: '',
      id: '',
      ipAddress: '',
      is_cspm: false,
      is_host: false,
      last_scan_status: '',
      last_scan_time: '',
      missed: false,
      name: '',
      next_scan_time: '',
      parent: '',
      scan: false,
      strict: false,
      strictChildren: false,
      tags: [],
      scans: [
        {
          audits: {
            href: '',
          },
          endTime: '',
          group: '',
          href: '',
          id: '',
          model: {
            href: '',
          },
          risks: {
            href: '',
          },
          startTime: '',
          status: '',
          subScans: [],
          target: '',
        },
      ],
      groups: [],
    },
    ...overrides,
  };
};

const getReachData = (reach_groups: { groups: ReachGroup[] }) => {
  const reachData: ReachData = {
    groups: {},
    hosts: {},
    cspm_accounts: {},
    parsed: false,
    parsing: false,
  };
  // Reach Data: convert array of groups -> dict { id: group } AND create hosts dict
  _.forEach(reach_groups?.groups, (group: ReachGroup) => {
    reachData.groups[group.id] = group;
    group.hasCspmAccounts = false;
    group.hasHosts = false;

    // set status
    if (
      group.hosts.length === 0 ||
      group.scheduleDisabled ||
      group.nextScanTime === ''
    ) {
      group.status = 'inactive';
    } else if (group.schedule?.year?.length === 1) {
      group.status = 'one-time';
    } else if (group.schedule?.dayOfMonth?.length === 1) {
      group.status = 'monthly';
    } else if (group.schedule?.dayOfWeek?.length === 1) {
      group.status = 'weekly';
    } else if (group.schedule?.hour?.length === 1) {
      group.status = 'daily';
    } else {
      group.status = 'active';
    }

    // create/update each host in group, set convenience flags
    _.forEach(group.hosts, (host_entry) => {
      // use domain name if available, else IP address
      let is_cspm = false;
      let key;
      if (_.trim(host_entry.domainName).length > 0) {
        key = host_entry.domainName.toLowerCase();
      } else if (_.trim(host_entry.ipAddress).length > 0) {
        key = host_entry.ipAddress;
      } else if (_.trim(host_entry.accountID).length > 0) {
        key = host_entry.accountID.toLowerCase();
        is_cspm = true;
      } else {
        return;
      }
      host_entry.name = key;
      host_entry.is_cspm = is_cspm;
      host_entry.is_host = !is_cspm;

      // create reach host entry
      const host: Host = _.has(reachData.hosts, key)
        ? reachData.hosts[key]
        : getBlankHost({
            name: key,
            is_cspm: is_cspm,
            is_host: !is_cspm,
            missed: true,
          });

      //add group id, description
      host.groups?.push(group.id);
      if (_.trim(host_entry.description).length > 0)
        host.description = host_entry.description;

      // add created time from entry if earlier than host.created_time
      const entry_created_earlier =
        Date.parse(host_entry.createTime) < Date.parse(host.created_time || '');
      if (
        Date.parse(host_entry.createTime) &&
        (!host.created_time || entry_created_earlier)
      )
        host.created_time = host_entry.createTime;

      // get most recent scan
      const entry_last_scan = _.maxBy(host_entry.scans, (scan) => {
        return Date.parse(scan.startTime);
      });

      // save time/status to host entry
      host_entry.last_scan_time = entry_last_scan
        ? entry_last_scan.startTime
        : '';
      host_entry.last_scan_status = entry_last_scan
        ? entry_last_scan.status
        : '';

      // did host miss last scan?
      host_entry.missed =
        host_entry.last_scan_status === 'failed' ||
        host_entry.last_scan_time === '';

      // if most recent scan for entry more recent than host.last_scan_time, update host
      if (
        entry_last_scan &&
        (!host.last_scan_time ||
          Date.parse(entry_last_scan.startTime) >
            Date.parse(host.last_scan_time))
      ) {
        host.last_scan_time = entry_last_scan.startTime;
        host.last_scan_status = entry_last_scan.status;
        host.missed = host.last_scan_status === 'failed';
      }

      // set next_scan_time if schedule is active and entry next scan is sooner
      host_entry.next_scan_time = '';
      if (
        !group.scheduleDisabled &&
        host_entry.scan &&
        Date.parse(group.nextScanTime)
      ) {
        host_entry.next_scan_time = group.nextScanTime;
        if (
          !host.next_scan_time ||
          Date.parse(group.nextScanTime) < Date.parse(host.next_scan_time)
        ) {
          host.next_scan_time = group.nextScanTime;
        }
      }

      if (is_cspm) {
        group.hasCspmAccounts = true;
        reachData.cspm_accounts[key] = host;
      } else {
        group.hasHosts = true;
        reachData.hosts[key] = host;
      }
    });
  });
  return reachData;
};

const getScoutData = (scout_clients: Client[], scout_groups: GroupParent[]) => {
  const scout: ScoutData = {
    clients: {},
    groups: {},
    parsed: false,
    parsing: false,
  };
  // suppressing clients with no devicename see https://arcticwolf.atlassian.net/browse/AWN-105861
  var clients_no_devicename: string[] = [];

  // convert array of clients -> dict { clientUUID: client }
  _.forEach(scout_clients, (client) => {
    client.clientUuid = client.clientUuid.toLowerCase();
    client.cve_groups = [];
    client.benchmark_groups = [];
    client.in_active_cve_group = false;
    client.in_active_benchmark_group = false;
    // suppressing clients with no devicename see https://arcticwolf.atlassian.net/browse/AWN-105861
    if (_.trim(client.deviceName.toString()) === '') {
      clients_no_devicename.push(client.clientUuid);
    } else {
      scout.clients[client.clientUuid] = client;
    }
  });
  // convert array of groups -> dict { id: group } AND add group id to each client
  _.forEach(scout_groups, function (item) {
    scout.groups[item.group.id] = item;

    // remove suppressed clients with no devicename see https://arcticwolf.atlassian.net/browse/AWN-105861
    _.remove(item.clients, (client) => {
      return _.includes(clients_no_devicename, client.clientUuid.toLowerCase());
    });

    // set status
    if (
      item.clients.length === 0 ||
      item.group.scheduleDisabled ||
      item.group.nextScanTime === ''
    ) {
      item.group.status = 'inactive';
    } else if (item.group.schedule?.year?.length === 1) {
      item.group.status = 'one-time';
    } else if (item.group.schedule?.dayOfMonth?.length === 1) {
      item.group.status = 'monthly';
    } else if (item.group.schedule?.dayOfWeek?.length === 1) {
      item.group.status = 'weekly';
    } else if (item.group.schedule?.hour?.length === 1) {
      item.group.status = 'daily';
    } else {
      item.group.status = 'active';
    }

    // add group id to each client in group, set convenience flags
    _.forEach(item.clients, (client) => {
      const key = client.clientUuid.toLowerCase();
      if (!_.has(scout.clients, key)) {
        return;
      }

      client = scout.clients[key];
      if (item.group.vulnerabilityScan) {
        client.cve_groups?.push(item.group.id);
        if (!item.group.scheduleDisabled) client.in_active_cve_group = true;
      }
      if (item.group.benchmarkScan) {
        client.benchmark_groups?.push(item.group.id);
        if (!item.group.scheduleDisabled)
          client.in_active_benchmark_group = true;
      }
    });
  });
  return scout;
};

/*
 *	Parse scanner (iVA) clients and groups data for customer into coverage data structure.
 *  @param {object} scanners - response JSON object from /rootsecure/v1/customers/{{ customer.id }}/scanners
 *  @param {object} scanner_schedules - a dict of response JSON objects from /rootsecure/v3/scanners/{{ scanner.id }}/schedules for each {{ scanner.id }}:
 *                                      { <scanner.id>: <schedules api response>, ...  }
 *  @param {object} scanner_assets - response JSON object from /rootsecure/v1/customers/{{ customer.id }}/assets?fromScore=0&search=&source=sensor&toScore=10.1
 *	@returns {void} This function does not return any value.
 */
const getScannerData = (
  scanners: Scanner[],
  scanner_schedules: ScannerSchedules,
  scanner_assets: AscAsset[]
) => {
  const iva: IvaData = {
    scanners: {},
    assets: {},
    parsed: false,
    parsing: false,
  };
  // ensure schedule keys are lowercase
  const scanner_schedules_map = _.mapKeys(scanner_schedules, (v, k) => {
    return k.toLowerCase();
  });
  // add .assets to each schedule, [<target>, <target>] -> { <target>: { assets:[], name:<target> }}
  _.forEach(scanner_schedules_map, (schedules) => {
    _.forEach(schedules, (schedule) => {
      schedule.assets = [];
      const targets: { [key: string]: ScannerTarget } = {};
      _.forEach(schedule.targets, (target) => {
        targets[target] = { name: target, assets: [], assetCount: 0 };
      });
      schedule.mappedTargets = targets;
    });
  });

  // convert array of scanners -> dict { scanner.id: scanner }; add schedules
  _.forEach(scanners, (scanner: Scanner) => {
    scanner.id = scanner.id.toLowerCase();
    scanner.assets = [];
    scanner.schedules = {};
    if (_.has(scanner_schedules_map, scanner.id)) {
      _.forEach(scanner_schedules_map[scanner.id], (schedule) => {
        scanner.schedules[schedule.id] = schedule;
      });
    }

    iva.scanners[scanner.id] = scanner;
  });

  // convert array of scanner assets -> dict { asset.id: asset }
  _.forEach(scanner_assets, (asset) => {
    asset.id = asset?.id?.toLowerCase();
    asset.scannerUUID = asset?.scannerUUID?.toLowerCase();
    asset.schedules = [];
    asset.schedule_targets = [];
    if (asset.id) iva.assets[asset.id] = asset;

    let scanner;
    if (asset.scannerUUID)
      // if asset has scanner, add asset to: scanner.assets, scanner.schedule, scanner.schedule.target
      scanner = _.has(iva.scanners, asset.scannerUUID)
        ? iva.scanners[asset.scannerUUID]
        : null;
    if (scanner) {
      if (asset.id) scanner.assets.push(asset.id || '');
      const asset_ip = new (IPCheck as any)(asset.ip);

      _.forEach(scanner.schedules, (schedule) => {
        _.forEach(schedule.mappedTargets, (target) => {
          const target_ipcidr = new IPCheck(target.name);

          let isInRange = false;
          if (target.name.includes('-')) {
            isInRange = isIpInRange(target.name, asset_ip.input);
          }

          // if asset IP in target IP/CIDR, create associations
          if (asset_ip.match(target_ipcidr) || isInRange) {
            asset.schedules.push(schedule.id);
            asset.schedule_targets.push(target.name);
            if (asset.id) {
              target.assets.push(asset.id);
              schedule.assets.push(asset.id);
            }
          }
        });
      });
    }
  });
  return iva;
};

export const AttackSurfaceCoverageCtx: FC<PropsWithChildren> = ({
  children,
}) => {
  const scoutClientsResults = useQueryScoutClients();
  const scoutGroupsResults = useQueryScoutGroups();
  const scoutData = getScoutData(
    scoutClientsResults?.data?.data.data,
    scoutGroupsResults?.data?.data.data
  );
  const scoutIsSuccess =
    scoutClientsResults.isSuccess && scoutGroupsResults.isSuccess;

  const reachGroupsResult = useQueryReachGroups();
  const reachData = getReachData(reachGroupsResult?.data?.data.data);
  const reachIsSuccess = reachGroupsResult.isSuccess;

  // retrieve scanner data via API
  const scannersResults = useQueryScanners();
  const scannersAssetsResults = useQueryScannerAssets();
  const scannerIds = scannersResults.isSuccess
    ? scannersResults.data.data.data.map((x: Scanner) => x.id)
    : [];
  const scannerScheduleResults = useQueriesScannerSchedules(scannerIds);
  const scannerIsSuccess =
    scannersResults.isSuccess &&
    scannersAssetsResults.isSuccess &&
    scannerScheduleResults &&
    scannerScheduleResults.every((result) => result.isSuccess);
  let ivaData = {
    scanners: {},
    assets: {},
    parsed: false,
    parsing: false,
  };
  // load scanner data into model
  if (scannerIsSuccess) {
    const scheduleMapObj: ScannerSchedules = {};
    scannerScheduleResults.forEach((query: any) => {
      scheduleMapObj[query?.data?.data[0]?.scannerId] = _.cloneDeep(
        query?.data?.data
      );
    });
    ivaData = getScannerData(
      scannersResults?.data?.data?.data,
      { ...scheduleMapObj },
      scannersAssetsResults?.data?.data?.data
    );
  }
  if (scannerIsSuccess && reachIsSuccess && scoutIsSuccess) {
    return (
      <AttackSurfaceContext.Provider
        value={{
          scouts: scoutData,
          reach: reachData,
          iva: ivaData,
        }}
      >
        {children}
      </AttackSurfaceContext.Provider>
    );
  }
  return (
    <WrapLoaderStyle>
      <ThreeDots color={BLUE.tertiary} height={240} width={240} />
    </WrapLoaderStyle>
  );
};
