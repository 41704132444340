import { Dispatch } from 'redux';
import { iGetState } from '../configureStore';
import { AxiosError, AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { routesMap } from '../routesMap';
import qs from 'qs';
import { iScanner } from './scannerReducer';
import riskAPI from '../utils/riskAPI';
import {
  QueuedScan,
  ScannerSummary,
  Schedule,
  ScheduleData,
  SchedulePost,
  SensorConfig,
  SensorCredential,
  SensorCredentials,
  SensorCredentialUpdate,
  SensorNetwork,
  SensorPreferences,
  SensorStatus,
  V1CustomerScannersResponse,
  V3ScannersScanResponse,
} from '../types/scanner';
import { ScannerActionTypes } from './actionEnums';

// ----- Action Types -----
type LoadScannersAction = {
  type: ScannerActionTypes.LOAD_SCANNERS;
  scanners: ScannerSummary[];
};

type LoadScansAction = {
  type: ScannerActionTypes.LOAD_SCANS;
  payload: {
    id: string;
    scans: QueuedScan[];
  };
};

type LoadNetworkAction = {
  type: ScannerActionTypes.LOAD_NETWORK;
  payload: {
    data: SensorNetwork;
    id: string;
  };
};

type LoadSchedulesAction = {
  type: ScannerActionTypes.LOAD_SCHEDULES;
  payload: {
    id: string;
    data: Schedule[];
  };
};

type LoadPreferencesAction = {
  type: ScannerActionTypes.LOAD_PREFERENCES;
  payload: {
    id: string;
    data: SensorPreferences;
  };
};

type LoadConfigAction = {
  type: ScannerActionTypes.LOAD_CONFIG;
  payload: {
    id: string;
    data: SensorConfig;
  };
};

type RemoveScheduleAction = {
  type: ScannerActionTypes.REMOVE_SCHEDULE;
  payload: {
    id: string;
    scheduleId: string;
  };
};

type ToggleScanAction = {
  type: ScannerActionTypes.TOGGLE_SCAN;
  payload: {
    id: string;
    index: number;
  };
};

type UpdateScheduleAction = {
  type: ScannerActionTypes.UPDATE_SCHEDULE;
  payload: {
    id: string;
    schedule: ScheduleData;
  };
};

type LoadCredScansAction = {
  type: ScannerActionTypes.LOAD_CRED_SCANS;
  payload: {
    data: SensorCredentials;
    id: string;
  };
};

type RemoveCredsAction = {
  type: ScannerActionTypes.REMOVE_CREDS;
  payload: {
    id: string;
    credsId: string;
  };
};

type AddScheduleAction = {
  type: ScannerActionTypes.ADD_SCHEDULE;
  payload: {
    id: string;
    schedule: Schedule;
  };
};

type SetCredentialAction = {
  type: ScannerActionTypes.SET_CREDENTIAL;
  payload: {
    id: string;
    data: SensorCredential;
  };
};

type UpdateStatusAction = {
  type: ScannerActionTypes.UPDATE_STATUS;
  payload: SensorStatus;
};

type SetCurrentScannerAction = {
  type: ScannerActionTypes.SET_CURRENT_SCANNER;
  scanner: ScannerSummary;
};

export type ScannerAction =
  | LoadScannersAction
  | LoadScansAction
  | LoadNetworkAction
  | LoadSchedulesAction
  | LoadPreferencesAction
  | LoadConfigAction
  | RemoveScheduleAction
  | ToggleScanAction
  | UpdateScheduleAction
  | LoadCredScansAction
  | RemoveCredsAction
  | AddScheduleAction
  | SetCredentialAction
  | UpdateStatusAction
  | SetCurrentScannerAction;

// ----- Action Creators -----

export const SCANNER_ACTIONS = {
  LOAD_SCANNERS: (scanners: ScannerSummary[]): LoadScannersAction => ({
    type: ScannerActionTypes.LOAD_SCANNERS,
    scanners,
  }),
  SET_CURRENT_SCANNER: (scanner: ScannerSummary): SetCurrentScannerAction => ({
    type: ScannerActionTypes.SET_CURRENT_SCANNER,
    scanner,
  }),
  LOAD_SCANS: (id: string, scans: QueuedScan[]): LoadScansAction => ({
    type: ScannerActionTypes.LOAD_SCANS,
    payload: {
      id,
      scans,
    },
  }),
  LOAD_CRED_SCANS: (
    id: string,
    sensorCredentials: SensorCredentials
  ): LoadCredScansAction => ({
    type: ScannerActionTypes.LOAD_CRED_SCANS,
    payload: {
      data: sensorCredentials,
      id,
    },
  }),
  SET_CREDENTIAL: (
    id: string,
    sensorCredentials: SensorCredential
  ): SetCredentialAction => ({
    type: ScannerActionTypes.SET_CREDENTIAL,
    payload: {
      id,
      data: sensorCredentials,
    },
  }),
  LOAD_SCHEDULES: (id: string, schedules: Schedule[]): LoadSchedulesAction => ({
    type: ScannerActionTypes.LOAD_SCHEDULES,
    payload: {
      id,
      data: schedules,
    },
  }),
  LOAD_PREFERENCES: (
    id: string,
    creds: SensorPreferences
  ): LoadPreferencesAction => ({
    type: ScannerActionTypes.LOAD_PREFERENCES,
    payload: {
      id,
      data: creds,
    },
  }),
  LOAD_CONFIG: (id: string, config: SensorConfig): LoadConfigAction => ({
    type: ScannerActionTypes.LOAD_CONFIG,
    payload: {
      id,
      data: config,
    },
  }),
  REMOVE_SCHEDULE: (id: string, scheduleId: string): RemoveScheduleAction => ({
    type: ScannerActionTypes.REMOVE_SCHEDULE,
    payload: {
      id,
      scheduleId,
    },
  }),
  TOGGLE_SCAN: (id: string, index: number): ToggleScanAction => ({
    type: ScannerActionTypes.TOGGLE_SCAN,
    payload: {
      id,
      index,
    },
  }),
  UPDATE_SCHEDULE: (
    id: string,
    schedule: ScheduleData
  ): UpdateScheduleAction => ({
    type: ScannerActionTypes.UPDATE_SCHEDULE,
    payload: {
      id,
      schedule,
    },
  }),
  REMOVE_CREDS: (id: string, credsId: string): RemoveCredsAction => ({
    type: ScannerActionTypes.REMOVE_CREDS,
    payload: {
      id,
      credsId,
    },
  }),
  ADD_SCHEDULE: (id: string, schedule: Schedule): AddScheduleAction => ({
    type: ScannerActionTypes.ADD_SCHEDULE,
    payload: {
      id,
      schedule,
    },
  }),
  UPDATE_STATUS: (data: SensorStatus): UpdateStatusAction => ({
    type: ScannerActionTypes.UPDATE_STATUS,
    payload: data,
  }),
  LOAD_NETWORK: (id: string, data: SensorNetwork): LoadNetworkAction => ({
    type: ScannerActionTypes.LOAD_NETWORK,
    payload: {
      id,
      data,
    },
  }),
};

// ----- API Calls -----

export const getScannerById = async (scannerId: string) => {
  let data: ScannerSummary = {
    id: '',
    deploymentID: '',
    label: '',
  };
  try {
    const response = await riskAPI.get(
      `/rootsecure/v1/sensordata/${scannerId}`
    );
    data = response.data;
  } catch (error) {
    const { code } = error as AxiosError;
    toastr.error(code || '', 'Failed to retrieve scanner');
  }
  return data;
};

export const getScannersByCustomerId = async (customerId: string) => {
  let data: Array<iScanner> = [];
  try {
    const response = await riskAPI.get(
      `/rootsecure/v1/customers/${customerId}/scanners`
    );
    data = response.data.data;
  } catch (error) {
    const { code } = error as AxiosError;
    toastr.error(code || '', 'Failed to retrieve scanners');
  }
  return data;
};

// ----- Action Constants -----

// ----- Thunk Action Creators -----

export const getScannersThunk =
  (id?: string) => (dispatch: Dispatch, getState: iGetState) => {
    const { config, customer } = getState();
    const customerId = id || customer.currentCustomer.id;
    return riskAPI
      .get<V1CustomerScannersResponse>(
        `${config.environment.servicesHost}/rootsecure/v1/customers/${customerId}/scanners`
      )
      .then((response) => {
        return dispatch(SCANNER_ACTIONS.LOAD_SCANNERS(response.data.data));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to retrieve scanners', error.code!)
      );
  };

export const getScansThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get<V3ScannersScanResponse>(`/rootsecure/v3/scanners/${id}/scans`)
        .then((response) =>
          dispatch(SCANNER_ACTIONS.LOAD_SCANS(id, response.data))
        )
        .catch((error: AxiosError) =>
          toastr.error('Failed to fetch Scans', error.code!)
        );
    }
  };

export const getCredScansThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get<SensorCredentials | null>(
          `${config.environment.servicesHost}/rootsecure/v1/credentials/sensor/${id}`
        )
        .then((response: AxiosResponse) =>
          dispatch(SCANNER_ACTIONS.LOAD_CRED_SCANS(id, response.data || []))
        )
        .catch((error: AxiosError) =>
          toastr.error(
            'Failed to fetch Credentialed Scans',
            error.code as string
          )
        );
    }
  };

export const getNetworkThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get(
          `${config.environment.servicesHost}/rootsecure/v1/sensor/${id}/network`
        )
        .then((response: AxiosResponse) =>
          dispatch(SCANNER_ACTIONS.LOAD_NETWORK(id, response.data))
        )
        .catch((error: AxiosError) => {
          toastr.error('Failed to fetch network data', error.code as string);
        });
    }
  };

export const getSchedulesThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get(
          `${config.environment.servicesHost}/rootsecure/v3/scanners/${id}/schedules`
        )
        .then((response: AxiosResponse) =>
          dispatch(SCANNER_ACTIONS.LOAD_SCHEDULES(id, response.data))
        )
        .catch((error: AxiosError) => {
          toastr.error('Failed to fetch Schedules', error.code as string);
        });
    }
  };

export const getPreferencesThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get(
          `${config.environment.servicesHost}/rootsecure/v2/scanners/${id}/preferences`
        )
        .then((response: AxiosResponse) =>
          dispatch(SCANNER_ACTIONS.LOAD_PREFERENCES(id, response.data))
        )
        .catch((error: AxiosError) => {
          toastr.error(
            'Failed to fetch Scanner Preferences',
            error.code as string
          );
        });
    }
  };

export const getConfigurationThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get(
          `${config.environment.servicesHost}/rootsecure/v1/sensor/${id}/config`
        )
        .then((response: AxiosResponse) =>
          dispatch(SCANNER_ACTIONS.LOAD_CONFIG(id, response.data))
        )
        .catch((error: AxiosError) => {
          toastr.error(
            'Failed to fetch Scanner Preferences',
            error.code as string
          );
        });
    }
  };

export const postConfigurationThunk =
  (newData: any, oldData: any, toastrMsg: string | undefined = undefined) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { config, scanner } = getState();
    // @ts-ignore
    const { id } = scanner.scanner;
    const {
      dns_servers,
      nmap,
      scans,
      pingOnly,
      networks,
      blacklist,
      hardstop,
      autoMitigateRiskDay,
    } = newData;
    const data = {
      dns_servers,
      nmap,
      scans,
      pingOnly,
      networks,
      blacklist,
      hardstop,
      autoMitigateRiskDay,
    };

    const options = {
      method: 'POST',
      data: qs.stringify({ newconfig: JSON.stringify(data) }),
      url: `${config.environment.servicesHost}/rootsecure/v1/sensor/${id}/config`,
    };
    // @ts-ignore
    return riskAPI(options)
      .then(() => {
        if (toastrMsg) {
          toastr.success('Success', toastrMsg);
        }
        dispatch(SCANNER_ACTIONS.LOAD_CONFIG(id, newData));
      })
      .catch((error: AxiosError) => {
        toastr.error(
          'Failed to Update Scanner Preferences',
          error.code as string
        );
        dispatch(SCANNER_ACTIONS.LOAD_CONFIG(id, oldData));
      });
  };

export const updateScannerConfigurationThunk =
  (
    id: string,
    item: string | Array<string>,
    key: string,
    successMsg: string | undefined = undefined
  ) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const currentScannerConfig = scanner.scanners.get(id)?.config;
    const newData = {
      ...currentScannerConfig,
      [key]: item,
    };
    return dispatch<any>(
      postConfigurationThunk(newData, currentScannerConfig, successMsg)
    );
  };

export const toggleScanThunk =
  (id: string, updates: Record<string, any>) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const currentScannerConfig = scanner.scanners.get(id)?.config;
    const newData = {
      ...currentScannerConfig,
      hardstop: false,
      ...updates,
    };
    return dispatch<any>(postConfigurationThunk(newData, currentScannerConfig));
  };

export const updatePreferencesThunk =
  (newData: any) => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    const oldData = scanner?.scanners?.get(id)?.preferences;
    return riskAPI
      .put(`/rootsecure/v2/scanners/${id}/preferences`, newData)
      .then(() => dispatch(SCANNER_ACTIONS.TOGGLE_SCAN(id, newData[0].id - 1)))
      .catch((error: AxiosError) => {
        toastr.error(
          'Failed to Update Scanner Preferences',
          error.code as string
        );
        dispatch(
          SCANNER_ACTIONS.LOAD_PREFERENCES(id, oldData as SensorPreferences)
        );
      });
  };

export const deleteScheduleThunk =
  (scheduleId: string) => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    return riskAPI
      .delete(`/rootsecure/v3/scanners/${id}/schedules/${scheduleId}`)
      .then(() => {
        dispatch(SCANNER_ACTIONS.REMOVE_SCHEDULE(id, scheduleId));
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to remove schedule', error.code as string);
      });
  };

export const addScanThunk =
  (schedule: SchedulePost) => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    return riskAPI
      .post(`/rootsecure/v3/scanners/${id}/schedules`, schedule, {
        maxRedirects: 2,
      })
      .then((response: AxiosResponse) => {
        return dispatch(
          SCANNER_ACTIONS.ADD_SCHEDULE(
            id,
            response.data.data.filter(
              (data: any) => data.targets[0] === schedule.targets[0]
            )[0]
          )
        );
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to add new schedule', error.code as string);
      });
  };

export const updateScanThunk =
  (schedule: any) => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    return riskAPI
      .put(`/rootsecure/v3/scanners/${id}/schedules/${schedule.id}`, schedule, {
        maxRedirects: 2,
      })
      .then(() => dispatch(SCANNER_ACTIONS.UPDATE_SCHEDULE(id, schedule)))
      .catch((error: AxiosError) => {
        toastr.error('Failed to update schedule', error.code as string);
      });
  };

export const deleteCredsThunk =
  (credsId: string) => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    return riskAPI
      .delete(`/rootsecure/v1/credentials/sensor/${id}/${credsId}`)
      .then(() => {
        dispatch(SCANNER_ACTIONS.REMOVE_CREDS(id, credsId));
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to remove schedule', error.code as string);
      });
  };

export const updateCredsThunk =
  (credsID: string, credsObject: SensorCredentialUpdate) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;

    return riskAPI
      .put<SensorCredential>(
        `/rootsecure/v1/credentials/sensor/${id}`,
        {
          ...credsObject,
          id: credsID,
        },
        {
          maxRedirects: 2,
        }
      )
      .then((response) => {
        dispatch(SCANNER_ACTIONS.SET_CREDENTIAL(id, response.data));
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to update credentials', error.code as string);
      });
  };

export const addCredsThunk =
  (credsObject: SensorCredentialUpdate) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    return riskAPI
      .post<SensorCredential>(
        `/rootsecure/v1/credentials/sensor/${id}`,
        credsObject
      )
      .then((response) => {
        dispatch(SCANNER_ACTIONS.SET_CREDENTIAL(id, response.data));
      })
      .catch((error: AxiosError) => {
        toastr.error('Failed to add new schedule', error.code as string);
      });
  };

export const getSensorStatusThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { scanner } = getState();
    const { id } = scanner.scanner;
    if (typeof id !== 'undefined') {
      return riskAPI
        .get(`/rootsecure/v1/sensor/${id}/status`)
        .then((response: AxiosResponse) => {
          dispatch(SCANNER_ACTIONS.UPDATE_STATUS(response.data));
        })
        .catch((error: AxiosError) => {
          toastr.error('Failed to get list of groups', error.code as string);
        });
    }
  };

export const scanNowThunk =
  (host: string, source: string, clientid: string, scannerId: string) => () => {
    riskAPI
      .post(
        `/rootsecure/v1/sensor/${scannerId}/scannow`,
        `hostdata=${JSON.stringify({
          host,
          source,
          clientid: clientid || '',
        })}`
      )
      .then(() => {
        toastr.success('Success', 'Scan has been added to the queue');
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to update Risk', error.code as string)
      );
  };

export const scanImmediateThunk =
  (host: string, source: string, clientid: string, scannerId: string) => () => {
    riskAPI
      .post(
        `/rootsecure/v1/sensor/${scannerId}/scanimmediate`,
        `hostdata=${JSON.stringify({
          host,
          source,
          clientid: clientid || '',
        })}`
      )
      .then(() => {
        toastr.success('Success', 'Scan will take place immediately');
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to scan immediately', error.code as string)
      );
  };

export const setScannerThunk =
  (scanner: ScannerSummary, reload: boolean) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { location } = getState();

    localStorage.setItem('scanner', JSON.stringify(scanner));
    localStorage.setItem('sensor', JSON.stringify(scanner));

    dispatch(SCANNER_ACTIONS.SET_CURRENT_SCANNER(scanner));

    if (reload) {
      // Refresh Data after selecting scanner
      // @ts-ignore
      const updateThunk: any = routesMap[location.type].thunk;
      if (updateThunk) {
        dispatch(updateThunk);
      }
    }
  };
