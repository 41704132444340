export declare interface Colors {
  black: string;
  body: string;
  bodyDisabled: string;
  border: string;
  blue: string;
  code: string;
  description: string;
  disabledBackground: string;
  gray: string;
  green: string;
  hoverBackground: string;
  label: string;
  orange: string;
  primary: string;
  primaryBackground: string;
  purple: string;
  quaternaryBackground: string;
  red: string;
  secondary: string;
  secondaryBackground: string;
  subtitle: string;
  tableBorder: string;
  tag: string;
  tertiary: string;
  tertiaryBackground: string;
  title: string;
  white: string;
  yellow: string;
}

export declare interface GlobalTheme {
  animationTimeVeryFast: number;
  animationTimeFast: number;
  animationTimeMedium: number;
  animationTimeSlow: number;
  badgeBackgroundGreen: string;
  badgeBackgroundRed: string;
  badgeBackgroundYellow: string;
  badgeBackgroundOrange: string;
  badgeBackgroundPurple: string;
  badgeBackgroundBlue: string;
  badgeBackgroundGray: string;
  badgeTextColorDark: string;
  badgeTextColorLight: string;
  buttonBorderRadius: string;
  buttonPadding: string;
  buttonFontWeight: string;
  buttonSmallHeight: string;
  buttonSmallFontSize: string;
  buttonDefaultHeight: string;
  buttonDefaultFontSize: string;
  buttonLargeHeight: string;
  buttonLargeFontSize: string;
  buttonPrimaryBackground: string;
  buttonPrimaryBorder: string;
  buttonPrimaryBorderColor: string;
  buttonPrimaryColor: string;
  buttonPrimaryHoverBackground: string;
  buttonPrimaryHoverColor: string;
  buttonPrimaryActiveBackground: string;
  buttonPrimaryActiveColor: string;
  buttonSecondaryBackground: string;
  buttonSecondaryBorder: string;
  buttonSecondaryBorderColor: string;
  buttonSecondaryColor: string;
  buttonSecondaryHoverBackground: string;
  buttonSecondaryHoverColor: string;
  buttonSecondaryActiveBackground: string;
  buttonSecondaryActiveColor: string;
  buttonTertiaryBackground: string;
  buttonTertiaryBorder: string;
  buttonTertiaryBorderColor: string;
  buttonTertiaryColor: string;
  buttonTertiaryHoverBackground: string;
  buttonTertiaryHoverColor: string;
  buttonTertiaryActiveBackground: string;
  buttonTertiaryActiveColor: string;
  buttonDangerBackground: string;
  buttonDangerBorder: string;
  buttonDangerBorderColor: string;
  buttonDangerColor: string;
  buttonDangerHoverBackground: string;
  buttonDangerHoverColor: string;
  buttonDangerActiveBackground: string;
  buttonDangerActiveColor: string;
  buttonGhostBackground: string;
  buttonGhostBorder: string;
  buttonLinkBackground: string;
  buttonLinkBorder: string;
  buttonLinkBorderColor: string;
  buttonLinkColor: string;
  buttonLinkHoverBackground: string;
  buttonLinkHoverColor: string;
  buttonLinkActiveBackground: string;
  buttonLinkActiveColor: string;
  checkboxLineHeight: string;
  checkboxMargin: string;
  checkboxFontSize: string;
  checkboxFontColor: string;
  checkboxBorder: string;
  checkboxHoverColor: string;
  checkboxHoverBorder: string;
  checkboxCheckedBackground: string;
  checkboxCheckedBorderColor: string;
  checkboxBorderRadius: string;
  collapseBorder: string;
  collapseBorderColor: string;
  collapseBorderRadius: string;
  collapseBoxShadow: string;
  collapseContentPadding: string;
  collapseContentBackground: string;
  collapseContentFooterHoverColor: string;
  collapseHeaderColor: string;
  collapseHeaderBackground: string;
  collapseHeaderHoverBackground: string;
  collapseHeaderHoverColor: string;
  collapseHeaderOpenBackground: string;
  collapseHeaderOpenColor: string;
  collapseHeaderPadding: string;
  collapseHeaderHeight: string;
  collapseIconSize: string;
  dividerBorder: string;
  dividerBackground: string;
  dividerTextPrimaryBackground: string;
  dividerTextColor: string;
  dropPadPadding: string;
  dropPadHoverBackground: string;
  dropPadBackground: string;
  dropPadBorder: string;
  dropPadHoverBorderColor: string;
  dropPadBorderColor: string;
  dropPadBorderRadius: string;
  dropPadFileLoadingBackground: string;
  dropPadFileLoadingBarBackground: string;
  inputBackground: string;
  inputBorder: string;
  inputBorderRadius: string;
  inputBorderColor: string;
  inputFocusBorderColor: string;
  inputColor: string;
  inputDefaultHeight: string;
  inputDefaultFontSize: number;
  inputDisabledBackground: string;
  inputFontFamily: string;
  inputStatusErrorBorderColor: string;
  inputStatusWarningBorderColor: string;
  inputStatusSuccessBorderColor: string;
  inputStatusLoadingBorderColor: string;
  inputSmallHeight: string;
  inputSmallFontSize: number;
  inputLargeHeight: string;
  inputLargeFontSize: number;
  inputPadding: string;
  inputTextAreaPadding: string;
  inputPlaceholderColor: string;
  layoutHeaderHeight: string;
  layoutHeaderPaddingSmall: string;
  layoutHeaderPaddingMedium: string;
  layoutHeaderPaddingLarge: string;
  layoutFooterHeight: string;
  layoutSiderWidthSmall: string;
  layoutSiderWidthMedium: string;
  layoutSiderWidthLarge: string;
  mediaScreenSmall: string;
  mediaScreenMedium: string;
  mediaScreenLarge: string;
  mediaScreenVeryLarge: string;
  menuItemPadding: string;
  menuItemHoverColor: string;
  menuItemHoverBackground: string;
  modalBackground: string;
  modalBorder: string;
  modalBorderColor: string;
  modalBorderRadius: string;
  modalBoxShadow: string;
  modalContentPadding: string;
  modalFooterBackground: string;
  modalFooterHeight: string;
  modalFooterPadding: string;
  modalHeaderBackground: string;
  modalHeaderColor: string;
  modalHeaderMarginTop: string;
  modalHeaderPadding: string;
  modalMaskBackground: string;
  modalMinHeight: string;
  modalMinWidth: string;
  notificationMinHeight: string;
  notificationMinWidth: string;
  notificationBorderRadius: string;
  notificationMaxWidth: string;
  notificationBoxShadow: string;
  notificationBackground: string;
  notificationBoxMinHeight: string;
  notificationBoxMaxWidth: string;
  notificationBoxColor: string;
  notificationBoxErrorBackground: string;
  notificationBoxSuccessBackground: string;
  notificationBoxWarningBackground: string;
  notificationBoxInfoBackground: string;
  notificationBoxDefaultBackground: string;
  notificationBoxBorderRadius: string;
  notificationBoxBoxShadow: string;
  notificationBoxPadding: string;
  panelBackground: string;
  panelBorder: string;
  panelBorderColor: string;
  panelBorderRadius: string;
  panelPadding: string;
  panelMargin: string;
  panelActiveBoxShadow: string;
  panelHoverBoxShadow: string;
  panelWithTitleBorder: string;
  panelWithTitleBorderColor: string;
  radioItemLineHeight: string;
  radioItemMargin: string;
  radioItemFontSize: string;
  radioItemFontColor: string;
  radioItemBorder: string;
  radioItemCheckedMiddleBackground: string;
  radioItemHoverColor: string;
  radioItemHoverBorder: string;
  radioItemCheckedBackground: string;
  radioItemCheckedBorderColor: string;
  selectControlBorderColor: string;
  selectControlHoverBorderColor: string;
  selectControlBackground: string;
  selectControlDisabledBackground: string;
  selectControlMinHeight: string;
  selectOptionColor: string;
  selectOptionSelectedBackground: string;
  selectOptionSelectedColor: string;
  selectOptionFocusedBackground: string;
  selectMenuBackground: string;
  selectMultiValueLabelColor: string;
  selectMultiValueBackground: string;
  selectMultiValueRemoveHoverBackground: string;
  selectSingleValueColor: string;
  tableEmptyContainerHeight: string;
  tableHeadCellPadding: string;
  tableHeadBorder: string;
  tableHeadBorderColor: string;
  tableHeadFontSize: string;
  tableHeadFontColor: string;
  tableBodyCellPadding: string;
  tableBodyRowBorder: string;
  tableBodyRowBorderColor: string;
  tableBodyFontSize: string;
  tableBodyFontColor: string;
  tabsInkbarThickness: string;
  tabsInkbarColor: string;
  tabsItemPadding: string;
  tabsItemColor: string;
  toggleHeight: string;
  toggleWidth: string;
  toggleOffBackground: string;
  toggleOnBackground: string;
  toggleDisabledBackground: string;
  toggleOnIconColor: string;
  toggleBorderRadius: string;
  toggleCircleBackground: string;
  toggleCircleHeight: string;
  toggleCircleWidth: string;
  toggleCircleTop: string;
  tooltipArrowSize: string;
  tooltipBorder: string;
  tooltipBorderColor: string;
  tooltipBorderRadius: string;
  tooltipBoxShadow: string;
  tooltipMargin: string;
  typographyTitleFontFamily: string;
  typographyTitle1Color: string;
  typographyTitle1FontSize: string;
  typographyTitle1FontWeight: string;
  typographyTitle1LineHeight: string;
  typographyTitle1LetterSpacing: string;
  typographyTitle2Color: string;
  typographyTitle2FontSize: string;
  typographyTitle2FontWeight: string;
  typographyTitle2LineHeight: string;
  typographyTitle2LetterSpacing: string;
  typographyTitle3Color: string;
  typographyTitle3FontSize: string;
  typographyTitle3FontWeight: string;
  typographyTitle3LineHeight: string;
  typographyTitle3LetterSpacing: string;
  typographyTitle4Color: string;
  typographyTitle4FontSize: string;
  typographyTitle4FontWeight: string;
  typographyTitle4LineHeight: string;
  typographyTitle4LetterSpacing: string;
  typographyTitle5Color: string;
  typographyTitle5FontSize: string;
  typographyTitle5FontWeight: string;
  typographyTitle5LineHeight: string;
  typographyTitle5LetterSpacing: string;
  typographyTitle6Color: string;
  typographyTitle6FontSize: string;
  typographyTitle6FontWeight: string;
  typographyTitle6LineHeight: string;
  typographyTitle6LetterSpacing: string;
  typographySubtitleColor: string;
  typographySubtitleFontFamily: string;
  typographySubtitleFontSize: string;
  typographySubtitleFontWeight: string;
  typographySubtitleLineHeight: string;
  typographySubtitleLetterSpacing: string;
  typographyBodyColor: string;
  typographyBodyFontFamily: string;
  typographyBodyFontSize: string;
  typographyBodyFontWeight: string;
  typographyBodyLineHeight: string;
  typographyBodyLetterSpacing: string;
  typographyBodyDisabledColor: string;
  typographyDescriptionColor: string;
  typographyDescriptionFontFamily: string;
  typographyDescriptionFontSize: string;
  typographyDescriptionFontWeight: string;
  typographyDescriptionLineHeight: string;
  typographyDescriptionLetterSpacing: string;
  typographyLabelColor: string;
  typographyLabelFontFamily: string;
  typographyLabelFontSize: string;
  typographyLabelFontWeight: string;
  typographyLabelLineHeight: string;
  typographyLabelLetterSpacing: string;
  typographyLabelTextTransform: string;
  colors: Colors;
}

export const useTheme = (): GlobalTheme => {
  return {
    animationTimeVeryFast: 0.1,
    animationTimeFast: 0.25,
    animationTimeMedium: 0.5,
    animationTimeSlow: 1,
    badgeBackgroundGreen: '#518042',
    badgeBackgroundRed: '#C43836',
    badgeBackgroundYellow: '#EAA543',
    badgeBackgroundOrange: '#F58220',
    badgeBackgroundPurple: '#893391',
    badgeBackgroundBlue: '#23618E',
    badgeBackgroundGray: '#58595B',
    badgeTextColorDark: '#000000',
    badgeTextColorLight: '#FFFFFF',
    buttonBorderRadius: '4px',
    buttonPadding: '0px 15px',
    buttonFontWeight: '500',
    buttonSmallHeight: '24px',
    buttonSmallFontSize: '12px',
    buttonDefaultHeight: '32px',
    buttonDefaultFontSize: '14px',
    buttonLargeHeight: '44px',
    buttonLargeFontSize: '16px',
    buttonPrimaryBackground: '#23618E',
    buttonPrimaryBorder: '1px solid',
    buttonPrimaryBorderColor: 'transparent',
    buttonPrimaryColor: '#FFFFFF',
    buttonPrimaryHoverBackground: '#194565',
    buttonPrimaryHoverColor: '#FFFFFF',
    buttonPrimaryActiveBackground: '#194565',
    buttonPrimaryActiveColor: '#FFFFFF',
    buttonSecondaryBackground: '#58595B',
    buttonSecondaryBorder: '1px solid',
    buttonSecondaryBorderColor: 'transparent',
    buttonSecondaryColor: '#FFFFFF',
    buttonSecondaryHoverBackground: '#3f4041',
    buttonSecondaryHoverColor: '#FFFFFF',
    buttonSecondaryActiveBackground: '#3f4041',
    buttonSecondaryActiveColor: '#FFFFFF',
    buttonTertiaryBackground: '#F58220',
    buttonTertiaryBorder: '1px solid',
    buttonTertiaryBorderColor: 'transparent',
    buttonTertiaryColor: '#000000',
    buttonTertiaryHoverBackground: '#d8690a',
    buttonTertiaryHoverColor: '#000000',
    buttonTertiaryActiveBackground: '#d8690a',
    buttonTertiaryActiveColor: '#000000',
    buttonDangerBackground: '#C43836',
    buttonDangerBorder: '1px solid',
    buttonDangerBorderColor: 'transparent',
    buttonDangerColor: '#FFFFFF',
    buttonDangerHoverBackground: '#9c2d2b',
    buttonDangerHoverColor: '#FFFFFF',
    buttonDangerActiveBackground: '#9c2d2b',
    buttonDangerActiveColor: '#FFFFFF',
    buttonGhostBackground: '#FFFFFF',
    buttonGhostBorder: '1px solid',
    buttonLinkBackground: 'transparent',
    buttonLinkBorder: '1px solid',
    buttonLinkBorderColor: 'transparent',
    buttonLinkColor: '#23618E',
    buttonLinkHoverBackground: 'transparent',
    buttonLinkHoverColor: '#58595B',
    buttonLinkActiveBackground: 'transparent',
    buttonLinkActiveColor: '#58595B',
    checkboxLineHeight: '16px',
    checkboxMargin: '4px',
    checkboxFontSize: '14px',
    checkboxFontColor: '#000000',
    checkboxBorder: '1px solid #CCCCCC',
    checkboxHoverColor: '#23618E',
    checkboxHoverBorder: '1px solid #23618E',
    checkboxCheckedBackground: '#23618E',
    checkboxCheckedBorderColor: '#23618E',
    checkboxBorderRadius: '4px',
    collapseBorder: '1px solid',
    collapseBorderColor: '#CCCCCC',
    collapseBorderRadius: '4px',
    collapseBoxShadow: 'none',
    collapseContentPadding: '10px',
    collapseContentBackground: '#FFFFFF',
    collapseContentFooterHoverColor: '#EEEEEE',
    collapseHeaderColor: '#000000',
    collapseHeaderBackground: '#FFFFFF',
    collapseHeaderHoverBackground: '#23618E',
    collapseHeaderHoverColor: '#FFFFFF',
    collapseHeaderOpenBackground: '#23618E',
    collapseHeaderOpenColor: '#FFFFFF',
    collapseHeaderPadding: '0px 15px',
    collapseHeaderHeight: '32px',
    collapseIconSize: '10px',
    dividerBorder: '#CCCCCC',
    dividerBackground: '#CCCCCC',
    dividerTextPrimaryBackground: '#FFFFFF',
    dividerTextColor: '#000000',
    dropPadPadding: '8px',
    dropPadHoverBackground: 'rgba(35,97,142,0.6)',
    dropPadBackground: 'rgba(35,97,142,0.2)',
    dropPadBorder: '1px dashed',
    dropPadHoverBorderColor: '#23618E',
    dropPadBorderColor: '#23618E',
    dropPadBorderRadius: '4px',
    dropPadFileLoadingBackground: '#F1F1F1',
    dropPadFileLoadingBarBackground: '#23618E',
    layoutHeaderHeight: '44px',
    layoutHeaderPaddingSmall: '0 8px',
    layoutHeaderPaddingMedium: '0 16px',
    layoutHeaderPaddingLarge: '0 24px',
    layoutFooterHeight: '60px',
    layoutSiderWidthSmall: '100px',
    layoutSiderWidthMedium: '200px',
    layoutSiderWidthLarge: '300px',
    inputBackground: '#FFFFFF',
    inputBorder: '1px solid',
    inputBorderRadius: '4px',
    inputBorderColor: '#CCCCCC',
    inputFocusBorderColor: '#23618E',
    inputColor: '#000000',
    inputDefaultHeight: '32px',
    inputDefaultFontSize: 14,
    inputDisabledBackground: '#EEEEEE',
    inputFontFamily: "'Lato', sans-serif",
    inputStatusErrorBorderColor: '#C43836',
    inputStatusWarningBorderColor: '#F58220',
    inputStatusSuccessBorderColor: '#518042',
    inputStatusLoadingBorderColor: '#23618E',
    inputSmallHeight: '24px',
    inputSmallFontSize: 12,
    inputLargeHeight: '44px',
    inputLargeFontSize: 18,
    inputPadding: '0 10px',
    inputTextAreaPadding: '10px 10px',
    inputPlaceholderColor: 'rgba(0,0,0,0.7)',
    mediaScreenSmall: '600px',
    mediaScreenMedium: '748px',
    mediaScreenLarge: '992px',
    mediaScreenVeryLarge: '1200px',
    menuItemPadding: '4px 16px',
    menuItemHoverColor: '#23618E',
    menuItemHoverBackground: '#EEEEEE',
    modalBackground: '#FFFFFF',
    modalBorder: 'none',
    modalBorderColor: 'transparent',
    modalBorderRadius: '4px',
    modalBoxShadow: '0px 4px 12px rgba(0,0,0,0.15)',
    modalContentPadding: '32px',
    modalFooterBackground: '#F1F1F1',
    modalFooterHeight: '60px',
    modalFooterPadding: '0 32px',
    modalHeaderBackground: 'transparent',
    modalHeaderColor: '#23618E',
    modalHeaderMarginTop: '24px',
    modalHeaderPadding: '0 32px',
    modalMaskBackground: 'rgba(0,0,0,0.45)',
    modalMinHeight: '200px',
    modalMinWidth: '520px',
    notificationBackground: '#FFFFFF',
    notificationBorderRadius: '4px',
    notificationMinHeight: '46px',
    notificationMinWidth: '350px',
    notificationMaxWidth: '520px',
    notificationBoxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
    notificationBoxMinHeight: '46px',
    notificationBoxMaxWidth: '520px',
    notificationBoxColor: '#000000',
    notificationBoxErrorBackground: '#FAA6A6',
    notificationBoxSuccessBackground: '#C6E3BC',
    notificationBoxWarningBackground: '#F9E6CF',
    notificationBoxInfoBackground: '#AACDE6',
    notificationBoxDefaultBackground: '#CDCDCD',
    notificationBoxBorderRadius: '4px',
    notificationBoxBoxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
    notificationBoxPadding: '8px 16px',
    panelBackground: '#FFFFFF',
    panelBorder: 'none',
    panelBorderColor: 'transparent',
    panelBorderRadius: '4px',
    panelPadding: '24px',
    panelMargin: '16px',
    panelActiveBoxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
    panelHoverBoxShadow: '0px 7px 21px rgba(0,0,0,0.07)',
    panelWithTitleBorder: '1px solid',
    panelWithTitleBorderColor: '#CCCCCC',
    radioItemLineHeight: '16px',
    radioItemMargin: '4px',
    radioItemFontSize: '14px',
    radioItemFontColor: '#000000',
    radioItemBorder: '1px solid #CCCCCC',
    radioItemCheckedMiddleBackground: '#FFFFFF',
    radioItemHoverColor: '#23618E',
    radioItemHoverBorder: '1px solid #23618E',
    radioItemCheckedBackground: '#23618E',
    radioItemCheckedBorderColor: '#23618E',
    selectControlBorderColor: '#CCCCCC',
    selectControlHoverBorderColor: '#23618E',
    selectControlBackground: '#FFFFFF',
    selectControlDisabledBackground: '#CCCCCC',
    selectControlMinHeight: '32px',
    selectOptionColor: '#000000',
    selectOptionSelectedBackground: '#EEEEEE',
    selectOptionSelectedColor: '#23618E',
    selectOptionFocusedBackground: 'rgba(111,164,203,0.15)',
    selectMenuBackground: '#FFFFFF',
    selectMultiValueLabelColor: '#0088CC',
    selectMultiValueBackground: 'rgba(111,164,203,0.15)',
    selectMultiValueRemoveHoverBackground: 'rgba(111,164,203,0.15)',
    selectSingleValueColor: '#23618E',
    tableEmptyContainerHeight: '100px',
    tableHeadBorder: '2px solid',
    tableHeadBorderColor: '#F0F4F7',
    tableHeadCellPadding: '8px 16px',
    tableHeadFontSize: '16px',
    tableHeadFontColor: '#58595B',
    tableBodyCellPadding: '8px 16px',
    tableBodyRowBorder: '1px solid',
    tableBodyRowBorderColor: '#F0F4F7',
    tableBodyFontSize: '16px',
    tableBodyFontColor: '#000000',
    tabsInkbarThickness: '4px',
    tabsInkbarColor: '#F58220',
    tabsItemPadding: '8px 16px',
    tabsItemColor: '#FFFFFF',
    toggleHeight: '16px',
    toggleWidth: '32px',
    toggleOffBackground: '#58595B',
    toggleOnBackground: '#23618E',
    toggleDisabledBackground: 'rgba(88,89,91,0.4)',
    toggleOnIconColor: '#FFFFFF',
    toggleBorderRadius: '8px',
    toggleCircleBackground: '#FFFFFF',
    toggleCircleHeight: '12px',
    toggleCircleWidth: '12px',
    toggleCircleTop: '2px',
    tooltipBorder: '1px solid',
    tooltipBorderColor: '#CCCCCC',
    tooltipBorderRadius: '4px',
    tooltipArrowSize: '10px',
    tooltipBoxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    tooltipMargin: '4px',
    typographyTitleFontFamily: 'Encode Sans, sans-serif',
    typographyTitle1Color: '#000000',
    typographyTitle1FontSize: '36px',
    typographyTitle1FontWeight: '700',
    typographyTitle1LineHeight: '1.1',
    typographyTitle1LetterSpacing: '0',
    typographyTitle2Color: '#000000',
    typographyTitle2FontSize: '24px',
    typographyTitle2FontWeight: '700',
    typographyTitle2LineHeight: '1.1',
    typographyTitle2LetterSpacing: '0',
    typographyTitle3Color: '#000000',
    typographyTitle3FontSize: '18px',
    typographyTitle3FontWeight: '700',
    typographyTitle3LineHeight: '1.1',
    typographyTitle3LetterSpacing: '0',
    typographyTitle4Color: '#000000',
    typographyTitle4FontSize: '16px',
    typographyTitle4FontWeight: '700',
    typographyTitle4LineHeight: '1.1',
    typographyTitle4LetterSpacing: '0',
    typographyTitle5Color: '#000000',
    typographyTitle5FontSize: '14px',
    typographyTitle5FontWeight: '700',
    typographyTitle5LineHeight: '1.1',
    typographyTitle5LetterSpacing: '0',
    typographyTitle6Color: '#000000',
    typographyTitle6FontSize: '12px',
    typographyTitle6FontWeight: '700',
    typographyTitle6LineHeight: '1.1',
    typographyTitle6LetterSpacing: '0',
    typographySubtitleColor: '#58595B',
    typographySubtitleFontFamily: 'Lato, sans-serif',
    typographySubtitleFontSize: '16px',
    typographySubtitleFontWeight: '700',
    typographySubtitleLineHeight: '1.5',
    typographySubtitleLetterSpacing: '0',
    typographyBodyColor: '#000000',
    typographyBodyFontFamily: 'Lato, sans-serif',
    typographyBodyFontSize: '16px',
    typographyBodyFontWeight: '400',
    typographyBodyLineHeight: '1.5',
    typographyBodyLetterSpacing: '0',
    typographyBodyDisabledColor: '#CCCCCC',
    typographyDescriptionColor: '#707F86',
    typographyDescriptionFontFamily: 'Lato, sans-serif',
    typographyDescriptionFontSize: '12px',
    typographyDescriptionFontWeight: '400',
    typographyDescriptionLineHeight: '1.5',
    typographyDescriptionLetterSpacing: '0',
    typographyLabelColor: '#000000',
    typographyLabelFontFamily: 'Lato, sans-serif',
    typographyLabelFontSize: '11px',
    typographyLabelFontWeight: '600',
    typographyLabelLineHeight: '1.5',
    typographyLabelLetterSpacing: '0',
    typographyLabelTextTransform: 'uppercase',
    colors: {
      black: '#000000',
      body: '#000000',
      bodyDisabled: '#CCCCCC',
      border: '#CCCCCC',
      blue: '#23618E',
      code: '#c41d7f',
      description: '#707F86',
      disabledBackground: '#CCCCCC',
      gray: '#58595B',
      green: '#518042',
      hoverBackground: 'rgba(238,238,238,0.3)',
      label: '#000000',
      orange: '#F58220',
      primary: '#23618E',
      primaryBackground: '#FFFFFF',
      purple: '#893391',
      quaternaryBackground: 'rgba(111,164,203,0.15)',
      red: '#C43836',
      secondary: '#58595B',
      secondaryBackground: '#F1F1F1',
      tableBorder: '#F0F4F7',
      subtitle: '#58595B',
      tag: '#0088CC',
      tertiary: '#F58220',
      tertiaryBackground: '#EEEEEE',
      title: '#000000',
      white: '#FFFFFF',
      yellow: '#EAA543',
    },
  };
};
