import {
  faAngleDown,
  faAngleUp,
  faCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  atoms,
  Grid,
  HStack,
  VStack,
  Text,
  Primitive,
} from '@rtkwlf/fenrir-react';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import { INode } from 'react-accessible-treeview/dist/TreeView/types';
import {
  getArtifactKey,
  getArtifactValue,
  getTreeData,
  Annotations,
  AnnotationArtifacts,
  isStrArray,
  isJson,
  longestKey,
} from './treeviewFunctions';
import styled from 'styled-components';
import React from 'react';
import ExpandableString from './ExpandableString';
import ExpandableObject from './ExpandableObject';

const TreeviewContainer = styled(Primitive.div)`
  ul.tree,
  ul.tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const ChildMetadataContainer = styled(Primitive.div)`
  border-left: 1px solid #696969;
  margin-left: 7px;
`;

export const artifactExclusionList = ['type', 'message', 'messages'];

export const collapsedStringMax = 250;

const FolderIcon = (isOpen: boolean) => {
  return (
    <FontAwesomeIcon
      icon={isOpen ? faAngleUp : faAngleDown}
      className={atoms({
        paddingRight: 'smedium',
      })}
      title={isOpen ? 'collapse-node' : 'expand-node'}
    />
  );
};

interface ChildDetailProps {
  artifacts: AnnotationArtifacts;
  artifactsType: string | undefined;
}

const ChildDetail: React.FC<ChildDetailProps> = ({
  artifacts,
  artifactsType,
}) => {
  const childExpandKeys: AnnotationArtifacts = [];
  return (
    <>
      {Object.keys(artifacts).map(
        (propt: string, parentArtifactsIndex: number) => {
          if (artifactExclusionList.includes(propt)) {
            return;
          }
          const valStr = getArtifactValue(
            propt,
            artifacts[propt],
            artifactsType
          );

          if (isJson(valStr)) {
            childExpandKeys.push(propt);
            return;
          }
          if (isStrArray(artifacts[propt])) {
            childExpandKeys.push(propt);
            return;
          }
          return (
            <React.Fragment
              key={`${parentArtifactsIndex}-parentArtifactsIndex`}
            >
              <Grid.Item fontWeight='bold' textTransform='capitalize'>
                {`${getArtifactKey(propt)}:`}
              </Grid.Item>
              <Grid.Item fontWeight='bold' textColor='warningDefault'>
                <ExpandableString
                  value={valStr}
                  expandedByParent={false}
                  canExpandSelf={true}
                />
              </Grid.Item>
            </React.Fragment>
          );
        }
      )}
      <>
        <VStack gap={'zero'}>
          {Object.keys(artifacts).map((propt, artifactsIndex) => {
            if (!childExpandKeys.includes(propt)) {
              return;
            }

            return (
              <Primitive.div
                key={`expandablePropParent${artifactsIndex}`}
                overflow='auto'
              >
                <ExpandableObject
                  property={propt}
                  artifact={artifacts[propt]}
                  artifactIndex={artifactsIndex}
                />
              </Primitive.div>
            );
          })}
        </VStack>
      </>
    </>
  );
};

const getDetailsType = (
  relatedArtifacts: AnnotationArtifacts
): string | undefined => {
  for (const propt in relatedArtifacts) {
    if ('type' === propt) {
      return relatedArtifacts[propt];
    }
  }
  return undefined;
};

const renderDetailsType = (value: string | undefined): JSX.Element[] => {
  const retVal = [];

  if (value) {
    retVal.push(
      <Grid.Item fontWeight='bold' key='type'>
        Type:
      </Grid.Item>
    );

    retVal.push(
      <Grid.Item fontWeight='bold' key={value} textColor='warningDefault'>
        {value}
      </Grid.Item>
    );
  }

  return retVal;
};

const renderDetailsMessage = (
  relatedArtifacts: AnnotationArtifacts
): JSX.Element => {
  let retVal = <Primitive.div key='message' />;

  for (const propt in relatedArtifacts) {
    if ('message' === propt) {
      retVal = (
        <Primitive.div key={propt} marginBottom='smedium' marginLeft='smedium'>
          {`Note: ${relatedArtifacts[propt]}`}
        </Primitive.div>
      );

      break;
    } else if (propt === 'messages') {
      const messages = Array.isArray(relatedArtifacts[propt])
        ? relatedArtifacts[propt]
        : [relatedArtifacts[propt]];
      retVal = (
        <Primitive.div key='message'>
          {messages.map((msg: string) => {
            return (
              <Primitive.div
                key={msg}
                marginBottom='smedium'
                marginLeft='smedium'
              >
                {`Note: ${msg}`}
              </Primitive.div>
            );
          })}
        </Primitive.div>
      );
      break;
    }
  }

  return retVal;
};

interface MetadataProps {
  artifactKey: string;
  artifacts: AnnotationArtifacts;
}

const Metadata = ({ artifactKey, artifacts }: MetadataProps) => {
  const type = getDetailsType(artifacts);
  return (
    <Primitive.div key={`${artifactKey}-parent`}>
      <Grid.Root
        key={artifactKey}
        backgroundColor='accentGreySecondary'
        columns={`${longestKey(artifacts, artifactExclusionList) + 1}ch 1fr`}
        marginLeft='smedium'
        padding='smedium'
        marginBottom='smedium'
        data-testid='node-metadata'
        overflow='auto'
      >
        {renderDetailsType(type)}
        <ChildDetail artifacts={artifacts} artifactsType={type} />
      </Grid.Root>
      {renderDetailsMessage(artifacts)}
    </Primitive.div>
  );
};

const ChildMetadata = ({
  element,
}: {
  element: INode<AnnotationArtifacts>;
}) => {
  const metadata = element.metadata || {};
  if (Object.keys(metadata).length < 1) {
    return <Primitive.div />;
  }

  return (
    <Primitive.div key='sub-detail-metadata'>
      {Object.entries(metadata).map(([key, value], index) => (
        <Metadata
          key={`${index}-sub-detail-metadata`}
          artifactKey={key}
          artifacts={value}
        />
      ))}
    </Primitive.div>
  );
};

const TreeNodeHeader = ({
  hasChildren,
  isExpanded,
  elementHasMetaData,
  name,
}: {
  hasChildren: boolean;
  isExpanded: boolean;
  elementHasMetaData: boolean;
  name: string;
}) => {
  return (
    <HStack gap='xsmall' cursor={hasChildren ? 'pointer' : 'auto'}>
      {hasChildren && FolderIcon(isExpanded)}
      {!hasChildren && !elementHasMetaData && (
        <FontAwesomeIcon
          icon={faCircle}
          className={atoms({
            textColor: 'accentGreyDefault',
          })}
          data-testid='no-metadata-node'
        />
      )}
      {elementHasMetaData && (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={atoms({
            textColor: 'accentOrangeDefault',
          })}
          title='vulnerability-check'
        />
      )}
      <Text styledAs='body1'>{name}</Text>
    </HStack>
  );
};

export const AgentTreeView = ({
  annotations,
}: {
  annotations: Annotations;
}) => {
  const folder = getTreeData(annotations);
  const data = flattenTree(folder);

  return (
    <Primitive.div
      borderWidth='default'
      borderColor='accentGreyDefault'
      borderRadius='default'
      backgroundColor='widgetDefault'
      width='full'
    >
      <Text styledAs='body2'>{annotations.title}</Text>
      <TreeviewContainer>
        <VStack>
          <Text styledAs='body1' marginTop='medium'>
            {annotations.finding}
          </Text>
          <TreeView
            data={data}
            aria-label='Agent vulnerability tree'
            defaultExpandedIds={Array.from(
              { length: data.length },
              (_, i) => i
            )}
            nodeRenderer={({ element, isExpanded, getNodeProps, level }) => {
              const hasChildren = element.children.length > 0;
              let elementHasMetaData = false;
              if (element.metadata) {
                elementHasMetaData = Object.keys(element?.metadata).length > 0;
              }

              return (
                <Primitive.div
                  {...getNodeProps()}
                  style={{
                    paddingLeft: 20 * (level - 1),
                  }}
                >
                  <TreeNodeHeader
                    hasChildren={hasChildren}
                    isExpanded={isExpanded}
                    elementHasMetaData={elementHasMetaData}
                    name={element.name}
                  />
                  {element.children && (
                    <ChildMetadataContainer marginTop='smedium'>
                      <ChildMetadata element={element} />
                    </ChildMetadataContainer>
                  )}
                </Primitive.div>
              );
            }}
          />
        </VStack>
      </TreeviewContainer>
    </Primitive.div>
  );
};

export default AgentTreeView;
