import React, { useCallback, useEffect, useState } from 'react';

import { ColumnProps, SortState } from '../../../Reusables/table/Table';
import { CSVLink } from 'react-csv';
import PanelTitle from '../../../Reusables/PanelTitle';
import AssetTable from '../AssetTable';

import { sortData } from '../utils';

import styled from 'styled-components';
import { EXPORT_CSV_TOOLTIP } from '../../../Constants/Common';
import { DownloadCSVIcon } from '../../../Reusables/DownloadCSVIcon';
import { assetProfileHistoryType, getAssetHistoryProfileData } from './utils';
import { iProfileHistory } from '../../../Global/assetReducer';
import { useAssetProfileData } from '../useAssetsQueries';

const WrapButtonStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const csvHeaders = [
  { label: 'IP', key: 'ip' },
  { label: 'Device Name', key: 'devicename' },
  { label: 'OS', key: 'os' },
  { label: 'MAC', key: 'mac' },
  { label: 'When', key: 'when' },
  { label: 'Type', key: 'type' },
  { label: 'Event', key: 'mappingeffects' },
  { label: 'Raw Log', key: 'raw' },
];

const columns: ColumnProps<assetProfileHistoryType>[] = [
  { key: 'ip', dataIndex: 'ip', title: 'IP', width: 10, sortable: true },
  {
    key: 'devicename',
    dataIndex: 'devicename',
    title: 'Device Name',
    width: 10,
    sortable: true,
  },
  { key: 'os', dataIndex: 'os', title: 'OS', width: 5, sortable: true },
  { key: 'mac', dataIndex: 'mac', title: 'MAC', width: 5, sortable: true },
  { key: 'when', dataIndex: 'when', title: 'When', width: 10, sortable: true },
  { key: 'type', dataIndex: 'type', title: 'Type', width: 5, sortable: true },
  {
    key: 'mappingeffects',
    dataIndex: 'mappingeffects',
    title: 'Event',
    width: 15,
    sortable: true,
  },
  {
    key: 'raw',
    dataIndex: 'raw',
    title: 'Raw Log',
    width: 15,
    sortable: true,
  },
];

type Props = {
  assetId: string;
  initialCustomerId: string;
  servicesHost: string;
};

const AssetProfileHistory = ({
  assetId,
  initialCustomerId,
  servicesHost,
}: Props) => {
  const [tableData, setTableData] = useState<Array<assetProfileHistoryType>>(
    []
  );

  const { isLoading, data } = useAssetProfileData(
    servicesHost,
    initialCustomerId,
    assetId,
    useCallback((data: iProfileHistory[]) => {
      return getAssetHistoryProfileData(data);
    }, [])
  );

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const handleSort = useCallback(
    (column: string, state: SortState) => {
      const newData = sortData(
        state === 'dsc',
        column as keyof assetProfileHistoryType,
        [...tableData]
      );

      setTableData(newData);
    },
    [tableData]
  );

  return (
    <>
      <div className='row' data-testid='asset-profile-history-header'>
        <div className='col-md-6'>
          <PanelTitle title={'Asset Profile History'} />
        </div>
        <div className='col-md-6'>
          <WrapButtonStyle>
            <CSVLink
              data={tableData}
              headers={csvHeaders}
              filename={'AssetProfileHistory.csv'}
              onClick={
                !tableData.length
                  ? () => {
                      return false;
                    }
                  : undefined
              }
            >
              <DownloadCSVIcon
                disabled={!tableData.length}
                tooltipText={EXPORT_CSV_TOOLTIP}
              />
            </CSVLink>
          </WrapButtonStyle>
        </div>
      </div>
      <div className='row' data-testid='asset-profile-history-container'>
        <div className='col-xs-12'>
          <AssetTable
            data={tableData}
            columns={columns}
            handleSort={handleSort}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AssetProfileHistory;
