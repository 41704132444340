import { HStack, Primitive, Text, Tooltip } from '@rtkwlf/fenrir-react';
import { CopyIcon } from '../../../Reusables/CopyIcon';

type InfoRowWithCopyProps = {
  field: { label: string; value: string };
};

const InfoRowWithCopy = ({ field }: InfoRowWithCopyProps) => {
  return (
    <HStack xAlign='between' width='full'>
      <Primitive.div maxWidth='50%'>
        <Text userSelect='all' styledAs='body2'>
          {field.label}
        </Text>
      </Primitive.div>
      <HStack maxWidth='50%' xAlign='between'>
        <Primitive.div maxWidth='80%' alignSelf='center'>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <Text textColor='infoDefault' truncate>
                {field.value}
              </Text>
            </Tooltip.Trigger>
            <Tooltip.Content side='top'>
              <Tooltip.Text>{field.value}</Tooltip.Text>
            </Tooltip.Content>
          </Tooltip.Root>
        </Primitive.div>
        <CopyIcon field={field} />
      </HStack>
    </HStack>
  );
};

export default InfoRowWithCopy;
