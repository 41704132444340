import React from 'react';
import { RowTile } from '../../Reusables/RowTile';
import { riskScorePriority } from '../Assets/utils';
import { SEVERITY_COLOR } from '../../Constants/Styles';
import { BLUE } from '../../Constants/Styles';
import { HStack } from '@rtkwlf/fenrir-react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface GaugeProps {
  gaugeId: string;
  title: string;
  tooltip: string;
  scale: number;
  fractionDigits: number;
  value?: number;
}

const getRiskScoreColor = (riskScore: number) => {
  if (riskScore >= riskScorePriority.critical) {
    return SEVERITY_COLOR.critical;
  }
  if (riskScore >= riskScorePriority.high) {
    return SEVERITY_COLOR.high;
  }
  if (riskScore >= riskScorePriority.medium) {
    return SEVERITY_COLOR.medium;
  }

  return SEVERITY_COLOR.low;
};

const Gauge = ({
  gaugeId,
  title,
  tooltip,
  value,
  scale,
  fractionDigits,
}: GaugeProps) => (
  <div className='col-md-4'>
    <RowTile id={`${gaugeId}-title`} title={title} description={tooltip}>
      <HStack xAlign='center' paddingY='xsmall'>
        <div style={{ maxWidth: '110px' }} id={`${gaugeId}-value`}>
          <CircularProgressbar
            value={value ?? 0}
            maxValue={scale}
            text={value?.toFixed(fractionDigits)}
            strokeWidth={18}
            styles={buildStyles({
              strokeLinecap: 'butt',
              pathColor: BLUE.tertiary,
              textColor: getRiskScoreColor(value ?? 0),
              textSize: '1rem',
            })}
          />
        </div>
      </HStack>
    </RowTile>
  </div>
);

export default Gauge;
