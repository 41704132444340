import { useQuery } from '@tanstack/react-query';
import riskAPI from '../../../utils/riskAPI';

export interface iRemediationDetails {
  details: string;
}

type SelectFn = ((data: string[]) => string[]) | undefined;

export const useRemediationDetails = (
  customerId: string,
  riskId: string,
  select?: SelectFn
) => {
  return useQuery({
    queryKey: [`getRemediationDetails-${riskId}`],
    queryFn: async () => {
      const apiUrl = `/rootsecure/v1/customers/${customerId}/risks-remediation/${riskId}`;
      const response = await riskAPI.get(apiUrl);
      return response.data;
    },
    retry: false,
    enabled: !!riskId,
    select,
  });
};
