import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse, AxiosError, HttpStatusCode } from 'axios';

import { iGetState } from '../configureStore';
import { routesMap } from '../routesMap';
import riskAPI from '../utils/riskAPI';
import {
  AssetActionTypes,
  iAssetCategories,
  iAssetSeverity,
  iAssetAction,
  iRiskFieldAction,
  iAssetSeverityType,
  iAsset,
} from './assetReducer';

import { AssetsParams } from '../types/assets';
import { AssetUpdateFieldsType } from '../Pages/Assets/utils';
import _ from 'lodash';

type iProcessingAction = {
  type: AssetActionTypes.PROCESSING_ASSETS;
  isProcessing: boolean;
};

type iProcessingAssetsNodeAction = {
  type: AssetActionTypes.PROCESSING_ALL_ASSETS_NODE;
  isProcessing: boolean;
};

type iSetAssetsDataAction = {
  type: AssetActionTypes.LOAD_ASSETS;
  payload: {
    data: Array<iAsset>;
    timestamp: number;
  };
};

type iSetAssetCategoriesAction = {
  type: AssetActionTypes.LOAD_ASSETS_CATEGORIES;
  payload: {
    categories: Array<iAssetCategories>;
  };
};

type iSetAssetSeveritiesAction = {
  type: AssetActionTypes.LOAD_ASSET_DATA;
  payload: {
    severities: Array<iAssetSeverity>;
  };
};

type iSetAssetActionsDataAction = {
  type: AssetActionTypes.LOAD_ASSET_ACTIONS_DATA;
  payload: {
    actions: Array<iAssetAction>;
  };
};

type iSetRiskActionsDataAction = {
  type: AssetActionTypes.LOAD_RISK_ACTIONS_DATA;
  payload: {
    actions: Array<iRiskFieldAction>;
  };
};

type iSetAssetSeverityDataAction = {
  type: AssetActionTypes.LOAD_ASSET_SEVERITY_DATA;
  payload: {
    severity: Array<iAssetSeverityType>;
  };
};

/**
 * Collection of all asset actions with their payload schema
 */
export type iAssetsAction =
  | iProcessingAction
  | iProcessingAssetsNodeAction
  | iSetAssetsDataAction
  | iSetAssetCategoriesAction
  | iSetAssetSeveritiesAction
  | iSetAssetActionsDataAction
  | iSetRiskActionsDataAction
  | iSetAssetSeverityDataAction;

// ----- Action Creators -----

/**
 * A type checking wrapper to create (plain object) case actions
 */
const CASE_ACTIONS = {
  LOAD_ASSETS: (data: iAsset[], timestamp: number): iSetAssetsDataAction => ({
    type: AssetActionTypes.LOAD_ASSETS,
    payload: { data, timestamp },
  }),
  LOAD_ASSETS_CATEGORIES: (
    categories: iAssetCategories[]
  ): iSetAssetCategoriesAction => ({
    type: AssetActionTypes.LOAD_ASSETS_CATEGORIES,
    payload: { categories },
  }),
  LOAD_ASSET_DATA: (
    severities: iAssetSeverity[]
  ): iSetAssetSeveritiesAction => ({
    type: AssetActionTypes.LOAD_ASSET_DATA,
    payload: { severities },
  }),
  LOAD_ASSET_ACTIONS_DATA: (
    actions: iAssetAction[]
  ): iSetAssetActionsDataAction => ({
    type: AssetActionTypes.LOAD_ASSET_ACTIONS_DATA,
    payload: { actions },
  }),
  LOAD_RISK_ACTIONS_DATA: (
    actions: iRiskFieldAction[]
  ): iSetRiskActionsDataAction => ({
    type: AssetActionTypes.LOAD_RISK_ACTIONS_DATA,
    payload: { actions },
  }),
  LOAD_ASSET_SEVERITY_DATA: (
    severity: iAssetSeverityType[]
  ): iSetAssetSeverityDataAction => ({
    type: AssetActionTypes.LOAD_ASSET_SEVERITY_DATA,
    payload: { severity },
  }),
};

// ----- Thunk Action Creators -----

export const getAssetsThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();
    riskAPI
      .get(
        `/rootsecure/v1/customers/${customer.currentCustomer.id}/assetOverviewByCategory`
      )
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.LOAD_ASSETS_CATEGORIES(response.data.categories));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Assets', error.code as string)
      );
  };

export const getAssetDataThunk =
  (params: AssetsParams = {}) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const timestamp = Date.now();
    const { customer } = getState();

    dispatch({
      type: AssetActionTypes.PROCESSING_ALL_ASSETS_NODE,
      isProcessing: true,
    });

    riskAPI
      .get(`/rootsecure/v1/customers/${customer.currentCustomer.id}/assets`, {
        params,
      })
      .then((response: AxiosResponse) => {
        if (HttpStatusCode.PartialContent === response.status) {
          toastr.warning(
            'There was a problem loading assets from one or more sources. Refresh the page. If the issue persists, contact your CST.',
            ''
          );
        }
        dispatch(CASE_ACTIONS.LOAD_ASSET_DATA(response.data.meta.severities));
        dispatch(CASE_ACTIONS.LOAD_ASSETS(response.data.data, timestamp));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Asset Data', error.code as string)
      )
      .finally(() => {
        dispatch({
          type: AssetActionTypes.PROCESSING_ALL_ASSETS_NODE,
          isProcessing: false,
        });
      });
  };

export const getAssetActionsDataThunk =
  (params?: { [key: string]: string | number | boolean }) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();

    dispatch({ type: AssetActionTypes.PROCESSING_ASSETS, isProcessing: true });

    return riskAPI
      .get(
        `/rootsecure/v1/customers/${customer.currentCustomer.id}/asset-action-class-overview`,
        { params }
      )
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.LOAD_ASSET_ACTIONS_DATA(response.data.data));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Asset Data', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: AssetActionTypes.PROCESSING_ASSETS,
          isProcessing: false,
        })
      );
  };

type RiskActionType = {
  groupBy:
    | 'source'
    | 'status'
    | 'state'
    | 'issueCategory'
    | 'assetID'
    | 'severity'
    | 'actionClass'
    | 'issueAction';
};

export const getRiskFieldSummaries =
  (params: RiskActionType) => (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();

    return riskAPI
      .get(
        `/rootsecure/rida/v1/customers/${customer.currentCustomer.id}/riskFieldSummaries`,
        { params }
      )
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.LOAD_RISK_ACTIONS_DATA(response.data.data));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Action Class Data', error.code as string)
      );
  };

export const getAssetSeverityDataThunk =
  (params?: { [key: string]: string | number | boolean }) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const { customer } = getState();

    dispatch({ type: AssetActionTypes.PROCESSING_ASSETS, isProcessing: true });

    return riskAPI
      .get(
        `/rootsecure/v1/customers/${customer.currentCustomer.id}/assetCategorySeverityOverview`,
        { params }
      )
      .then((response: AxiosResponse) => {
        dispatch(CASE_ACTIONS.LOAD_ASSET_SEVERITY_DATA(response.data.data));
      })
      .catch((error: AxiosError) =>
        toastr.error('Failed to fetch Asset Data', error.code as string)
      )
      .finally(() =>
        dispatch({
          type: AssetActionTypes.PROCESSING_ASSETS,
          isProcessing: false,
        })
      );
  };

export const setRefreshAssetsThunk =
  () => (dispatch: Dispatch, getState: iGetState) => {
    const { location } = getState();
    const updateThunk: any = routesMap[location.type].thunk;
    if (updateThunk) {
      dispatch(updateThunk);
    }
  };

export const deleteAssetThunk =
  (deviceId: string, onSingleAssetDeleteSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const { customer, location } = getState();

    const apiURL = `/rootsecure/v1/dashboard/customers/${customer.currentCustomer.id}/assets/${deviceId}`;
    const requestObj = JSON.stringify({ active: false });

    try {
      const response = await riskAPI.patch(apiURL, requestObj);

      if (response.status === 200) {
        toastr.success('Successfully removed the asset', '');
        if (onSingleAssetDeleteSuccess) {
          onSingleAssetDeleteSuccess();
        }
        const updateThunk: any = routesMap[location.type].thunk;
        if (updateThunk) {
          dispatch(updateThunk);
        }
      }
    } catch (error) {
      toastr.error(
        'Delete failed',
        'The device has been seen in the last 48 hours or the device has unresolved risks.'
      );
    }
  };

export const bulkUpdateAssets =
  (
    assetObj: AssetUpdateFieldsType[],
    servicesHost: string,
    customerId: string,
    clearFields: () => void
  ) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    try {
      const updateURL: string = `${servicesHost}/rootsecure/v1/customers/${customerId}/assets`;
      const assetsJson = JSON.stringify(assetObj);

      const response = await riskAPI.patch(updateURL, assetsJson);
      if (response.status === 200) {
        toastr.success('Successfully updated the selected asset', '');

        const { location } = getState();
        const updateThunk: any = routesMap[location.type].thunk;
        if (updateThunk) {
          dispatch(updateThunk);
        }

        clearFields();
      }
    } catch (error) {
      toastr.error(_.get(error, 'code', ''), 'Failed to update selected asset');
    }
  };
