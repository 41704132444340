import Heatmap, { HeatMapHostEntry } from './Heatmap';
import React from 'react';
import { Grid } from 'react-loader-spinner';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { Button } from '@rtkwlf/fenrir-react';
import {
  getColorForSeverity,
  getSeverityForCVSS,
} from '../../../utils/formatters';
import { push } from 'redux-first-router';
import { exportCSVFile } from '../../../utils';
import { RisksParams, Severity } from '../../../types/risks';
import { RowTile } from '../../../Reusables/RowTile';
import { BLUE } from '../../../Constants/Styles';
import { RISK_STATES, STATUS } from '../../../Constants/Risks';
import riskAPI from '../../../utils/riskAPI';
import { iRisk } from '../../../Global/riskReducer';
import { useSelector } from 'react-redux';
import { customerSelectors } from '../../../Global/customerReducer';

const ROW_FETCH_LIMIT = 50000;

const HeatMapContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
`;

const HeatMapTooltipContainer = styled.div`
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 12.5px);
    left: 100%;
    z-index: 1;
    display: block;
    border-width: 12.5px 0 12.5px 15px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.6);
    border-style: solid;
  }

  display: block;
  height: 120px;
  width: 200px;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: #fff;
  padding: 5px;
  pointer-events: none;
  transform: translate(calc(-100% - 15px), -50%);

  h1,
  h2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 190px;
    margin: 0;
    font-size: 14px;
  }

  h2 {
    font-size: 12px;
    padding-bottom: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid #999;
  }
`;

const HeatMapTooltipBody = styled.div`
  font-size: 12px;
  font-weight: normal;

  table {
    width: 100%;
    margin-left: 0.5em;

    td {
      padding: 0 1em;
      white-space: nowrap;
      text-transform: capitalize;

      span {
        line-height: 1em;
      }
    }
  }
`;

// This should roughly match the `Unresolved Risks` redirect link in src/Global/TopNav/TopTiles.tsx
// Differences include fromLevel, limit and fields
const HEATMAP_API_QUERY_PARAMS: RisksParams = {
  fromLevel: 1,
  fields: ['host', 'issueLevel', 'attributes'],
  limit: ROW_FETCH_LIMIT,
  state: [
    RISK_STATES.OPEN,
    RISK_STATES.FIXED,
    RISK_STATES.ACKNOWLEDGED,
    RISK_STATES.IN_PROGRESS,
    RISK_STATES.FAILED_VALIDATION,
  ],
  status: [STATUS.ACTIVE, STATUS.INACTIVE],
};

type HeatmapAPIRisk = Pick<iRisk, 'host' | 'issueLevel' | 'attributes'>;

type HeatmapAPIResponse = {
  data: HeatmapAPIRisk[];
};

const getURL = (customerId: string) =>
  `rootsecure/rida/v1/customers/${customerId}/risks`;

const fetchNetHealth = (customerId: string): Promise<HeatmapAPIRisk[]> => {
  return riskAPI
    .get<HeatmapAPIResponse>(getURL(customerId), {
      params: HEATMAP_API_QUERY_PARAMS,
    })
    .then((response) => response.data.data);
};

const getHeatmapData = (rawData: HeatmapAPIRisk[]): HeatMapHostEntry[] => {
  const mapped: HeatMapHostEntry[] = [];
  rawData.forEach((entry) => {
    const name = entry.attributes.hostDisplayName || entry.host;
    const value = entry.issueLevel;
    // Check if entry exists in our array already, if so create/add child bucket
    const existing = mapped.find((existing) => existing.name === name);
    if (existing) {
      existing.children.push({ value });
    } else {
      mapped.push({
        name,
        children: [{ value }],
      });
    }
  });
  return mapped;

  // Optionally we could change the sorting to some type of weighted score
  // like sum of squared CVSS:
  // return mapped.sort((a: HeatMapHostEntry, b: HeatMapHostEntry): number => {
  //   return b.children.reduce((previousValue, currentValue) => previousValue + (currentValue.value ** 2), 0) -
  //     a.children.reduce((previousValue, currentValue) => previousValue + (currentValue.value ** 2), 0));
  // }
};

const getCsvData = (rawData: HeatmapAPIRisk[]) => {
  const header = `"Hostname","CVSS","First Identified","Status","Owner","Due Date","CVEs"`;
  const body = rawData
    .map((entry) => {
      const name = entry.attributes.hostDisplayName || entry.host;
      const issueLevel = entry.issueLevel.toFixed(1);

      const {
        firstIdentified,
        status,
        assignment,
        cveList = [],
      } = entry.attributes;
      const owner = assignment?.owner ?? '';
      const dueDate = assignment?.dueDate ?? '';

      return `"${name}","${issueLevel}","${firstIdentified}","${status}","${owner}","${dueDate}","${cveList.join(
        ','
      )}"`;
    })
    .join('\r\n');

  return `${header}\r\n${body}`;
};

const NetworkHealth = () => {
  const customerId = useSelector(customerSelectors.getCustomerId);

  const { isLoading, isError, data } = useQuery({
    queryKey: ['networkHealth', customerId],
    queryFn: () => fetchNetHealth(customerId),
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

  const heatmapData: HeatMapHostEntry[] = React.useMemo(
    () => (data ? getHeatmapData(data) : []),
    [data]
  );

  const generateCSV = React.useCallback(() => {
    const csvData = getCsvData(data ?? []);
    exportCSVFile(csvData, 'network-health');
  }, [data]);

  const [tooltip, setTooltip] = React.useState<React.ReactNode>(null);
  const handleMouseEnter = React.useCallback(
    (entry: HeatMapHostEntry, x: number, y: number) => {
      const severities: Severity[] = ['critical', 'high', 'medium', 'low'];
      const stats: Record<Severity, number> = {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        none: 0,
      };
      const name = entry.name.split(' ', 2);
      entry.children.forEach((risk) => stats[getSeverityForCVSS(risk.value)]++);
      setTooltip(
        <HeatMapTooltipContainer style={{ left: x, top: y }}>
          <h1>{name[0]}</h1>
          <h2>{name[1] || <>&nbsp;</>}</h2>
          <HeatMapTooltipBody>
            <table>
              <tbody>
                {severities.map((severity, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        style={{
                          display: 'inline-block',
                          marginRight: 5,
                          borderRadius: '10px',
                          width: 10,
                          height: 10,
                          backgroundColor: getColorForSeverity(severity),
                        }}
                      />{' '}
                      {severity}
                    </td>
                    <td>{stats[severity]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </HeatMapTooltipBody>
        </HeatMapTooltipContainer>
      );
    },
    [setTooltip]
  );

  const handleMouseLeave = React.useCallback(() => {
    setTooltip(null);
  }, []);

  const handleClick = React.useCallback((entry: HeatMapHostEntry) => {
    push(`/risks?search=${encodeURIComponent(entry.name)}`);
  }, []);

  return (
    <RowTile
      id='asset-health'
      title={'Asset Health'}
      description={
        'A heatmap of the assets in your network, based on risk score, where low is a better score.'
      }
      buttons={
        <Button
          variant='secondary'
          id='export-network-health-to-csv'
          isLoading={isLoading}
          onClick={generateCSV}
          iconLeft={<i className={'fa fa-download'} />}
        >
          CSV
        </Button>
      }
    >
      {isLoading ? (
        <div
          style={{
            margin: '75px 0px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid color={BLUE.tertiary} height={150} width={150} />
        </div>
      ) : isError ? (
        <div>Error retrieving network health heatmap</div>
      ) : (
        <HeatMapContainer>
          {tooltip}
          <Heatmap
            data={heatmapData}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </HeatMapContainer>
      )}
    </RowTile>
  );
};

export default NetworkHealth;
