import { escapeVal, exportCSVFile } from '../../../utils';
import { parseRawData } from '../../../utils/parsers';

function onAssetClassHealthCSVbtnClick(assetClassRawDataArr) {
  if (!document.getElementById('export-asset-scatter-to-csv').disabled) {
    var formattedDataArr = [];
    assetClassRawDataArr.forEach((item) => {
      var arrLength = item.data.length;
      for (var i = 0; i < arrLength; i++) {
        formattedDataArr.push({
          name: escapeVal(item.name),
          numbOfVulnerabilities: escapeVal(item.data[i][0]),
          riskScore: escapeVal(item.data[i][1]),
          ipAddr: escapeVal(item.data[i][2]),
        });
      }
    });
    var headers = {
      name: 'Name',
      numbOfVulnerabilities: 'Number of Vulnerabilities',
      riskScore: 'Risk Score',
      ipAddr: escapeVal('IP (DNS, Netbios)'),
    };
    formattedDataArr.unshift(headers);
    var jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function initAssetClassHealthCSV(assetClassRawDataArr) {
  var fileTitle = 'asset-class-health';
  var csv = onAssetClassHealthCSVbtnClick(assetClassRawDataArr);
  return exportCSVFile(csv, fileTitle);
}
