import moment from 'moment';
import { iAssetCatalogDataType } from '../../../Global/assetReducer';

/**
 * Identity if asset is an agent last seen within the last 24 hours.
 */
export const isFreshAgent = (asset: iAssetCatalogDataType) => {
  if (asset.source === 'agent') {
    if (asset.lastSeen !== 'Unknown') {
      const lastSeen = moment(asset.lastSeen);
      return moment.utc().diff(lastSeen, 'days', true) <= 1;
    }
  }
  return false;
};

export const isEVAorFreshAgent = (a: iAssetCatalogDataType) =>
  a.source === 'reach' || isFreshAgent(a);
