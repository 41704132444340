import { Fragment } from 'react';
import { AscContainer } from './AttackSurfaceCoverageContainer';
import React from 'react';
import { AttackSurfaceCoverageCtx } from './Context/AscContext';

const AttackSurfaceCoverage: React.FC = () => {
  return (
    <AttackSurfaceCoverageCtx>
      <Fragment>
        <AscContainer
          description={
            'A page detailing the coverage of scanners in the customers network.'
          }
        ></AscContainer>
      </Fragment>
    </AttackSurfaceCoverageCtx>
  );
};

export default AttackSurfaceCoverage;
