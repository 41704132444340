/// <reference types="styled-components/cssprop" />
import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import type {} from 'styled-components/cssprop';

// Components
import Pages from './Pages';
import Footer from './Global/Footer';
import Sidebar from './Global/Sidebar';
import TopNav from './Global/TopNav';
import InfoPaneController from './InfoPanes/InfoPaneController';
import { ToastProvider, useTheme } from '@rtkwlf/fenrir-react';
import { FeatureFlagsView } from './Reusables/FeatureFlagsView';
import { FEATURE_FLAGS_KEYS } from '../public/js/features';

interface AppProps {
  page: string;
  infoPane: boolean;
}

export const App = ({ page, infoPane }: AppProps) => {
  const theme = useTheme();
  /**
   * Since we only support light mode for now we are going to force the theme mode to light
   */
  React.useEffect(() => {
    theme?.setThemeMode('light');
  }, [theme]);

  React.useEffect(() => {
    document.title = 'Arctic Wolf';
  }, []);

  const [isSmallIcon, setIsSmallIcon] = useState(false);
  // @ts-ignore
  const MainView: FunctionComponent = Pages[page];
  return (
    <ToastProvider>
      {process.env.NODE_ENV === 'development' ||
      window.localStorage.getItem('ff_flag_toggle_widget') === 'true' ? (
        <FeatureFlagsView
          flags={Object.keys(FEATURE_FLAGS_KEYS).map((s) => ({
            key: s,
            label: s,
          }))}
        />
      ) : null}
      <div className='container body' style={{ minWidth: 815 }}>
        <div className='main_container'>
          <Sidebar isSmallIcon={isSmallIcon} setIsSmallIcon={setIsSmallIcon} />
          <div style={{ background: '#f7f7f7' }}>
            <TopNav isSmallIcon={isSmallIcon} setIsSmallIcon={setIsSmallIcon} />
            <div
              className='right_col cerberus-col-margin-left'
              role='main'
              style={{ minHeight: '87vh' }}
            >
              <MainView />
            </div>
            {infoPane && <InfoPaneController />}
            <Footer />
          </div>
        </div>
      </div>
      <div>test deploy 22</div>
    </ToastProvider>
  );
};

const mapStateToProps = ({ page, infoPane }: any) => ({
  page,
  infoPane: infoPane.isOpen,
});

export default connect(mapStateToProps)(App);
