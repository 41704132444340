// ----- Action Constants -----
import { iAwnCustomer } from './customerReducer';
import { Dispatch, iGetState } from '../configureStore';
import { getServicesHost } from './configReducer';
import { getCustomerPod } from '../utils/auth/authorization';

export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_SERVICE_HOST = 'SET_SERVICE_HOST';

// ----- Action Creators -----

// ----- Thunk Action Creators -----
export const setCustomerServiceHost =
  ({ id }: iAwnCustomer) =>
  (dispatch: Dispatch, getState: iGetState) => {
    const currentHost = getState().config.environment.servicesHost;

    const pod = getCustomerPod(id);
    localStorage.setItem('apiPod', pod);

    const host = getServicesHost();

    if (currentHost !== host) {
      dispatch({
        type: SET_SERVICE_HOST,
        payload: {
          host,
        },
      });
    }
  };
