import { toastr } from 'react-redux-toastr';
import { CRITICALITY_MAP, CRITICALITY_VALUE_MAP } from '../../Constants/Common';
import {
  iAssetsState,
  iAssetAction,
  iAssetSeverity,
  iAsset,
  iAssetCatalogDataType,
  DataWithDuplicateAssets,
} from '../../Global/assetReducer';
import riskAPI from '../../utils/riskAPI';
import moment from 'moment-timezone';
import { TagType } from '../../Global/assetTags';
import _ from 'lodash';
import Papa, { ParseResult } from 'papaparse';

export type AssetUpdateFieldsType = {
  device_id: string;
  category: string;
  deviceName?: string;
};

export type AssetUpdateFieldsTypeV3 = {
  device_id: string;
  category: string;
  deviceName?: string;
  tags: number[];
  criticality: number;
};

export type ParsedAssetFields = {
  device_id: string;
  category: string;
  devicename: string;
  tags: string;
  criticality: string;
};

export type assetsUpdatedFieldsType = {
  id: string;
  oldCategory: string;
  newCategory: string;
  oldDeviceName: string;
  newDeviceName: string;
};

export type assetsUpdatedFieldsType_V2 = {
  id: string;
  oldCategory: string;
  newCategory: string;
  oldDeviceName: string;
  newDeviceName: string;
  oldTags: string;
  newTags: string;
  oldCriticalityString: string;
  newCriticalityString: string;
};

export const riskScorePriority = {
  critical: 9,
  high: 7,
  medium: 4,
};

const UNKNOWN_VALUE = 'unknown';

// deepcode ignore HardcodedNonCryptoSecret: This is not actually a secret key, just a key used for localstorage value
export const HIDDEN_COLUMN_KEYS_STORAGE_KEY = 'assets_table_hidden_columns';

// input: "DNS:<name>|NBName:<name>"
export const parseDeviceName = (name: string) => {
  if (name.includes('DNS:')) {
    const deviceNameArr = name.split('|');
    const dns = deviceNameArr[0].substr(4).trim();
    const nb = deviceNameArr[1].substr(7).trim();

    if (dns !== '') {
      if (nb !== '') {
        return dns + '(' + nb + ')';
      } else {
        return dns;
      }
    } else if (nb !== '') {
      return nb;
    } else {
      return '';
    }
  } else {
    return name;
  }
};

// findLastSeen returns the most recent time from the params.
export const findLastSeen = (
  deviceNameTime: number,
  macTime: number,
  osTime: number,
  lastScan: number
) => {
  const maxDate = Math.max(deviceNameTime, macTime, osTime, lastScan);

  return moment.unix(maxDate).utc().format();
};

export const getFormattedLastScannedTime = (lastScannedTime: number) => {
  if (lastScannedTime === -1) {
    return 'No Recent Scans';
  } else if (lastScannedTime === 0) {
    return 'Unsupported';
  }

  return moment.unix(lastScannedTime).utc().format();
};

const getSourceName = (source: string | undefined) => {
  if (source === 'sensor') {
    return 'IVA';
  } else if (source === 'agent') {
    return 'Agent';
  } else if (source === 'reach') {
    return 'EVA';
  } else {
    return 'Unknown';
  }
};

export const getSelectedValues = (
  filters: number | Array<number>,
  options: Array<{ value: string; label: string }>
) => {
  return options.filter((option) => {
    if (Array.isArray(filters)) {
      return filters?.includes(Number(option.value));
    } else {
      return filters === Number(option.value);
    }
  });
};

export const getAssetCatalogData = (assetsData: iAssetsState) => {
  const catalogData: Array<iAssetCatalogDataType> = [];
  if (assetsData?.allAssets) {
    if (assetsData.allAssets.length === 0) return catalogData;

    const allAssets: iAsset[] = assetsData.allAssets;

    allAssets.forEach((assetRow) => {
      const isUnknownLastseen =
        assetRow.lastVerifiedDeviceName === 0 &&
        assetRow.lastVerifiedMac === 0 &&
        assetRow.lastVerifiedOs === 0 &&
        (assetRow.lastScannedTime === 0 || assetRow.lastScannedTime === -1);

      const formattedLastSeen = findLastSeen(
        assetRow.lastVerifiedDeviceName,
        assetRow.lastVerifiedMac,
        assetRow.lastVerifiedOs,
        assetRow.lastScannedTime
      );

      const lastSeen = isUnknownLastseen ? 'Unknown' : formattedLastSeen;

      const criticality = assetRow.criticality ?? 0;

      const arrAsset: iAssetCatalogDataType = {
        id: assetRow.id ?? '',
        ip: assetRow.ip ?? '',
        source: assetRow.source ?? '',
        deploymentID: assetRow.deploymentID ?? '',
        deviceName:
          assetRow.userDeviceName !== ''
            ? assetRow.userDeviceName
            : parseDeviceName(assetRow.deviceName),
        mac: assetRow.mac ?? '',
        risk: assetRow.risk ?? 0,
        criticality,
        category: assetRow.category ?? '',
        vulns: assetRow.vulns ?? 0,
        os: assetRow?.osInfo?.product ?? 'unknown',
        clientUUID: assetRow.clientUUID ?? '',
        scannerUUID: assetRow.scannerUUID ?? '',
        sourceName: getSourceName(assetRow.source),
        lastSeen,
        lastScannedTime: getFormattedLastScannedTime(assetRow.lastScannedTime),
        manufacturer: assetRow.manufacturer || 'unknown',
        tags: assetRow.tags,
      };

      catalogData.push(arrAsset);
    });
  }
  return catalogData;
};

type typeName = 'actions' | 'severities';
type ObjectType<T> = T extends 'actions'
  ? iAssetAction
  : T extends 'severities'
    ? iAssetSeverity
    : never;

const NO_RECENT_SCANS = 'No Recent Scans';

const LAST_SCANNED_TIME = 'lastScannedTime';

const sortLastScannedTime = (data: any) => {
  if (data.lastScannedTime === NO_RECENT_SCANS) return -Infinity;
  else return new Date(data.lastScannedTime);
};

export const sortData = <T>(
  reverse: boolean,
  column: keyof T,
  tableData: T[]
) => {
  const data: T[] = JSON.parse(JSON.stringify(tableData));

  if (column.toString() === LAST_SCANNED_TIME) {
    const sortedLastScannedTime = _.sortBy(data, sortLastScannedTime);
    if (reverse) return sortedLastScannedTime.reverse();
    return sortedLastScannedTime;
  }

  if (reverse) {
    return data.sort((b, a) => {
      const _a = a[column];
      const _b = b[column];

      if (typeof _a === 'string' && typeof _b === 'string') {
        return _a.localeCompare(_b);
      } else if (typeof _a === 'number' && typeof _b === 'number') {
        return _a - _b;
      }

      return 1;
    });
  }
  return data.sort((a, b) => {
    const _a = a[column];
    const _b = b[column];

    if (typeof _a === 'string' && typeof _b === 'string') {
      return _a.localeCompare(_b);
    } else if (typeof _a === 'number' && typeof _b === 'number') {
      return _a - _b;
    }

    return 1;
  });
};

export interface csvAssetCatalogDataType extends iAssetCatalogDataType {
  tagCSV?: string;
  critCSV?: string;
}

export const getSkippedRecords = (
  beforeUpdateAssetCatalogArr: csvAssetCatalogDataType[],
  skippedAssetsForUpdate: Array<Record<string, string>>
) => {
  const tableData: assetsUpdatedFieldsType_V2[] = [];

  beforeUpdateAssetCatalogArr.forEach((beforeAsset) => {
    const skippedUpdate = skippedAssetsForUpdate.find((item) => {
      return beforeAsset.id === item.device_id;
    });

    const newCategoryString =
      skippedUpdate?.category ?? 'Set to Sensor Default';
    const newDeviceNameString =
      skippedUpdate?.devicename ?? 'Set to Sensor Default';
    const newTagsString = skippedUpdate?.tags ?? 'Set to Sensor Default';

    const entry: assetsUpdatedFieldsType_V2 = {
      id: beforeAsset.id,
      oldCategory: beforeAsset.category,
      newCategory: newCategoryString,
      oldDeviceName: beforeAsset.deviceName,
      newDeviceName: newDeviceNameString,
      oldTags: beforeAsset?.tagCSV ?? '',
      newTags: newTagsString,
      oldCriticalityString: beforeAsset?.critCSV ?? '',
      newCriticalityString: skippedUpdate?.criticality ?? '',
    };

    tableData.push(entry);
  });

  return tableData;
};

const DateStringIsBefore = (_a: string, _b: string) => {
  // Unknown is considered oldest
  if ((_a === 'Unknown' && _b === 'Unknown') || _b === 'Unknown') {
    return false;
  } else if (_a === 'Unknown') {
    return true;
  }
  return moment(_a).isSameOrBefore(moment(_b));
};

export const getDuplicateDataWithCSVTags = (
  tableDataToFilter: iAssetCatalogDataType[],
  fields: Array<string>,
  isExcludeUnknownDevice: boolean,
  showOnlyDuplicate: boolean,
  allTags?: Array<TagType>
) => {
  const duplicateMap: Record<string, DataWithDuplicateAssets> = {};
  tableDataToFilter.forEach((tabledata: iAssetCatalogDataType) => {
    const data: DataWithDuplicateAssets = { ...tabledata };

    //  Exclude data for unknown device name.
    if (isExcludeUnknownDevice && data.deviceName === UNKNOWN_VALUE) {
      return;
    }

    // Generating assets tags data for each row
    if (allTags) {
      const filteredTags = allTags.filter(
        (tag: TagType) => data.tags && data.tags.includes(tag.tagID)
      );

      if (filteredTags.length > 0) {
        data.assetTags = filteredTags;
      }

      // Generating and adding Tags & Criticality for CSV.
      const tagNameList = filteredTags.map((tag: TagType) => tag.name) || [];
      data.tagCSV = tagNameList.join();
      data.critCSV = CRITICALITY_MAP[data.criticality || 0].value;
    }

    if (fields.length > 0) {
      let key = '';
      fields.forEach((field: string) => {
        const tyedField = field as keyof iAssetCatalogDataType;
        key += data[tyedField];
      });
      if (key in duplicateMap) {
        if (!duplicateMap[key].duplicateData) {
          duplicateMap[key].duplicateData = [];
        }
        if (DateStringIsBefore(duplicateMap[key].lastSeen, data.lastSeen)) {
          const tempObject = {
            ...duplicateMap[key],
          };
          delete tempObject.duplicateData;
          duplicateMap[key] = {
            ...data,
            duplicateData: duplicateMap[key].duplicateData,
          };
          duplicateMap[key].duplicateData?.push(tempObject);
        } else {
          duplicateMap[key].duplicateData?.push(data);
        }
      } else {
        duplicateMap[key] = data;
      }
    } else {
      duplicateMap[data.id] = data;
    }
  });

  // To return only duplicates data.
  if (showOnlyDuplicate) {
    const duplicateData = Object.values(duplicateMap).filter(
      (item: DataWithDuplicateAssets) => item.duplicateData
    );

    return duplicateData;
  }

  return Object.values(duplicateMap);
};

export const sortByReservedTags = (tags: TagType[]) => {
  // tags are sorted as reserved tags first
  return tags.sort((x, y) => {
    if (x.reserved === y.reserved) return 0;
    if (x.reserved) return -1;
    return 1;
  });
};

export const updateAssetCatalogUserConfigRecords = async (
  validAssets: Array<Record<string, string | number | number[]>>,
  apiURL: string,
  beforeUpdateAssetCatalogArr: csvAssetCatalogDataType[],
  skippedAssetsForUpdate: Array<Record<string, string>>
) => {
  const tableData: assetsUpdatedFieldsType_V2[] = [];
  const validAssetsJSON = JSON.stringify(validAssets, [
    'device_id',
    'devicename',
    'category',
    'tags',
    'criticality',
  ]); // filter columns

  try {
    const response = await riskAPI.patch(apiURL, validAssetsJSON);
    const data: Array<Record<string, string>> = response.data;

    beforeUpdateAssetCatalogArr.forEach((beforeAsset) => {
      const skippedUpdate = skippedAssetsForUpdate.find(
        (item) => beforeAsset.id === item.device_id
      );
      const validUpdate = validAssets.find(
        (item) => beforeAsset.id === item.device_id
      );

      if (data?.length === 0) {
        toastr.error('', 'Failed to import asset user config');
        return;
      }

      const updatedAssetConfig = data.find(
        (item) => item.device_id === beforeAsset.id
      );

      let newCategoryString =
        updatedAssetConfig?.category &&
        updatedAssetConfig.category !== beforeAsset.category
          ? updatedAssetConfig.category
          : 'Set to Sensor Default';

      if (skippedUpdate?.category) {
        newCategoryString = skippedUpdate.category;
      }

      let newDeviceNameString =
        updatedAssetConfig?.devicename &&
        updatedAssetConfig.devicename !== beforeAsset.deviceName
          ? updatedAssetConfig.devicename
          : 'Set to Sensor Default';

      if (skippedUpdate?.devicename) {
        newDeviceNameString = skippedUpdate.devicename;
      }

      const newTagsString =
        validUpdate?.tagCSV && validUpdate.tagCSV !== beforeAsset.tagCSV
          ? (validUpdate?.tagCSV as string)
          : 'Set to Sensor Default';

      const oldCriticalityString =
        CRITICALITY_MAP[beforeAsset.criticality || 0].value;

      const newCriticalityString =
        validUpdate?.criticality &&
        validUpdate?.criticality !== beforeAsset.criticality
          ? CRITICALITY_MAP[(validUpdate.criticality as number) || 0].value
          : 'Set to Sensor Default';

      const updatedFields: assetsUpdatedFieldsType_V2 = {
        id: beforeAsset.id,
        oldCategory: beforeAsset.category,
        newCategory: newCategoryString,
        oldDeviceName: beforeAsset.deviceName,
        newDeviceName: newDeviceNameString,
        oldTags: beforeAsset?.tagCSV ?? '',
        newTags: newTagsString,
        oldCriticalityString,
        newCriticalityString,
      };

      tableData.push(updatedFields);
    });
  } catch (error) {
    toastr.error(
      _.get(error, 'code', ''),
      'Failed to import asset user config'
    );
  }

  return tableData;
};

// copy of above function to handle the changes with FF.
export const updateAssetsByCSV_v2 = async (
  customerId: string,
  servicesHost: string,
  tableData: csvAssetCatalogDataType[],
  uploadedFile: File | null,
  setUpdatedData: (data: assetsUpdatedFieldsType_V2[]) => void,
  setSkippedData: (data: assetsUpdatedFieldsType_V2[]) => void,
  onCSVImportSuccess: () => void,
  allTags?: TagType[]
) => {
  let updatedData: assetsUpdatedFieldsType_V2[] = [];
  const beforeUpdateAssetCatalogArr: iAssetCatalogDataType[] = [];
  let parseResult: ParseResult<ParsedAssetFields>;
  const validAssets: Array<Record<string, string | number | number[]>> = [];
  const skippedAssetsForUpdate: Array<Record<string, string>> = [];
  const reader = new FileReader();

  reader.onload = async (e: ProgressEvent<FileReader>) => {
    const csvString = e?.target?.result;

    if (typeof csvString === 'string') {
      const rowCount = csvString.split(/\r\n|\n/)?.length || 0;

      if (rowCount > 10000) {
        toastr.error(
          'Number of Rows Exceeded',
          'Please reduce number of rows in CSV and try again.'
        );
        return;
      }

      // the following check if csv has required headers and manipulates
      // header of csv read (plain text file) and adds the appropriate
      // headers names to match server-side request
      if (
        csvString.search(/Device ID/s) >= 0 &&
        csvString.search(/Device Name/s) >= 0 &&
        csvString.search(/Category/s) >= 0 &&
        csvString.search(/Tags/s) >= 0 &&
        csvString.search(/Asset Criticality/s) >= 0
      ) {
        let assetText = csvString.replace(/Device ID/s, 'device_id');
        assetText = assetText.replace(/Device Name/s, 'devicename');
        assetText = assetText.replace(/Category/s, 'category');
        assetText = assetText.replace(/Tags/s, 'tags');
        assetText = assetText.replace(/Asset Criticality/s, 'criticality');

        parseResult = Papa.parse(assetText, {
          header: true,
          dynamicTyping: true,
        });
      } else {
        toastr.error(
          'Required columns not included in CSV file',
          'Please include all of the following headers: Device ID, Device Name, Category, Tags and Criticality.'
        );
        return;
      }
      parseResult.data.forEach((asset) => {
        let isTagsValid = true;
        let isTagsUpdate = false;
        let resultTags: number[] = [];
        if (!asset['device_id']) {
          return;
        }
        asset.category = asset.category ? asset.category : '';
        asset.devicename = asset.devicename ? asset.devicename : '';

        const result = tableData.find((item) => {
          return item.id === asset['device_id'];
        });

        if (result !== undefined) {
          beforeUpdateAssetCatalogArr.push(result);
          resultTags = result.tags ?? [];
        }

        asset.tags = asset.tags ? asset.tags.trim().toLowerCase() : '';
        const newTags: number[] = [];
        const csvTags: string[] = asset.tags.includes(',')
          ? asset.tags.split(',')
          : [asset.tags];

        if (asset.tags === '' && asset.tags !== result?.tagCSV) {
          isTagsUpdate = true;
        }

        if (csvTags.length && asset.tags !== '') {
          csvTags.forEach((tagName) => {
            const tagRow = allTags?.find((row) => row.name === tagName.trim());

            if (tagRow) {
              newTags.push(tagRow.tagID);
              if (resultTags.length && !resultTags.includes(tagRow.tagID)) {
                isTagsUpdate = true;
              }
            } else {
              isTagsValid = false;
            }
          });

          if (isTagsValid && resultTags.length !== csvTags.length) {
            isTagsUpdate = true;
          }
        }

        const criticality =
          CRITICALITY_VALUE_MAP[asset.criticality?.trim().toLowerCase()];

        if (
          result !== undefined &&
          asset.category.length <= 64 &&
          asset.devicename.length <= 255 &&
          asset.tags.length <= 255 &&
          isTagsValid &&
          criticality !== undefined &&
          (result.category !== asset.category ||
            result.deviceName !== asset.devicename ||
            result.criticality !== criticality ||
            isTagsUpdate)
        ) {
          validAssets.push({
            ...asset,
            tags: newTags,
            tagCSV: asset['tags'],
            criticality,
          });
        } else {
          const skippedUpdate: Record<string, string> = {};
          if (asset.category.length > 64) {
            skippedUpdate['category'] = 'Update skipped -> Length exceeded';
          }
          if (asset.devicename.length > 255) {
            skippedUpdate['devicename'] = 'Update skipped -> Length exceeded';
          }
          if (asset.tags.length > 255) {
            skippedUpdate['tags'] = 'Update skipped -> Length exceeded';
          }
          if (!isTagsValid) {
            skippedUpdate['tags'] =
              'Update skipped -> Invalid tags added for update';
          }
          if (criticality === undefined) {
            skippedUpdate['criticality'] =
              'Update skipped -> Invalid criticality added for update';
          }

          if (
            skippedUpdate.category ||
            skippedUpdate.devicename ||
            skippedUpdate.tags ||
            skippedUpdate.criticality
          ) {
            skippedAssetsForUpdate.push({
              device_id: asset['device_id'],
              ...skippedUpdate,
            });
          }
        }
      });

      if (validAssets.length > 0) {
        const updateURL: string = `${servicesHost}/rootsecure/v1/customers/${customerId}/assets`;

        updatedData = await updateAssetCatalogUserConfigRecords(
          validAssets,
          updateURL,
          beforeUpdateAssetCatalogArr,
          skippedAssetsForUpdate
        );

        setUpdatedData(updatedData);
        onCSVImportSuccess();
        return;
      } else {
        if (skippedAssetsForUpdate.length > 0) {
          const skippedData = getSkippedRecords(
            beforeUpdateAssetCatalogArr,
            skippedAssetsForUpdate
          );
          toastr.warning(
            `Update failed due to some fields are incorrect or exceeded the length in CSV`,
            'Please try again.'
          );
          setSkippedData(skippedData);
        } else {
          toastr.warning(
            'CSV file does not include any updates',
            'Please try again.'
          );
        }
        return;
      }
    }
  };

  if (uploadedFile) {
    reader.readAsText(uploadedFile);
  }
};
