interface AssetSource {
  [key: string]: string;
}

export const SOURCE_ICONS: AssetSource = {
  sensor: 'fa-bug',
  agent: 'fa-binoculars',
  reach: 'fa-globe',
};

export const SOURCE_TEXT: AssetSource = {
  sensor: 'IVA',
  agent: 'Agent',
  reach: 'EVA',
};
