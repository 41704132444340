export enum SOURCE {
  SENSOR = 'sensor',
  REACH = 'reach',
  AGENT = 'agent',
}

export enum STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  MITIGATED = 'Mitigated',
  OBSOLETE = 'Obsolete',
}

export const DEFAULT_FILTER_STATE = {
  page: 0,
  search: '',
  deploymentID: [],
  actionClass: [],
  owner: [],
  source: [SOURCE.SENSOR, SOURCE.REACH, SOURCE.AGENT],
  status: [],
  state: [],
  issueAction: '',
  fromLevel: 4,
  toLevelInclusive: 10,
  firstIdentifiedAfter: undefined,
  firstIdentifiedBefore: undefined,
  resolvedAfter: undefined,
  resolvedBefore: undefined,
  category: [],
};

export const READONLY_STATUS: string[] = [STATUS.MITIGATED, STATUS.OBSOLETE];

export const RISK_SELECTABLE_STATES = {
  OPEN: 'Open',
  ACKNOWLEDGED: 'Acknowledged, In-Planning',
  IN_PROGRESS: 'Mitigation/Fix in Progress',
  FIXED: 'Fixed, Waiting Validation',
  FALSE_POSITIVE: 'False Positive',
  ACCEPTED: 'Accepted',
};

export const RISK_STATES = {
  OPEN: RISK_SELECTABLE_STATES.OPEN,
  ACKNOWLEDGED: RISK_SELECTABLE_STATES.ACKNOWLEDGED,
  IN_PROGRESS: RISK_SELECTABLE_STATES.IN_PROGRESS,
  FIXED: RISK_SELECTABLE_STATES.FIXED,
  FAILED_VALIDATION: 'Unsuccessful Validation',
  MITIGATED: 'Mitigated',
  FALSE_POSITIVE: RISK_SELECTABLE_STATES.FALSE_POSITIVE,
  ACCEPTED: RISK_SELECTABLE_STATES.ACCEPTED,
};

export const HEADERS = {
  Source: 'source',
  Host: 'host',
  Issue: 'issueName',
  'Risk Score': 'issueLevel',
  Action: 'issueAction',
  State: 'state',
  Status: 'status',
  'Resolution Date': 'resolutionDate',
  Age: 'age',
  'Days To Resolution': 'daysToResolution',
  'Deployment ID': 'deploymentID',
};

export const ACTIONS = {
  ALL: 'All',
  BREACH: 'Breach',
  CONFIGURATION: 'Configuration',
  UNDER_INVESTIGATION: 'Under Investigation',
  UPGRADE: 'Upgrade',
};
