import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';
import { AxiosError } from 'axios';

import { Grid } from 'react-loader-spinner';
import AssetInfoPanel from './AssetInfoPanel';

import { atoms, Button, Primitive } from '@rtkwlf/fenrir-react';
import AssetProfileHistory from './AssetProfileHistory';
import AgentAudits from './AgentAudits';

import { customerSelectors } from '../../../Global/customerReducer';
import { configSelectors } from '../../../Global/configReducer';
import { assetSelectors } from '../../../Global/assetReducer';
import { assetProfileHistoryType, getAgentAudit } from './utils';
import { RoutesKey } from '../../../routesMap';

import { BLUE, GREY, WHITE } from '../../../Constants/Styles';
import styled from 'styled-components';
import {
  LinuxHardware,
  MacHardware,
  parseAgentAudit,
  WindowsHardware,
} from './AgentAudits/parser';
import { useAppDispatch } from '../../../hooks';
import { useAssetProfileData, useQueryAssetDetails } from '../useAssetsQueries';

const WrapStyle = styled.div`
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid ${GREY.lightTertiary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const WrapLoaderStyle = styled.div`
  margin: 75px 0px;
  display: flex;
  justify-content: center;
`;

const NoDataStyle = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  background: ${WHITE.tertiary};
  align-items: center;
  margin-bottom: 15px;
`;

const AssetDetailsContainer = styled(Primitive.div)`
  margin: 0px -20px 0px -20px;
`;

const panel = atoms({
  margin: 'default',
  padding: 'medium',
  backgroundColor: 'monoWhite',
  borderRadius: 'default',
});

export const AssetDetails = () => {
  const dispatch = useAppDispatch();
  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const location = useSelector(assetSelectors.getLocation);
  const isAuth0loading = useSelector(configSelectors.getAuth0);
  const query = location.query || {};
  const assetId = query.assetid || '';
  const clientId = query.clientid || '';
  const [sourceType, setSourceType] = useState<string>('');
  const initialCustomerId = React.useRef(customerId).current;
  const [profileResponse, setProfileResponse] = useState<
    Array<assetProfileHistoryType>
  >([]);

  useEffect(() => {
    if (!assetId && !clientId && !isAuth0loading) {
      toastr.error('Error:', 'Failed to load asset details data');

      dispatch({ type: RoutesKey.ASSETS });
    }
  }, [assetId, clientId, dispatch, isAuth0loading]);

  const {
    data: assetDetailsData,
    isLoading: isDetailsLoading,
    isSuccess: isAssetDetailsSuccess,
    isError: isDetailsError,
    error: assetDetailsError,
  } = useQueryAssetDetails(customerId, assetId, servicesHost, clientId);

  const isEnableAgentAudit = isAssetDetailsSuccess && sourceType === 'agent';
  const assetIdFromDetails = isAssetDetailsSuccess
    ? assetDetailsData.id
    : assetId;

  const {
    data: agentAuditData,
    isError: agentAuditError,
    isLoading: isLoadingAgentAudit,
  } = useQuery({
    queryKey: [`getAgentAudit-${assetIdFromDetails}`],
    queryFn: () => {
      const uuid = assetDetailsData!.scannerUUID || customerId;
      const clientUUID = assetDetailsData!.clientUUID;

      return getAgentAudit(uuid, servicesHost, clientUUID);
    },
    retry: false,
    enabled: isEnableAgentAudit,
  });

  useEffect(() => {
    if (agentAuditError && !isAuth0loading) {
      toastr.error('Failed to fetch Agent audit data', '');
    }
  }, [agentAuditError, isAuth0loading]);

  const { isLoading, data: assetsProfileData } = useAssetProfileData(
    servicesHost,
    initialCustomerId,
    assetIdFromDetails
  );

  useEffect(() => {
    if (!isLoading && assetsProfileData) {
      setProfileResponse(assetsProfileData);
    }
  }, [isLoading, assetsProfileData]);

  useEffect(() => {
    if (assetDetailsData) {
      setSourceType(assetDetailsData.source ?? 'nonAgent');
    }
  }, [assetDetailsData]);

  useEffect(() => {
    if (isDetailsError && !isAuth0loading) {
      const axiosError = assetDetailsError as AxiosError;

      if (axiosError.response?.status === 404) {
        toastr.error('The specified asset ID is not valid', '');
      } else {
        toastr.error('Failed to load asset details data', '');
      }

      // Redirect to asset homepage
      dispatch({ type: RoutesKey.ASSETS });
    }
  }, [isDetailsError, assetDetailsError, dispatch, isAuth0loading]);

  const parsedAudit = useMemo(() => {
    if (assetDetailsData && agentAuditData) {
      return parseAgentAudit(agentAuditData, assetDetailsData.osInfo.name);
    }
  }, [assetDetailsData, agentAuditData]);

  const hardWareDetails = {
    os: parsedAudit?.os,
    hardwareData: parsedAudit?.hardware as
      | WindowsHardware
      | LinuxHardware
      | MacHardware
      | undefined,
  };

  const handleGoBack = () => {
    const url = document.referrer || '/assets';
    window.open(url, '_self');
  };

  return (
    <>
      <Button
        style={{ margin: '1rem 0px 0px -10px' }}
        variant='secondary'
        iconLeft={<i className='fa fa-angle-double-left' />}
        onClick={handleGoBack}
      >
        Back to Assets
      </Button>

      <AssetDetailsContainer>
        <Primitive.div className={panel} style={{ margin: '1rem 11px' }}>
          {isDetailsLoading ? (
            <WrapLoaderStyle>
              <Grid color={BLUE.tertiary} height={120} width={120} />
            </WrapLoaderStyle>
          ) : !assetDetailsData || isDetailsError || !sourceType ? (
            <NoDataStyle>No data</NoDataStyle>
          ) : (
            <AssetInfoPanel
              sourceType={sourceType}
              profileResponse={profileResponse}
              data={assetDetailsData}
              hardWareDetails={hardWareDetails}
            />
          )}
          <WrapStyle>
            <AssetProfileHistory
              assetId={assetIdFromDetails}
              servicesHost={servicesHost}
              initialCustomerId={initialCustomerId}
            />
          </WrapStyle>
        </Primitive.div>
      </AssetDetailsContainer>

      {assetDetailsData && sourceType === 'agent' ? (
        <AgentAudits
          isError={agentAuditError}
          isLoading={isLoadingAgentAudit}
          parsedAudit={parsedAudit}
        />
      ) : null}
    </>
  );
};

export default AssetDetails;
