import React, { useMemo, useState } from 'react';

import AssetTable from '../../AssetTable';
import { ColumnProps, SortState } from '../../../../Reusables/table/Table';
import { sortData } from '../../utils';
import { AGENT_OS } from '../../../../Constants/Assets';
import { LinuxSoftware, MacSoftware, SoftwareByOS } from './parser';

const WINDOWS_SOFTWARE_COLUMNS: ColumnProps<LinuxSoftware>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'version',
    dataIndex: 'version',
    title: 'Version',
    sortable: true,
  },
  {
    key: 'vendor',
    dataIndex: 'vendor',
    title: 'Vendor',
    sortable: true,
  },
  {
    key: 'installDate',
    dataIndex: 'installDate',
    title: 'Installed',
    sortable: true,
  },
  {
    key: 'installLocation',
    dataIndex: 'installLocation',
    title: 'Install Location',
    sortable: true,
  },
  {
    key: 'installSource',
    dataIndex: 'installSource',
    title: 'Install Source',
    sortable: true,
  },
];

const MAC_SOFTWARE_COLUMNS: ColumnProps<MacSoftware>[] = [
  {
    key: 'kind',
    dataIndex: 'kind',
    title: 'Kind',
    sortable: true,
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'version',
    dataIndex: 'version',
    title: 'Version',
    sortable: true,
  },
  {
    key: 'location',
    dataIndex: 'location',
    title: 'Location',
    sortable: true,
  },
  {
    key: 'signedBy',
    dataIndex: 'signedBy',
    title: 'Signed By',
    sortable: true,
  },
  {
    key: 'intel64Bit',
    dataIndex: 'intel64Bit',
    title: 'Intel 64bit',
    sortable: true,
  },
  {
    key: 'lastModified',
    dataIndex: 'lastModified',
    title: 'Last Modified',
    sortable: true,
  },
  {
    key: 'obtainedFrom',
    dataIndex: 'obtainedFrom',
    title: 'Obtained From',
    sortable: true,
  },
];

const LINUX_SOFTWARE_COLUMNS: ColumnProps<LinuxSoftware>[] = [
  {
    key: 'arch',
    dataIndex: 'arch',
    title: 'Arch',
    sortable: true,
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    sortable: true,
  },
  {
    key: 'summary',
    dataIndex: 'summary',
    title: 'Summary',
    sortable: true,
  },
  {
    key: 'version',
    dataIndex: 'version',
    title: 'Version',
    sortable: true,
  },
];

const COLUMNS_BY_OS = {
  [AGENT_OS.WINDOWS]: WINDOWS_SOFTWARE_COLUMNS,
  [AGENT_OS.MACOS]: MAC_SOFTWARE_COLUMNS,
  [AGENT_OS.LINUX]: LINUX_SOFTWARE_COLUMNS,
};

export type Props<OS extends AGENT_OS> = {
  os: OS;
  softwareData: SoftwareByOS[OS][];
  isLoading: boolean;
};

const SoftwareTable = <OS extends AGENT_OS>({
  isLoading,
  os,
  softwareData,
}: Props<OS>) => {
  type DataType = SoftwareByOS[OS];
  type SoftwareField = keyof DataType;

  const [sortCol, setSortCol] = useState<SoftwareField>();
  const [sortState, setSortState] = useState<SortState>('none');

  const handleSort = (col: string, state: SortState) => {
    setSortCol(col as SoftwareField);
    setSortState(state);
  };

  const data = useMemo(() => {
    if (!sortCol || sortState === 'none') {
      return softwareData;
    }

    return sortData<SoftwareByOS[OS]>(
      sortState === 'dsc',
      sortCol as SoftwareField,
      [...softwareData]
    );
  }, [softwareData, sortCol, sortState]);

  return (
    <AssetTable
      data={data}
      columns={COLUMNS_BY_OS[os] as unknown as ColumnProps<DataType>[]}
      isLoading={isLoading}
      handleSort={handleSort}
      isSearch
    />
  );
};

export default SoftwareTable;
