import React, { Fragment, useMemo } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { CredScanTile } from './CredScansTile';
import ScannerConfigurationRow from './ScannerConfigurationRow';
import ScanningQueue from './ScanningQueue';
import { ScanningSchedule } from './ScanningSchedule';
import { deleteScheduleThunk } from '../../Global/scannerActions';
import { Dispatch, iState } from '../../configureStore';
import { Schedule } from '../../types/scanner';
import { MissingData } from '../../Reusables/MissingData';
import {
  convertScheduleToLocal,
  convertSchedulesToLocal,
} from '../../utils/parsers';
import { Button, VStack } from '@rtkwlf/fenrir-react';

import {
  ffLittleWinsMR20240821,
  isFeatureEnabled,
} from '../../../public/js/features';
import { customerSelectors } from '../../Global/customerReducer';

const ScannerConfiguration = ({
  deleteSchedule,
  scans,
  schedules = new Map(),
  id,
}: PropsFromRedux) => {
  const currentCustomerDetails = useSelector(
    customerSelectors.getCurrentCustomerDetails
  );

  const scannerLS = localStorage.getItem('scanner');
  const scanner = JSON.parse(
    scannerLS && scannerLS !== null ? scannerLS : '{}'
  );

  const convertedSchedules = useMemo(() => {
    return convertSchedulesToLocal(
      Array.from(schedules.values()).map((s) => {
        const filterTargets = new Set(s.targets);
        s.targets = Array.from(filterTargets);
        return s;
      })
    );
  }, [schedules]);

  const convertedScans = useMemo(() => {
    const cScans = [];
    if (scans?.length) {
      for (let x = 0; x < scans?.length || 0; x++) {
        const s = {
          ...scans[x],
          schedule:
            convertScheduleToLocal(scans[x].schedule) || scans[x].schedule,
        };
        cScans.push(s);
      }
      return cScans;
    } else {
      return scans;
    }
  }, [scans]);

  return (
    <Fragment>
      {scanner?.id ? (
        <>
          {isFeatureEnabled(ffLittleWinsMR20240821) && (
            <VStack style={{ marginBottom: '10px', paddingLeft: '10px' }}>
              <a
                href={`https://dashboard.arcticwolf.com/telemetry/scan-schedules?source=IVA&id=${currentCustomerDetails?.id}`}
                target='_blank'
              >
                <Button
                  variant='link'
                  iconRight={<i className='fa fa-external-link' />}
                >
                  Configure Scan Schedules in the Unified Portal
                </Button>
              </a>
            </VStack>
          )}

          <ScannerConfigurationRow id={id} />
          {scans?.length ? (
            <ScanningQueue scans={convertedScans || []} />
          ) : null}
          <ScanningSchedule
            schedules={convertedSchedules}
            deleteSchedule={deleteSchedule}
          />

          <CredScanTile />
        </>
      ) : (
        <MissingData />
      )}
    </Fragment>
  );
};

const connector = connect(
  ({ scanner }: iState) => {
    const id = scanner.scanner?.id;
    const currentScanner = scanner.scanners.get(id);

    // workaround to fix schedule rows not updating after a change
    const schedulesMapClone = new Map(
      currentScanner?.schedules as Map<string, Schedule>
    );

    return {
      id,
      scans: currentScanner?.scans,
      schedules: schedulesMapClone,
    };
  },
  (dispatch: Dispatch) => {
    return {
      deleteSchedule: (id: string) => dispatch(deleteScheduleThunk(id)),
    };
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScannerConfiguration);
