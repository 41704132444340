import React from 'react';
import styled from 'styled-components';
import { STATUS_COLOR } from '../Constants/Styles';
import { STATUS, StatusType } from '../Constants/Labels';

export interface StatusIconProps {
  text: StatusType;
  icon?: string;
}

const getColour = (text: StatusType) => {
  switch (text.toLowerCase()) {
    case STATUS.DEGRADED.toLowerCase():
    case STATUS.DISCONNECTED.toLowerCase():
    case STATUS.NO_BRUTE_FORCE.toLowerCase():
    case STATUS.NO_CGI.toLowerCase():
    case STATUS.NOT_CONFIGURED.toLowerCase():
    case STATUS.NOT_SCANNING.toLowerCase():
      return STATUS_COLOR.red;
    case STATUS.CONNECTED.toLowerCase():
    case STATUS.RUNNING.toLowerCase():
    case STATUS.SCANNING.toLowerCase():
    case STATUS.IDLE.toLowerCase():
      return STATUS_COLOR.green;
    case STATUS.LOADING.toLowerCase():
    case STATUS.SCHEDULED.toLowerCase():
      return STATUS_COLOR.blue;
    case STATUS.DISABLED.toLowerCase():
      return STATUS_COLOR.grey;
    case STATUS.MISCONFIGURED.toLowerCase():
    default:
      return STATUS_COLOR.yellow;
  }
};

const StyledIcon = styled.i`
  margin-right: 10px;
`;

const StyledContainer = styled.div`
  margin: 5px 10px;
  text-transform: capitalize;
`;

const StyledBadge = styled.div<{ $background: string }>`
  font-size: 14px;
  white-space: nowrap;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  display: inline-block;
  align-items: center;
  text-align: center;
  overflow: visible;
  background: ${(props) => props.$background};
  border-radius: 15px;
  cursor: pointer;
`;

const StatusIcon = ({ text, icon, ...rest }: StatusIconProps) => (
  <StyledBadge $background={getColour(text)} {...rest}>
    <StyledContainer>
      {icon && <StyledIcon className={`fa ${icon}`} />}
      {text}
    </StyledContainer>
  </StyledBadge>
);
StatusIcon.displayName = 'StatusIcon';

export default StatusIcon;
