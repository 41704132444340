import React from 'react';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
export interface Props {
  icon: string;
  title: string;
  value: number; // value has to be a number for safe Math operations
  lastValue: number; // lastValue has to be a number for safe Math operations
  displayValue?: React.ReactNode; // optional, will be used to replace value as display
  helpText: string;
  iconId?: string;
  onIconClick?(): void;
  href?: string;
  positiveIncrease?: boolean;
}

const Tile: React.FC<Props> = ({
  icon,
  title,
  value,
  lastValue,
  displayValue,
  helpText,
  iconId,
  onIconClick,
  href,
  positiveIncrease,
}) => {
  const DeltaText = React.useMemo(() => {
    if (lastValue === undefined) return null;
    if (lastValue === 0) {
      return (
        <div className='delta_content'>
          <i className={'fa fa-minus'} style={{ marginRight: '3px' }} /> No
          change from last week
        </div>
      );
    }

    const increaseColor = positiveIncrease ? '#518042' : '#C43836';
    const decreaseColor = positiveIncrease ? '#C43836' : '#518042';
    const iconColor = lastValue < 0 ? decreaseColor : increaseColor;
    const deltaIcon = lastValue < 0 ? 'fa fa-arrow-down' : 'fa fa-arrow-up';
    const deltaPercentage =
      lastValue < 0 ? `${Math.abs(lastValue)}%` : `${lastValue}%`;

    return (
      <div className='delta_content' style={{ color: iconColor }}>
        <i className={deltaIcon} style={{ marginRight: '3px' }} />
        {deltaPercentage} from last week
      </div>
    );
  }, [lastValue, positiveIncrease]);

  return (
    <div className='col-lg-2 tile_stats_count'>
      <div className='tile_box'>
        <span className='count_top'>
          <div className='tile_title'>
            <b>{title}</b>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <FontAwesomeIcon
                  id={iconId}
                  icon={faInfoCircle}
                  style={{
                    color: '#23618E',
                    marginLeft: '3px',
                    marginRight: '10px',
                  }}
                  cursor='pointer'
                  onClick={onIconClick}
                />
              </Tooltip.Trigger>
              <Tooltip.Content side='top' maxWidth='20rem'>
                <Tooltip.Text>{helpText}</Tooltip.Text>
              </Tooltip.Content>
            </Tooltip.Root>
          </div>
          <i
            className={icon}
            style={{
              marginRight: 0,
              marginLeft: 'auto',
              fontSize: '20px',
              color: '#23618E',
            }}
          />
        </span>
        <div className='tile_bottom'>
          <a href={href} className='count' data-testid={title}>
            {displayValue ?? value}
          </a>
          <span className='count_bottom'>{DeltaText}</span>
        </div>
      </div>
    </div>
  );
};

export default Tile;
