import { atoms, Heading, HStack, Primitive } from '@rtkwlf/fenrir-react';
import React from 'react';
import Modal, { Styles } from 'react-modal';
import styled from 'styled-components';

const StyledClose = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 2;
`;

export enum ModalPositionType {
  top = 'flex-start',
  center = 'center',
}

export interface Props {
  children: React.ReactNode;
  onCloseModal: () => void;
  width?: string;
  title?: string;
  footer?: React.ReactNode;
  isOpen?: boolean;
  position?: ModalPositionType;
}

export const ModalBox: React.FC<Props> = ({
  children,
  onCloseModal,
  width,
  title,
  footer,
  isOpen,
  position,
}) => {
  const customStyles: Styles = {
    content: {
      position: 'relative',
      width: width || '1200px',
      margin: '30px auto',
      padding: '0',
      inset: 'auto',
      overflow: 'unset',
    },
    overlay: {
      zIndex: 2,
      overflow: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      alignItems: position ?? ModalPositionType.center,
    },
  };

  return (
    <Modal
      isOpen={isOpen ?? true}
      style={customStyles}
      onRequestClose={() => {
        onCloseModal();
      }}
      ariaHideApp={false}
    >
      {title ? (
        <HStack xAlign='between' yAlign='center' padding='large'>
          <Heading level='2' textColor='brandDefault'>
            {title}
          </Heading>

          <Primitive.div
            onClick={() => onCloseModal()}
            position='absolute'
            top='1.5rem'
            right='1.5rem'
            data-testid='modal-close'
          >
            <i
              className={`${atoms({
                textColor: 'brandDefault',
              })} fa fa-times fa-lg`}
            />
          </Primitive.div>
        </HStack>
      ) : (
        <StyledClose onClick={() => onCloseModal()} data-testid='modal-close'>
          <i
            className={`${atoms({
              textColor: 'brandDefault',
            })} fa fa-times fa-lg`}
          />
        </StyledClose>
      )}

      {children}

      {footer && (
        <Primitive.div
          paddingY='small'
          paddingX='medium'
          backgroundColor='widgetTertiary'
          data-testid='modal-footer'
        >
          {footer}
        </Primitive.div>
      )}
    </Modal>
  );
};
