import { useQuery } from '@tanstack/react-query';
import { iRisk } from '../../../Global/riskReducer';
import riskAPI from '../../../utils/riskAPI';

type SelectFn = ((data: iRisk) => iRisk) | undefined;

export const useRiskDetails = (
  customerId: string,
  enabled: boolean,
  riskId: string,
  select?: SelectFn
) => {
  return useQuery({
    queryKey: [`getRiskDetails-${riskId}`],
    queryFn: async () => {
      const apiURL = `/rootsecure/rida/v1/customers/${customerId}/risks/${riskId}`;
      const response = await riskAPI.get(apiURL);

      return response.data.data;
    },
    enabled: enabled,
    retry: false,
    select,
  });
};
