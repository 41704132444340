import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useRendallApi } from '../../apiClient/rendall/useRendallApi';
import { customerSelectors } from '../customerReducer';

export const useQueryRiskScoreDetails = () => {
  const api = useRendallApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  return useQuery({
    queryKey: ['risk-score-query', organizationId],
    queryFn: async () => api.getRiskScoreDetails({ organizationId }),
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
};

export const toggleMenu = () => $('body').toggleClass('nav-md nav-sm');
