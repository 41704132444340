import { useQuery } from '@tanstack/react-query';
import riskAPI from '../../utils/riskAPI';

export const useScannerPrefs = (scannerId: string) => {
  return useQuery({
    queryKey: [`getScannerPrefs-${scannerId}`],
    queryFn: async () => {
      const apiURL = `/rootsecure/v2/scanners/${scannerId}/preferences`;
      const response = await riskAPI.get(apiURL);
      return response;
    },
    retry: false,
  });
};

export const useScannerConfig = (scannerId: string) => {
  return useQuery({
    queryKey: [`getScannerConfig-${scannerId}`],
    queryFn: async () => {
      const apiURL = `/rootsecure/v1/sensor/${scannerId}/config`;
      const response = await riskAPI.get(apiURL);
      return response;
    },
    retry: false,
  });
};
