import React, { useState, useMemo } from 'react';

import AssetTable from '../../AssetTable';
import { ColumnProps, SortState } from '../../../../Reusables/table/Table';

import { RsCommafyInt } from '../utils';
import { sortData } from '../../utils';
import { AGENT_OS } from '../../../../Constants/Assets';
import {
  WindowsTaskListType,
  LinuxMacTaskListType,
  TaskListByOS,
} from './parser';

const WINDOWS_TASKLIST_COLUMNS: ColumnProps<WindowsTaskListType>[] = [
  {
    key: 'processName',
    dataIndex: 'processName',
    title: 'Name',
    width: 10,
    sortable: true,
  },
  {
    key: 'pid',
    dataIndex: 'pid',
    title: 'Process ID',
    width: 10,
    sortable: true,
  },
  {
    key: 'handleCount',
    dataIndex: 'handleCount',
    title: 'Handle Count',
    width: 10,
    sortable: true,
  },
  {
    key: 'workingSetSize',
    title: 'Working Set Size',
    width: 15,
    render: ({ record: { workingSetSize } }) => (
      <>{RsCommafyInt(workingSetSize)}</>
    ),
    sortable: true,
  },
  {
    key: 'sessionId',
    dataIndex: 'sessionId',
    title: 'Session ID',
    width: 15,
    sortable: true,
  },
  {
    key: 'threadCount',
    dataIndex: 'threadCount',
    title: 'Thread Count',
    width: 15,
    sortable: true,
  },
  {
    key: 'priority',
    dataIndex: 'priority',
    title: 'Priority',
    width: 10,
    sortable: true,
  },
];

const LINUX_MAC_TASKLIST_COLUMNS: ColumnProps<LinuxMacTaskListType>[] = [
  { key: 'tt', dataIndex: 'tt', title: 'TT', width: 5, sortable: true },
  { key: 'pid', dataIndex: 'pid', title: 'PID', width: 5, sortable: true },
  { key: 'rss', dataIndex: 'rss', title: 'RSS', width: 7, sortable: true },
  { key: 'vsz', dataIndex: 'vsz', title: 'VSZ', width: 7, sortable: true },
  { key: 'pcpu', dataIndex: 'pcpu', title: 'PCPU', width: 7, sortable: true },
  { key: 'pmem', dataIndex: 'pmem', title: 'PMEM', width: 7, sortable: true },
  { key: 'ppid', dataIndex: 'ppid', title: 'PPID', width: 7, sortable: true },
  { key: 'stat', dataIndex: 'stat', title: 'STAT', width: 7, sortable: true },
  { key: 'time', dataIndex: 'time', title: 'Time', width: 7, sortable: true },
  {
    key: 'command',
    dataIndex: 'command',
    title: 'Command',
    width: 20,
    sortable: true,
  },
];

const COLUMNS_BY_OS = {
  [AGENT_OS.WINDOWS]: WINDOWS_TASKLIST_COLUMNS,
  [AGENT_OS.MACOS]: LINUX_MAC_TASKLIST_COLUMNS,
  [AGENT_OS.LINUX]: LINUX_MAC_TASKLIST_COLUMNS,
};

export type Props<OS extends AGENT_OS> = {
  os: OS;
  taskListData: TaskListByOS[OS][];
  isLoading: boolean;
};

const TaskListTable = <OS extends AGENT_OS>({
  isLoading,
  os,
  taskListData,
}: Props<OS>) => {
  type DataType = TaskListByOS[OS];
  type TaskListField = keyof DataType;

  const [sortCol, setSortCol] = useState<TaskListField>();
  const [sortState, setSortState] = useState<SortState>('none');

  const handleSort = (col: string, state: SortState) => {
    setSortCol(col as TaskListField);
    setSortState(state);
  };

  const data = useMemo(() => {
    if (!sortCol || sortState === 'none') {
      return taskListData;
    }

    return sortData<TaskListByOS[OS]>(
      sortState === 'dsc',
      sortCol as TaskListField,
      [...taskListData]
    );
  }, [taskListData, sortCol, sortState]);

  return (
    <AssetTable
      data={data}
      columns={COLUMNS_BY_OS[os] as unknown as ColumnProps<DataType>[]}
      isLoading={isLoading}
      handleSort={handleSort}
      isSearch
    />
  );
};

export default TaskListTable;
