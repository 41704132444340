import { Dispatch, iGetState } from '../configureStore';
import riskAPI from '../utils/riskAPI';
import { RoutesKey, routesMap } from '../routesMap';
import {
  getScannerById,
  getScannersByCustomerId,
  getScannersThunk,
  SCANNER_ACTIONS,
  setScannerThunk,
} from './scannerActions';
import { iAwnCustomer, iCustomer } from './customerReducer';
import { iScanner } from './scannerReducer';
import { SET_SERVICE_HOST, setCustomerServiceHost } from './configActions';
import { ScannerSummary } from '../types/scanner';
import { getServicesHost } from './configReducer';
import { RSAuthorize, getCustomerPod } from '../utils/auth/authorization.js';
import { RSAuthenticate, waitForAuth0 } from '../utils/auth/authentication';

// ----- API Calls -----

// ----- Action Constants -----

export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const LOAD_CUSTOMER_DETAILS = 'LOAD_CUSTOMER_DETAILS';
export const SET_CUSTOMER = 'SET_CUSTOMER';

// ----- Action Creators -----

// ----- Thunk Action Creators -----

export const getCustomersThunk = () => (dispatch: Dispatch) => {
  const customers = JSON.parse(localStorage.getItem('customers')!);
  dispatch({
    type: LOAD_CUSTOMERS,
    payload: customers,
  });
};

export const setCustomerThunk =
  (uuid: string, reload: boolean) =>
  async (dispatch: Dispatch, getState: iGetState) => {
    const { location, customer } = getState();
    const selectedCustomer = customer.customers.get(uuid)!;

    dispatch(setCustomerServiceHost(selectedCustomer));

    const detail = await getCustomerDetail(selectedCustomer.riskID);
    const pod = getCustomerPod(selectedCustomer.id);
    localStorage.setItem('apiPod', pod);

    dispatch({
      type: SET_CUSTOMER,
      payload: {
        customer: {
          id: detail?.id,
          name: detail?.name,
          label: detail?.label,
          deploymentID: detail?.deploymentID,
        },
      },
    });
    dispatch(SCANNER_ACTIONS.SET_CURRENT_SCANNER({} as ScannerSummary));
    dispatch(SCANNER_ACTIONS.LOAD_SCANNERS([]));

    localStorage.setItem('currentCustomer', JSON.stringify(detail));
    localStorage.removeItem('sensor');
    localStorage.removeItem('scanner');

    dispatch(getScannersThunk(detail?.id));

    if (reload) {
      // Refresh Data after selecting customer
      // @ts-ignore
      const updateThunk: any = routesMap[location.type].thunk;
      if (updateThunk) {
        dispatch(updateThunk);
      }
    }

    if (location.type === RoutesKey.ASSET_DETAILS) {
      // Redirect to asset homepage
      dispatch({ type: RoutesKey.ASSETS });
    }
  };

// TODO: consider caching this in Redux, so we don't repeated call lookup
//  when the pod has not changed
const getCustomerDetails = async () => {
  return (await riskAPI.get('/rootsecure/v1/lookup')).data
    .customers as iCustomer[];
};

const getCustomerDetail = async (uuid: string) => {
  return (await getCustomerDetails()).find(
    (customerDetail) => customerDetail.id === uuid
  );
};

/**
 * There can be a race condition in the value of the localStorage value `currentCustomer`.
 * - Redux will read it upon app initialization and sync it to the store.
 * - authorization.js may update `currentCustomer` based on URL query param
 *    (that's how we support "auto customer switch")
 *
 * This function compares the store value and the current localStorage value and return the latest,
 *  with localStorage being favored.
 */
const decideInitialCustomer = (dispatch: Dispatch, getState: iGetState) => {
  const customer = getState().customer.currentCustomer;

  if (localStorage.getItem('currentCustomer')) {
    const storageCustomer = JSON.parse(
      localStorage.getItem('currentCustomer')!
    );

    if (customer.id !== storageCustomer.id) {
      // update the store
      dispatch({
        type: SET_CUSTOMER,
        payload: {
          customer: storageCustomer,
        },
      });

      // also update service host if necessary
      // Redux might have been init too early, before the right pod is set by authorization.js
      const host = getServicesHost();

      if (host !== getState().config.environment.servicesHost) {
        dispatch({
          type: SET_SERVICE_HOST,
          payload: {
            host,
          },
        });
      }

      return storageCustomer;
    }
  }

  return customer;
};

export const setupCustomerThunk = async (
  dispatch: any,
  getState: iGetState
) => {
  const state = getState();
  const { location, scanner } = state;
  const query = location.query || {};

  if (localStorage.getItem('customers') === null) {
    RSAuthenticate().then(() => {
      RSAuthorize();
    });
  }
  // Get Customer List
  const customerList: iAwnCustomer[] = JSON.parse(
    localStorage.getItem('customers')!
  );

  const currentCustomer: any = decideInitialCustomer(dispatch, getState);
  await waitForAuth0(dispatch);

  // Get Scanners List
  // Load customer list to state
  dispatch({
    type: LOAD_CUSTOMERS,
    payload: customerList,
  });
  const customerScanners = await getScannersByCustomerId(currentCustomer?.id);

  dispatch(SCANNER_ACTIONS.LOAD_SCANNERS(customerScanners));

  const currentScanner = scanner.scanner || localStorage.getItem('scanner');

  if (query.scannerId) {
    let scanner: any = customerScanners.find(
      (scanner: iScanner) => scanner.id === query.scannerId
    );

    if (!scanner) {
      scanner = await getScannerById(query.scannerId);
    }

    const selectedCustomer: any = customerList.find(
      (customer) => customer.id === scanner?.customerID
    );

    const customer = {
      id: selectedCustomer?.id,
      name: selectedCustomer?.name,
      label: selectedCustomer?.label,
      deploymentID: selectedCustomer?.deploymentID,
    };
    localStorage.setItem('currentCustomer', JSON.stringify(selectedCustomer));
    dispatch({
      type: SET_CUSTOMER,
      payload: {
        customer,
      },
    });

    dispatch(
      setScannerThunk(
        {
          label: scanner?.label,
          deploymentID: scanner?.deploymentID,
          id: scanner?.id,
        },
        false
      )
    );

    delete query.scannerId;
    return dispatch({ type: 'RISKS', query });
  } else if (
    !currentScanner ||
    !customerScanners.find(
      (scanner: iScanner) => scanner.id === currentScanner.id
    )
  ) {
    const scanner = customerScanners[customerScanners.length - 1];
    dispatch(
      setScannerThunk(
        {
          label: scanner?.label,
          deploymentID: scanner?.deploymentID,
          id: scanner?.id,
        },
        false
      )
    );
  }
  return;
};
