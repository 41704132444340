import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@rtkwlf/fenrir-react';

import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { PdfFilter } from '../../Modals/Modals/PdfFilters';

const StyledContainer = styled.div`
  margin: 14px 10px;
  display: flex;
`;

export const CSV: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [summary, setSummary] = useState(false);

  return (
    <StyledContainer id='rs-top-menu-items-div'>
      <Button
        variant='secondary'
        onClick={() => {
          setSummary(true);
          setShowModal(true);
        }}
        iconLeft={<i className='fa fa-cloud-download' />}
      >
        Executive Summary
      </Button>
      <Button
        variant='secondary'
        iconLeft={<i className='fa fa-cloud-download' />}
        onClick={() => {
          setSummary(false);
          setShowModal(true);
        }}
      >
        Risk Assessment
      </Button>

      {showModal && (
        <ModalBox
          width='auto'
          onCloseModal={() => setShowModal(false)}
          position={ModalPositionType.top}
        >
          <PdfFilter summary={summary} onClose={() => setShowModal(false)} />
        </ModalBox>
      )}
    </StyledContainer>
  );
};
