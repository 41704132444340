import {
  Configuration,
  DefaultApi,
  HTTPHeaders,
} from '@rtkwlf/asset-data-service';
import { getAuthToken, RSAuthenticate } from '../../utils/auth/authentication';
export const AUTH_TOKEN = 'access_token';

export const useAdsApi = (headers?: HTTPHeaders): DefaultApi => {
  const basePath = (() => {
    const isE2eTesting = window.localStorage.getItem('e2e_testing');
    const pod = window.localStorage.getItem('apiPod');

    if (isE2eTesting === 'true') return 'http://localhost:8082';
    else {
      return `https://asset.managedgw.${pod}.arcticwolf.net`;
    }
  })();

  const api = new DefaultApi(
    new Configuration({
      basePath,
      headers,
      accessToken: RSAuthenticate().then(
        () => getAuthToken() as Promise<string>
      ),
    })
  );
  return api;
};
