import React, { useState } from 'react';
import { Button, HStack, Primitive } from '@rtkwlf/fenrir-react';
import styled from 'styled-components';
import { GREY } from '../../../Constants/Styles';
import { ModalBox } from '../../../Reusables/ModalBox';

const StyleSpan = styled.span`
  color: ${GREY.bright};
  margin: 0 8px 5px;
`;

type Props = {
  page: number;
  pageSize: number;
  totalItems: number;
  setPage: (page: number) => void;
  highlightList: Array<string>;
  selectAll: (checked: boolean) => void;
};

export const Footer: React.FC<Props> = ({
  page,
  pageSize,
  totalItems,
  setPage,
  highlightList,
  selectAll,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pageToSet, setpageToSet] = useState<number>();
  const totalPages = Math.ceil(totalItems / pageSize);

  const pageChanged = (page: number) => {
    // need to - 1 from page because its 0 based but we have
    // added 1 for display and simplifying code below
    if (highlightList.length > 0) {
      setpageToSet(page - 1);
      setModalOpen(true);
    } else {
      setPage(page - 1);
    }
  };
  const currentPage = page + 1;

  return (
    <Primitive.div marginTop='smedium'>
      <ModalBox
        title={'Confirm Clear Selection'}
        isOpen={modalOpen}
        onCloseModal={() => setModalOpen(false)}
        width='520px'
        footer={
          <HStack xAlign='right' gap='xsmall'>
            <Button
              appearance='default'
              onClick={() => {
                setPage(pageToSet!);
                setModalOpen(false);
                selectAll(false);
              }}
            >
              Confirm
            </Button>
            <Button variant='secondary' onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </HStack>
        }
      >
        <HStack paddingX='large' paddingBottom='large'>
          Switching pages with risks selected will clear your selection, are you
          sure?
        </HStack>
      </ModalBox>
      <HStack
        alignItems='center'
        justifyContent='flex-end'
        data-testid='risk-table-footer'
        gap='zero'
      >
        <StyleSpan>
          Showing {currentPage * pageSize - pageSize + 1} -{' '}
          {currentPage * pageSize > totalItems
            ? totalItems
            : currentPage * pageSize}{' '}
          of {totalItems} matches
        </StyleSpan>
        {totalPages > 1 ? (
          <>
            <Button
              data-testid='risk-first-page'
              isDisabled={currentPage === 1}
              onClick={() => pageChanged(1)}
              iconLeft={<i className='fa fa-angle-double-left' />}
            />
            <Button
              data-testid='risk-prev-page'
              isDisabled={currentPage === 1}
              onClick={() => pageChanged(currentPage - 1)}
              iconLeft={<i className='fa fa-angle-left' />}
            />
            <input
              name='page-number'
              style={{
                width: '45px',
                textAlign: 'center',
                height: '32px',
                marginBottom: '5px',
                marginRight: '5px',
              }}
              value={currentPage}
              disabled
            />
            <StyleSpan>{` of ${totalPages} pages `}</StyleSpan>
            <Button
              data-testid='risk-next-page'
              isDisabled={totalPages === currentPage}
              onClick={() => pageChanged(currentPage + 1)}
              iconLeft={<i className='fa fa-angle-right' />}
            />
            <Button
              data-testid='risk-last-page'
              isDisabled={totalPages === currentPage}
              onClick={() => pageChanged(totalPages)}
              iconLeft={<i className='fa fa-angle-double-right' />}
            />
          </>
        ) : null}
      </HStack>
    </Primitive.div>
  );
};
