import React from 'react';
import styled from 'styled-components';
import { WHITE, GREY } from '../Constants/Styles';
import PanelTitle from './PanelTitle';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid ${GREY.lightTertiary};
  display: inline-block;
  background: #fff;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
`;

const StyledRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
  background: ${WHITE.primary};
  box-shadow: ${GREY.primary};
`;

const StyledTitleContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

const StyledButtonBar = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

interface Props {
  id?: string;
  title: string;
  description?: string;
  buttons?: React.ReactNode;
  buttonsContainerStyle?: React.CSSProperties;
  children: any;
}

export const RowTileV2 = ({
  id,
  title,
  buttons,
  description,
  children,
  buttonsContainerStyle,
}: Props) => (
  <StyledContainer data-testid={`${id}-wrapper`}>
    <StyledRow>
      <StyledTitleContainer data-testid={id}>
        <PanelTitle title={title} tooltipText={description} />
        <StyledButtonBar style={buttonsContainerStyle}>
          {buttons}
        </StyledButtonBar>
      </StyledTitleContainer>
      {children}
    </StyledRow>
    <div className='clearfix' />
  </StyledContainer>
);
