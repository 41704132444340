import React, { useRef, useState } from 'react';

import { MultiSelect, MultiSelectOption } from '@rtkwlf/fenrir-react';
import { isValidTarget, removeLeadingZerosFromIp } from '../utils/ipUtils';
import { debounce, isEqual } from 'lodash';

type MultiSelectProps = {
  items: MultiSelectOption[];
  update: (input: string[]) => void;
  debounceTimer?: number;
};

const DEBOUNCE_TIMER = 3000;
export const ScannerConfigMultiSelect: React.FC<MultiSelectProps> = ({
  items,
  update,
  debounceTimer = DEBOUNCE_TIMER,
}) => {
  const [options, setOptions] = useState<MultiSelectOption[]>(items);
  const [selected, setSelected] = useState<MultiSelectOption[]>(items);
  const [message, setMessage] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<MultiSelectOption[]>(items);

  const arrayEquality = (
    initialOptions: MultiSelectOption[],
    selectedOptions: MultiSelectOption[]
  ) => {
    const initialItems = initialOptions.map((i) => i.value).sort();
    const selectedItems = selectedOptions.map((i) => i.value).sort();
    return isEqual(initialItems, selectedItems);
  };

  const handleSelectionChange = (selectedOptions: MultiSelectOption[]) => {
    setSelected(selectedOptions);
  };

  const handleCreate = (
    newItem: string,
    updateFilterString: (filterString: string) => void
  ) => {
    const newIp = newItem.trim();
    const isValid = isValidTarget(newIp);

    if (isValid) {
      const trimmedIp = removeLeadingZerosFromIp(newIp);
      const isPresentInSelectedList = selected.some(
        (item) => item.value === trimmedIp
      );
      const isPresentInOptionList = options.some(
        (item) => item.value === trimmedIp
      );

      // Creating Option
      const newOption: MultiSelectOption = {
        label: trimmedIp,
        value: trimmedIp,
      };

      // If entered IP is present in the selected list
      if (isPresentInSelectedList) {
        setMessage(`Duplicate entry found: "${trimmedIp}"`);
        updateFilterString('');
        return;
      } else if (isPresentInOptionList) {
        // If entered IP is present in the option list
        setSelected((prev) => [...prev, newOption]);
        updateFilterString('');
        return;
      }

      // If entered IP is not present in option or selected list
      setOptions((prev) => [...prev, newOption]);
      setSelected((prev) => [...prev, newOption]);
    }

    setMessage(!isValid ? 'Please enter a valid IP address.' : '');
    updateFilterString('');
  };

  const debouncedUpdate = useRef(
    debounce((ipAddresses: MultiSelectOption[]) => {
      update(ipAddresses.map((i) => i.value));
      setLastUpdated(ipAddresses);
    }, debounceTimer)
  ).current;

  React.useEffect(() => {
    const hasInvalid = selected.some((i) => !isValidTarget(i.value));
    const isSameValues = arrayEquality(lastUpdated, selected);

    // clear the queue if the user reverts their selection
    // or if current selections are invalid
    if (hasInvalid || isSameValues) debouncedUpdate.cancel();
    else debouncedUpdate(selected);
  }, [selected, lastUpdated, debouncedUpdate]);

  const handleFilterChange = () => {
    if (message) {
      setMessage('');
    }
  };

  return (
    <MultiSelect
      isCreatable
      appearance={message ? 'warning' : 'neutral'}
      validationMessage={message}
      placeholder={`${selected.length} ${
        selected.length === 1 ? 'Entry' : 'Entries'
      }`}
      options={options}
      selectedOptions={selected}
      onSelectedOptionsChange={handleSelectionChange}
      handleCreate={handleCreate}
      onFilterChange={handleFilterChange}
    />
  );
};
