import React from 'react';

import { EnrichmentCard, EnrichmentFieldsType } from './AssetDetails';

import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HStack } from '@rtkwlf/fenrir-react';

interface CardToggleButtonProps {
  cardObj: EnrichmentCard;
  fieldsByOS: EnrichmentFieldsType | undefined;
  toggleCard: (carKey: string) => void;
}

const CardToggleButton = ({
  cardObj,
  fieldsByOS,
  toggleCard,
}: CardToggleButtonProps) => {
  if (!fieldsByOS) {
    return null;
  }

  return (
    <HStack
      padding='smedium'
      alignItems='center'
      justifyContent='center'
      cursor='pointer'
      borderColor='neutralDefault'
      borderStyle='solid'
      borderTopWidth='default'
      borderWidth='zero'
      textColor='brandDefault'
      onClick={() => toggleCard(cardObj.key)}
      data-testid='card-toggle-button'
    >
      {cardObj.isExpanded ? (
        <FontAwesomeIcon icon={faChevronCircleUp} />
      ) : (
        <FontAwesomeIcon icon={faChevronCircleDown} />
      )}
    </HStack>
  );
};

export default CardToggleButton;
