import { SOURCE_ICONS, SOURCE_TEXT } from '../constants';
import React from 'react';
import { iAssetCatalogDataType } from '../../../Global/assetReducer';

const AssetSourceRenderer: React.FunctionComponent<{
  record: iAssetCatalogDataType;
}> = ({ record }) => {
  const { source, id, clientUUID } = record;

  const detailsPagePath =
    source === SOURCE_TEXT.agent.toLocaleLowerCase()
      ? `/asset-details?clientid=${clientUUID}`
      : `/asset-details?assetid=${id}`;

  return (
    <>
      <a href={detailsPagePath}>
        <i className={`fa ${SOURCE_ICONS[source]}`} />
        {`  ${SOURCE_TEXT[source]}`}
      </a>
    </>
  );
};

export { AssetSourceRenderer };
