import { Tooltip, IconButton } from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@rtkwlf/fontawesome-pro-solid/faCheck';
import { useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type CopyIconProps = {
  field: { label: string; value: string };
};

export const CopyIcon = ({ field }: CopyIconProps) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(field.value);
    setCopied(true);

    return setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <IconButton
          aria-label='copy'
          onClick={copyToClipboard}
          appearance={copied ? 'default' : 'neutral'}
          margin='zero'
        >
          <FontAwesomeIcon
            icon={copied ? (faCheck as IconProp) : (faCopy as IconProp)}
          />
        </IconButton>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Tooltip.Text>{`Copy ${field.label}`}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};
