import { OPEN_MODAL, CLOSE_MODAL } from './modalActions';

export interface iModalState {
  isOpen: boolean;
  modal: string;
  payload: any;
}

const initialState: iModalState = {
  isOpen: false,
  modal: '',
  payload: {},
};

export const modalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        modal: action.payload.modal,
        payload: action.payload.payload,
      };
    case CLOSE_MODAL:
      return {
        isOpen: false,
        modal: '',
        payload: {},
      };
    default:
      return state;
  }
};
