// configureStore.js
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
} from 'redux';
import { connectRoutes, LocationState } from 'redux-first-router';
import ReduxThunk, { ThunkDispatch } from 'redux-thunk';
import queryString from 'query-string';

import { RoutesKey, routesMap } from './routesMap';

// Reducers
import { assetReducer, iAssetsState } from './Global/assetReducer';
import { caseReducer, iCases } from './Global/caseReducer';
import { customerReducer, iCustomerState } from './Global/customerReducer';
import { iConfigState, configReducer } from './Global/configReducer';
import { iModalState, modalReducer } from './Modals/modalReducer';
import { infoPaneReducer, iInfoPaneState } from './InfoPanes/infoPaneReducer';
import { iUserState, user } from './Global/userReducer';
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr';
import { riskReducer, iRiskState } from './Global/riskReducer';
import { scannerReducer, iScannerState } from './Global/scannerReducer';
import { statReducer, iStatReducer } from './Global/statReducer';
import { visualizationReducer } from './Global/visualizationReducer';
import { pageReducer, iPageReducerState } from './pageReducer';

export interface iGetState {
  (): iState;
}

export interface iState {
  asset: iAssetsState;
  cases: iCases;
  config: iConfigState;
  customer: iCustomerState;
  infoPane: iInfoPaneState;
  location: LocationState<{}, iState> & { type: RoutesKey };
  modal: iModalState;
  page: iPageReducerState;
  risk: iRiskState;
  scanner: iScannerState;
  stats: iStatReducer;
  toasts: ToastrState;
  user: iUserState;
  visualization: any;
}

//TODO: make this a real collection of all action schemas to achieve type safety when using Redux
export type iAction = any;

export type Dispatch = ThunkDispatch<iState, null, iAction>;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

export function configureStore(preloadedState: any) {
  const { reducer, middleware, enhancer } = connectRoutes(routesMap, {
    querySerializer: queryString,
  });
  const rootReducer = combineReducers({
    asset: assetReducer,
    cases: caseReducer,
    config: configReducer,
    customer: customerReducer,
    infoPane: infoPaneReducer,
    location: reducer,
    modal: modalReducer,
    page: pageReducer,
    risk: riskReducer,
    scanner: scannerReducer,
    stats: statReducer,
    toasts: toastrReducer,
    user,
    visualization: visualizationReducer,
  });

  const middlewares = applyMiddleware(middleware, ReduxThunk);

  const enhancers: StoreEnhancer =
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(enhancer, middlewares)
      : compose(enhancer, middlewares);

  return createStore(rootReducer, preloadedState, enhancers);
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
