import React from 'react';
import { ResponsiveContainer, Treemap } from 'recharts';
import { getColorForCVSS } from '../../../utils/formatters';

type HeatMapHandlers = {
  onClick: (entry: HeatMapHostEntry) => void;
  onMouseEnter: (entry: HeatMapHostEntry, x: number, y: number) => void;
  onMouseLeave: () => void;
};

export type HeatMapHostEntry = {
  name: string;
  children: { value: number }[];
};

type HeatMapProps = { data?: HeatMapHostEntry[] } & HeatMapHandlers;

const HeatMapCell: React.FC<HeatMapHandlers> = (props) => {
  const { onClick, onMouseEnter, onMouseLeave } = props;
  const { depth, x, y, width, height, name, children, value } = props as any;
  let styles: React.CSSProperties = {
    pointerEvents: 'none',
    strokeOpacity: 0,
    strokeWidth: 0,
  };
  let handlers: React.SVGProps<SVGRectElement> = {};
  if (depth === 1) {
    const entry = { name, children };
    styles = {
      strokeOpacity: 1,
      cursor: 'pointer',
      strokeWidth: 2,
    };
    handlers = {
      onClick: () => onClick(entry),
      onMouseEnter: () => onMouseEnter(entry, x + width / 2, y + height / 2),
      onMouseLeave: () => onMouseLeave(),
    };
  }
  return depth < 1 ? null : (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      style={{
        fill: getColorForCVSS(value),
        stroke: '#fff',
        ...styles,
      }}
      {...handlers}
    />
  );
};

const Heatmap: React.FC<HeatMapProps> = React.memo((props) => {
  const { data = [], onClick, onMouseEnter, onMouseLeave } = props;
  return (
    <div
      style={{
        width: '100%',
        height: '95%',
        background: '#f9f9f9',
        border: '1px solid #eeeeee',
      }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        <Treemap
          data={data}
          isAnimationActive={false}
          stroke='#fff'
          fill='#8884d8'
          content={
            <HeatMapCell
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          }
        />
      </ResponsiveContainer>
    </div>
  );
});

export default Heatmap;
