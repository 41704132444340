import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, HStack, TextareaField, Field } from '@rtkwlf/fenrir-react';
import { DisplayTable } from '../../Reusables/DisplayTable';
import { BLUE } from '../../Constants/Styles';
import { iRisk } from '../../Global/riskReducer';
import { useSelector } from 'react-redux';
import { customerSelectors } from '../../Global/customerReducer';
import { useMutation, useQuery } from '@tanstack/react-query';
import riskAPI from '../../utils/riskAPI';
import { toastr } from 'react-redux-toastr';
import { ThreeDots } from 'react-loader-spinner';

interface iNotesState {
  risk: iRisk;
  riskType: string;
}

const MAX_COMMENT_LENGTH = 5000;
const COMMENT_EMPTY_MEssAGE = 'Comment cannot be empty';
const COMMENT_MAX_LENGTH_MEssAGE = 'Comment should not exceeds 5000 characters';

const StyledRow = styled.div`
  padding: 10px 20px 10px 20px;
`;

const StyledTableRow = styled.div`
  padding: 10px 20px 10px 20px;
  max-height: 350px;
  overflow-x: scroll;
`;

const StyledTitle = styled.h2`
  margin-left: 10px;
  color: ${BLUE.primary};
  font-size: 24px;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const StyledInnerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLabel = styled.label`
  font-family: Lato;
  line-height: 20px;
  text-align: left;
`;

const StyledText = styled.p`
  font-family: Lato;
  line-height: 20px;
`;

const HEADERS = ['Note', 'Username', 'Modified Date'];
const FILTERS = [
  {
    text: '3',
    value: '3',
  },
  {
    text: '5',
    value: '5',
  },
  {
    text: '10',
    value: '10',
  },
];

const createNote = async (params: {
  riskId: string;
  customerId: string;
  note: string;
}) => {
  const apiURL = `/rootsecure/v1/customers/${params.customerId}/risks/${params.riskId}/notes`;
  const requestObj = {
    note: params.note,
  };
  const response = await riskAPI.post(apiURL, requestObj);
  return response;
};

const useQueryGetNotes = (riskId: string, customerId: string) => {
  return useQuery({
    queryKey: [`getNotes-${riskId}`],
    queryFn: () =>
      riskAPI.get(
        `/rootsecure/v1/customers/${customerId}/risks/${riskId}/notes`
      ),
    retry: false,
  });
};

export const Comments: React.FC<iNotesState> = ({ risk, riskType }) => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const [note, setNote] = useState('');
  const [newComment, setNewComment] = useState<boolean>(false);
  const [selectedText, setSelectedText] = useState<string>('');
  const [validationMessage, setValidationMessage] = useState('');

  const {
    data: notes,
    isLoading,
    refetch,
    isError,
  } = useQueryGetNotes(risk.id, customerId);

  const onClick = (value: any) => setSelectedText(value[0] || '');

  const { mutate: createNewNote } = useMutation({
    mutationFn: createNote,
    onError: () => {
      toastr.error('Failed to create comment', '');
    },
    onSuccess: () => {
      toastr.success('Comment created successfully', '');
      refetch();
    },
  });

  useEffect(() => {
    if (isError) {
      toastr.error('Failed to fetch comments', '');
    }
  }, [isError]);

  const comments = notes?.data.data || [];

  const handleOnChange = (e: any) => {
    setNote(e.target.value);
    setValidationMessage('');

    if (e.target.value.length > MAX_COMMENT_LENGTH) {
      setValidationMessage(COMMENT_MAX_LENGTH_MEssAGE);
    }
  };

  const handleCreateNote = useCallback(() => {
    const commentText = note.trim();
    if (commentText.length === 0) {
      setValidationMessage(COMMENT_EMPTY_MEssAGE);
      return false;
    } else if (commentText.length > MAX_COMMENT_LENGTH) {
      setValidationMessage(COMMENT_MAX_LENGTH_MEssAGE);
      return false;
    }

    if (!validationMessage) {
      createNewNote({
        riskId: risk.id,
        customerId,
        note: commentText,
      });
      setNote('');
      setNewComment(false);
      setValidationMessage('');
    }
  }, [createNewNote, customerId, note, risk.id, validationMessage]);

  return (
    <Fragment>
      <StyledRow>
        <StyledTitle>Comments</StyledTitle>
        <StyledInnerRow>
          <StyledColumn
            style={{ margin: '10px', flexBasis: '50%', minWidth: '30%' }}
          >
            <StyledColumn>
              <StyledLabel htmlFor='riskHost'>Risk</StyledLabel>
              <StyledText>{risk?.issueName}</StyledText>
            </StyledColumn>
            <StyledColumn>
              <StyledLabel htmlFor='riskHost'>Host</StyledLabel>
              <StyledText>{risk?.attributes?.hostDisplayName}</StyledText>
            </StyledColumn>
          </StyledColumn>
          <StyledColumn
            style={{
              minWidth: '65%',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            <StyledLabel htmlFor='riskNote'>Note</StyledLabel>(Click on a row to
            preview)
            <StyledText>{selectedText}</StyledText>
          </StyledColumn>
        </StyledInnerRow>
        <StyledColumn>
          {newComment ? (
            <Fragment>
              <StyledColumn>
                <StyledLabel htmlFor='riskNote'>Comment</StyledLabel>
                <Field.Root
                  appearance={validationMessage ? 'danger' : undefined}
                >
                  <Field.Content>
                    <TextareaField
                      value={note}
                      onChange={handleOnChange}
                      id='riskNote'
                    />
                  </Field.Content>
                  {validationMessage && (
                    <Field.Message>{validationMessage}</Field.Message>
                  )}
                </Field.Root>
              </StyledColumn>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                  variant='secondary'
                  onClick={(e: any) => {
                    setNote('');
                    setNewComment(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleCreateNote}>Submit</Button>
              </div>
            </Fragment>
          ) : (
            <Button
              style={{ width: '65px', marginLeft: '10px' }}
              onClick={(e: any) => {
                setNewComment(true);
              }}
            >
              Create
            </Button>
          )}
        </StyledColumn>
      </StyledRow>

      {isLoading ? (
        <HStack xAlign='center' yAlign='center'>
          <ThreeDots color={BLUE.tertiary} height={50} width={50} />
        </HStack>
      ) : (
        comments.length > 0 && (
          <StyledTableRow data-testid={`risk-comments-wrapper`}>
            <DisplayTable
              rowClick={onClick}
              headers={HEADERS}
              rows={comments.map((comment: any) => [
                comment.note,
                comment.lastModifiedUser,
                comment.lastModifiedTime,
              ])}
              filterOptions={FILTERS}
            />
          </StyledTableRow>
        )
      )}
    </Fragment>
  );
};
