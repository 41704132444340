import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import {
  Button,
  Primitive,
  Skeleton,
  Text,
  VStack,
  atoms,
} from '@rtkwlf/fenrir-react';

import { SOURCE } from '../../../Constants/Risks';
import { useAppDispatch } from '../../../hooks';
import { Observations } from '../../../Modals/Modals/Observations';
import { customerSelectors } from '../../../Global/customerReducer';
import { iRisk, riskSelectors } from '../../../Global/riskReducer';
import { RoutesKey } from '../../../routesMap';

import AgentTreeView from './AgentTreeView';
import { useRiskDetails } from './useRiskDetails';
import { decompressJovalAnnotations } from './treeviewFunctions';

const panel = atoms({
  margin: 'default',
  padding: 'medium',
  backgroundColor: 'monoWhite',
  borderRadius: 'default',
});

const NoDataStyle = atoms({
  width: 'full',
  height: '12rem',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'widgetTertiary',
  alignItems: 'center',
});

const RiskDetailsContainer = styled(Primitive.div)`
  margin: 0px -20px;
`;

const RenderNoData = () => (
  <Primitive.div className={NoDataStyle}>No Data</Primitive.div>
);

export const RiskDetails = () => {
  const dispatch = useAppDispatch();
  const customerId = useSelector(customerSelectors.getCustomerId);
  const location = useSelector(riskSelectors.getLocation);
  const query = location.query || {};
  const riskId: string = query.riskid || '';

  useEffect(() => {
    if (!riskId) {
      toastr.error(
        'Failed to load risk details data',
        'Please specify the Risk ID'
      );

      dispatch({ type: RoutesKey.RISKS });
    }
  }, [riskId, dispatch]);

  const {
    data: riskDetailsData,
    isFetching,
    isError,
    error: riskDetailsError,
  } = useRiskDetails(customerId, true, riskId, (data: iRisk) => {
    return data;
  });

  useEffect(() => {
    if (isError) {
      const axiosError = riskDetailsError as AxiosError;

      if (axiosError.response?.status === 404) {
        toastr.error('The specified Risk ID is not valid', '');
      } else {
        toastr.error('Failed to load Risk details data', '');
      }

      dispatch({ type: RoutesKey.RISKS });
    }
  }, [isError, riskDetailsError, dispatch]);

  const readableAnnotations = useMemo(() => {
    return decompressJovalAnnotations(riskDetailsData);
  }, [riskDetailsData]);

  const handleGoBack = () => {
    const url = document.referrer || '/risks';
    window.open(url, '_self');
  };

  return (
    <>
      <Button
        data-testid='back-to-risks'
        variant='secondary'
        iconLeft={<i className='fa fa-angle-double-left' />}
        onClick={handleGoBack}
        style={{ margin: '1rem 0px 0px -10px' }}
      >
        Back to Risks
      </Button>

      <RiskDetailsContainer>
        <Primitive.div className={panel} style={{ margin: '1rem 11px' }}>
          {!isFetching ? (
            isError || !riskDetailsData ? (
              <RenderNoData />
            ) : (
              !isFetching && (
                <VStack textAlign='left' yAlign='top'>
                  <Text
                    styledAs='custom'
                    fontSize='xlarge'
                    fontWeight='bold'
                    fontFamily='heading'
                    textColor='brandDefault'
                  >
                    {`Additional Details: ${riskDetailsData?.issueName}`}
                  </Text>

                  {riskDetailsData.source === SOURCE.AGENT ? (
                    Object.keys(readableAnnotations).length === 0 ? (
                      <RenderNoData />
                    ) : (
                      <AgentTreeView annotations={readableAnnotations} />
                    )
                  ) : riskDetailsData.observations?.length ? (
                    <Observations
                      observations={riskDetailsData.observations}
                      isModal={false}
                    />
                  ) : (
                    <RenderNoData />
                  )}
                </VStack>
              )
            )
          ) : (
            <Skeleton
              height='12rem'
              borderRadius={undefined}
              marginY='medium'
            />
          )}
        </Primitive.div>
      </RiskDetailsContainer>
    </>
  );
};

export default RiskDetails;
