import styled from 'styled-components';
import { ColumnProps, Table } from '../../../Reusables/table/Table';
import { AscAgentInfo } from '../utils';

const StyledContainer = styled.div`
  display: block;
  overflow-x: auto;
  padding-bottom: 40px;

  table thead th {
    white-space: nowrap;
  }

  td {
    vertical-align: top;
  }
`;

export interface Props {
  ascAgentArray: AscAgentInfo[];
}

const columns: ColumnProps<AscAgentInfo>[] = [
  {
    key: 'agentList',
    dataIndex: 'agentList',
    title: 'Agents Not Being Scanned',
    justify: 'flex-start',
    render: ({ record: { agentList } }) => (
      <>
        {agentList.map((agent) => (
          <div key={agent}>{agent}</div>
        ))}
      </>
    ),
  },
  {
    key: 'agentCount',
    dataIndex: 'agentCount',
    title: 'Agents Not Being Scanned Count',
    justify: 'flex-start',
  },
  {
    key: 'agentPercent',
    dataIndex: 'agentPercent',
    title: 'Percentage Agents Not Being Scanned',
    justify: 'flex-start',
    render: ({ record: { agentPercent } }) => <>{agentPercent}</>,
  },
];

const AscAgentTable = ({ ascAgentArray }: Props) => {
  return (
    <StyledContainer data-testid='Asc-agent-table'>
      <Table
        data={ascAgentArray}
        columns={columns}
        dataUniqueKey='id'
        emptyComponent={<div>No Data Available</div>}
      />
    </StyledContainer>
  );
};

export default AscAgentTable;
