import { SET_USERS } from './userActions';

export interface iUser {
  email: string;
  firstname: string;
  lastname: string;
}

export interface iUserState {
  users: Array<iUser>;
}

const initialState: iUserState = {
  users: [],
};

export const user = (state = initialState, action: any) => {
  const { payload, type } = action;
  const newState = { ...state };
  switch (type) {
    case SET_USERS:
      newState.users = payload;
      return newState;
    default:
      return state;
  }
};
