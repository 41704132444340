import React from 'react';
import { iAssetCatalogDataType } from '../../../Global/assetReducer';
import { useAssetCatalog } from './hooks';
import { ColumnProps, Table } from '../../../Reusables/table/Table';
import { AssetSourceRenderer } from './AssetSourceRenderer';
import { useDeleteAssets } from '../useDeleteAssets';
import { isFreshAgent } from './utils';
import {
  Banner,
  BannerContent,
  BannerDescription,
  BannerIcon,
  BannerRoot,
  BannerTextContent,
  Button,
  Checkbox,
  Heading,
  HStack,
  Primitive,
  Text,
  VStack,
} from '@rtkwlf/fenrir-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalBox } from '../../../Reusables/ModalBox';
import styled from 'styled-components';

/**
 * ALl the possible DUPLICATE_CRITERIA as columns.
 */
const ALL_COLUMNS: ColumnProps<iAssetCatalogDataType>[] = [
  {
    key: 'source',
    title: 'Source',
    render: AssetSourceRenderer,
  },
  { key: 'ip', dataIndex: 'ip', title: 'IP' },
  {
    key: 'deviceName',
    dataIndex: 'deviceName',
    title: 'Device Name',
  },
  { key: 'mac', dataIndex: 'mac', title: 'MAC' },
];

const TableContainer = styled(Primitive.div)`
  tbody td {
    border-bottom: none;
  }
`;

type Props = {
  assets: Array<iAssetCatalogDataType>;
  isOpen: boolean;
  onClose: () => void;
};

const DeleteDuplicateModal: React.FC<Props> = ({ assets, onClose, isOpen }) => {
  const { selectedCriteriaKeys } = useAssetCatalog();
  const assetIDs = assets.map((a) => a.id);
  const { onDelete } = useDeleteAssets(assetIDs, onClose);

  const [reviewed, setReviewed] = React.useState(false);

  React.useEffect(() => {
    // reset checkbox on modal open changing
    setReviewed(false);
  }, [isOpen]);

  const columns = React.useMemo(
    () =>
      ALL_COLUMNS.filter((c) => selectedCriteriaKeys.includes(c.key as string)),
    [selectedCriteriaKeys]
  );

  const hasFreshAgent = assets.some(isFreshAgent);

  return (
    <ModalBox
      title={'Delete Potential Duplicate Asset(s)'}
      isOpen={isOpen}
      width='690px'
      onCloseModal={onClose}
      footer={
        <HStack xAlign='right' gap='xsmall' height='2rem'>
          <Button
            onClick={() => onDelete()}
            isDisabled={!reviewed}
            appearance='danger'
          >
            Delete
          </Button>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      }
    >
      <VStack paddingX='large' paddingBottom='large'>
        <Heading
          level='3'
          data-testid='duplicate-delete-heading'
        >{`${assetIDs.length} Asset(s) Selected`}</Heading>

        <Text>
          {`Are you sure you want to delete ${
            assetIDs.length > 1 ? 'all ' : ''
          }potential duplicate asset(s) with these
          identical values?`}
        </Text>

        <TableContainer width='full'>
          <Table<iAssetCatalogDataType>
            data={[assets[0]]}
            columns={columns}
            dataUniqueKey='id'
          />
        </TableContainer>

        <BannerRoot appearance='warning'>
          <BannerContent>
            <BannerIcon>
              <FontAwesomeIcon icon={faExclamationCircle} />
            </BannerIcon>
            <BannerTextContent>
              <BannerDescription>
                Deleting potential duplicate asset(s) cannot be undone.
                <br />
                Any associated risks will be deleted along with the asset(s).
              </BannerDescription>
            </BannerTextContent>
          </BannerContent>
        </BannerRoot>
        {hasFreshAgent && (
          <Banner
            marginTop='small'
            appearance='danger'
            description='You cannot delete any Agents that were active in the last 24 hours.'
          />
        )}

        <Checkbox.Root
          isChecked={reviewed}
          onCheckedChange={() => setReviewed((r) => !r)}
        >
          <Checkbox.Text>
            I have reviewed the asset(s) and understand the implications of
            deleting them.
          </Checkbox.Text>
        </Checkbox.Root>
      </VStack>
    </ModalBox>
  );
};

export { DeleteDuplicateModal };
