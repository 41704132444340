import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';

import { Button, HStack, Primitive } from '@rtkwlf/fenrir-react';
import { AgentDetails } from './AgentDetails';
import { NonAgentDetails } from './NonAgentDetails';
import {
  AgentDetailsType,
  HardwareDetailsType,
  NonAgentDetailsType,
} from './AssetDetails';
import PanelTitle from '../../../Reusables/PanelTitle';
import { CSVLink } from 'react-csv';

import { configSelectors } from '../../../Global/configReducer';
import { customerSelectors } from '../../../Global/customerReducer';
import { assetSelectors, iAsset } from '../../../Global/assetReducer';
import {
  assetProfileHistoryType,
  getAssetDetailsCSVData,
  getAssetImage,
  processAssetDetailsInfo,
  resetSensorDefaults,
} from './utils';

import type {
  AssetDetailsCSVDataType,
  AssetResetSensorType,
} from '../../../types/assets';
import {
  ASSET_PROFILE_LABEL,
  EXPORT_CSV_TOOLTIP,
} from '../../../Constants/Common';

import styled from 'styled-components';
import { DownloadCSVIcon } from '../../../Reusables/DownloadCSVIcon';
import { ModalBox } from '../../../Reusables/ModalBox';

const WrapHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ProfilePic = styled(Primitive.img)`
  margin: -8px 15px 0;
`;

const exportAssetsHeaders = [
  { label: 'Device Name', key: 'deviceName' },
  { label: 'Detailed Name', key: 'os' },
  { label: 'Detailed Name Reason', key: 'foundBy' },
  { label: 'Manufacturer Name', key: 'manufacturer' },
  { label: 'DNS HostName', key: 'dnsHostname' },
  { label: 'NetBIOS Name', key: 'netBIOSName' },
  { label: 'IPv4 Address', key: 'iPv4Address' },
  { label: 'Asset ID', key: 'assetId' },
  { label: 'Profile Created', key: 'assetCreatedElem' },
  { label: 'Profile Last Updated', key: 'assetLastUpdatedElem' },
];

export const resetSensor = async (params: AssetResetSensorType) => {
  return resetSensorDefaults(params);
};

export type Props = {
  sourceType: string;
  profileResponse: Array<assetProfileHistoryType>;
  data: iAsset;
  hardWareDetails: HardwareDetailsType;
};

export const AssetInfoPanel = ({
  sourceType,
  profileResponse,
  data,
  hardWareDetails,
}: Props) => {
  const queryClient = useQueryClient();
  const servicesHost = useSelector(configSelectors.getServiceHost);
  const customerId = useSelector(customerSelectors.getCustomerId);
  const location = useSelector(assetSelectors.getLocation);
  const query = location.query || {};
  const [assetData, setAssetData] = useState<
    AgentDetailsType | NonAgentDetailsType
  >();
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [csvData, setCSVData] = useState<Array<AssetDetailsCSVDataType>>();

  useEffect(() => {
    if (data) {
      const source = data?.source ?? 'nonAgent';
      const detailsData = processAssetDetailsInfo(
        data,
        profileResponse,
        source
      ) as AgentDetailsType | NonAgentDetailsType;

      setAssetData(detailsData);
      setCSVData(getAssetDetailsCSVData(detailsData));
    }
  }, [data, profileResponse]);

  const profileImage = useMemo(() => {
    let imgSrc = '';
    if (data) {
      imgSrc =
        getAssetImage(data.osInfo.product) !== 'images/unknown.jpg'
          ? getAssetImage(data.osInfo.product)
          : getAssetImage(data.osInfo.name);
    }

    return (
      <ProfilePic src={imgSrc} width='2rem' height='2rem' alt='profile_image' />
    );
  }, [data]);

  const { mutate: resetSensorMuted, isPending: isResetSensorLoading } =
    useMutation({
      mutationFn: resetSensor,
      onError: async () => {
        toastr.error('Reset asset sensor failed', '');
        setShowConfirmationBox(false);
      },
      onSuccess: async () => {
        toastr.success('Successfully reset the asset sensor', '');
        queryClient.invalidateQueries({
          queryKey: [`getAssetDetails-${query.assetid || query.clientid}`],
        });
        setShowConfirmationBox(false);
      },
    });

  const handleResetSensor = () => {
    if (query.assetid || query.clientid) {
      const params: AssetResetSensorType = {
        customerId: customerId,
        assetId: query.assetid || query.clientid || '',
        servicesHost: servicesHost,
      };
      resetSensorMuted(params);
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <PanelTitle title={ASSET_PROFILE_LABEL} />
        </div>
        <WrapHeaderRight className='col-md-6'>
          <Button
            variant='secondary'
            onClick={() => setShowConfirmationBox(true)}
            iconLeft={<i className='fa fa-repeat' />}
          >
            Reset to Sensor Defaults
          </Button>
          {csvData ? (
            <CSVLink
              data={csvData}
              headers={exportAssetsHeaders}
              filename={'selected-asset-profile.csv'}
            >
              <DownloadCSVIcon tooltipText={EXPORT_CSV_TOOLTIP} />
            </CSVLink>
          ) : null}
        </WrapHeaderRight>
      </div>
      {assetData ? (
        sourceType === 'agent' ? (
          <AgentDetails
            data={assetData as AgentDetailsType}
            hardWareDetails={hardWareDetails}
            profileImage={profileImage}
          />
        ) : (
          <NonAgentDetails
            data={assetData as NonAgentDetailsType}
            profileImage={profileImage}
          />
        )
      ) : null}
      <ModalBox
        title={'Confirm Reset to Sensor Defaults'}
        onCloseModal={() => setShowConfirmationBox(false)}
        isOpen={showConfirmationBox}
        width='520px'
        footer={
          <HStack yAlign='center' xAlign='between'>
            <Button
              onClick={() => setShowConfirmationBox(false)}
              variant='secondary'
            >
              Cancel
            </Button>
            <Button
              onClick={handleResetSensor}
              isLoading={isResetSensorLoading}
            >
              Confirm
            </Button>
          </HStack>
        }
      >
        <Primitive.div paddingX='large' paddingBottom='large'>
          Are you sure you want to reset to sensor defaults? <br /> All changes
          made to the sensor will be reset and cannot be recovered.
        </Primitive.div>
      </ModalBox>
    </>
  );
};

export default AssetInfoPanel;
