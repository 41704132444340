import { ToastData } from '@rtkwlf/fenrir-react';

export const TOASTS: { [key: string]: ToastData } = {
  ALL_SUCCESS: {
    id: 'iva_stop_all_success',
    title: 'Success',
    message:
      'Your request to disable the scan schedules and stop active scans was successfully queued.',
    appearance: 'success',
    duration: 20,
  },
  ALL_FAIL: {
    id: 'iva_stop_all_fail',
    title: 'Error',
    message:
      'Scan schedules could not be disabled and active host scans could not be stopped.',
    appearance: 'danger',
    duration: 20,
  },
  SCHEDULE_SUCCESS: {
    id: 'iva_disable_schedule_success',
    title: 'Success',
    message:
      'Your request to disable the scan schedules was successfully queued.',
    appearance: 'success',
    duration: 20,
  },
  SCHEDULE_FAIL: {
    id: 'iva_disable_schedule_fail',
    title: 'Error',
    message: 'Scan schedules could not be disabled.',
    appearance: 'danger',
    duration: 20,
  },
  SCAN_SUCCESS: {
    id: 'iva_stop_scans_success',
    title: 'Success',
    message: 'Your request to stop active scans was successfully queued.',
    appearance: 'success',
    duration: 20,
  },
  SCAN_FAIL: {
    id: 'iva_stop_scans_fail',
    title: 'Error',
    message: 'Active host scans could not be stopped.',
    appearance: 'danger',
    duration: 20,
  },
  ENABLE_SUCCESS: {
    id: 'iva_enable_scan_success',
    title: 'Success',
    message:
      'Your request to enable the scan schedules was successfully queued.',
    appearance: 'success',
    duration: 20,
  },
  ENABLE_FAIL: {
    id: 'iva_enable_scan_fail',
    title: 'Error',
    message: 'Scan schedules could not be enabled.',
    appearance: 'danger',
    duration: 20,
  },
};
