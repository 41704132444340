import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { CustomerSelection } from '../../Modals/Modals/CustomerSelection';
import { HStack, Skeleton } from '@rtkwlf/fenrir-react';
import { useLocalStorage } from '@uidotdev/usehooks';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledTitle = styled.div`
  margin-right: 5px;
  font-weight: bold;
`;

const StyledIcon = styled.i`
  margin: auto 0px auto 20px;

  &:hover {
    cursor: pointer;
  }
`;

const blankedOutId = <Skeleton height='1.25rem' width='50%' margin={'zero'} />;

export const CustomerSelect: React.FC = () => {
  const [currentCustomer, _saveCustomer] = useLocalStorage('currentCustomer', {
    name: '',
    label: '',
    deploymentID: '',
    id: '',
  });
  const [showModal, setShowModal] = useState(false);
  const hideCustomer = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const newCustomer = urlParams.get('id');
    return newCustomer && newCustomer !== currentCustomer.id;
  }, [currentCustomer]);

  return (
    <HStack>
      <StyledContainer>
        <StyledRow>
          <StyledTitle>Name:</StyledTitle>
          {hideCustomer ? blankedOutId : currentCustomer.name || blankedOutId}
        </StyledRow>
        <StyledRow>
          <StyledTitle>Deployment ID:</StyledTitle>
          {hideCustomer
            ? blankedOutId
            : currentCustomer.deploymentID || blankedOutId}
        </StyledRow>
        <StyledRow>
          <StyledTitle>ID:</StyledTitle>
          {hideCustomer ? blankedOutId : currentCustomer.id || blankedOutId}
        </StyledRow>
      </StyledContainer>

      <StyledIcon
        className='fa fa-search fa-lg'
        onClick={() => setShowModal(true)}
      />

      {showModal && (
        <ModalBox
          onCloseModal={() => setShowModal(false)}
          position={ModalPositionType.top}
        >
          <CustomerSelection onCloseModal={() => setShowModal(false)} />
        </ModalBox>
      )}
    </HStack>
  );
};
