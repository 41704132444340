import CaseManagement from './CaseManagement';
import Overview from './Overview';
import Risks from './Risks';
import RiskDetails from './Risks/RiskDetails';
import ScannerConfig from './ScannerConfig';
import Unlicensed from './Unlicensed';
import Assets from './Assets/index';
import AssetDetails from './Assets/AssetDetails';
import AttackSurfaceCoverage from './AttackSurfaceCoverage';

const Pages = {
  Overview,
  Unlicensed,
  ScannerConfig,
  CaseManagement,
  Risks,
  RiskDetails,
  Assets,
  AssetDetails,
  AttackSurfaceCoverage,
};

export default Pages;
