import React from 'react';
import { Grid, Primitive } from '@rtkwlf/fenrir-react';
import { ArtifactObject, longestKey } from './treeviewFunctions';
import { artifactExclusionList } from './AgentTreeView';
import ExpandableString from './ExpandableString';

interface ExpandedNestedObjectProps {
  object: ArtifactObject;
  index: number;
}

const ExpandedNestedObject: React.FC<ExpandedNestedObjectProps> = ({
  object,
  index,
}) => {
  return (
    <Primitive.div
      key={`expandedNestedJsonWrapperIndex${index}`}
      style={{ width: '85vw' }}
      overflow='auto'
    >
      {index > 0 && (
        <Primitive.div paddingLeft={'xxlarge'} key={`${index}separator`}>
          <hr style={{ margin: '0' }} />
        </Primitive.div>
      )}
      <Grid.Root
        key={`expandedNestedJsonGridIndex${index}`}
        columns={`${longestKey(object, artifactExclusionList)}ch 1fr`}
        paddingBottom='xsmall'
        paddingTop='xsmall'
        paddingLeft='xxlarge'
        data-testid='node-child-metadata-json'
        overflow='auto'
      >
        {Object.keys(object).map((key, objIndex) => {
          return (
            <React.Fragment
              key={`expandedNestedJsonGridItemsWrapperIndex${objIndex}`}
            >
              <Grid.Item
                fontWeight='bold'
                textTransform='capitalize'
              >{`${key}:`}</Grid.Item>

              <Grid.Item fontWeight='bold' textColor='warningDefault'>
                <ExpandableString
                  value={object[key]?.toString()}
                  expandedByParent={false}
                  canExpandSelf={true}
                />
              </Grid.Item>
            </React.Fragment>
          );
        })}
      </Grid.Root>
    </Primitive.div>
  );
};

export default ExpandedNestedObject;
