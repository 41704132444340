import { ScannerAction } from './scannerActions';
import {
  QueuedScan,
  ScannerSummary,
  Schedule,
  SensorConfig,
  SensorCredential,
  SensorNetwork,
  SensorPreferences,
  SensorStatus,
} from '../types/scanner';
import { iAsset } from './assetReducer';
import { ScannerActionTypes } from './actionEnums';
import { iState } from '../configureStore';

/**
 * Each scanner will have a basic summary from the list API,
 * from there, we load other detail fields on demand, so they may not exist.
 */
export type iScanner = ScannerSummary & {
  status?: SensorStatus;
  scans?: QueuedScan[];
  credScans?: Map<string, SensorCredential>;
  schedules?: Map<string, Schedule>;
  config?: SensorConfig;
  preferences?: SensorPreferences;
  network?: SensorNetwork;
};

// TODO: move asset types to somewhere else
export interface iAssetCategory {
  name: string;
  risk: number;
  vulns: number;
  assets: Array<iAsset>;
}

export interface iScannerGroup {
  id: string;
  name: string;
  sensors: Array<iScanner>;
}

export interface iScannerState {
  scanners: Map<string, iScanner>;
  scanner: ScannerSummary;
}

const initialState: iScannerState = {
  scanners: new Map(),
  scanner: JSON.parse(localStorage.getItem('scanner')!),
};

export const scannerReducer = (state = initialState, action: ScannerAction) => {
  // updates to scanners map must happen to a cloned map
  const newState = {
    ...state,
  };

  switch (action.type) {
    case ScannerActionTypes.SET_CURRENT_SCANNER: {
      newState.scanner = action.scanner;
      return newState;
    }

    case ScannerActionTypes.LOAD_SCANNERS: {
      newState.scanners = new Map();
      action.scanners?.forEach((scanner) => {
        newState.scanners.set(scanner.id, scanner);
      });
      if (!newState.scanner?.id) {
        newState.scanner = action.scanners?.[0];
      }
      return newState;
    }
    case ScannerActionTypes.LOAD_SCANS: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          scans: payload.scans,
        };
        newState.scanners.set(payload.id, newData);
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.LOAD_CRED_SCANS: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          credScans: new Map(
            payload.data.map((item: SensorCredential) => [item.id, item])
          ),
        };
        newState.scanners.set(payload.id, newData);
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.SET_CREDENTIAL: {
      const {
        payload: { id, data },
      } = action;

      const scanner = newState.scanners.get(id);

      if (!scanner) {
        // can't set credential on a non-existing scanner
        return state;
      }

      // Clone Map objects to ensure referential inequality
      const credScans = scanner.credScans
        ? new Map(scanner.credScans)
        : new Map();
      credScans.set(data.id, data);

      newState.scanners = new Map(state.scanners);
      newState.scanners.set(id, {
        ...scanner,
        credScans,
      });

      return newState;
    }
    case ScannerActionTypes.LOAD_NETWORK: {
      const { payload } = action;
      const scanner = newState.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          network: payload.data,
        };
        if (newData) {
          newState.scanners.set(payload.id, newData);
          return newState;
        }
      }
      return state;
    }
    case ScannerActionTypes.LOAD_SCHEDULES: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          schedules: new Map<string, Schedule>(
            payload.data.map((item: Schedule) => [item.id ? item.id : '', item])
          ),
        };
        if (newData) {
          newState.scanners.set(payload.id, newData);
          return newState;
        }
      }
      return state;
    }
    case ScannerActionTypes.ADD_SCHEDULE: {
      const { payload } = action;
      const scanner = newState.scanners.get(payload.id);
      if (scanner && scanner.schedules && payload.schedule.id) {
        scanner.schedules.set(payload.schedule.id, payload.schedule);
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.UPDATE_SCHEDULE: {
      const { payload } = action;
      if (payload.schedule.id) {
        const schedule = state.scanners
          ?.get(payload.id)!
          .schedules?.get(payload.schedule.id);
        state.scanners.get(payload.id)?.schedules?.set(payload.schedule.id, {
          ...schedule,
          ...payload.schedule,
        });
        return { ...state };
      }
      return state;
    }
    case ScannerActionTypes.REMOVE_SCHEDULE: {
      const { payload } = action;
      newState.scanners = new Map(state.scanners);
      const scanner = newState.scanners.get(payload.id);
      if (scanner && scanner.schedules) {
        scanner.schedules = new Map<string, Schedule>(scanner.schedules);
        scanner.schedules.delete(payload.scheduleId);
        newState.scanners.set(payload.id, { ...scanner });
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.REMOVE_CREDS: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        scanner.credScans?.delete(payload.credsId);
        newState.scanners.set(payload.id, { ...scanner });
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.LOAD_PREFERENCES: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          preferences: payload.data,
        };
        newState.scanners.set(payload.id, newData);
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.TOGGLE_SCAN: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
        };
        if (newData.preferences) {
          newData.preferences[payload.index].enabled =
            !newData.preferences[payload.index].enabled;
          newState.scanners.set(payload.id, newData);
          return newState;
        }
      }
      return state;
    }
    case ScannerActionTypes.LOAD_CONFIG: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.id);
      if (scanner) {
        const newData = {
          ...scanner,
          config: payload.data,
        };
        newState.scanners.set(payload.id, newData);
        return newState;
      }
      return state;
    }
    case ScannerActionTypes.UPDATE_STATUS: {
      const { payload } = action;
      const scanner = state.scanners.get(payload.uuid);
      if (scanner) {
        const newData = {
          ...scanner,
          status: payload,
        };
        if (scanner && newData && newData.id) {
          newState.scanners.set(payload.uuid, newData);
          return newState;
        }
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

// Selectors

const getScanners = (store: iState): Map<string, iScanner> =>
  store.scanner.scanners;
const getCurrentScannerID = (store: iState): string =>
  store.scanner.scanner?.id;
const getCurrentScannerCredScans = (
  store: iState
): Map<string, SensorCredential> | undefined => {
  const id = getCurrentScannerID(store);
  return store.scanner.scanners.get(id)?.credScans;
};

export const scannerSelectors = {
  getScanners,
  getCurrentScannerID,
  getCurrentScannerCredScans,
};
