// pageReducer.js
import { NOT_FOUND } from 'redux-first-router';

export interface iPageReducerState {
  OVERVIEW: string;
  SCANNER_CONFIG: string;
  CASE_MANAGEMENT: string;
  RISKS: string;
  RISK_DETAILS: string;
  BETA_RISKS: string;
  ASSETS: string;
  ASSET_DETAILS: string;
  ATTACK_SURFACE_COVERAGE: string;
  REACH_GUIDES: string;
  [NOT_FOUND]: string;
}

const components: any = {
  CASE_MANAGEMENT: 'CaseManagement',
  OVERVIEW: 'Overview',
  SCANNER_CONFIG: 'ScannerConfig',
  RISKS: 'Risks',
  RISK_DETAILS: 'RiskDetails',
  ASSETS: 'Assets',
  ASSET_DETAILS: 'AssetDetails',
  ATTACK_SURFACE_COVERAGE: 'AttackSurfaceCoverage',
  BETA_RISKS: 'BetaRisks',
  REACH_GUIDES: 'Overview',
  [NOT_FOUND]: 'Unlicensed',
};

export const pageReducer = (state = 'Overview', action: any = {}) =>
  components[action.type] || state;
