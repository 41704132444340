/* eslint-disable no-undef */
import { saveAs } from 'file-saver';

import { JSZip } from 'jszip';
import moment from 'moment';

import {
  initRiskViewCSV,
  onRiskTrendsCSVbtnClick,
} from '../Pages/Overview/RiskScoreTrendUtils';
import { parseRawData } from './parsers';

export function isScoutClientMac() {
  const detailedName = document.getElementById('asset-profile-detailedname');
  return detailedName && detailedName.innerText === 'macOS';
}

export function escapeVal(value) {
  let str = value;
  if (
    str !== null &&
    str !== undefined &&
    typeof str === 'string' &&
    str !== ''
  ) {
    str = str.replace(/"/g, '""');
    str = str.replace(/[\r\n\f\v]/g, '\n');
  }
  return '"' + str + '"';
}

export function getSensorId() {
  const sensorStr = localStorage.getItem('sensor');
  const sensor = JSON.parse(sensorStr);

  return sensor !== undefined && sensor !== null && !Object.isEmpty(sensor)
    ? sensor.uuid
    : '';
}

function getSensorLabel() {
  const sensorStr = localStorage.getItem('sensor');
  const sensor = JSON.parse(sensorStr);

  return sensor !== undefined && sensor !== null && !Object.isEmpty(sensor)
    ? sensor.label
    : '';
}

function setSensor(id, label) {
  const sensor = {
    uuid: id,
    label: label,
  };

  localStorage.setItem('sensor', JSON.stringify(sensor));
}

export function tenantSelect(val) {
  const id = getSensorId();
  const label = getSensorLabel();
  const curid = id.split(',')[0];

  if (val.length > 0) {
    setSensor(curid + ',' + val, label);
  } else {
    setSensor(curid, label);
  }

  window.location.reload();
}

export function RsNotificationError(message, details) {
  return new PNotify({
    title: message,
    text: details,
    type: 'error',
    styling: 'bootstrap3',
  });
}

export const isPositiveInteger = (str) =>
  String(str).isInteger() && Number(str) >= 0;

export function getScoutTaskList() {
  if (
    typeof taskListRawDataArr == 'undefined' ||
    taskListRawDataArr.length < 1
  ) {
    return;
  } else {
    let headers = {};
    const formattedDataArr = [];
    if (isScoutClientMac()) {
      taskListRawDataArr.forEach((item) => {
        formattedDataArr.push({
          tt: escapeVal(item[0]),
          pid: escapeVal(item[1]),
          rss: escapeVal(item[2]),
          vsz: escapeVal(item[3]),
          pcpu: escapeVal(item[4]),
          pmem: escapeVal(item[5]),
          ppid: escapeVal(item[6]),
          stat: escapeVal(item[7]),
          time: escapeVal(item[8]),
          command: escapeVal(item[9]),
        });
      });
      headers = {
        tt: 'TT',
        pid: 'PID',
        rss: 'RSS',
        vsz: 'VSZ',
        pcpu: 'PCPU',
        pmem: 'PMEM',
        ppid: 'PPID',
        stat: 'STAT',
        time: 'Time',
        command: 'Command',
      };
    } else {
      taskListRawDataArr.forEach((item) => {
        formattedDataArr.push({
          name: escapeVal(item[0]),
          processId: escapeVal(item[1]),
          handleCount: escapeVal(item[2]),
          workingSetSize: escapeVal(item[3]),
          sessionId: escapeVal(item[4]),
          threadCount: escapeVal(item[5]),
          priority: escapeVal(item[6]),
        });
      });
      headers = {
        name: 'Name',
        processId: 'Process ID',
        handleCount: 'Handle Count',
        workingSetSize: 'Working Set Size',
        sessionId: 'Session ID',
        threadCount: 'Thread Count',
        priority: 'Priority',
      };
    }
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  }
}

export function getSoftwarePackages() {
  if (
    typeof softwarePackagesRawDataArr == 'undefined' ||
    softwarePackagesRawDataArr.length < 1
  ) {
    return;
  } else {
    let headers = {};
    const formattedDataArr = [];
    if (isScoutClientMac()) {
      softwarePackagesRawDataArr.forEach((item) => {
        formattedDataArr.push({
          kind: escapeVal(item[0]),
          name: escapeVal(item[1]),
          version: escapeVal(item[2]),
          location: escapeVal(item[3]),
          signedby: escapeVal(item[4]),
          intel64bit: escapeVal(item[5]),
          lastmodified: escapeVal(item[6]),
          obtainedfrom: escapeVal(item[7]),
        });
      });
      headers = {
        kind: 'Kind',
        name: 'Name',
        version: 'Version',
        location: 'Location',
        signedby: 'Signed By',
        intel64bit: 'Intel 64bit',
        lastmodified: 'Last Modified',
        obtainedfrom: 'Obtained From',
      };
    } else {
      softwarePackagesRawDataArr.forEach((item) => {
        formattedDataArr.push({
          name: escapeVal(item[0]),
          version: escapeVal(item[1]),
          vendor: escapeVal(item[2]),
          installed: escapeVal(item[3]),
          installLoc: escapeVal(item[4]),
          installSrc: escapeVal(item[5]),
        });
      });
      headers = {
        name: 'Name',
        version: 'Version',
        vendor: 'Vendor',
        installed: 'Installed',
        installLoc: 'Install Location',
        installSrc: 'Install Source',
      };
    }
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  }
}

export function getUsbDevices() {
  if (
    typeof usbDevicesRawDataArr == 'undefined' ||
    usbDevicesRawDataArr.length < 1
  ) {
    return;
  } else {
    let headers = {};
    const formattedDataArr = [];
    if (isScoutClientMac()) {
      usbDevicesRawDataArr.forEach((item) => {
        formattedDataArr.push({
          name: escapeVal(item[0]),
          manufacturer: escapeVal(item[1]),
          speed: escapeVal(item[2]),
          version: escapeVal(item[3]),
          vendorid: escapeVal(item[4]),
          productid: escapeVal(item[5]),
          serialnumber: escapeVal(item[6]),
        });
      });
      headers = {
        name: 'Name',
        manufacturer: 'Manufacturer',
        speed: 'Speed',
        version: 'Verison',
        vendorid: 'Vendor ID',
        productid: 'Product ID',
        serialnumber: 'Serial Number',
      };
    } else {
      usbDevicesRawDataArr.forEach((item) => {
        formattedDataArr.push({
          name: escapeVal(item[0]),
          deviceId: escapeVal(item[1]),
          status: escapeVal(item[2]),
        });
      });
      headers = {
        name: 'Name',
        deviceId: 'Device ID',
        status: 'Status',
      };
    }
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  }
}

export function getWirelessNetworks() {
  if (
    typeof wirelessNetworksRawDataArr == 'undefined' ||
    wirelessNetworksRawDataArr.length < 1
  ) {
    return;
  } else {
    let headers = {};
    const formattedDataArr = [];
    if (isScoutClientMac()) {
      wirelessNetworksRawDataArr.forEach((item) => {
        formattedDataArr.push({
          ssidname: escapeVal(item[0]),
          security: escapeVal(item[1]),
          iscurrent: escapeVal(item[2]),
          mode: escapeVal(item[3]),
          bssid: escapeVal(item[4]),
          noise: escapeVal(item[5]),
          signal: escapeVal(item[6]),
          channel: escapeVal(item[7]),
          country: escapeVal(item[8]),
          networktype: escapeVal(item[9]),
          transmitrate: escapeVal(item[10]),
          mcsindex: escapeVal(item[11]),
        });
      });
      headers = {
        ssidname: 'SSID Name',
        security: 'Security',
        iscurrent: 'Is Current',
        mode: 'Mode',
        bssid: 'BSSID',
        noise: 'Noise',
        signal: 'Signal',
        channel: 'Channel',
        country: 'Country',
        networktype: 'Network Type',
        transmitrate: 'Transmit Rate',
        mcsindex: 'MCS Index',
      };
    } else {
      wirelessNetworksRawDataArr.forEach((item) => {
        formattedDataArr.push({
          name: escapeVal(item[0]),
          networkType: escapeVal(item[1]),
          authentication: escapeVal(item[2]),
          encryption: escapeVal(item[3]),
        });
      });
      headers = {
        name: 'Name',
        networkType: 'Network Type',
        authentication: 'Authentication',
        encryption: 'Encryption',
      };
    }
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  }
}

// using momentjs to get local timestamp with no whitespaces.
export function getLocalTimeNoSpace() {
  let local = moment().local();
  local = moment(local).format('YYYY-MM-DD_HH-mm-ss_[[]ZZ[]]');
  return local;
}

export function initOverViewCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'risk-trends_' + localTime + '.csv',
    data: onRiskTrendsCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-class-health_' + localTime + '.csv',
    data: onAssetClassHealthCSVbtnClick(),
  });
  zipObj.push({
    file: 'network-health_' + localTime + '.csv',
    data: onNetworkHealthCSVbtnClick(),
  });
  return zipObj;
}

export function initAssetViewCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'asset-category_' + localTime + '.csv',
    data: onAssetCategoryCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-category-risk_' + localTime + '.csv',
    data: onAssetCategoryRiskCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-category-health_' + localTime + '.csv',
    data: onAssetHealthCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-os-breakdown_' + localTime + '.csv',
    data: onAssetOSBreakdownCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-activity_' + localTime + '.csv',
    data: onAssetActivityCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-activity-drilldown_' + localTime + '.csv',
    data: onAssetActivityDrilldownCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-catalog_' + localTime + '.csv',
    data: onAssetCatalogCSVbtnClick(),
  });
  return zipObj;
}

export function initAssetDetailsCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'asset-catalog_' + localTime + '.csv',
    data: onAssetCatalogCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-profile-notes_' + localTime + '.csv',
    data: onAssetProfileNotesCSVbtnClick(),
  });
  zipObj.push({
    file: 'asset-profile-history_' + localTime + '.csv',
    data: onAssetProfileHistoryCSVbtnClick(),
  });
  zipObj.push({
    file: 'agent-audits-task-list_' + localTime + '.csv',
    data: getScoutTaskList(),
  });
  zipObj.push({
    file: 'agent-audits-wireless-networks_' + localTime + '.csv',
    data: getWirelessNetworks(),
  });
  zipObj.push({
    file: 'agent-audits-software-packages_' + localTime + '.csv',
    data: getSoftwarePackages(),
  });
  zipObj.push({
    file: 'agent-audits-usb-devices_' + localTime + '.csv',
    data: getUsbDevices(),
  });
  return zipObj;
}

function initLogViewCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'dropped_blocked-attacks_' + localTime + '.csv',
    data: onFirewallAttacksDrillDownCSVbtnClick(),
  });
  zipObj.push({
    file: 'connection-source-list_' + localTime + '.csv',
    data: onConnSrcListCSVbtnClick(),
  });
  zipObj.push({
    file: 'firewall-attack-map_' + localTime + '.csv',
    data: onFirewallAttachMapCSVbtnClick(),
  });
  zipObj.push({
    file: 'firewall-connections-map_' + localTime + '.csv',
    data: onFirewallConnMapCSVbtnClick(),
  });
  zipObj.push({
    file: 'dropped_blocked-attacks_' + localTime + '.csv',
    data: onFirewallAttacksDrillDownCSVbtnClick(),
  });
  zipObj.push({
    file: 'connection-source-list_' + localTime + '.csv',
    data: onConnSrcListCSVbtnClick(),
  });
  zipObj.push({
    file: 'SIEM-log-aggregator_' + localTime + '.csv',
    data: onSiemLogAggregatorCSVbtnClick(),
  });
  return zipObj;
}

function initScoutViewCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'clients-by-os_' + localTime + '.csv',
    data: onScoutClientByOsCSVbtnClick(),
  });
  zipObj.push({
    file: 'alerts-by-severity_' + localTime + '.csv',
    data: onScoutAlertsBySeverityCSVbtnClick(),
  });
  zipObj.push({
    file: 'clients-by-location_' + localTime + '.csv',
    data: onScoutClientsByLocCSVbtnClick(),
  });
  zipObj.push({
    file: 'agent-alerts_' + localTime + '.csv',
    data: onScoutAlertsCSVbtnClick(),
  });
  return zipObj;
}

function initEchoIndexCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'event-results_' + localTime + '.csv',
    data: parseClickResultsToCSV(),
  });
  zipObj.push({
    file: 'aggregate-results_' + localTime + '.csv',
    data: parseSplitResultsToCSV(),
  });
  zipObj.push({
    file: 'phishing-campaigns_' + localTime + '.csv',
    data: parseCampaignResultsToCSV(),
  });
  return zipObj;
}

function initReachCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'eva_groups_' + localTime + '.csv',
    data: onReachGroupsCSVbtnClick(),
  });
  zipObj.push({
    file: 'eva_risks_' + localTime + '.csv',
    data: onReachRisksCSVbtnClick(),
  });
  return zipObj;
}

function initScoutRisksCSV() {
  const localTime = getLocalTimeNoSpace();
  const zipObj = [];
  zipObj.push({
    file: 'agent_risk_groups_' + localTime + '.csv',
    data: onScoutRisksGroupsCSVbtnClick(),
  });
  zipObj.push({
    file: 'agent_risk_risks_' + localTime + '.csv',
    data: onScoutRisksCSVbtnClick(),
  });
  return zipObj;
}

export function initDownloadAllCSV(page) {
  const zip = new JSZip();
  const localTime = getLocalTimeNoSpace();
  let fileName;
  switch (page) {
    case 'overview':
      fileName = 'overview-csv-all_' + localTime + '.zip';
      const overViewZipObj = initOverViewCSV();
      for (let a = 0; a < overViewZipObj.length; a++) {
        if (overViewZipObj[a].data !== '') {
          zip.file(overViewZipObj[a].file, overViewZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'riskview':
      fileName = 'riskview-csv-all_' + localTime + '.zip';
      const riskViewZipObj = initRiskViewCSV();
      for (let a = 0; a < riskViewZipObj.length; a++) {
        if (riskViewZipObj[a].data !== '') {
          zip.file(riskViewZipObj[a].file, riskViewZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'assetview':
      const assetViewZipObj = initAssetViewCSV();
      fileName = 'assetview-csv-all_' + localTime + '.zip';
      for (let a = 0; a < assetViewZipObj.length; a++) {
        if (assetViewZipObj[a].data !== '') {
          zip.file(assetViewZipObj[a].file, assetViewZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'assetdetails':
      const assetDetailsZipObj = initAssetDetailsCSV();
      fileName = 'assetdetails-csv-all_' + localTime + '.zip';
      for (let a = 0; a < assetDetailsZipObj.length; a++) {
        if (assetDetailsZipObj[a].data !== '') {
          zip.file(assetDetailsZipObj[a].file, assetDetailsZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'logview':
      const logViewZipObj = initLogViewCSV();
      fileName = 'logview-csv-all_' + localTime + '.zip';
      for (let a = 0; a < logViewZipObj.length; a++) {
        if (logViewZipObj[a].data !== '') {
          zip.file(logViewZipObj[a].file, logViewZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'scoutview':
      const scoutViewZipObj = initScoutViewCSV();
      fileName = 'agentview-csv-all_' + localTime + '.zip';
      for (let a = 0; a < scoutViewZipObj.length; a++) {
        if (scoutViewZipObj[a].data !== '') {
          zip.file(scoutViewZipObj[a].file, scoutViewZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'echoindex':
      const echoIndexZipObj = initEchoIndexCSV();
      fileName = 'echoindex-csv-all_' + localTime + '.zip';
      for (let a = 0; a < echoIndexZipObj.length; a++) {
        if (echoIndexZipObj[a].data !== '') {
          zip.file(echoIndexZipObj[a].file, echoIndexZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'reach':
      fileName = 'eva-csv-all_' + localTime + '.zip';
      const reachZipObj = initReachCSV();
      for (let a = 0; a < reachZipObj.length; a++) {
        if (reachZipObj[a].data !== '') {
          zip.file(reachZipObj[a].file, reachZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    case 'scout':
      fileName = 'agent-risks-csv-all_' + localTime + '.zip';
      const scoutRisksZipObj = initScoutRisksCSV();
      for (let a = 0; a < scoutRisksZipObj.length; a++) {
        if (scoutRisksZipObj[a].data !== '') {
          zip.file(scoutRisksZipObj[a].file, scoutRisksZipObj[a].data);
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, fileName);
      });
      break;
    default:
      return null;
  }
}

export function escapeSpecialChar(entry) {
  if (typeof entry != 'undefined') {
    let str = JSON.stringify(entry);
    str = str.replace(/\r?\n|\r/g, '');
    str = str.replace(/"/g, ''); //XXX not sure this is doing what we think it should be doing...
    str = str.replace(/,/g, '');
    return str;
  } else {
    return 'undefined';
  }
}

export function onAssetClassHealthCSVbtnClick() {
  if (!document.getElementById('export-asset-scatter-to-csv').disabled) {
    const formattedDataArr = [];
    assetClassRawDataArr.forEach((item) => {
      const arrLength = item.data.length;
      for (let i = 0; i < arrLength; i++) {
        formattedDataArr.push({
          name: escapeVal(item.name),
          numbOfVulnerabilities: escapeVal(item.data[i][0]),
          riskScore: escapeVal(item.data[i][1]),
          ipAddr: escapeVal(item.data[i][2]),
        });
      }
    });
    const headers = {
      name: 'Name',
      numbOfVulnerabilities: 'Number of Vulnerabilities',
      riskScore: 'Risk Score',
      ipAddr: escapeVal('IP (DNS, Netbios)'),
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onNetworkHealthCSVbtnClick() {
  if (!document.getElementById('export-network-health-to-csv').disabled) {
    const formattedDataArr = [];
    networkHealthRawDataArr.forEach((item) => {
      const value = item.c[3].v;
      let level = '';
      if (value > 0.0 && value < 4.0) {
        level = 'low';
      } else if (value > 3.9 && value < 7.0) {
        level = 'medium';
      } else if (value > 6.9 && value <= 10.0) {
        level = 'high';
      }
      formattedDataArr.push({
        ipAddress: escapeVal(item.c[0].v),
        network: escapeVal(item.c[1].v),
        riskLevel: escapeVal(level),
      });
    });
    const headers = {
      ipAddress: 'IP address',
      network: 'Network Type',
      riskLevel: 'Risk Level',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetCategoryCSVbtnClick() {
  if (!document.getElementById('export-asset-categories-to-csv').disabled) {
    const formattedDataArr = [];
    assetCategoriesRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        value: escapeVal(item.value),
      });
    });
    const headers = {
      name: 'Name',
      value: 'Value',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetCategoryRiskCSVbtnClick() {
  if (!document.getElementById('export-asset-category-risk-to-csv').disabled) {
    const formattedDataArr = [];
    assetCategoryRiskRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        value: escapeVal(item.value),
      });
    });
    const headers = {
      name: 'Name',
      value: 'Value',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetHealthCSVbtnClick() {
  if (
    !document.getElementById('export-asset-category-health-to-csv').disabled
  ) {
    const formattedDataArr = [];
    assetHealthRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        value: escapeVal(item.value),
      });
    });
    const headers = {
      name: 'Name',
      value: 'Value',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
  }
}

export function onAssetOSBreakdownCSVbtnClick() {
  if (!document.getElementById('export-asset-os-breakdown-to-csv').disabled) {
    const formattedDataArr = [];
    assetOSBreakdownRawDataArr.forEach((item) => {
      formattedDataArr.push({
        os: escapeVal(item.os),
        manufacturer: escapeVal(item.manufacturer),
        numbOfAssetsDiscovered: escapeVal(item.numbOfAssetsDiscovered),
      });
    });
    const headers = {
      os: 'OS',
      manufacturer: 'Manufacturer by OS',
      numbOfAssetsDiscovered: 'Number of Assets Discovered',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetActivityDrilldownCSVbtnClick() {
  if (
    !document.getElementById('export-asset-activity-details-to-csv').disabled
  ) {
    const formattedDataArr = [];
    assetActivityDrilldownRawDataArr.forEach((item) => {
      let activities = '0';
      if (item.numbOfActivities !== 0) {
        activities = item.numbOfActivities;
      }
      formattedDataArr.push({
        day: escapeVal(item.day),
        time: escapeVal(item.time),
        numbOfActivities: escapeVal(activities),
      });
    });
    const headers = {
      day: 'Day',
      time: 'Time',
      numbOfActivities: 'Number of Activities',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetActivityCSVbtnClick() {
  if (!document.getElementById('export-asset-activity-to-csv').disabled) {
    const formattedDataArr = [];
    assetActivityRawDataArr.forEach((item) => {
      formattedDataArr.push({
        time: escapeVal(item.time),
        manufacturer: escapeVal(item.manufacturer),
        numbOfAssetsOnline: escapeVal(item.numbOfAssetsOnline),
      });
    });
    const headers = {
      time: 'Time',
      manufacturer: 'Manufacturer',
      numbOfAssetsOnline: 'Number of Assets Online',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onFirewallAttacksDrillDownCSVbtnClick() {
  if (!document.getElementById('export-blocked-attacks-to-csv').disabled) {
    const formattedDataArr = [];
    fireWallAttackDrillDownRawDataArr.forEach((item) => {
      formattedDataArr.push({
        timestamp: escapeVal(item.timestamp),
        srcip: escapeVal(item.srcip),
        dstport: escapeVal(item.dstport),
        loc: escapeVal(item.location),
        lat: escapeVal(item.latitude),
        long: escapeVal(item.longitude),
      });
    });
    const headers = {
      timestamp: 'TimeStamp',
      srcip: 'Source IP',
      dstport: 'Target Port',
      loc: 'Location',
      lat: 'Latitude',
      long: 'Longitude',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetCatalogCSVbtnClick() {
  if (!document.getElementById('export-asset-catalog-to-csv').disabled) {
    const formattedDataArr = [];
    assetCatalogRawDataArr.forEach((item) => {
      let vulnerabilities = '0';
      if (item[7] !== 0) {
        vulnerabilities = item[7];
      }
      formattedDataArr.push({
        ip: escapeVal(item[2]),
        deviceName: escapeVal(item[3]),
        mac: escapeVal(item[4]),
        os: escapeVal(item[5]),
        risk: escapeVal(item[6]),
        vulns: escapeVal(vulnerabilities),
        category: escapeVal(item[8]),
        manufacturer: escapeVal(item[9]),
        lastVerifiedDeviceName: escapeVal(item[10]),
        lastVerifiedMac: escapeVal(item[11]),
        lastVerifiedOs: escapeVal(item[12]),
      });
    });
    const headers = {
      ip: 'IP',
      deviceName: 'Device Name',
      mac: 'MAC',
      os: 'OS',
      risk: 'Risk',
      vulns: 'Vulnerabilities',
      category: 'Category',
      manufacturer: 'Manufacturer',
      lastVerifiedDeviceName: 'Last Verified Device Name',
      lastVerifiedMac: 'Last Verified MAC',
      lastVerifiedOs: 'Last Verified OS',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetProfileHistoryCSVbtnClick() {
  if (
    !document.getElementById(
      'export-asset-profile-history-asset-details-to-csv'
    ).disabled
  ) {
    const formattedDataArr = [];
    assetProfileHistoryRawData.forEach((item) => {
      formattedDataArr.push({
        ip: escapeVal(item[1]),
        deviceName: escapeVal(item[2]),
        os: escapeVal(item[3]),
        mac: escapeVal(item[4]),
        when: escapeVal(item[5]),
        type: escapeVal(item[6]),
        event: escapeVal(item[7]),
        rawLog: escapeVal(item[8]),
      });
    });
    const headers = {
      ip: 'IP',
      deviceName: 'Device Name',
      os: 'OS',
      mac: 'MAC',
      when: 'When',
      type: 'Type',
      event: 'Event',
      rawLog: 'Raw Log',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onAssetProfileNotesCSVbtnClick() {
  if (document.getElementById('export-asset-notes-to-csv').disabled) {
    return '';
  } else {
    const formattedDataArr = [];
    assetProfileRawDataArr.forEach((item) => {
      formattedDataArr.push({
        note: item[0],
        who: item[1],
        when: item[2],
      });
    });
    const headers = {
      note: 'Note',
      who: 'Who',
      when: 'When',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  }
}

export function onConnSrcListCSVbtnClick() {
  if (!document.getElementById('export-connection-sources-to-csv').disabled) {
    const formattedDataArr = [];
    firewallConnSrcListRawDataArr.forEach((item) => {
      formattedDataArr.push({
        timestamp: escapeVal(item.timestamp),
        extip: escapeVal(item.extip),
        intip: escapeVal(item.intip),
        loc: escapeVal(item.location),
        lat: escapeVal(item.latitude),
        long: escapeVal(item.longitude),
        istor: escapeVal(item.istor),
      });
    });
    const headers = {
      timestamp: 'Timestamp',
      extip: 'External IP',
      intip: 'Internal IP',
      loc: 'Location',
      lat: 'Latitude',
      long: 'Longitude',
      istor: 'Tor Node',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onFirewallAttachMapCSVbtnClick() {
  if (!document.getElementById('export-firewall-attack-map-to-csv').disabled) {
    const formattedDataArr = [];
    firewallAttackMapRawDataArr.forEach((item) => {
      formattedDataArr.push({
        lat: escapeVal(item.c[0].v),
        long: escapeVal(item.c[1].v),
        attempts: escapeVal(item.c[2].v),
      });
    });
    const headers = {
      lat: 'Latitude',
      long: 'Longitude',
      attempts: 'Attempts',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onFirewallConnMapCSVbtnClick() {
  if (!document.getElementById('export-firewall-conn-map-to-csv').disabled) {
    const formattedDataArr = [];
    firewallConnMapRawDataArr.forEach((item) => {
      formattedDataArr.push({
        lat: escapeVal(item.c[0].v),
        long: escapeVal(item.c[1].v),
        attempts: escapeVal(item.c[2].v),
      });
    });
    const headers = {
      lat: 'Latitude',
      long: 'Longitude',
      attempts: 'Attempts',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onScoutAlertsBySeverityCSVbtnClick() {
  if (
    !document.getElementById('export-scout-alerts-by-severity-to-csv').disabled
  ) {
    const formattedDataArr = [];
    alertsBySeverityRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        value: escapeVal(item.value),
      });
    });
    const headers = {
      name: 'Name',
      value: 'Value',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onScoutAlertsCSVbtnClick() {
  let logStr = '';
  if (!document.getElementById('export-scout-alerts-to-csv').disabled) {
    const formattedDataArr = [];
    scoutAlertsRawDataArr.forEach((item) => {
      logStr = '';
      logStr = item[6].replace(/"/g, '');
      logStr = logStr.replace(/(\n)/g, '');
      formattedDataArr.push({
        deviceName: escapeVal(item[1]),
        osName: escapeVal(item[2]),
        ruleID: escapeVal(item[3]),
        severity: escapeVal(item[4]),
        description: escapeVal(item[5]),
        log: escapeVal(logStr),
        when: escapeVal(item[7]),
        pcsiDss: escapeVal(item[8]),
      });
    });
    const headers = {
      //uuid: "uuid",
      deviceName: 'Device Name',
      osName: 'OS Name',
      ruleID: 'RuleID',
      severity: 'Severity',
      description: 'Description',
      log: 'Log',
      when: 'When',
      pcsiDss: 'PCI DSS',
    };

    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onScoutClientByOsCSVbtnClick() {
  if (!document.getElementById('export-scout-clients-by-os-to-csv').disabled) {
    const formattedDataArr = [];
    scoutCientByosRawDataArr.forEach((item) => {
      formattedDataArr.push({
        name: escapeVal(item.name),
        value: escapeVal(item.value),
      });
    });
    const headers = {
      name: 'Name',
      value: 'Value',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onScoutClientsByLocCSVbtnClick() {
  if (
    !document.getElementById('export-scout-clients-by-location-to-csv').disabled
  ) {
    const formattedDataArr = [];
    scoutClientsByLocationRawDataArr.forEach((item) => {
      formattedDataArr.push({
        lat: escapeVal(item.c[0].v),
        long: escapeVal(item.c[1].v),
        attempts: escapeVal(item.c[2].v),
      });
    });
    const headers = {
      lat: 'Latitude',
      long: 'Longitude',
      attempts: 'Attempts',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onSiemLogAggregatorCSVbtnClick() {
  if (!document.getElementById('export-log-aggregator-to-csv').disabled) {
    const formattedDataArr = [];
    siemLogAggregatorRawDataArr.forEach((item) => {
      formattedDataArr.push({
        timestamp: escapeVal(item.timestamp),
        msg: escapeVal(item.message),
      });
    });
    const headers = {
      timestamp: 'Timestamp',
      msg: 'Message',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function parseClickResultsToCSV() {
  if (!document.getElementById('#exportClickResultsButton').disabled) {
    const headers = {
      campaign_name: 'campaign_name',
      created_date: 'created_date',
      opened: 'opened_events',
      answered: 'answered_call_events',
      input: 'submitted_input_events',
      clicked: 'clicked_link_events',
      errors: 'error_events',
    };
    const dataArray = [];
    let jsonObj = {};

    clickResultsData.forEach((index, result) => {
      dataArray.push({
        campaign_name: escapeVal(result.campaign_name),
        created_date: escapeVal(result.created_date),
        opened: escapeVal(result.opened),
        answered: escapeVal(result.answered),
        input: escapeVal(result.input),
        clicked: escapeVal(result.clicked),
        errors: escapeVal(result.errors),
      });
    });

    dataArray.unshift(headers);
    jsonObj = JSON.stringify(dataArray);

    return parseRawData(jsonObj);
  }
}

export function parseSplitResultsToCSV() {
  if (!document.getElementById('#exportSplitResultsButton').disabled) {
    const headers = {
      opened: 'opened',
      answered: 'answered_calls',
      input: 'submitted_input',
      clicked: 'clicked_links',
      errors: 'errors',
    };
    const dataArray = [];
    let jsonObj = {};

    splitResultsData.forEach((index, result) => {
      dataArray.push({
        opened: escapeVal(result.opened),
        answered: escapeVal(result.answered),
        input: escapeVal(result.input),
        clicked: escapeVal(result.clicked),
        errors: escapeVal(result.errors),
      });
    });

    dataArray.unshift(headers);
    jsonObj = JSON.stringify(dataArray);

    return parseRawData(jsonObj);
  }
}

export function parseCampaignResultsToCSV() {
  if (!document.getElementById('#exportCampaignResultsCsvButton').disabled) {
    const headers = {
      firstname: 'first_name',
      lastname: 'last_name',
      email: 'email_address',
      position: 'position',
      opened: 'opened',
      clicked: 'clicked',
      errors: 'errors',
      status: 'status',
    };
    const dataArray = [];
    let jsonObj = {};

    campaignResultsData.forEach((index, result) => {
      dataArray.push({
        firstname: escapeVal(result.firstname),
        lastname: escapeVal(result.lastname),
        email: escapeVal(result.email),
        position: escapeVal(result.position),
        opened: escapeVal(result.opened),
        clicked: escapeVal(result.clicked),
        errors: escapeVal(result.errors),
        status: escapeVal(result.status),
      });
    });

    dataArray.unshift(headers);
    jsonObj = JSON.stringify(dataArray);

    return parseRawData(jsonObj);
  }
}

export function onReachGroupsCSVbtnClick() {
  if (!document.getElementById('export-reach-groups-overview-csv').disabled) {
    const formattedDataArr = [];
    reachGroupsRawDataArr.forEach((item) => {
      formattedDataArr.push({
        group: escapeVal(item[0]),
        description: escapeVal(item[1]),
        hosts: escapeVal(item[2]),
        schedule: escapeVal(item[4]),
        created: escapeVal(item[5]),
        lastScan: escapeVal(item[6]),
        nextScan: escapeVal(item[7]),
      });
    });
    const headers = {
      group: 'Group',
      description: 'Description',
      hosts: 'Hosts',
      schedule: 'Schedule',
      created: 'Created',
      lastScan: 'Last Scan',
      nextScan: 'Next Scan',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onReachRisksCSVbtnClick() {
  if (!document.getElementById('export-reach-risks-csv').disabled) {
    const formattedDataArr = [];
    reachRisksRawDataArr?.forEach((item) => {
      formattedDataArr.push({
        riskScore: escapeVal(item[1]),
        host: escapeVal(item[2]),
        name: escapeVal(item[3]),
        description: escapeVal(item[4]),
        recommendations: escapeVal(item[5]),
        created: escapeVal(item[6]),
        riskId: escapeVal(item[7]),
      });
    });
    const headers = {
      riskScore: 'Risk Score',
      host: 'Host',
      name: 'Name',
      description: 'Description',
      recommendations: 'Recommendations',
      created: 'Created',
      riskId: 'Risk ID',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function onScoutRisksCSVbtnClick() {
  const formattedDataArr = [];
  _scoutRisksRawDataArr.forEach((item) => {
    formattedDataArr.push({
      riskScore: escapeVal(item[1]),
      host: escapeVal(item[2]),
      category: escapeVal(item[3]),
      name: escapeVal(item[4]),
      description: escapeVal(item[5]),
      created: escapeVal(item[6]),
      riskId: escapeVal(item[7]),
    });
  });
  const headers = {
    riskScore: 'Risk Score',
    host: 'Host',
    category: 'Category',
    name: 'Name',
    description: 'CVEs',
    created: 'Created',
    riskId: 'Scan ID',
  };
  formattedDataArr.unshift(headers);
  const jsonObj = JSON.stringify(formattedDataArr);
  return parseRawData(jsonObj);
}

export function onScoutRisksGroupsCSVbtnClick() {
  if (!document.getElementById('export-scout-groups-overview-csv').disabled) {
    const formattedDataArr = [];
    _scoutGroupsRawDataArr.forEach((item) => {
      formattedDataArr.push({
        group: escapeVal(item[0]),
        description: escapeVal(item[1]),
        hosts: escapeVal(item[2]),
        schedule: escapeVal(item[4]),
        created: escapeVal(item[5]),
        lastScan: escapeVal(item[6]),
        nextScan: escapeVal(item[7]),
      });
    });
    const headers = {
      group: 'Group',
      description: 'Description',
      hosts: 'Hosts',
      schedule: 'Schedule',
      created: 'Created',
      lastScan: 'Last Scan',
      nextScan: 'Next Scan',
    };
    formattedDataArr.unshift(headers);
    const jsonObj = JSON.stringify(formattedDataArr);
    return parseRawData(jsonObj);
  } else {
    return;
  }
}

export function exportCSVFile(csv, fileTitle) {
  const localTime = getLocalTimeNoSpace();
  const filename = fileTitle + '_' + localTime + '.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function initNetworkHealth() {
  const fileTitle = 'network-health';
  const csv = onNetworkHealthCSVbtnClick();
  return exportCSVFile(csv, fileTitle);
}
