import { iState } from '../configureStore';
import {
  CLOSE_INFO_PANE,
  OPEN_INFO_PANE,
  SET_INFO_PANE_DATA,
  SET_INFO_PANE_STATE,
} from './infoPaneActions';

export interface iInfoPaneState {
  isOpen: boolean;
  openFrom?: string;
  pane: string;
  payload: any;
  state?: any;
  width?: string;
}

const initialState: iInfoPaneState = {
  isOpen: false,
  pane: '',
  payload: {},
  state: {},
};

export const infoPaneReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case CLOSE_INFO_PANE:
      return {
        isOpen: false,
        pane: '',
        payload: {},
        state: {},
      };
    case OPEN_INFO_PANE:
      return {
        isOpen: true,
        ...payload,
      };
    case SET_INFO_PANE_DATA:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...payload,
        },
      };
    case SET_INFO_PANE_STATE:
      return {
        ...state,
        state: {
          ...state.state,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export const infoPanSelectors = {
  getPaneIsOpen: (store: iState) => store.infoPane.isOpen,
  getSelectedRiskPayload: (store: iState) => store.infoPane.payload,
};
