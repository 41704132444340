import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  HStack,
  Primitive,
  Select,
  Switch,
  Text,
  VStack,
} from '@rtkwlf/fenrir-react';
import { connect } from 'react-redux';
import { iState } from '../../../configureStore';
import { getCasesThunk } from '../../../Global/caseActions';
import { iUser } from '../../../Global/userReducer';
import { RISK_STATES } from '../../../Constants/Risks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledRadio = styled.input`
  &&& {
    margin: auto 5px;
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
  }
`;

const textStyle = `
  font-family: Lato, FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const StyledLabel = styled.label`
  margin: auto 0;
  padding-right: 5px;

  font-family: Lato;
  font-style: normal;
  font-size: 14px;
`;

const StyledDate = styled(DatePicker)`
  width: 150px;
  height: 39px;

  padding-left: 10px;

  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;

  ${textStyle}
`;

const getDateOrUndefined = (date: number) => {
  if (!date) return;
  return new Date(date * 1000);
};

const riskScores = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const RISK_SCORE_OPTIONS = riskScores.map((score) => ({
  value: score,
  text: score,
}));

const RISK_STATE_OPTIONS = Object.values(RISK_STATES).map((state) => ({
  value: state,
  text: state,
}));

const SELECT_PLACEHOLDER = 'Select...';

const Filters = ({
  caseFilters,
  goToToday,
  pageZoom,
  riskFilters,
  updatePage,
  users,
  zoomTo,
}: any) => {
  const formattedUsers = users.map((user: iUser) => ({
    value: user.email,
    text: `${user.firstname?.slice(0, 1)}.${user.lastname}`,
  }));

  const onChange = (
    key: string,
    value: string | number | boolean | Array<string> | undefined
  ) => {
    const newFilters = { ...riskFilters };
    newFilters[key] = value;
    if (key !== 'page') {
      newFilters.page = 0;
    }
    return updatePage(caseFilters, newFilters);
  };

  return (
    <VStack width='full'>
      <HStack xAlign='between' width='full'>
        <StyledContainer>
          <StyledRadio
            checked={pageZoom === 'week'}
            type='radio'
            id='oneWeek'
            name='filters'
            value='week'
            onChange={() => {
              zoomTo('week');
            }}
          />
          <StyledLabel htmlFor='oneWeek'>Week</StyledLabel>
          <StyledRadio
            checked={pageZoom === 'month'}
            type='radio'
            id='oneMonth'
            name='filters'
            value='month'
            onChange={() => {
              zoomTo('month');
            }}
          />
          <StyledLabel htmlFor='oneMonth'>Month</StyledLabel>
          <StyledRadio
            checked={pageZoom === 'quarter'}
            type='radio'
            id='threeMonths'
            name='filters'
            value='quarter'
            onChange={() => {
              zoomTo('quarter');
            }}
          />
          <StyledLabel htmlFor='threeMonths'>Quarter</StyledLabel>
        </StyledContainer>
        <StyledContainer>
          <StyledLabel style={{ margin: '5px' }}>Created Before</StyledLabel>
          <StyledDate
            selected={getDateOrUndefined(riskFilters?.createdBefore)}
            onChange={(date: any) => {
              if (moment(date).isValid()) {
                const createdBefore = moment(date).unix();
                onChange('createdBefore', createdBefore);
              } else {
                onChange('createdBefore', undefined);
              }
            }}
            popperPlacement='bottom-end'
          />
        </StyledContainer>
        {/* </StyledRow> */}
      </HStack>

      <HStack width='full'>
        <HStack gap='xsmall'>
          <Text styledAs='body2'>Users</Text>
          <Primitive.div width='18rem'>
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={formattedUsers}
              value={riskFilters?.owner}
              onValueChange={(user) => onChange('owner', user)}
            />
          </Primitive.div>
        </HStack>

        <HStack gap='xsmall'>
          <Text styledAs='body2'>Risk State</Text>
          <Primitive.div width='18rem'>
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={RISK_STATE_OPTIONS}
              value={riskFilters?.state}
              onValueChange={(state) => onChange('state', state)}
            />
          </Primitive.div>
        </HStack>
      </HStack>

      <HStack
        xAlign='between'
        width='full'
        paddingBottom='smedium'
        borderColor='neutralSecondary'
        borderStyle='solid'
        borderWidth='zero'
        borderBottomWidth='large'
      >
        <HStack gap='small'>
          <Text styledAs='body2'>Risk Score</Text>
          <Primitive.div width='8rem'>
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={RISK_SCORE_OPTIONS}
              value={riskFilters?.fromLevel}
              onValueChange={(score) => onChange('fromLevel', score)}
            />
          </Primitive.div>
          <Text styledAs='body2'>to</Text>
          <Primitive.div width='8rem'>
            <Select
              placeholder={SELECT_PLACEHOLDER}
              options={RISK_SCORE_OPTIONS}
              value={riskFilters?.toLevelInclusive}
              onValueChange={(score) => onChange('toLevelInclusive', score)}
            />
          </Primitive.div>
        </HStack>
        <Button
          onClick={() => updatePage(caseFilters, undefined)}
          margin='zero'
        >
          Clear All
        </Button>
      </HStack>

      <HStack width='full' xAlign='right' marginBottom='default'>
        <Button
          margin='zero'
          variant='tertiary'
          onClick={goToToday}
          iconLeft={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          Today
        </Button>
        <HStack yAlign='center' gap='xsmall'>
          <Text styledAs='body4'>Closed Plans</Text>
          <Switch.Root
            margin='zero'
            aria-label='Toggle Close and Open Plans'
            isChecked={caseFilters?.status === 'Open'}
            onCheckedChange={(isChecked) => {
              updatePage(
                isChecked ? { status: 'Open' } : { status: 'Resolved' },
                riskFilters
              );
            }}
          >
            <Switch.Thumb />
          </Switch.Root>
          <Text styledAs='body4'>Open Plans</Text>
        </HStack>
      </HStack>
    </VStack>
  );
};

const mapStateToProps = ({ config, user }: iState) => ({
  caseFilters: config.pageState.cases?.filters,
  riskFilters: config.pageState.assignedRisks?.filters,
  users: user.users,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  updatePage: (
    params: { [key: string]: string | number | boolean },
    riskParams: { [key: string]: string | number | boolean }
  ) => {
    dispatch(getCasesThunk(params, riskParams, true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
