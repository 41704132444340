import React from 'react';

// Components
import Gauges from './Gauges';
import AssetHealth from './AssetHealth';
import RiskScoreTrends from './RiskScoreTrends';

import { iState } from '../../configureStore';
import { connect } from 'react-redux';
import { iAsset } from '../../Global/assetReducer';
import NetworkHealth from './NetworkHealth';

const Overview = ({ assetData, hasTrendData }: any) => {
  return (
    <div style={{ marginRight: '-20px', marginTop: '5px' }}>
      <Gauges />
      {hasTrendData && <RiskScoreTrends />}
      <div className='row d-flex'>
        <div className='col-md-6'>
          <AssetHealth data={assetData} />
        </div>
        <div className='col-md-6' style={{ height: '382px' }}>
          <NetworkHealth />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ asset, stats }: iState) => {
  const data: any = [];

  if (asset?.assets) {
    asset.assets.forEach((category: any) => {
      category.assets.forEach((asset: iAsset) => {
        const hostName = asset.deviceName
          .replace('DNS:', '')
          .replace('NBName:', '')
          .split('|');
        data.push({
          ip: asset.ip,
          host: `${asset.ip} (${
            hostName[0].length ? hostName[0] : hostName[1]
          })`,
          riskscore: asset.risk,
          countvulns: asset.vulns,
          assetclass: category.name,
        });
      });
    });
  }

  return {
    hasTrendData: !!stats.allSummaries.length,
    assetData: data,
  };
};

export default connect(mapStateToProps)(Overview);
