import React from 'react';
import { useSelector } from 'react-redux';
import { customerSelectors } from '../../../../Global/customerReducer';
import { useQueryAllTags } from '../../../../Global/assetTags';
import { DetailsData } from '../AssetDetails';
import { RendererProps } from './types';
import TagPills from '../../../../Reusables/TagPills';

/**
 * Given a list of tag IDs, query & return the full tag objects.
 * Useful to enrich tag info on asset/risk objects, where only IDs are available.
 */
const useTagsByID = (tagIDs: number[] = []) => {
  const customerId = useSelector(customerSelectors.getCustomerId);
  const { data: allTags } = useQueryAllTags(customerId);
  return React.useMemo(
    () => allTags?.filter((tag) => tagIDs.includes(tag.tagID)) || [],
    [allTags, tagIDs]
  );
};

export const TagsRenderer: React.FC<RendererProps> = (props) => {
  // a harsh conversion because AgentDetailsType/AgentDetailsType are poorly typed
  // but do know from processAssetDetailsInfo the exact schema of details
  const { tagIDs } = props.data as unknown as DetailsData;

  const tags = useTagsByID(tagIDs);

  if (tags.length === 0) {
    return <div>--</div>;
  }

  return <TagPills tags={tags} tooltipPosition='top' justify='right' />;
};
