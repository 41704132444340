import { Auth0Client } from '@auth0/auth0-spa-js';

// WARNING this file is duplicated for jquery pages /public/js/authentication.js

const RS_AUTH0_TOKEN = 'access_token';
const RS_AUTH0_AUTHENTICATED = 'auth_authenticated';
export const SET_AUTH0_LOADING_COMPLETE = 'SET_AUTH0_LOADING_COMPLETE';

const getAuth0Params = () => {
  const auth0Params = {
    responseType: 'token',
    scope: 'openid id_token',
    leeway: 60,
    redirectUri: window.location.origin,

    // default: sandbox
    audience: 'https://api.arcticwolf.com/',
    clientID: '7AwIJKq17hxCdWrfFVL0wuYy677OHsOg',
    domain: 'awn-sandbox.auth0.com',
  };

  auth0Params.clientID = 'mu15UiDJ2GtnYsE8vzrnCCdEHXVIIDqr';
  auth0Params.domain = 'auth.arcticwolf.com';

  return auth0Params;
};

const getAuth0SpaParams = () => {
  const params = getAuth0Params();

  // transform option keys to snake case
  return {
    scope: params.scope,
    leeway: params.leeway,
    redirect_uri: params.redirectUri,
    audience: params.audience,
    client_id: params.clientID,
    domain: params.domain,
    useRefreshTokens: true,
    cacheLocation: window.Cypress ? 'localstorage' : 'memory',
  };
};

const getReturnTo = () => {
  return (
    window.location.pathname + window.location.search + window.location.hash
  );
};

let _AUTH0_SPA_CLIENT = null;

if (!_AUTH0_SPA_CLIENT) {
  _AUTH0_SPA_CLIENT = new Auth0Client(getAuth0SpaParams());
  if (
    window.location.href.indexOf('?') > 0 &&
    window.location.href.indexOf('code') > 0
  ) {
    _AUTH0_SPA_CLIENT
      .handleRedirectCallback()
      .then((res) => {
        if (res && res.appState && res.appState.returnTo) {
          window.location.assign(res.appState.returnTo);
        }
      })
      .catch((e) => {
        console.error('Unexpected auth0 redirect callback error', e);
      });
  }
}

// Singleton reference of the initial token fetch
let _auth0GetTokenPromise;

export const waitForAuth0 = async (dispatch) => {
  try {
    await _auth0GetTokenPromise;
  } catch (e) {
    // _auth0GetTokenPromise does not reject; can safely ignore errors
    console.error(e);
  } finally {
    dispatch({
      type: SET_AUTH0_LOADING_COMPLETE,
    });
  }
};

// Include this on your dashboard to fetch bearer tokens from Auth0
// When no valid session is found per Auth0 SDK, it attempts to login silently asynchronously
// upon errors it will redirect to the Auth0 login prompt
export const RSAuthenticate = async () => {
  if (!_auth0GetTokenPromise) {
    _auth0GetTokenPromise = new Promise((resolve, reject) => {
      return _AUTH0_SPA_CLIENT
        .getTokenSilently()
        .then((token) => {
          // Note: this is to support the heavily consumed getAuthToken function which must be synchronous
          // ideally, getAuthToken callers should asynchronously call getTokenSilently to get the token
          sessionStorage.setItem(RS_AUTH0_AUTHENTICATED, 'true');
          sessionStorage.setItem(RS_AUTH0_TOKEN, token);
          resolve();
        })
        .catch((e) => {
          if (e.error === 'login_required') {
            _AUTH0_SPA_CLIENT.loginWithRedirect({
              appState: {
                returnTo: getReturnTo(),
              },
            });
          } else {
            console.error('unexpected auth0 error', e);
          }
        });
    });
  }
  return _auth0GetTokenPromise;
};

export const RSLogout = () => {
  sessionStorage.removeItem(RS_AUTH0_TOKEN);
  sessionStorage.removeItem(RS_AUTH0_AUTHENTICATED);
  _AUTH0_SPA_CLIENT.logout({
    returnTo: window.location.origin,
  });
};

// For use across the application where a token is required
export const getAuthToken = () => {
  if (sessionStorage.getItem(RS_AUTH0_TOKEN)) {
    return sessionStorage.getItem(RS_AUTH0_TOKEN);
  } else {
    return RSAuthenticate().then(() => {
      return sessionStorage.getItem(RS_AUTH0_TOKEN);
    });
  }
};
