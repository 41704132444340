import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { DatePicker } from '../../Reusables/DatePicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { addScanThunk, updateScanThunk } from '../../Global/scannerActions';
import { BLUE, RED } from '../../Constants/Styles';
import { useSelector } from 'react-redux';
import { isValidTarget, INVALID_SCHEDULE_TARGET } from '../../utils/ipUtils';
import { iState } from '../../configureStore';
import styled from 'styled-components';
import {
  Button,
  HStack,
  VStack,
  Field,
  Text,
  TextField,
  Primitive,
  MultiSelect,
  MultiSelectOption,
  Checkbox,
} from '@rtkwlf/fenrir-react';
import { CronType, ScheduleData, ScheduleWindow } from '../../types/scanner';
import { convertScheduleToLocal, parseCronType } from '../../utils/parsers';
import { useAppDispatch } from '../../hooks';

const baseInputStyles = `
  width: 120px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 30px
`;

const StyledContainer = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h2`
  margin: 28px 44px 32px 28px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${BLUE.primary};
`;

const StyledText = styled.p<{ color?: string }>`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  ${(props) => (props.color ? `color: ${props.color};` : '')}
`;

const StyledSelect = styled.select`
  ${baseInputStyles}
`;

const StyledFooter = styled.div`
  height: 80px;
  margin-top: 30px;

  background: #eeeeee;
  border-radius: 0px 0px 4px 4px;
`;

const StyledButtonContainer = styled.div`
  float: right;
  margin: 22px;
  display: flex;
`;

const StyledTime = styled(TimePicker)`
  max-width: 55px;

  & .rc-time-picker-input {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
`;

const StyledScanWindow = styled.input`
  padding-left: 8px;

  &::placeholder {
    color: #707f86;
  }

  width: 50px;
  ${baseInputStyles}
`;

const SCHEDULES = [
  CronType.CONTINUOUS,
  CronType.DAILY,
  CronType.WEEKLY,
  CronType.MONTHLY,
];

const PRIORITIES = {
  Low: 100,
  Medium: 50,
  High: 1,
};

const WEEKDAY_OPTIONS = [
  {
    name: 'Sunday',
    checked: false,
    dayOfWeek: 0,
  },
  {
    name: 'Monday',
    checked: false,
    dayOfWeek: 1,
  },
  {
    name: 'Tuesday',
    checked: false,
    dayOfWeek: 2,
  },
  {
    name: 'Wednesday',
    checked: false,
    dayOfWeek: 3,
  },
  {
    name: 'Thursday',
    checked: false,
    dayOfWeek: 4,
  },
  {
    name: 'Friday',
    checked: false,
    dayOfWeek: 5,
  },
  {
    name: 'Saturday',
    checked: false,
    dayOfWeek: 6,
  },
];

type FormData = {
  length: string;

  minute?: string;
  hour?: string;
  dayOfMonth?: number[];
  dayOfWeek?: number[];

  month?: number[];
  year?: number[];
};

const generateWindow = ({
  length,
  minute,
  hour,
  dayOfMonth,
  dayOfWeek,
  month,
  year,
}: FormData): ScheduleWindow => {
  return {
    cron: {
      minute: minute ? [parseInt(minute, 10)] : undefined,
      hour: hour ? [parseInt(hour, 10)] : undefined,
      dayOfMonth,
      month,
      dayOfWeek,
      year,
    },
    lengthMinute: parseInt(length, 10) * 60,
  };
};

type Props = {
  uuid?: string;
  cancel: () => void;
};

export const ScannerSchedule: React.FC<Props> = ({ uuid, cancel }) => {
  const dispatch = useAppDispatch();

  const currentScanner = useSelector((state: iState) =>
    state?.scanner.scanners.get(state?.scanner.scanner.id)
  );
  const schedule = convertScheduleToLocal(
    currentScanner?.schedules?.get(uuid ?? '')
  );
  const edit = !!schedule;

  const scheduleData = useMemo(() => {
    const sData: any = {
      targets: schedule?.targets,
      windows: schedule?.windows,
      priority: schedule?.priority,
      tagDetails: schedule?.tags,
      capabilities: currentScanner?.config?.capabilities,
    };

    if (schedule?.windows?.length) {
      sData.cron = schedule?.windows[0]?.cron;
      sData.lengthMinute = schedule?.windows[0]?.lengthMinute;
    }

    return sData;
  }, [currentScanner, schedule]);

  const scheduleName = scheduleData.tagDetails
    ? scheduleData.tagDetails.name
    : '';
  const scheduleDescription = scheduleData.tagDetails
    ? scheduleData.tagDetails.description
    : '';

  const [type, setType] = useState(CronType.CONTINUOUS);
  const [window, setWindow] = useState('8');
  const [targetErrors, setTargetErrors] = useState<string[]>([]);
  const [time, setTime] = useState(moment().startOf('day'));
  const [weekdays, setWeekdays] = useState(cloneDeep(WEEKDAY_OPTIONS));
  const [date, setDate] = useState(new Date());
  const [priority, setPriority] = useState(PRIORITIES.Low.toString());
  const [weeklyError, setWeeklyError] = useState('');
  const [windowError, setWindowError] = useState('');
  const [name, setName] = useState(scheduleName);
  const [description, setDescription] = useState(scheduleDescription);
  const [targets, setTargets] = useState<MultiSelectOption[]>(
    schedule?.targets.map((i) => ({ label: i, value: i })) || []
  );
  const [selectedTargets, setSelectedTargets] = useState<MultiSelectOption[]>(
    schedule?.targets.map((i) => ({ label: i, value: i })) || []
  );

  const isSupportScheduleTag =
    scheduleData.capabilities &&
    scheduleData.capabilities.includes('schedule_tags');

  const setAndValidateTargets = (targetList: MultiSelectOption[]) => {
    setSelectedTargets(targetList);
    const validationMessages: string[] = [];
    targetList.forEach(({ value: target }) => {
      if (!isValidTarget(target)) {
        validationMessages.push(`Target "${target}" is not a valid IP.`);
      } else if (INVALID_SCHEDULE_TARGET === target) {
        validationMessages.push(
          `Target "${target}" is not a valid IP for schedule.`
        );
      }
    });
    setTargetErrors(validationMessages);
  };

  useEffect(() => {
    if (edit && uuid) {
      const currentType = parseCronType(scheduleData.cron);
      setType(currentType);

      if (
        currentType !== CronType.CONTINUOUS &&
        scheduleData.cron.hour &&
        scheduleData.cron.minute
      ) {
        setTime(
          moment()
            .hour(scheduleData.cron.hour[0])
            .minute(scheduleData.cron.minute[0])
        );
      }
      setWindow((scheduleData.lengthMinute / 60).toString());
      setAndValidateTargets(
        scheduleData.targets.map((i: string) => ({
          label: i,
          value: i,
        }))
      );
      setPriority(scheduleData.priority);
      switch (currentType) {
        case CronType.DAILY:
          break;
        case CronType.WEEKLY:
          const currentWeekdays = scheduleData.windows?.map(
            (window: any) => window.cron.dayOfWeek[0]
          );
          const days = cloneDeep(WEEKDAY_OPTIONS);
          currentWeekdays.forEach((dayNumber: number) => {
            days[dayNumber].checked = true;
          });
          setWeekdays(days);
          break;
        case CronType.MONTHLY:
          const date = new Date();
          date.setDate(scheduleData.cron.dayOfMonth[0]);
          setDate(date);
          break;
        default:
          break;
      }
    }
    // Only run this effect once on mount to initialize form data for edits
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const validateWindow = (window: string) => {
    setWindowError('');
    if (!window) {
      setWindowError('Please select a valid value for scan window.');
      return false;
    }
    return true;
  };

  const setSchedule = useCallback(
    async (schedule: ScheduleData) => {
      const { targets, windows, priority, id, tags } = schedule;
      if (id) {
        return dispatch(
          updateScanThunk({
            targets,
            windows,
            priority,
            id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            tags,
          })
        );
      }
      return dispatch(
        addScanThunk({
          targets,
          windows,
          priority,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      );
    },
    [dispatch]
  );

  const handleSubmit = useCallback(() => {
    setWeeklyError('');
    const hour = time.format('HH');
    const minutes = time.format('mm');
    const schedule: any = {
      targets: Array.from(new Set(selectedTargets.map((t) => t.value))),
      priority: parseInt(priority as any),
    };
    if (edit) {
      schedule.id = uuid;
    }

    if (edit && isSupportScheduleTag) {
      schedule.tags = {
        name,
        description,
      };
    }

    switch (type) {
      case CronType.CONTINUOUS:
        return setSchedule({ ...schedule, windows: [{ cron: {} }] }).then(
          cancel
        );
      case CronType.DAILY:
        if (!validateWindow(window)) return;
        return setSchedule({
          ...schedule,
          windows: [
            generateWindow({
              length: window,
              hour,
              minute: minutes,
            }),
          ],
        }).then(cancel);
      case CronType.WEEKLY:
        if (!validateWindow(window)) return;
        const selectedDaysOfWeek = weekdays
          .filter((day) => day.checked)
          .map((option) => option.dayOfWeek);
        if (selectedDaysOfWeek.length === 0) {
          setWeeklyError('Please select at least one day for a weekly scan.');
          return;
        }
        return setSchedule({
          ...schedule,
          windows: selectedDaysOfWeek.map((dayOfWeek) =>
            generateWindow({
              length: window,
              hour,
              minute: minutes,
              dayOfWeek: [dayOfWeek],
            })
          ),
        }).then(cancel);
      case CronType.MONTHLY:
        if (!validateWindow(window)) return;
        const day = moment(date).date().toString();
        return setSchedule({
          ...schedule,
          windows: [
            generateWindow({
              length: window,
              hour,
              minute: minutes,
              dayOfMonth: [parseInt(day, 10)],
            }),
          ],
        }).then(cancel);
      default:
        return;
    }
  }, [
    cancel,
    date,
    description,
    edit,
    isSupportScheduleTag,
    name,
    priority,
    setSchedule,
    selectedTargets,
    time,
    type,
    uuid,
    weekdays,
    window,
  ]);

  const handleCheckbox = (name: string) => {
    const newData = weekdays.map((day: any) => {
      if (day.name === name) {
        day.checked = !day.checked;
      }
      return day;
    });
    setWeekdays(newData);
  };

  return (
    <Fragment>
      <StyledContainer>
        <StyledTitle>Configure Scanner Schedule</StyledTitle>

        <VStack marginLeft='large' marginBottom='medium'>
          {edit && isSupportScheduleTag ? (
            <VStack maxWidth='40rem' width='full'>
              <Field.Root appearance='neutral'>
                <Field.Label>Name</Field.Label>
                <Field.Content>
                  <TextField
                    isDisabled
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setName(e.target.value)
                    }
                    name='schedule-name'
                  />
                </Field.Content>
              </Field.Root>

              <Field.Root appearance='neutral'>
                <Field.Label marginTop='medium'>Description</Field.Label>
                <Field.Content>
                  <TextField
                    isDisabled
                    value={description}
                    data-lpignore='true'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDescription(e.target.value)
                    }
                    name='schedule-description'
                  />
                </Field.Content>
              </Field.Root>
            </VStack>
          ) : null}
          <HStack width='full' gap='large'>
            <Primitive.div maxWidth='25rem'>
              <StyledText>Targets</StyledText>
              <MultiSelect
                isCreatable
                placeholder={'Add network to scan'}
                options={targets}
                selectedOptions={selectedTargets}
                onSelectedOptionsChange={setAndValidateTargets}
                handleCreate={(
                  newItem: string,
                  updateFilterString: (filterString: string) => void
                ) => {
                  const newOption = { label: newItem, value: newItem };
                  setTargets((prev) => [...prev, newOption]);
                  setAndValidateTargets([...selectedTargets, newOption]);
                  updateFilterString('');
                }}
              />
            </Primitive.div>
            {targetErrors.length > 0 ? (
              <VStack>
                <Text textColor='accentRedDefault'>Errors(s):</Text>
                <Primitive.ul>
                  {targetErrors.map((targetError, index) => (
                    <Primitive.li key={index}>{targetError}</Primitive.li>
                  ))}
                </Primitive.ul>
              </VStack>
            ) : null}
          </HStack>
        </VStack>
        <HStack marginLeft='large' marginBottom='medium'>
          <div>
            <StyledText>Type</StyledText>
            <StyledSelect
              data-testid='schedule-select'
              value={type}
              onChange={(event: any) => setType(event.target.value)}
            >
              {SCHEDULES.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </StyledSelect>
          </div>
          <div>
            <StyledText>Priority</StyledText>
            <StyledSelect
              value={priority}
              onChange={(event: any) => setPriority(event.target.value)}
            >
              {Object.entries(PRIORITIES).map(([key, value]: any) => (
                <option key={key} value={value}>
                  {key}
                </option>
              ))}
            </StyledSelect>
          </div>
        </HStack>
        {type === CronType.WEEKLY && (
          <HStack marginLeft='large' marginBottom='medium'>
            {weekdays.map((w, i) => (
              <Checkbox.Root
                data-testid={`checkbox-group-${i}`}
                isChecked={w.checked}
                key={w.name}
                name={w.name}
                onCheckedChange={() => handleCheckbox(w.name)}
              >
                <Checkbox.Text>{w.name}</Checkbox.Text>
              </Checkbox.Root>
            ))}
            <div style={{ color: RED.primary }}>{weeklyError}</div>
          </HStack>
        )}

        {(type === CronType.DAILY ||
          type === CronType.WEEKLY ||
          type === CronType.MONTHLY) && (
          <VStack marginLeft='large' gap='zero' marginBottom='medium'>
            <StyledText>
              {type === CronType.MONTHLY ? 'Date & ' : 'Start '}Time
            </StyledText>
            <HStack xAlign='left' yAlign='center' marginBottom='medium'>
              {type === CronType.MONTHLY && (
                <DatePicker
                  popperPlacement='right'
                  selected={date}
                  onChange={(event: any) => setDate(new Date(event))}
                />
              )}
              <StyledTime
                allowEmpty={false}
                defaultValue={time}
                showSecond={false}
                onChange={(momentValue: any) => setTime(momentValue)}
              />
              <Text>{`${moment().tz(moment.tz.guess()).format('z')}`}</Text>
            </HStack>
            <StyledText>Scan Window (Hours)</StyledText>
            <StyledScanWindow
              data-testid='scan-window-text'
              value={window}
              onChange={(event: any) => setWindow(event.target.value)}
            />
            <div style={{ color: RED.primary }}>{windowError}</div>
          </VStack>
        )}
      </StyledContainer>
      <StyledFooter>
        <StyledButtonContainer>
          <Button
            data-testid='configure-scanning-schedule'
            onClick={handleSubmit}
            isDisabled={targets.length < 1 || targetErrors.length > 0}
          >
            Configure
          </Button>
          <Button variant='secondary' onClick={cancel}>
            Cancel
          </Button>
        </StyledButtonContainer>
      </StyledFooter>
    </Fragment>
  );
};
