import React, { Fragment } from 'react';
import CaseRow from './CaseRow';
import { RISK_STATES, STATUS } from '../../Constants/Risks';
import ActionList from '../Risks/ActionList';

const RISK_INITIAL_FILTERS = {
  order: 'issueLevel',
  direction: 'desc',
  page: 0,
  limit: 10,
  fromLevel: 4,
  toLevelInclusive: 10,
  source: ['sensor', 'reach', 'agent'],
  status: [STATUS.ACTIVE, STATUS.INACTIVE],
  state: [
    RISK_STATES.OPEN,
    RISK_STATES.IN_PROGRESS,
    RISK_STATES.ACKNOWLEDGED,
    RISK_STATES.FAILED_VALIDATION,
    RISK_STATES.FIXED,
  ],
  assigned: false,
};

const CaseManagement = () => {
  return (
    <Fragment>
      <CaseRow />
      <ActionList
        title='Unassigned Risks'
        description={'The risks which are not assigned to any plan.'}
        initialFilters={RISK_INITIAL_FILTERS}
        filterDescription={
          <>
            You can use filters to adjust the items in the table. For example,
            select or deselect a Source to show or hide table entries for that
            scan type.
          </>
        }
        unassigned
      />
    </Fragment>
  );
};

export default CaseManagement;
