import React from 'react';
import { createCaseThunk } from '../../Global/caseActions';
import {
  Button,
  Field,
  HStack,
  Select,
  TextField,
  TextareaField,
  VStack,
} from '@rtkwlf/fenrir-react';
import { useAppDispatch } from '../../hooks';
import { ModalBox } from '../../Reusables/ModalBox';

const SEVERITIES = ['High', 'Medium', 'Low'];
const SEVERITY_OPTIONS = SEVERITIES.map((s) => ({
  text: s,
  value: s,
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateCase: React.FC<Props> = ({ isOpen, onClose }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [severity, setSeverity] = React.useState<string>(
    SEVERITY_OPTIONS[0].value
  );

  React.useEffect(() => {
    // reset form when modal is re-opened
    setTitle('');
    setDescription('');
    setSeverity(SEVERITY_OPTIONS[0].value);
  }, [isOpen]);

  const formComplete = title && description && severity;

  const dispatch = useAppDispatch();

  const onOk = React.useCallback(() => {
    if (title && description && severity) {
      dispatch(createCaseThunk({ title, description, severity: severity }));
      onClose();
    }
  }, [description, dispatch, onClose, severity, title]);

  return (
    <ModalBox
      onCloseModal={onClose}
      isOpen={isOpen}
      title='Create Plan'
      width='520px'
      footer={
        <HStack yAlign='center' xAlign='right' gap='xsmall'>
          <Button onClick={onOk} isDisabled={!formComplete}>
            Create Plan
          </Button>
        </HStack>
      }
    >
      <VStack paddingX='large' paddingBottom='large' gap='xsmall'>
        <Field.Root appearance='neutral'>
          <Field.Label>Plan</Field.Label>
          <Field.Content>
            <TextField
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              data-lpignore='true'
              id='caseTitle'
            />
          </Field.Content>
        </Field.Root>

        <Field.Root appearance='neutral'>
          <Field.Label marginTop='medium'>Description</Field.Label>
          <Field.Content>
            <TextareaField
              value={description}
              data-lpignore='true'
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(e.target.value)
              }
              id='caseDescription'
            />
          </Field.Content>
        </Field.Root>

        <Field.Root appearance='neutral'>
          <Field.Label marginTop='medium'>Severity</Field.Label>
          <Field.Content>
            <Select
              placeholder='Select a severity...'
              options={SEVERITY_OPTIONS}
              value={severity}
              onValueChange={(value) => setSeverity(value)}
            />
          </Field.Content>
        </Field.Root>
      </VStack>
    </ModalBox>
  );
};

export default CreateCase;
