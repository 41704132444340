import { HStack, Primitive, VStack } from '@rtkwlf/fenrir-react';
import { useRemediationDetails } from '../use-remediation-details/use-remediation-details';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { ThreeDots } from 'react-loader-spinner';
import { BLUE } from '../../../Constants/Styles';
import { toastr } from 'react-redux-toastr';

type RemediationDetailsProps = {
  customerId: string;
  riskId: string;
};

const StyledData = styled(Primitive.div)`
  font-family: Lato;
  font-style: normal;
  font-weight: 320;
  font-size: 1rem;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #000204;
  word-break: break-word;
  margin-bottom: unset;
`;

const StyledLink = styled(Primitive.a)`
  color: #23618e;
  text-decoration: underline;
  word-break: break-word;
  &:hover i {
    color: #0a58ca;
  }
`;

const NA = 'N/A';

export const RemediationDetails = ({
  customerId,
  riskId,
}: RemediationDetailsProps) => {
  const { data, isFetching, isError } = useRemediationDetails(
    customerId,
    riskId,
    (data: string[]) => {
      return data;
    }
  );
  const isUrl = (str: string) => {
    return str.startsWith('https') || str.startsWith('http');
  };

  if (!isFetching && isError) {
    toastr.error('Failed to fetch agent remediation details', '');

    return (
      <Primitive.div data-testid='remediation-details-error'>
        <VStack gap='zero' marginBottom='smedium'>
          <StyledData>{NA}</StyledData>
        </VStack>
      </Primitive.div>
    );
  }

  if (!isFetching && !isError && data === undefined) {
    return (
      <Primitive.div data-testid='remediation-details-undefined'>
        <VStack gap='zero' marginBottom='smedium'>
          <StyledData>{NA}</StyledData>
        </VStack>
      </Primitive.div>
    );
  }

  if (
    !isFetching &&
    !isError &&
    data !== undefined &&
    data.length === 1 &&
    data[0] === ''
  ) {
    return (
      <Primitive.div data-testid='remediation-details-no-data'>
        <VStack gap='zero' marginBottom='smedium'>
          <StyledData>{NA}</StyledData>
        </VStack>
      </Primitive.div>
    );
  }

  return (
    <Primitive.div data-testid='remediation-details'>
      {!isFetching && data !== undefined ? (
        <VStack gap='zero' marginBottom='small'>
          {data.map((row: string, index: number) => (
            <Primitive.div key={index} width='full'>
              {isUrl(row) ? (
                <StyledLink href={row} target='_blank' rel='noreferrer'>
                  <HStack
                    marginBottom='small'
                    gap='small'
                    justifyContent='space-between'
                  >
                    {row}
                    <FontAwesomeIcon icon={faExternalLink} />
                  </HStack>
                </StyledLink>
              ) : (
                <StyledData>{row}</StyledData>
              )}
            </Primitive.div>
          ))}
        </VStack>
      ) : (
        <HStack xAlign='center' yAlign='center'>
          <ThreeDots color={BLUE.tertiary} height={50} width={50} />
        </HStack>
      )}
    </Primitive.div>
  );
};
