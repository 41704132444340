import React from 'react';
import {
  Button,
  HStack,
  Text,
  Tooltip,
  useToastContext,
} from '@rtkwlf/fenrir-react';
import { ThreeDots } from 'react-loader-spinner';
import { BLUE } from '../Constants/Styles';
import { TOASTS } from './IvaScanControlMessages';
import { ModalBox } from './ModalBox';

export type Props = {
  modalTitle: string;
  modalSentenceOne: string;
  modalSentenceTwo: string;
  buttonText: string;
  showModal: boolean;
  isLoading: boolean;
  warning: string;
  modalClose: () => void;
  modalConfirm: () => Promise<boolean[]>;
  stopScanClick: () => void;
};

export const ScanningDisableControls: React.FC<Props> = ({
  modalTitle,
  modalSentenceOne,
  modalSentenceTwo,
  buttonText,
  showModal,
  modalClose,
  modalConfirm,
  stopScanClick,
  warning,
  isLoading,
}) => {
  const { addToast } = useToastContext();

  const stopAllScans = async () => {
    // get request statuses
    const [scheduledStopped, scansStopped] = await modalConfirm();

    // close modal
    modalClose();

    // set notifications once both requests are resolved
    if (scansStopped && scheduledStopped) {
      addToast(TOASTS.ALL_SUCCESS);
    } else if (scheduledStopped) {
      addToast(TOASTS.SCHEDULE_SUCCESS);
      addToast(TOASTS.SCAN_FAIL);
    } else if (scansStopped) {
      addToast(TOASTS.SCAN_SUCCESS);
      addToast(TOASTS.SCHEDULE_FAIL);
    } else {
      addToast(TOASTS.ALL_FAIL);
    }
  };

  return (
    <>
      <ModalBox
        title={modalTitle}
        onCloseModal={modalClose}
        isOpen={showModal}
        width='520px'
        footer={
          <HStack xAlign='right' gap='xsmall' height='2rem'>
            {!isLoading && (
              <>
                <Button
                  data-testid='stop-all-modal-confirm'
                  appearance='danger'
                  iconLeft={<i className='fa fa-stop-circle' />}
                  onClick={stopAllScans}
                  hidden={isLoading}
                >
                  {buttonText}
                </Button>
                <Button
                  data-testid='stop-all-modal-cancel'
                  onClick={modalClose}
                  variant='secondary'
                  hidden={isLoading}
                >
                  Cancel
                </Button>
              </>
            )}
          </HStack>
        }
      >
        <HStack paddingX='large' paddingBottom='large'>
          {isLoading ? (
            <ThreeDots color={BLUE.tertiary} height={50} width={50} />
          ) : (
            <Text styledAs='body1'>
              <div>{modalSentenceOne}</div>
              <div>{modalSentenceTwo}</div>
              <br />
              <div>{warning}</div>
            </Text>
          )}
        </HStack>
      </ModalBox>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Button
            onClick={() => stopScanClick()}
            key='Stop'
            data-testid='stop-all-scans'
            appearance='danger'
            iconLeft={<i className='fa fa-stop-circle' />}
          >
            Stop All Scan Schedules
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content maxWidth='12rem'>
          <Tooltip.Text>
            Disabling a scan schedule will stop currently running scans.
          </Tooltip.Text>
        </Tooltip.Content>
      </Tooltip.Root>
    </>
  );
};
