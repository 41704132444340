import React, { useState, useMemo } from 'react';

import AssetTable from '../../AssetTable';
import { ColumnProps, SortState } from '../../../../Reusables/table/Table';

import { sortData } from '../../utils';
import { AGENT_OS } from '../../../../Constants/Assets';
import {
  WindowsWirelessType,
  MacWirelessType,
  LinuxWirelessType,
  WirelessNetworkByOS,
} from './parser';

const MAC_WIRELESS_COLUMNS: ColumnProps<MacWirelessType>[] = [
  {
    key: 'ssidname',
    dataIndex: 'ssidname',
    title: 'SSID Name',
    width: 10,
    sortable: true,
  },
  {
    key: 'security',
    dataIndex: 'security',
    title: 'Security',
    width: 7,
    sortable: true,
  },
  {
    key: 'iscurrent',
    dataIndex: 'iscurrent',
    title: 'Is Current',
    width: 7,
    sortable: true,
  },
  { key: 'mode', dataIndex: 'mode', title: 'Mode', width: 7, sortable: true },
  {
    key: 'bssid',
    dataIndex: 'bssid',
    title: 'BSSID',
    width: 7,
    sortable: true,
  },
  {
    key: 'noise',
    dataIndex: 'noise',
    title: 'Noise',
    width: 7,
    sortable: true,
  },
  {
    key: 'signal',
    dataIndex: 'signal',
    title: 'Signal',
    width: 7,
    sortable: true,
  },
  {
    key: 'channel',
    dataIndex: 'channel',
    title: 'Channel',
    width: 7,
    sortable: true,
  },
  {
    key: 'country',
    dataIndex: 'country',
    title: 'Country',
    width: 7,
    sortable: true,
  },
  {
    key: 'networktype',
    dataIndex: 'networktype',
    title: 'Network Type',
    width: 7,
    sortable: true,
  },
  {
    key: 'transmitrate',
    dataIndex: 'transmitrate',
    title: 'Transmit Rate',
    width: 7,
    sortable: true,
  },
  {
    key: 'mcsindex',
    dataIndex: 'mcsindex',
    title: 'MCS Index',
    width: 7,
    sortable: true,
  },
];

const LINUX_WIRELESS_COLUMNS: ColumnProps<LinuxWirelessType>[] = [
  { key: 'message', dataIndex: 'message', title: 'Message', sortable: true },
  { key: 'networks', dataIndex: 'networks', title: 'Networks' },
];

const WINDOWS_WIRELESS_COLUMNS: ColumnProps<WindowsWirelessType>[] = [
  { key: 'ssidName', dataIndex: 'ssidName', title: 'Name', sortable: true },
  {
    key: 'networkType',
    dataIndex: 'networkType',
    title: 'Network Type',
    sortable: true,
  },
  {
    key: 'authentication',
    dataIndex: 'authentication',
    title: 'Authentication',
    sortable: true,
  },
  {
    key: 'encryption',
    dataIndex: 'encryption',
    title: 'Encryption',
    sortable: true,
  },
];

const COLUMNS_BY_OS = {
  [AGENT_OS.WINDOWS]: WINDOWS_WIRELESS_COLUMNS,
  [AGENT_OS.MACOS]: MAC_WIRELESS_COLUMNS,
  [AGENT_OS.LINUX]: LINUX_WIRELESS_COLUMNS,
};

export type Props<OS extends AGENT_OS> = {
  os: OS;
  wirelessData: WirelessNetworkByOS[OS][];
  isLoading: boolean;
};

const WirelessNetworksTable = <OS extends AGENT_OS>({
  isLoading,
  os,
  wirelessData,
}: Props<OS>) => {
  type DataType = WirelessNetworkByOS[OS];
  type WirelessField = keyof DataType;

  const [sortCol, setSortCol] = useState<WirelessField>();
  const [sortState, setSortState] = useState<SortState>('none');

  const handleSort = (col: string, state: SortState) => {
    setSortCol(col as WirelessField);
    setSortState(state);
  };

  const data = useMemo(() => {
    if (!sortCol || sortState === 'none') {
      return wirelessData;
    }

    return sortData<WirelessNetworkByOS[OS]>(
      sortState === 'dsc',
      sortCol as WirelessField,
      [...wirelessData]
    );
  }, [wirelessData, sortCol, sortState]);

  return (
    <AssetTable
      data={data}
      columns={COLUMNS_BY_OS[os] as unknown as ColumnProps<DataType>[]}
      isLoading={isLoading}
      handleSort={handleSort}
      isSearch
    />
  );
};

export default WirelessNetworksTable;
