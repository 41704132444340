//TODO: refactor this file e.g. relocate typings

import { ScoutAuditType } from '../../../../types/assets';
import {
  AGENT_AUDIT_RULE_ID_BY_OS,
  AGENT_AUDIT_RULE_ID_MAP,
  AGENT_OS,
  AuditCategory,
} from '../../../../Constants/Assets';
import { RsLocalDateFromStr } from '../utils';

// Types of parsed audit data, everything here should map to a rule in AGENT_AUDIT_RULE_ID

// Windows

export type WindowsTaskListType = {
  processName: string;
  pid: string;
  handleCount: string;
  workingSetSize: number;
  sessionId: string;
  threadCount: string;
  priority: string;
};

export type WindowsWirelessType = {
  ssidName: string;
  networkType: string;
  authentication: string;
  encryption: string;
};

export type WindowsWirelessDataType = {
  name: string;
  networkType: string;
  authentication: string;
  encryption: string;
};

export type WindowsSoftware = {
  name: string;
  version: string;
  vendor: string;
  installDate: string;
  installLocation: string;
  installSource: string;
};

export type WindowsUSBDeviceType = {
  name: string;
  deviceID: string;
  status: string;
};

export type WindowsHardware = {
  hostName: string;
  osName: string;
  osVersion: string;
  osManufacturer: string;
  osConfiguration: string;
  osBuildType: string;
  registeredOwner: string;
  registeredOrganization: string;
  productId: string;
  originalInstallDate: string;
  systemBootTime: string;
  systemManufacturer: string;
  pageFileLocation: string;
  domain: string;
  loginServer: string;
  hotfixes: string;
  numberHotFixes: string;
  networkCards: string;
  numberNetworkCards: string;
  hyperVRequirements: string;
  systemModel: string;
  systemType: string;
  processor: string;
  numberProcessor: string;
  biosVersion: string;
  windowsDirectory: string;
  systemDirectory: string;
  bootDevice: string;
  systemLocale: string;
  inputLocale: string;
  timeZone: string;
  totalPhysicalMemory: string;
  availablePhysicalMemory: string;
  virtualMaxMemory: string;
  virtualAvailMemory: string;
  virtualInuseMemory: string;
};
// Mac

export type MacSoftware = {
  name: string;
  version: string;
  obtainedFrom: string;
  lastModified: string;
  kind: string;
  intel64Bit: string;
  signedBy: string;
  location: string;
};

export type MacWirelessType = {
  ssidName: string;
  security: string;
  isCurrent: string;
  mode: string;
  bssid: string;
  noise: string;
  signal: string;
  channel: string;
  countryCode: string;
  networkType: string;
  transmitRate: string;
  mcsIndex: string;
};

export type MacWirelessDataType = {
  ssidname: string;
  security: string;
  iscurrent: string;
  mode: string;
  bssid: string;
  noise: string;
  signal: string;
  channel: string;
  country: string;
  networktype: string;
  transmitrate: string;
  mcsindex: string;
};

export type MacUSBDeviceType = {
  name?: string;
  productId?: string;
  vendorId?: string;
  version?: string;
  serialNumber?: string;
  speed?: string;
  manufacturer?: string;
  capacity?: string;
  removableMedia?: string;
  bsdName?: string;
  logicalUnit?: string;
  partitionMapType?: string;
  content?: string;
};

export type MacHardware = {
  computerName: string;
  userName: string;
  timeSinceBoot: string;
  systemVersion: string;
  systemIntegrityProtection: string;
  smcVersion: string;
  serialNumber: string;
  secureVirtualMemory: string;
  l3Cache: string;
  l2Cache: string;
  kernelVersion: string;
  hardwareUuid: string;
  bootVolume: string;
  bootRomVersion: string;
  bootMode: string;
  processorSpeed: string;
  processorName: string;
  hostnumberOfProcessorsName: string;
  numberOfCores: string;
  modelName: string;
  modelIdentifier: string;
  memory: string;
};

// Linux

export type LinuxMacTaskListType = {
  tt: string;
  pid: string;
  rss: string;
  vsz: string;
  pcpu: string;
  pmem: string;
  ppid: string;
  stat: string;
  time: string;
  command: string;
  started?: string;
};

export type LinuxSoftware = {
  name: string;
  version: string;
  arch: string;
  summary: string;
};

export type LinuxWirelessType = {
  message: string;
  networks: string;
};

export type LinuxUSBDeviceType = {
  bus: string;
  device: string;
  id: string;
  name: string;
};

export type LinuxHardware = {
  osName: string;
  osVersion: string;
  systemManufacturer: string;
  timeSinceBoot: string;
  serialNumber: string;
  cacheSize: string;
  hardwareUuid: string;
  mobo: string;
  boardManufacturer: string;
  processorName: string;
  processorSpeed: string;
  processorCores: string;
  processorGeneration: string;
  processorManufacturer: string;
  processorModel: string;
  biosVendor: string;
  biosVersion: string;
  biosReleaseDate: string;
};

// maps

export type TaskListByOS = {
  [AGENT_OS.LINUX]: LinuxMacTaskListType;
  [AGENT_OS.MACOS]: LinuxMacTaskListType;
  [AGENT_OS.WINDOWS]: WindowsTaskListType;
};

export type WirelessNetworkByOS = {
  [AGENT_OS.LINUX]: LinuxWirelessType;
  [AGENT_OS.MACOS]: MacWirelessDataType;
  [AGENT_OS.WINDOWS]: WindowsWirelessDataType;
};

export type SoftwareByOS = {
  [AGENT_OS.LINUX]: LinuxSoftware;
  [AGENT_OS.MACOS]: MacSoftware;
  [AGENT_OS.WINDOWS]: WindowsSoftware;
};

export type USBDevicesByOS = {
  [AGENT_OS.LINUX]: LinuxUSBDeviceType;
  [AGENT_OS.MACOS]: MacUSBDeviceType;
  [AGENT_OS.WINDOWS]: WindowsUSBDeviceType;
};

type HardwareByOS = {
  [AGENT_OS.LINUX]: LinuxSoftware;
  [AGENT_OS.MACOS]: MacHardware;
  [AGENT_OS.WINDOWS]: WindowsHardware;
};

export type ParsedAudit<OS extends AGENT_OS> = {
  os: OS;
  taskList: { data: TaskListByOS[OS][]; auditTime: string };
  wirelessNetwork: {
    data: (WindowsWirelessDataType | MacWirelessDataType | LinuxWirelessType)[];
    auditTime: string;
  };
  software: {
    data: (WindowsSoftware | MacSoftware | LinuxSoftware)[];
    auditTime: string;
  };
  usbDevices: { data: USBDevicesByOS[OS][]; auditTime: string };
  hardware: HardwareByOS[OS];
};

export type AllParsedAudit =
  | ParsedAudit<AGENT_OS.WINDOWS>
  | ParsedAudit<AGENT_OS.LINUX>
  | ParsedAudit<AGENT_OS.MACOS>
  | undefined;

/**
 * Given agent audit records, find out the OS. Returns undefined if it cannot be determined.
 * This is done by checking if any known ruleId of a particular OS is seen in the audit records.
 * e.g. if an audit is found with rule WindowsTaskList(100051), we assume this is OS windows
 *
 * The prerequisite of this method is that each agent can only contain audits of a single OS.
 */
const getOSFromAudit = (data: ScoutAuditType[]): AGENT_OS | undefined => {
  // look at the ruleIds in the agent audit data
  // whenever we see a ruleId that belongs to an OS, assume that is the agent's OS
  const ruleIds = data.map((audit) => audit.ruleId);

  if (
    AGENT_AUDIT_RULE_ID_BY_OS[AGENT_OS.WINDOWS].some((windowsRuleId) =>
      ruleIds.find((ruleId) => ruleId === windowsRuleId)
    )
  ) {
    return AGENT_OS.WINDOWS;
  }

  if (
    AGENT_AUDIT_RULE_ID_BY_OS[AGENT_OS.LINUX].some((linuxRuleId) =>
      ruleIds.find((ruleId) => ruleId === linuxRuleId)
    )
  ) {
    return AGENT_OS.LINUX;
  }

  if (
    AGENT_AUDIT_RULE_ID_BY_OS[AGENT_OS.MACOS].some((macRuleId) =>
      ruleIds.find((ruleId) => ruleId === macRuleId)
    )
  ) {
    return AGENT_OS.MACOS;
  }

  return undefined;
};

const parseWirelessNetworkData = <OS extends AGENT_OS>(
  os: OS,
  wirelessJsonLog: string
): WirelessNetworkByOS[AGENT_OS][] => {
  const dataArr = JSON.parse(wirelessJsonLog);
  const wirelessData: WirelessNetworkByOS[AGENT_OS][] = [];

  switch (os) {
    case AGENT_OS.WINDOWS:
      if (dataArr.networks) {
        dataArr.networks.forEach((wirelessNetworkObj: WindowsWirelessType) => {
          wirelessData.push({
            name: wirelessNetworkObj.ssidName,
            networkType: wirelessNetworkObj.networkType,
            authentication: wirelessNetworkObj.authentication,
            encryption: wirelessNetworkObj.encryption,
          });
        });
      }

      return wirelessData;
    case AGENT_OS.MACOS:
      if (dataArr.networks) {
        dataArr.networks.forEach((wirelessNetworkObj: MacWirelessType) => {
          wirelessData.push({
            ssidname: wirelessNetworkObj.ssidName,
            security: wirelessNetworkObj.security,
            iscurrent: wirelessNetworkObj.isCurrent,
            mode: wirelessNetworkObj.mode,
            bssid: wirelessNetworkObj.bssid,
            noise: wirelessNetworkObj.noise,
            signal: wirelessNetworkObj.signal,
            channel: wirelessNetworkObj.channel,
            country: wirelessNetworkObj.countryCode,
            networktype: wirelessNetworkObj.networkType,
            transmitrate: wirelessNetworkObj.transmitRate,
            mcsindex: wirelessNetworkObj.mcsIndex,
          });
        });
      }

      return wirelessData;
    case AGENT_OS.LINUX:
      if (dataArr.networks) {
        // linux Wireless Networks
        const networkString: Array<string> = [];
        for (let e = 0; e < dataArr.networks.length; e++) {
          networkString.push(JSON.stringify(dataArr.networks[e]));
        }

        wirelessData.push({
          message: dataArr.message,
          networks: networkString.join(','),
        });
      }

      return wirelessData;
    default:
      return wirelessData;
  }
};

const parseSoftwareNetworkData = <OS extends AGENT_OS>(
  os: OS,
  softwareJsonLog: string
): SoftwareByOS[AGENT_OS][] => {
  const dataArr = JSON.parse(softwareJsonLog);
  const softwareData: SoftwareByOS[AGENT_OS][] = [];

  if (dataArr) {
    switch (os) {
      case AGENT_OS.WINDOWS:
        dataArr.forEach((softwareObj: WindowsSoftware) => {
          if (Object.values(softwareObj).some((v) => !!v)) {
            softwareData.push(softwareObj);
          }
        });
        return softwareData;
      case AGENT_OS.MACOS:
        dataArr.forEach((softwareObj: MacSoftware) => {
          if (Object.values(softwareObj).some((v) => !!v)) {
            softwareData.push(softwareObj);
          }
        });
        return softwareData;
      case AGENT_OS.LINUX:
        dataArr.forEach((softwareObj: LinuxSoftware) => {
          if (Object.values(softwareObj).some((v) => !!v)) {
            softwareData.push(softwareObj);
          }
        });
        return softwareData;
      default:
        return softwareData;
    }
  }

  return softwareData;
};

/**
 * Given an OS and the raw audit data, look up the corresponding rule IDs and build a ParsedAudit object.
 * TODO: confirm default value used when rule doesn't exist (array vs object vs null)
 */
const parseRawAudit = <OS extends AGENT_OS>(
  os: OS,
  audit: ScoutAuditType[]
): ParsedAudit<OS> => {
  const osRules = AGENT_AUDIT_RULE_ID_MAP[os];

  const taskListObj = audit.find(
    (a) => a.ruleId === osRules[AuditCategory.TASK_LIST]
  );
  const taskList = taskListObj
    ? {
        data: JSON.parse(taskListObj.jsonLog),
        auditTime: RsLocalDateFromStr(taskListObj.auditTime),
      }
    : { data: [], auditTime: '' }; // default to empty array

  const wirelessNetworkObj = audit.find(
    (a) => a.ruleId === osRules[AuditCategory.WIRELESS_NETWORK]
  );

  const wirelessNetwork = wirelessNetworkObj
    ? {
        data: parseWirelessNetworkData(os, wirelessNetworkObj.jsonLog),
        auditTime: RsLocalDateFromStr(wirelessNetworkObj.auditTime),
      }
    : { data: [], auditTime: '' }; // default to empty object

  const softwareObj = audit.find(
    (a) => a.ruleId === osRules[AuditCategory.SOFTWARE]
  );
  const software = softwareObj
    ? {
        data: parseSoftwareNetworkData(os, softwareObj.jsonLog),
        auditTime: RsLocalDateFromStr(softwareObj.auditTime),
      }
    : { data: [], auditTime: '' }; // default to empty array

  const usbDevicesObj = audit.find(
    (a) => a.ruleId === osRules[AuditCategory.USB_DEVICES]
  );
  const usbDevices = usbDevicesObj
    ? {
        data: JSON.parse(usbDevicesObj.jsonLog),
        auditTime: RsLocalDateFromStr(usbDevicesObj.auditTime),
      }
    : { data: [], auditTime: '' }; // default to empty array

  const rawHardware =
    audit.find((a) => a.ruleId === osRules[AuditCategory.HARDWARE])?.jsonLog ??
    '{}'; // default to empty object

  return {
    os,
    taskList,
    wirelessNetwork,
    software,
    usbDevices,
    hardware: JSON.parse(rawHardware),
  };
};

/**
 * Given the raw data from agent audit API and the OS name of the asset,
 * determine the OS of this agent (one of AGENT_OS) and build ParsedAudit
 */
export const parseAgentAudit = (
  audit: ScoutAuditType[] = [],
  assetOSName: string
): AllParsedAudit => {
  // determine OS
  // if assetOSName is 'macOS', we know it's mac; otherwise determine it based on audit records
  const os: AGENT_OS | undefined =
    assetOSName === 'macOS' ? AGENT_OS.MACOS : getOSFromAudit(audit);

  switch (os) {
    case AGENT_OS.WINDOWS:
      return parseRawAudit(AGENT_OS.WINDOWS, audit);
    case AGENT_OS.MACOS:
      return parseRawAudit(AGENT_OS.MACOS, audit);
    case AGENT_OS.LINUX:
      return parseRawAudit(AGENT_OS.LINUX, audit);
    default:
      return undefined;
  }
};
