import React from 'react';
import AscAgentTable from './AscAgentTable';
import {
  AscAgentInfo,
  getPercentageString,
  getScoutClientsNotInActiveBenchmarkGroup,
  getScoutClientsNotInActiveCVEGroup,
} from '../utils';
import { Accordion } from '../Accordion';
import { Client, ScoutData } from '../../../types/attackSurface';
import { useAttackSurfaceContext } from '../Context/AscContext';

export type Agent = {
  deviceName: string;
};

const createVulnerabilityAgentArray = (
  total: number,
  scouts: ScoutData
): AscAgentInfo[] => {
  const agentList: Client[] = getScoutClientsNotInActiveCVEGroup(scouts);
  const agentCount = agentList.length;
  const agentPercent = getPercentageString(agentCount, total);

  return [
    {
      agentList: agentList.map((agent) => agent.deviceName),
      agentCount,
      agentPercent,
    },
  ];
};

const createBenchmarkAgentArray = (
  total: number,
  scouts: ScoutData
): AscAgentInfo[] => {
  const agentList: Client[] = getScoutClientsNotInActiveBenchmarkGroup(scouts);
  const agentCount = agentList.length;
  const agentPercent = getPercentageString(agentCount, total);

  return [
    {
      agentList: agentList.map((agent) => agent.deviceName),
      agentCount,
      agentPercent,
    },
  ];
};

const AgentNestedRenderer: React.FC = () => {
  const { scouts } = useAttackSurfaceContext();
  const count = Object.keys(scouts.clients).length;
  const vulnerabilityData = createVulnerabilityAgentArray(count, scouts);
  const vulnerabilityCount = getScoutClientsNotInActiveCVEGroup(scouts).length;
  const benchmarkCount =
    getScoutClientsNotInActiveBenchmarkGroup(scouts).length;
  const benchmarkData = createBenchmarkAgentArray(count, scouts);

  const AgentItems = [
    {
      title: `Not Being Scanned - Vulnerability (${vulnerabilityCount})`,
      node: (
        <div data-testid={`agent-schedule-container`}>
          <AscAgentTable ascAgentArray={vulnerabilityData} />
        </div>
      ),
    },
    {
      title: `Not Being Scanned - Benchmark (${benchmarkCount})`,
      node: (
        <div data-testid={`agent-schedule-container`}>
          <AscAgentTable ascAgentArray={benchmarkData} />
        </div>
      ),
    },
  ];

  return <Accordion childrenNodes={AgentItems} />;
};

export { AgentNestedRenderer };
