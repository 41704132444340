import { SEVERITY_COLOR } from './Styles';

export const ASSET_CATEGORIES_LABEL = 'Asset Categories';
export const ASSET_CATEGORIES_TOOLTIP =
  'High level asset categories discovered by the sensor';
export const ASSET_FILTERS_LABEL = 'Filters';
export const ASSET_FILTERS_TOOLTIP =
  'You can use filters to adjust the items in the table. For example, select or deselect a Source to show or hide table entries for that scan type..';
export const ASSET_PROFILE_LABEL = 'Asset Profile';

export const EXPORT_CSV_TOOLTIP = 'Download CSV';

export const CRITICALITY_MAP: {
  [id: number]: { value: string; icon: string; color: string };
} = {
  0: {
    value: 'Unassigned',
    icon: 'fa-minus',
    color: SEVERITY_COLOR.unassigned,
  },
  1: {
    value: 'None',
    icon: 'fa-check',
    color: SEVERITY_COLOR.none,
  },
  2: {
    value: 'Low',
    icon: 'fa-arrow-down',
    color: SEVERITY_COLOR.low,
  },
  3: {
    value: 'Medium',
    icon: 'fa-chevron-up',
    color: SEVERITY_COLOR.medium,
  },
  4: {
    value: 'High',
    icon: 'fa-arrow-up',
    color: SEVERITY_COLOR.high,
  },
  5: {
    value: 'Critical',
    icon: 'fa-exclamation',
    color: SEVERITY_COLOR.critical,
  },
};

export const CRITICALITY_VALUE_MAP: {
  [criticalityString: string]: number;
} = {
  unassigned: 0,
  none: 1,
  low: 2,
  medium: 3,
  high: 4,
  critical: 5,
};

// map old severity color codes to new fenrir named colors
type Color_Map_Colors =
  | 'accentRedSecondary'
  | 'accentOrangeSecondary'
  | 'accentYellowDefault'
  | 'accentBlueSecondary'
  | 'accentGreenSecondary'
  | 'widgetTertiary';

type Color_Map = {
  [id: string]: { description: string; namedColor: Color_Map_Colors };
};

export const FENRIR_COLOR_MAP: Color_Map = {
  '#F18C7E': {
    description: 'critical',
    namedColor: 'accentRedSecondary',
  },
  '#F9B479': {
    description: 'high',
    namedColor: 'accentOrangeSecondary',
  },
  '#F2D600': {
    description: 'medium',
    namedColor: 'accentYellowDefault',
  },
  '#9ED7FF': {
    description: 'low',
    namedColor: 'accentBlueSecondary',
  },
  '#81CA72': {
    description: 'none',
    namedColor: 'accentGreenSecondary',
  },
  '#F1F1F1': {
    description: 'unassigned',
    namedColor: 'widgetTertiary',
  },
};
// end fenrir color map

export declare type SortState = 'none' | 'asc' | 'dsc';

export declare type ItemKeyType = string | number;

export const TROUBLESHOOTING_WARNING_ONE =
  'This action should only be used in an emergency.';
export const TROUBLESHOOTING_WARNING_TWO =
  'This action turns off Host Identification Scans and Vulnerability Scans.';
export const TROUBLESHOOTING_WARNING_THREE = 'This action cannot be undone.';
