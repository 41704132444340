import {
  ADD_NOTE,
  CLEAR_ASSIGNED_RISKS,
  REMOVE_RISK,
  SET_ACTION_LIST,
  SET_ASSIGNED_RISKS,
  SET_NOTES,
  UPDATE_RISK,
  SET_ASSIGNMENT,
  BULK_UPDATE_RISK,
} from './riskActions';
import { iRiskAssignment } from './caseReducer';
import { iUpdateAssignmentAction } from './caseActions';
import { CaseActionTypes } from './actionEnums';
import { iState } from '../configureStore';
import { TagType } from './assetTags';
import { LocationState } from 'redux-first-router';
import { RoutesKey } from '../routesMap';
import { Observation } from '../types/risks';

export interface iRiskMeta {
  next: string;
  pageSize: number;
  prev: string;
  total: number;
}

export interface iMetas {
  risk: iRiskMeta;
  mitigated: iRiskMeta;
  assigned: iRiskMeta;
}

export enum RiskType {
  assignedRisks = 'assignedRisks',
  mitigatedRisks = 'mitigatedRisks',
  risks = 'risks',
}

export type iRiskState = {
  [RiskType.assignedRisks]: Map<string, iRisk>;
  [RiskType.mitigatedRisks]: Map<string, iRisk>;
  [RiskType.risks]: Map<string, iRisk>;
  meta: iMetas;
};

export interface iRiskAttributes {
  acknowledged: boolean;
  assignment?: iRiskAssignment;
  caseId?: string;
  clientUUID?: string;
  cveList: Array<string>;
  deviceID: string;
  hostDisplayName: string;
  scannerID: string;
  status: string;
  statusReason: string;
  uniformID: string;
  firstIdentified?: string;
  resolvedTime?: string;
}

export interface iRisk {
  attributes: iRiskAttributes;
  assetID: string;
  comments?: any;
  createTime?: string;
  customerID: string;
  deploymentID: string;
  endTime?: string;
  host: string;
  hostAnnotations: Array<any>;
  hostType: string;
  id: string;
  issueAction: string;
  issueCategory: string;
  issueDescription: string;
  issueID: string;
  issueImpact: string;
  issueLevel: number;
  issueName: string;
  issueRecommendations?: Array<any>;
  issueReferences?: Array<any>;
  issueSource: string;
  lastModifiedTime?: any;
  lastModifiedUser: string;
  notes: string;
  observations?: Array<Observation>;
  resource?: any;
  source: string;
  state: string;
}

export interface RiskWithAssetInfo extends iRisk {
  assetTags?: TagType[];
  assetCriticality?: number;
  assetOS?: string;
  assetCategory?: string;
}

const initialState: iRiskState = {
  assignedRisks: new Map(),
  meta: {
    // unused
    risk: {} as iRiskMeta,
    // unused
    mitigated: {} as iRiskMeta,

    // used by ActionList ( NOT related to assigned risks )
    assigned: {} as iRiskMeta,
  },
  risks: new Map(),
  mitigatedRisks: new Map(),
};

export const riskReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  const newState: iRiskState = { ...state };
  let risk: iRisk;
  switch (type) {
    case CLEAR_ASSIGNED_RISKS:
      newState.assignedRisks = new Map();
      return newState;
    case SET_ASSIGNED_RISKS:
      payload.risks.forEach((risk: iRisk) => {
        newState.assignedRisks.set(risk.id, risk);
      });
      return newState;
    case SET_ACTION_LIST:
      newState.risks = new Map();
      payload.risks.forEach((risk: iRisk) => {
        newState.risks.set(risk.id, risk);
      });
      newState.meta.assigned = payload.meta;
      return newState;
    case UPDATE_RISK:
      // @ts-ignore
      newState[payload.key] = new Map(newState[payload.key]);
      // @ts-ignore
      newState[payload.key].set(payload.risk.id, payload.risk);
      return newState;
    case BULK_UPDATE_RISK:
      newState.risks = new Map(newState.risks);
      // @ts-ignore
      payload.risks.forEach((risk) => {
        // @ts-ignore
        newState.risks.set(risk.id, risk);
      });
      return newState;
    case SET_ASSIGNMENT:
      // @ts-ignore
      newState[payload.key] = new Map(newState[payload.key]);
      // @ts-ignore
      payload.risks.forEach((response) => {
        const { assignment, risk: riskData } = response;
        const { id } = riskData;
        // @ts-ignore
        risk = newState[payload.key].get(id);
        // @ts-ignore
        newState[payload.key].set(id, {
          ...risk,
          attributes: {
            ...risk.attributes,
            assignment: {
              ...risk.attributes.assignment,
              ...assignment,
            },
          },
        });
      });
      return newState;
    case REMOVE_RISK:
      // @ts-ignore
      newState[payload.key].delete(payload.riskId);
      return newState;
    case SET_NOTES:
      // @ts-ignore
      risk = newState[payload.riskType].get(payload.riskId);
      // @ts-ignore
      newState[payload.riskType].set(payload.riskId, {
        ...risk,
        comments: payload.comments,
      });
      return newState;
    case ADD_NOTE:
      // @ts-ignore
      risk = newState[payload.type].get(payload.riskId);
      // @ts-ignore
      newState[payload.type].set(payload.riskId, {
        ...risk,
        comments: [...risk.comments, payload.comment],
      });
      return newState;
    case CaseActionTypes.UPDATE_ASSIGNMENT: {
      const { riskType, riskId, caseId, assignment } = (
        action as iUpdateAssignmentAction
      ).payload;
      const oldMap = newState[riskType];
      const oldRisk = oldMap.get(riskId);

      if (!oldRisk) {
        // invalid action payload, do nothing
        return state;
      }

      const newMap = new Map(oldMap);
      newMap.set(payload.riskId, {
        ...oldRisk,
        attributes: {
          ...oldRisk.attributes,
          caseId,
          assignment: {
            ...oldRisk.attributes.assignment,
            ...assignment,
          },
        },
      });
      return { ...newState, [riskType]: newMap };
    }
    case CaseActionTypes.REMOVE_ASSIGNMENT:
      // @ts-ignore
      risk = newState[payload.riskType].get(payload.riskId);
      // @ts-ignore
      newState[payload.riskType].set(payload.riskId, {
        ...risk,
        attributes: {
          ...risk.attributes,
          assignment: {},
        },
      });
      return newState;
    case CaseActionTypes.REMOVE_RISK_FROM_CASE:
      // @ts-ignore
      const updatedRisk = { ...newState[payload.riskType].get(payload.riskId) };
      delete updatedRisk['caseId'];
      // @ts-ignore
      newState[payload.riskType].set(payload.riskId, updatedRisk);
      return newState;
    default:
      return state;
  }
};

export const riskSelectors = {
  getLocation: (
    store: iState
  ): LocationState<{}, iState> & { type: RoutesKey } => store.location,
  getAssignedRiskMap: (store: iState): Map<string, iRisk> =>
    store.risk.assignedRisks,
  getRisksMap: (store: iState): Map<string, iRisk> => store.risk.risks,
};
