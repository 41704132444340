import moment from 'moment';
import { SEVERITY_COLOR } from '../Constants/Styles';
import { riskScorePriority } from '../Pages/Assets/utils';

type TimeMetricsType = {
  resolutionDate: string;
  age: string;
  daysToResolution: string;
};

const NA = 'N/A';

export const getRiskColor = (riskScore: number) => {
  if (riskScore >= riskScorePriority.critical) {
    return SEVERITY_COLOR.critical;
  }
  if (riskScore >= riskScorePriority.high) {
    return SEVERITY_COLOR.high;
  }
  if (riskScore >= riskScorePriority.medium) {
    return SEVERITY_COLOR.medium;
  }
  return SEVERITY_COLOR.low;
};

export const getTimeMetrics = (
  firstIdentifiedTime: string | undefined,
  dateOfResolution: string | undefined
) => {
  const timeMetrics: TimeMetricsType = {
    resolutionDate: NA,
    age: NA,
    daysToResolution: NA,
  };

  if (dateOfResolution) {
    timeMetrics.resolutionDate = dateOfResolution;
  }

  if (firstIdentifiedTime) {
    const identifiedDate = moment(firstIdentifiedTime);
    const ageInDays = moment().diff(identifiedDate, 'days');
    timeMetrics.age = `${ageInDays} day(s)`;
  }

  if (firstIdentifiedTime && dateOfResolution) {
    const identifiedDate = moment(firstIdentifiedTime);
    const resolvedDate = moment(dateOfResolution);
    const resolutionInDays = resolvedDate.diff(identifiedDate, 'days');
    timeMetrics.daysToResolution = `${resolutionInDays} day(s)`;
  }

  return timeMetrics;
};
